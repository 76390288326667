import {
  ECBox,
  ECCollapseDrawerItem,
  ECDrawer,
  ECDrawerItem,
  ECIconButton,
  ECList,
} from '../../components';
import {
  Speed,
  BarChart,
  ManageAccounts,
  LocationOn,
  Build,
  Contacts,
  Description,
  MonetizationOn,
  HomeRepairService,
  MapsHomeWork,
  Policy,
  SettingsSuggest,
  DoubleArrow,
  Login,
  CalendarToday,
  VerifiedUser,
  Notifications,
  Construction,
  Engineering,
  LibraryAdd,
  Calculate,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { usePanelDrawerExpandedItem } from './context/panel-drawer-expanded-item.context';
import { usePanelDrawerSecondLevelExpandedItem } from './context/panel-drawer-second-level-expanded-item.context';
import { useTranslation } from 'react-i18next';
import { P, CompanyConfigurationFields } from 'types/Permission';
import { hasPermission } from 'utils/permissions';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';

export const PanelDrawer = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const {
    state: { openSecondLevelDrawer, expandedItemTitle },
    dispatch,
  } = usePanelDrawerExpandedItem();
  const { dispatch: dispatchSecondLevel } =
    usePanelDrawerSecondLevelExpandedItem();

  const { data: userData, isSuccess } = useGetUserProfileQuery();

  const [userType, setUserType] = useState<string>('');

  useEffect(() => {
    if (isSuccess && Array.isArray(userData?.permissions)) {
      const isServiceProvider = hasPermission({
        userPermissions: userData.permissions?.map(p => p.action),
        scopes: [P.SPMenu],
      });
      setUserType(isServiceProvider ? 'service_provider' : 'customer');
    }
  }, [userData, isSuccess]);

  const [hasGetInventoryPermission] = useHasPermission([P.GetInventory]);
  const [hasGetInventoryStoragePermission] = useHasPermission([
    P.GetInventoryStorage,
  ]);
  const [hasGetSupplierPermission] = useHasPermission([P.GetSupplier]);
  const [hasGetPurchaseOrderPermission] = useHasPermission([
    P.GetPurchaseOrder,
  ]);

  const handleDrawerClose = () => {
    dispatch({ type: 'hideSideMenu' });
    dispatch({ type: 'closeDrawer' });
    setIsOpen(false);
  };

  const handleDrawerOpen = () => {
    dispatch({ type: 'hideSideMenu' });
    if (expandedItemTitle === t('translation:panelDrawer.configuration')) {
      dispatch({ type: 'close' });
    }
    dispatch({ type: 'openDrawer' });
    setIsOpen(true);
  };

  useEffect(() => {
    dispatchSecondLevel({ type: 'close' });
  }, [openSecondLevelDrawer, dispatchSecondLevel]);

  useEffect(() => {
    if (
      !!expandedItemTitle &&
      expandedItemTitle !== t('translation:panelDrawer.configuration')
    ) {
      dispatch({ type: 'openDrawer' });
      setIsOpen(true);
    }
  }, [expandedItemTitle, t]);

  if (!userType) {
    return <></>;
  }

  return userType === 'service_provider' ? (
    <>
      <ECDrawer
        PaperProps={{
          sx: theme => ({
            backgroundColor: `${theme.palette.marketingSuggestion.marketingSuggestion} !important`,
            borderRight: 1,
            borderColor: 'white',
          }),
        }}
        variant="permanent"
        open={isOpen}
      >
        <ECList
          sx={{
            borderTop: 8,
            borderColor: 'white',
            marginTop: '3.5rem',
          }}
        >
          <ECBox display="flex" justifyContent={isOpen ? 'flex-end' : 'center'}>
            {isOpen ? (
              <ECIconButton
                sx={{
                  color: theme => theme.palette.background.paper,
                  transform: 'rotate(180deg)',
                }}
                onClick={handleDrawerClose}
              >
                <DoubleArrow />
              </ECIconButton>
            ) : (
              <ECIconButton
                sx={{ color: theme => theme.palette.background.paper }}
                onClick={handleDrawerOpen}
              >
                <DoubleArrow />
              </ECIconButton>
            )}
          </ECBox>
          <ECDrawerItem
            title={t('translation:panelDrawer.reports')}
            icon={<BarChart />}
            uri={'reports'}
            open={isOpen}
            scopes={[P.GetQsReport]}
            scores={[P.GetQsReport]}
          />
          <ECDrawerItem
            title={t('translation:panelDrawer.workOrders')}
            icon={<Contacts />}
            uri={'sp/work-orders'}
            open={isOpen}
            scopes={[P.GetAllWorkOrders]}
            scores={[P.GetAllWorkOrders]}
          />
          <ECDrawerItem
            title={t('translation:panelDrawer.invoices')}
            icon={<MonetizationOn />}
            uri={'sp/invoices'}
            open={isOpen}
            scopes={[P.GetAllInvoices]}
            scores={[P.GetAllInvoices]}
          />
          <ECDrawerItem
            title={t('translation:panelDrawer.proposals')}
            icon={<Description />}
            uri={'sp/proposals'}
            open={isOpen}
            scopes={[P.GetAllProposals]}
            scores={[P.GetAllProposals]}
          />
          <ECDrawerItem
            title={t('translation:panelDrawer.inventoryAndParts.inventory')}
            icon={<Construction />}
            uri={'sp/inventory'}
            isRootMenu
            open={isOpen}
            scopes={[P.GetInventory]}
            scores={[P.GetInventory]}
            companyConfigScopes={[CompanyConfigurationFields.EnableInventory]}
          />
          <ECDrawerItem
            title={t('translation:panelDrawer.requestsForPricing')}
            icon={<LibraryAdd />}
            uri={'sp/rfps'}
            open={isOpen}
            scores={[P.GetAllRequestsForPricing]}
            scopes={[P.GetAllRequestsForPricing]}
          />
          <ECDrawerItem
            title={t('translation:panelDrawer.customers')}
            icon={<HomeRepairService />}
            uri={'sp/customers'}
            open={isOpen}
            scopes={[P.GetAllCustomers]}
            scores={[P.GetAllCustomers]}
          />
          <ECDrawerItem
            title={'Impersonate'}
            icon={<Login />}
            uri={'impersonate'}
            open={isOpen}
            scopes={[P.UserOverride]}
            scores={[P.UserOverride]}
          />
          <ECCollapseDrawerItem
            isDrawerOpen={isOpen}
            text={t('translation:panelDrawer.configuration')}
            icon={<SettingsSuggest />}
            onClick={handleDrawerOpen}
          >
            <ECList noPadding>
              <ECDrawerItem
                title={t('translation:panelDrawer.userManagement')}
                uri={'sp/configuration/user-management'}
                open={isOpen}
              />
              <ECDrawerItem
                title={t('translation:panelDrawer.profile')}
                uri={'sp/configuration/profile'}
                open={isOpen}
              />
              <ECDrawerItem
                title={t('translation:panelDrawer.generalSettings')}
                uri={'sp/configuration/general-settings'}
                open={isOpen}
              />
            </ECList>
          </ECCollapseDrawerItem>
        </ECList>
      </ECDrawer>
    </>
  ) : (
    <>
      <ECDrawer
        PaperProps={{
          sx: theme => ({
            backgroundColor: `${theme.palette.marketingSuggestion.marketingSuggestion} !important`,
            borderRight: 1,
            borderColor: 'white',
            display: 'flex',
            flex: 1,
          }),
        }}
        variant="permanent"
        open={isOpen}
      >
        <ECList
          sx={{
            borderTop: 8,
            borderColor: 'white',
            marginTop: '3.5rem',
          }}
        >
          <ECBox display="flex" justifyContent={isOpen ? 'flex-end' : 'center'}>
            {isOpen ? (
              <ECIconButton
                sx={{
                  color: theme => theme.palette.background.paper,
                  transform: 'rotate(180deg)',
                }}
                onClick={handleDrawerClose}
              >
                <DoubleArrow />
              </ECIconButton>
            ) : (
              <ECIconButton
                sx={{ color: theme => theme.palette.background.paper }}
                onClick={handleDrawerOpen}
              >
                <DoubleArrow />
              </ECIconButton>
            )}
          </ECBox>
          <ECDrawerItem
            title={t('translation:panelDrawer.dashboard')}
            icon={<Speed />}
            uri={'dashboard'}
            open={isOpen}
          />
          <ECDrawerItem
            title={t('translation:panelDrawer.reports')}
            icon={<BarChart />}
            uri={'reports'}
            open={isOpen}
            scopes={[P.GetQsReport]}
            scores={[P.GetQsReport]}
          />
          <ECDrawerItem
            title={t('translation:panelDrawer.scheduleLog')}
            icon={<CalendarToday />}
            uri={'schedule-log'}
            open={isOpen}
            scopes={[P.GetScheduledTask]}
            scores={[P.GetScheduledTask]}
          />
          <ECDrawerItem
            title={t('translation:panelDrawer.workOrders')}
            icon={<Contacts />}
            uri={'work-orders'}
            open={isOpen}
            scopes={[P.GetAllWorkOrders]}
            scores={[P.GetAllWorkOrders]}
          />
          <ECDrawerItem
            title={t('translation:panelDrawer.invoices')}
            icon={<MonetizationOn />}
            uri={'invoices'}
            open={isOpen}
            scopes={[P.GetAllInvoices]}
            scores={[P.GetAllInvoices]}
          />
          <ECDrawerItem
            title={t('translation:panelDrawer.proposals')}
            icon={<Description />}
            uri={'proposals'}
            open={isOpen}
            scopes={[P.GetAllProposals]}
            scores={[P.GetAllProposals]}
          />
          <ECDrawerItem
            title={t('translation:panelDrawer.requestsForPricing')}
            icon={<LibraryAdd />}
            uri={'rfps'}
            open={isOpen}
            scopes={[P.GetAllRequestsForPricing]}
            scores={[P.GetAllRequestsForPricing]}
          />
          <ECDrawerItem
            title={t('translation:panelDrawer.assets')}
            icon={<Build />}
            uri={'assets/managed-assets'}
            open={isOpen}
            scopes={[P.GetAllAssets]}
            scores={[P.GetAllAssets]}
            isRootMenu
          />
          <ECDrawerItem
            title={t(
              'translation:panelDrawer.inventoryAndParts.inventoryAndParts',
            )}
            icon={<Construction />}
            uri={'inventory-parts'}
            open={isOpen}
            isRootMenu
            scopesOptional={[
              P.GetInventory,
              P.GetSupplier,
              P.GetInventoryStorage,
              P.GetPurchaseOrder,
            ]}
            scores={[
              P.GetInventory,
              P.GetSupplier,
              P.GetInventoryStorage,
              P.GetPurchaseOrder,
            ]}
          />
          <ECDrawerItem
            title={t('translation:panelDrawer.dispatch')}
            uri={'dispatch'}
            icon={<Engineering />}
            scopes={[P.GetDispatch]}
            scores={[P.GetDispatch]}
            companyConfigScopes={[CompanyConfigurationFields.EnableDispatch]}
            open={isOpen}
          />
          <ECDrawerItem
            title={t('translation:panelDrawer.branches')}
            icon={<MapsHomeWork />}
            uri={'locations'}
            open={isOpen}
            scopes={[P.GetAllBranches]}
            scores={[P.GetAllBranches]}
          />
          <ECDrawerItem
            title={t('translation:panelDrawer.serviceProviders')}
            icon={<HomeRepairService />}
            uri="service-providers/service-providers"
            open={isOpen}
            scopes={[P.GetAllServiceProviders]}
            scores={[P.GetAllServiceProviders]}
            isRootMenu
          />
          <ECDrawerItem
            title={t('translation:panelDrawer.preventiveMaintenance')}
            icon={<Policy />}
            uri={'pms'}
            open={isOpen}
            scopes={[P.GetPreventiveMaintenancesList]}
            scores={[P.GetPreventiveMaintenancesList]}
          />
          <ECDrawerItem
            title={'Impersonate'}
            icon={<Login />}
            uri={'impersonate'}
            open={isOpen}
            scopes={[P.UserOverride]}
            scores={[P.UserOverride]}
          />
          <ECDrawerItem
            title={'Notifications Control'}
            icon={<Notifications />}
            uri={'notifications'}
            open={isOpen}
            scopes={[P.SearchGenericNotification]}
            scores={[P.SearchGenericNotification]}
          />
          {/* /* removed on DEV-13527  <ECDrawerItem
            title={t('translation:panelDrawer.admin')}
            icon={<AdminPanelSettings />}
            uri={'admin'}
            open={isOpen}
          /> */}
          <ECDrawerItem
            title={'Asset Verification'}
            icon={<VerifiedUser />}
            uri={'asset-verification'}
            open={isOpen}
            scopes={[P.AssetVerification]}
            scores={[P.AssetVerification]}
          />
          <ECCollapseDrawerItem
            isDrawerOpen={isOpen}
            text={t('translation:panelDrawer.configuration')}
            icon={<SettingsSuggest />}
            onClick={handleDrawerOpen}
          >
            <ECList noPadding>
              <ECDrawerItem
                title={t('translation:panelDrawer.users')}
                icon={<ManageAccounts />}
                uri="configuration/users/management"
                scopesOptional={[P.GetAllUsers, P.GetAllJobTitle]}
                scores={[P.GetAllUsers, P.GetAllJobTitle]}
                open={isOpen}
              />
              <ECDrawerItem
                title={t('translation:panelDrawer.accounting')}
                icon={<Calculate />}
                uri="configuration/accounting/exports"
                scopesOptional={[P.ViewLambdaExports, P.GetGlCodes]}
                scores={[P.ViewLambdaExports, P.GetGlCodes]}
                open={isOpen}
              />
              <ECDrawerItem
                title={t('translation:panelDrawer.locationManagement')}
                icon={<LocationOn />}
                uri="configuration/location-management/concepts"
                open={isOpen}
              />
              <ECDrawerItem
                title={t('translation:panelDrawer.assetWorkflow')}
                uri={'configuration/asset-workflow/asset-types'}
                scopesOptional={[
                  P.GetAllAssetTypes,
                  P.GetAllAssetTypesWithNames,
                  P.GetAllTrades,
                  P.GetAllProblemTypes,
                  P.GetAllRepairTypes,
                  P.GetAllAssetTypesWithRepairs,
                ]}
                scores={[
                  P.GetAllAssetTypes,
                  P.GetAllAssetTypesWithNames,
                  P.GetAllTrades,
                  P.GetAllProblemTypes,
                  P.GetAllRepairTypes,
                  P.GetAllAssetTypesWithRepairs,
                ]}
                open={isOpen}
              />
              <ECDrawerItem
                title={t('translation:panelDrawer.troubleshooting')}
                uri={'configuration/troubleshooting/library'}
                scopesOptional={[
                  P.GetAllTroubleshoot,
                  P.GetAllAssetGroupProblemTroubleshoot,
                ]}
                scores={[
                  P.GetAllTroubleshoot,
                  P.GetAllAssetGroupProblemTroubleshoot,
                ]}
                open={isOpen}
              />
              <ECDrawerItem
                title={t('translation:panelDrawer.approval')}
                uri={'configuration/approval'}
                scopes={[P.GetAllApproval]}
                scores={[P.GetAllApproval]}
                open={isOpen}
              />
              <ECDrawerItem
                title={t('translation:panelDrawer.generalSettings')}
                uri={'configuration/general-settings/settings'}
                open={isOpen}
              />
              {/* removed on DEV-13527 <ECDrawerItem
                title={t('translation:panelDrawer.companyProfile')}
                uri={'configuration/company-profile'}
                scopes={[P.GetOrgTreeByCompany]}
                open={isOpen}
              /> */}
              <ECDrawerItem
                title={t('translation:panelDrawer.profile')}
                uri={'configuration/profile'}
                scopes={[P.GetOrg]}
                scores={[P.GetOrg]}
                open={isOpen}
              />
            </ECList>
          </ECCollapseDrawerItem>
        </ECList>
      </ECDrawer>
    </>
  );
};
