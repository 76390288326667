import { InputAdornment } from '@mui/material';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { ECTextField } from '../ECTextField';
import { ECIconButton } from '../ECIconButton';
import { Clear } from '@mui/icons-material';

interface Props {
  onChange?: (event, value: number | string | undefined) => void;
  value?: number | string;
  format?: 'currency' | 'percentage';
  variant?: 'standard' | 'filled';
  disabled?: boolean;
  label?: string;
  style?: CSSProperties;
  readOnly?: boolean;
  minimumValue?: number | string;
  error?: boolean;
  baseSx?: any;
  validationMessage?: string;
  maxLength?: number;
  maxValue?: number;
  hiddenLabel?: boolean;
  defaultValue?: number | string;
  id: string;
  name?: string;
  InputProps?: any;
  placeholder?: string;
  disableUnderline?: boolean;
}

export default function ECCurrencyField({ onChange, ...props }: Props) {
  const {
    id,
    name,
    variant = 'standard',
    error,
    value,
    format = 'currency',
    style,
    baseSx,
    validationMessage,
    maxLength,
    readOnly,
    maxValue,
    hiddenLabel = false,
    defaultValue,
    InputProps,
    placeholder,
    disableUnderline,
  } = props;

  const [isMyInputFocused, setIsMyInputFocused] = useState(false);
  const runOnce = useRef(true);

  const handleChange = useCallback(
    e => {
      const eventTargetValue = e.target.value;

      if (
        Number(eventTargetValue) === Number(value) &&
        eventTargetValue?.length === value?.toString()?.length
      )
        return;

      // if it is not a number, do not change the value
      if (isNaN(eventTargetValue) && eventTargetValue !== '') return;

      let newValue: string | undefined = undefined;

      if (format === 'currency') {
        newValue =
          eventTargetValue === ''
            ? ''
            : eventTargetValue
                ?.replace(/[^0-9.]/g, '')
                .match(/^\d+(?:\.\d{0,2})?/)?.[0] || eventTargetValue;

        if (newValue && +newValue > (maxValue || 999999.99)) return;
      } else if (format === 'percentage') {
        newValue =
          eventTargetValue === ''
            ? ''
            : eventTargetValue
                ?.replace(/[^0-9.]/g, '') // Allow digits and decimal points
                .match(/^\d+(?:\.\d{0,2})?/)?.[0]; // Match up to 2 decimal places

        if (newValue && +newValue > 999.99) return; // Limit percentage to 999.99
      }
      onChange?.(e, newValue);
    },
    [maxValue, onChange, format],
  );

  useEffect(() => {
    if (typeof value === 'number' && runOnce.current) {
      handleChange({ target: { value: value.toString() } });
      runOnce.current = false;
    }
  }, [value]);

  return (
    <ECTextField
      {...props}
      id={id}
      name={id}
      // logic below is 1 - if input is not focused, always validate
      // 2 - if input is focused, validate only if readOnly is true so it doesnt change value after clicking tablecell
      value={
        readOnly && format === 'percentage'
          ? `${validateValue(value, format)} %` // Append % directly to the value if readOnly and format is percentage
          : !isMyInputFocused
            ? validateValue(value, format)
            : readOnly
              ? validateValue(value, format)
              : value || ''
      }
      onBlur={() => setIsMyInputFocused(false)}
      onFocus={e => {
        setIsMyInputFocused(true);

        if (format === 'percentage' && (value == 0 || value === '')) {
          // Select all text if value is zero or empty
          e.target.select();
        }
      }}
      error={error}
      helperText={validationMessage ? validationMessage : null}
      onChange={handleChange}
      variant={variant}
      sx={{
        ...baseSx,
        ...style,
        cursor: readOnly ? 'pointer !important' : 'auto',
      }}
      placeholder={placeholder ?? '0.00'}
      InputProps={{
        disableUnderline,
        startAdornment:
          format === 'currency' ? (
            <InputAdornment position="start">$</InputAdornment>
          ) : undefined,
        endAdornment:
          readOnly && format === 'percentage' ? undefined : ( // Do not show endAdornment if readOnly and format is percentage
            <>
              {format === 'percentage' && (
                <InputAdornment position="end">%</InputAdornment>
              )}
              {(isMyInputFocused || value) && !readOnly && (
                <InputAdornment position="end">
                  <ECIconButton
                    onClick={() => {
                      handleChange({ target: { value: '' } });
                    }}
                    sx={{ padding: 0 }}
                  >
                    <Clear />
                  </ECIconButton>
                </InputAdornment>
              )}
            </>
          ),
        ...InputProps,
      }}
      inputProps={{
        maxLength: maxLength || 10,
        readOnly,
      }}
      hiddenLabel={hiddenLabel}
    />
  );
}

const validateValue = (
  value: string | number | undefined,
  format: 'percentage' | 'currency',
) => {
  if (format === 'currency') {
    return validateCurrency(value);
  } else if (format === 'percentage') {
    return value ? value.toString() : '';
  }
  return value;
};

const validateCurrency = value => {
  if (value === 0) return '0.00';
  if (!value) return '';

  const isNegativeNumber = Number(value) < 0;

  let newValue = `${value}`.replace(/[^0-9.,]/g, '');

  if (newValue.split('.')[1]?.length !== 2) {
    newValue = isNegativeNumber
      ? `${(Math.floor(Math.abs(Number(newValue)) * 100) * -1) / 100}`
      : `${Number(newValue).toFixed(2)}`;
  }

  if (newValue.includes('.')) {
    const splittedValue = newValue.split('.');
    newValue = `${Number(splittedValue[0].replaceAll(',', ''))
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${
      splittedValue[1]
        ? `.${splittedValue[1].replaceAll(',', '').substring(0, 2)}`
        : '.'
    }`;
  } else {
    newValue = `${Number(newValue.replaceAll(',', ''))
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }

  if (newValue.charAt(0) === '.') newValue = '0' + newValue;

  if (newValue.length > 2 && newValue.charAt(0) === '0') {
    newValue = '0' + newValue;
  }

  if (isNegativeNumber) {
    newValue = `-${newValue}`;
  }

  return newValue;
};
