import {
  Attachment,
  AttachmentDelete,
  AttachmentDocumentType,
  AttachmentList,
  AttachmentListParams,
  AttachmentPost,
  AttachmentRequiredDocsParams,
  AttachmentUpdate,
} from 'types/Attachment';
import { spProfileModules } from '../utils/common';

import { emptyApi } from './emptyApi';
import { QueryParams } from 'types/QueryParams';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['Attachment', 'AttachmentReports'],
});

export const attachmentApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    uploadAttachment: builder.mutation<void, AttachmentPost>({
      query: body => {
        const formData = new FormData();
        const module = spProfileModules.includes(body.module)
          ? 'spcustomer'
          : body.module;
        formData.append('module', module?.trim());
        formData.append('moduleId', body.id.toString()?.trim());
        formData.append('description', body.description?.trim() || '');
        formData.append('isRestricted', body.isRestricted ? '1' : '0');
        formData.append(
          'documentCoreTypeId',
          body.documentCoreTypeId?.toString()?.trim() || '',
        );
        formData.append('expiresOn', body.expiresOn ? body.expiresOn : '');

        if (body.isReqDocExpirationDate) {
          formData.append('expires', body.isReqDocExpirationDate ? '1' : '0');
        } else {
          formData.append(
            'expires',
            body.expiresOn ? '1'?.trim() : '0'?.trim(),
          );
        }

        body.files.forEach(file => {
          formData.append('files', file);
        });
        if (body.documentRequiredId) {
          formData.append(
            'documentRequiredId',
            body.documentRequiredId?.toString(),
          );
        }

        return {
          url: body.isCustomerOwnRequiredDocs
            ? 'attachment/company/document-required'
            : 'attachment',
          method: 'post',
          body: formData,
        };
      },
      invalidatesTags: ['Attachment'],
    }),
    updateAttachment: builder.mutation<void, AttachmentUpdate>({
      query: ({ module, attachmentId, ...body }) => {
        if (!module || !attachmentId) {
          return {
            url: '',
          };
        }

        const moduleName = spProfileModules.includes(module)
          ? 'spcustomer'
          : module;

        if (body.isRequiredDoc) {
          const reqDocBody = {
            expires: body.isReqDocExpirationDate ? 1 : 0,
            documentCoreTypeId: body.documentCoreTypeId,
            description: body.description,
          };

          return {
            url: `attachment/company/document-required/${attachmentId}`,
            method: 'put',
            body: reqDocBody,
          };
        }

        delete body.isRequiredDoc;
        delete body.isReqDocExpirationDate;
        return {
          url: `attachment/${moduleName}/${attachmentId}`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['Attachment'],
    }),
    deleteAttachment: builder.mutation<void, AttachmentDelete>({
      query: ({
        module,
        moduleId,
        attachmentId,
        isCustomerOwnRequiredDocs,
      }) => {
        const moduleName = spProfileModules.includes(module)
          ? 'spcustomer'
          : module;

        return {
          url: isCustomerOwnRequiredDocs
            ? `attachment/company/document-required/${attachmentId}`
            : `attachment/${moduleName}/${moduleId}`,
          method: 'delete',
          body: [attachmentId],
        };
      },
      invalidatesTags: ['Attachment'],
    }),
    getAttachment: builder.query<AttachmentList, AttachmentListParams>({
      query: ({ module, moduleId, category, isCustomerRequiredDoc }) => {
        if (!module || !moduleId) {
          return {
            url: '',
          };
        }

        const moduleName = spProfileModules.includes(module)
          ? 'spcustomer'
          : module;

        return {
          url: isCustomerRequiredDoc
            ? `attachment/document-required/company/${moduleId}`
            : `/attachment/${moduleName}/${moduleId}/${category}`,
        };
      },
      transformResponse: (
        response: Attachment[] | any,
        _meta,
        args,
      ): AttachmentList => {
        if (args.isCustomerRequiredDoc) {
          const attachments = response
            .filter(res => res.attachment)
            .map(res => {
              const attachType = res.attachment.attachType?.name?.toLowerCase();
              return {
                ...res.attachment,
                url: res.attachment.name,
                attachmentType: attachType,
                fileType: attachType,
                type: attachType,
              };
            });

          return {
            thumbnails: [],
            others: attachments,
            moduleName: args.module,
          };
        } else {
          const thumbnailsAttachments = response.filter(attachment =>
            attachment.url.includes('thumbnail'),
          );
          const notThumbnailsAttachments = response.filter(
            attachment => !attachment.url.includes('thumbnail'),
          );

          return {
            thumbnails: thumbnailsAttachments,
            others: notThumbnailsAttachments,
            moduleName: args.module,
          };
        }
      },
      providesTags: ['Attachment'],
    }),
    downloadAttachment: builder.query<any, AttachmentListParams>({
      queryFn: async (
        { module, moduleId, fileName },
        _api,
        _extraOptions,
        baseQuery,
      ) => {
        const result = await baseQuery({
          url: `/attachment/${module}/${moduleId}`,
          responseHandler: response => response.blob(),
        });
        const hiddenElement = document.createElement('a');
        const url = window.URL || window.webkitURL;
        const blob = url.createObjectURL(result.data as any);

        hiddenElement.href = blob;
        hiddenElement.target = '_blank';
        hiddenElement.download = fileName || 'attachment';
        hiddenElement.click();

        return { data: null };
      },
    }),
    getAttachmentDocumentTypes: builder.query<AttachmentDocumentType[], string>(
      {
        query: module => {
          if (!module) {
            return {
              url: '',
            };
          }

          return {
            url: `/attachment/${module}/document/types`,
          };
        },
      },
    ),
    checkAllUplaodedRequiredDocsBySPAndCustomer: builder.query<
      AttachmentDocumentType[],
      AttachmentRequiredDocsParams
    >({
      query: ({ customerId, spId }) => {
        if (!customerId || !spId) {
          return {
            url: '',
          };
        }

        return {
          url: `attachment/document-required/company/${customerId}/sp/${spId}`,
        };
      },
      providesTags: ['Attachment'],
    }),
    getAttachmentReports: builder.query<any, QueryParams>({
      query: params => {
        return {
          url: 'attachment/reports',
          params,
        };
      },
      providesTags: ['AttachmentReports'],
    }),
  }),
});

export const {
  useGetAttachmentQuery,
  useLazyGetAttachmentQuery,
  useUploadAttachmentMutation,
  useUpdateAttachmentMutation,
  useDeleteAttachmentMutation,
  useDownloadAttachmentQuery,
  useLazyDownloadAttachmentQuery,
  useGetAttachmentDocumentTypesQuery,
  useLazyGetAttachmentDocumentTypesQuery,
  useCheckAllUplaodedRequiredDocsBySPAndCustomerQuery,
  useLazyCheckAllUplaodedRequiredDocsBySPAndCustomerQuery,
  useGetAttachmentReportsQuery,
  useLazyGetAttachmentReportsQuery,
} = attachmentApi;
