import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  ECBox,
  ECIconButton,
  ECInfiniteScroll,
  ECList,
  ECStack,
  ECTextField,
  ECTypography,
} from '..';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { ECAutocomplete } from '../ECAutocomplete';
import { useGetAllWorkflowStatusQuery } from 'services/workflowApi';
import { useLazyGetWorkorderDispatchColumnUnassignedViewQuery } from 'services/workOrdersApi';
import { DispatchWorkOrderCard } from 'app/pages/DispatchPage/DispatchSwimlanesView/DispatchWorkOrdersCard';
import SortableWorkOrderCardItem from 'app/pages/DispatchPage/DispatchSwimlanesView/SortableWorkOrderCardItem';
import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import _ from 'lodash';
import { WorkflowStatus } from '../ECWorkflowStatusBadge';

const unAssignedPanelName = 'unAssignedPanel';
const defaultStatus = WorkflowStatus.PendingAcceptance;

interface ECCalendarViewUnassignedTechPanelProps {
  onUnassignedWorkOrderDataUpdate?: (data: any) => void;
  dragPanelId?: string;
  isDraggingFinished?: boolean;
  isRenderDisabled?: boolean;
  unAssignedPanelData?: any[];
}

export const ECCalendarViewUnassignedTechPanel: React.FC<
  ECCalendarViewUnassignedTechPanelProps
> = ({
  onUnassignedWorkOrderDataUpdate,
  dragPanelId,
  isDraggingFinished,
  isRenderDisabled,
  unAssignedPanelData,
}) => {
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [searchTextBoxValue, setSearchTextBoxValue] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { setNodeRef } = useDroppable({ id: unAssignedPanelName });
  const [panelWOCards, setPanelWOCards] = useState<any[]>([]);

  const {
    data: workflowStatuses,
    isFetching: isFetchingWorkflowStatuses,
    isSuccess: isSuccessWorkflowStatuses,
  } = useGetAllWorkflowStatusQuery('workorder');

  const statusOptions = useMemo(() => {
    const uniqueStatuses = _.uniqBy(workflowStatuses, 'name');
    const orderedStatuses = _.orderBy(uniqueStatuses, 'name');
    return (
      orderedStatuses
        ?.map(status => ({
          id: status?.id,
          label: status.name,
          fieldName: workflowStatuses
            ?.filter(
              workflowStatus =>
                workflowStatus.name === status.name && workflowStatus.groupId,
            )
            ?.map(workflowStatus => workflowStatus.groupId)
            ?.join(','),
          isInitialSelected: status.name === defaultStatus,
        }))
        ?.filter(
          status =>
            status.label !== WorkflowStatus.Completed &&
            status.label !== WorkflowStatus.Canceled &&
            status.label !== WorkflowStatus.Unassigned &&
            status.label !== WorkflowStatus.Cancelled,
        ) ?? []
    );
  }, [workflowStatuses]);

  const [externalInfiniteScrollLoading, setExternalInfiniteScrollLoading] =
    useState(false);

  const handleChange = (event, newValue) => {
    setSelectedOption(newValue);
    setExternalInfiniteScrollLoading(true);
    setSearchTextBoxValue('');
    setSearchTerm('');
  };

  const handleSearchClicked = () => {
    setSearchTerm(searchTextBoxValue);
  };

  const renderItem = useMemo(
    () => (workorder, index) => {
      return (
        <SortableWorkOrderCardItem id={workorder?.id} key={workorder?.id}>
          <DispatchWorkOrderCard
            key={`${workorder?.id}-${workorder?.assetName}-${workorder?.locationName}`}
            workOrder={workorder ?? {}}
          />
        </SortableWorkOrderCardItem>
      );
    },
    [],
  );

  useEffect(() => {
    if (!selectedOption) {
      onUnassignedWorkOrderDataUpdate?.([]);
    }
  }, [selectedOption]);

  const handleUnassignedWorkOrderDataUpdate = useCallback(
    (newData: any[]) => {
      onUnassignedWorkOrderDataUpdate?.(newData);

      setPanelWOCards(prevWOCards => {
        const newUniqueTasks = newData.filter(
          item => !prevWOCards.some(prevItem => prevItem.id === item.id),
        );

        if (newUniqueTasks.length === 0) {
          return prevWOCards;
        }
        return [...prevWOCards, ...newUniqueTasks];
      });
    },
    [setPanelWOCards],
  );

  useEffect(() => {
    if (isSuccessWorkflowStatuses) {
      setSelectedOption(
        statusOptions.find(option => option.isInitialSelected) ?? null,
      );
    }
  }, [isSuccessWorkflowStatuses]);

  const queryParamsMemoized = useMemo(() => {
    if (dragPanelId && !isDraggingFinished) {
      return null;
    }
    const queryParams = {
      t: 10,
      o: 'd',
      ob: '',
      sb: 'wrkflwstts.name',
      s: defaultStatus,
    };

    if (selectedOption) {
      queryParams['sb'] = 'wrkflwstts.name';
      queryParams['s'] = selectedOption?.label;
      queryParams[`sg`] = 1;
      queryParams[`sglo`] = 'and';
    }
    const searchFields = [
      'wrkordr.id',
      'asst.name',
      'asst.id',
      'rqstctgry.name',
      'assttp.name',
      'brnch.id',
      'brnch.name',
      'brnch.storeNumber',
    ];

    if (searchTerm) {
      searchFields.forEach((field, index) => {
        queryParams[`sb${index + 1}`] = field;
        queryParams[`s${index + 1}`] = `*${searchTerm}*`;
        queryParams[`sg${index + 1}`] = 2;
        queryParams[`sglo${index + 1}`] = 'or';
      });
    }
    return queryParams;
  }, [selectedOption, searchTerm, dragPanelId, isDraggingFinished]);

  return (
    <ECBox
      sx={{
        height: '100%',
        boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
        backgroundColor: 'white',
        zIndex: 10,
        position: 'relative',
        width: '240px',
        minWidth: '240px',
      }}
    >
      <ECStack
        direction="column"
        border="1px solid #E0E0E0"
        borderRadius="4px"
        display="flex"
        justifyContent="space-between"
        bgcolor="#0072CE"
        minWidth="200px"
        sx={{ height: '56px' }}
      >
        <ECTypography
          variant="body2"
          color={theme => theme.palette.background.default}
          fontWeight="bold"
          fontSize={12}
          whiteSpace="nowrap"
          marginLeft="10px"
          lineHeight="24px"
        >
          No Technician Assigned
        </ECTypography>
        <hr
          color="#eeeeee"
          style={{
            width: '100%',
            margin: 0,
            border: 'none',
            borderTop: '1px solid #ffffff',
          }}
        />
        <ECAutocomplete
          id="wo-status-select"
          placeholder={defaultStatus}
          options={statusOptions}
          getOptionLabel={option => option?.label || option?.text || option}
          value={selectedOption}
          onChange={handleChange}
          onKeyDown={e => {
            e.preventDefault();
            return false;
          }}
          onPaste={e => {
            e.preventDefault();
          }}
          freeSolo={false}
          disableClearable
          sx={{
            backgroundColor: '#0072CE',
            height: '30px',
            '& .MuiAutocomplete-inputRoot': {
              padding: '0px',
              height: '30px',
            },
            '& .MuiInputBase-input': {
              color: theme => theme.palette.background.default,
              fontSize: '16px',
              fontWeight: 'bold',
              marginLeft: '8px',
              transform: 'translateY(-5px)',
              pointerEvents: 'auto',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiAutocomplete-paper': {
              backgroundColor: '#0072CE',
              zIndex: 1300,
              pointerEvents: 'auto',
            },
            '& .MuiAutocomplete-option': {
              pointerEvents: 'auto',
              color: theme => theme.palette.background.default,
            },
            '& .MuiIconButton-root': {
              color: theme => theme.palette.background.default,
            },
            '& .MuiAutocomplete-endAdornment': {
              color: theme => theme.palette.background.default,
            },
          }}
        />
      </ECStack>
      <ECBox p={1} height={'calc(100% - 56px)'}>
        <ECTextField
          fullWidth
          variant="filled"
          value={searchTextBoxValue}
          onChange={e => {
            setSearchTextBoxValue(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ transform: 'translateY(-5px)' }}
              >
                <ECIconButton
                  onClick={handleSearchClicked}
                  size="small"
                  sx={{
                    transform: 'translateY(-2px)',
                    fontSize: '24px',
                    width: '24px',
                    height: '24px',
                  }}
                >
                  <SearchIcon sx={{ fontSize: '24px' }} />
                </ECIconButton>
              </InputAdornment>
            ),
          }}
          placeholder="Search..."
          sx={{
            gap: '8px',
            borderRadius: '4px',
            padding: '4px 4px',
            '& .MuiInputBase-input': {
              padding: '4px 0',
              transform: 'translateY(-1px)',
              height: '36px',
            },
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              setSearchTerm(searchTextBoxValue);
            }
          }}
        />
        <SortableContext
          id="noTechAssigned-popover"
          items={panelWOCards}
          strategy={verticalListSortingStrategy}
        >
          <ECBox
            ref={setNodeRef}
            height="calc(100% - 43px)"
            sx={{
              position: 'relative',
              opacity: dragPanelId === unAssignedPanelName ? 0.6 : 1,
              filter:
                dragPanelId === unAssignedPanelName ? 'grayscale(40%)' : 'none',
            }}
          >
            <ECInfiniteScroll
              queryFn={useLazyGetWorkorderDispatchColumnUnassignedViewQuery}
              ContainerComponent={ECList}
              containerProps={{
                noPadding: true,
                height: '100%',
                overflow: 'auto',
              }}
              wrapperProps={{ height: '100%' }}
              queryParams={queryParamsMemoized}
              renderItem={renderItem}
              onDataUpdate={handleUnassignedWorkOrderDataUpdate}
              rerenderDisabled={!!dragPanelId || isRenderDisabled}
              externalData={unAssignedPanelData}
              customEmptyMessage="No records found"
              externalLoading={externalInfiniteScrollLoading}
              setExternalLoading={setExternalInfiniteScrollLoading}
            />
          </ECBox>
        </SortableContext>
      </ECBox>
    </ECBox>
  );
};
