const sessionToken = 'idToken';
const overrideUser = 'overrideUser';

export const findToken = () => {
  const token: string = localStorage.getItem(sessionToken) || '';
  return token;
};

export const findRefreshToken = () => {
  const token: string = localStorage.getItem('refreshToken') || '';
  return token;
};

export const findAccessToken = () => {
  const token: string = localStorage.getItem('accessToken') || '';
  return token;
};

export const isSSOUser = () => {
  const isSSO: string = localStorage.getItem('isSSO') || '0';
  return isSSO;
};

export const removeToken = () => {
  localStorage.removeItem(sessionToken);
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('sessionInfo');
  localStorage.removeItem('sessionInfoUserName');
  localStorage.removeItem('isSSO');
};

export const clearEverythingFromLogin = () => {
  localStorage.removeItem(sessionToken);
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('sessionInfo');
  localStorage.removeItem('sessionInfoUserName');
  localStorage.removeItem('userProfileLastCallTimestamp');
  localStorage.removeItem('userProfile');
  localStorage.removeItem('userProfileFromCache');
  localStorage.removeItem('userProfileLastCallTimestamp');
  localStorage.removeItem('companyProfile');
  localStorage.removeItem('companyProfileFromCache');
  localStorage.removeItem('companyProfileLastCallTimestamp');
  localStorage.removeItem('customSessionTimeoutTimestamp');
  localStorage.removeItem('customSSOSessionTimeoutTimestamp');
  removeToken();
  removeOverrideUser();
};

export const storeToken = token => {
  localStorage.setItem(sessionToken, token);
};

export const findOverrideUser = () => {
  const user: string = localStorage.getItem(overrideUser) || '';
  return user;
};

export const removeOverrideUser = () => {
  localStorage.removeItem(overrideUser);
};

export const storeOverrideUser = user => {
  localStorage.setItem(overrideUser, user);
};

export const convertRouteNameToFilterEndpoint = () => {
  return window.location.href
    ?.replaceAll('work-order', 'workorder')
    ?.replaceAll('general-asset', 'assetgeneral')
    ?.replaceAll('assets', 'asset')
    ?.replaceAll('allocated-to', 'inventoryAllocation');
};
/** Gets auth user id */
export const getAuthUserId = () => {
  const userProfile = localStorage.getItem('userProfile');
  return userProfile ? JSON.parse(userProfile).userId : null;
};
