import { ECBox, ECButton } from 'app/components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EditForm2 } from 'app/components/ECDynamicPageTemplate/EditForm2';
import { Edit } from '@mui/icons-material';
import { useGetCalculationMethodsQuery } from 'services/lookupApi';
import { populateDropDownFields } from 'utils/pageUtils';
import { useUpdateWORefrigerantTrackingMutation } from 'services/workOrdersApi';
import { WorkorderRefrigerationTracking } from 'types/WorkOrders';
import moment from 'moment';
import _ from 'lodash';

export interface RTCardProps {
  woId: string;
  initialData?: WorkorderRefrigerationTracking;
  shouldShowEditButton?: boolean;
  isEditButtonCentered?: boolean;
  isCustomer?: boolean;
  onButtonClick?: (boolean) => void;
  isVerificationAndTechnicianCompleted?: boolean;
}
const formElementsOfRTDetails = require('./fancy_form_config_RT_details.json');
const formElementsOfRTEdit = require('./fancy_form_config_RT_edit.json');

export const RTCard = ({
  woId,
  initialData,
  shouldShowEditButton = true,
  isEditButtonCentered,
  isCustomer,
  onButtonClick,
  isVerificationAndTechnicianCompleted,
}: RTCardProps) => {
  const marginBottom = useMemo(() => {
    return `${window.innerHeight - 400}px`;
  }, [window.innerHeight]);

  const offRefrigerantFields = [
    {
      type: 'switch',
      label: 'Did you use Refrigerant on this Work Order?',
      placeholder: 'Did you use Refrigerant on this Work Order?',
      fieldName: 'refrigerantUsedOnWorkOrder',
      switchActiveLabel: 'Yes',
      switchInactiveLabel: 'No',
      overrideRowValue: true,
      optionalSx: { marginBottom },
      value: 0,
    },
  ];

  const [formElementsOfRTDetailsFields, setFormElementsOfRTDetailsFields] =
    useState<any[]>(formElementsOfRTDetails?.fields);
  const [formElementsOfRTEditFields, setFormElementsOfRTEditFields] =
    useState<any[]>(offRefrigerantFields);
  const [updateRTMode, setUpdateRTMode] = useState(false);
  const [refrigerantUsedOnWorkOrder, setRefrigerantUsedOnWorkOrder] =
    useState(false);

  const {
    data: calculationMethodsData,
    isSuccess: isSuccessCalculationMethodData,
  } = useGetCalculationMethodsQuery();

  useEffect(() => {
    if (isSuccessCalculationMethodData && calculationMethodsData) {
      const initialFields = formElementsOfRTEdit?.fields?.find(
        field => field.groupName === 'initialFields',
      )?.subFields;
      const finalFields = formElementsOfRTEdit?.fields?.find(
        field => field.groupName === 'finalFields',
      )?.subFields;

      populateDropDownFields({
        responseData: calculationMethodsData,
        createFormFields: initialFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'initialVerification.id',
      });
      const initialVerificationMethodField = initialFields?.find(
        field => field.fieldName === 'initialVerification.id',
      );
      if (initialVerificationMethodField) {
        initialVerificationMethodField.value =
          initialData?.initialVerification?.id;
      }

      populateDropDownFields({
        responseData: calculationMethodsData,
        createFormFields: finalFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'finalVerification.id',
      });
      const finalVerificationMethodField = finalFields?.find(
        field => field.fieldName === 'finalVerification.id',
      );
      if (finalVerificationMethodField) {
        finalVerificationMethodField.value = initialData?.finalVerification?.id;
      }
    }
  }, [
    isSuccessCalculationMethodData,
    calculationMethodsData,
    formElementsOfRTEdit,
    initialData,
  ]);

  const [
    doUpdateWORefrigerantTracking,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset,
    },
  ] = useUpdateWORefrigerantTrackingMutation();

  useEffect(() => {
    if (isUpdateSuccess || isUpdateError) {
      reset();
    }
  }, [isUpdateSuccess, isUpdateError]);

  const newData = useMemo(() => {
    const currentRefrigerantUsed =
      isVerificationAndTechnicianCompleted || refrigerantUsedOnWorkOrder;

    formElementsOfRTDetailsFields.forEach(field => {
      if (field.fieldName !== 'refrigerantUsedOnWorkOrder') {
        field.visible = currentRefrigerantUsed;
      }
    });

    return {
      ...initialData,
      refrigerantUsedOnWorkOrder: currentRefrigerantUsed,
    };
  }, [
    initialData,
    refrigerantUsedOnWorkOrder,
    isVerificationAndTechnicianCompleted,
  ]);

  useEffect(() => {
    const initialFields = formElementsOfRTEdit?.fields?.find(
      field => field.groupName === 'initialFields',
    )?.subFields;
    const finalFields = formElementsOfRTEdit?.fields?.find(
      field => field.groupName === 'finalFields',
    )?.subFields;
    const initialVerificationDateField = initialFields?.find(
      field => field.fieldName === 'initialVerification.date',
    );
    const today = moment();

    if (initialVerificationDateField) {
      initialVerificationDateField.minDate = today;
    }
    const finalVerificationDateField = finalFields?.find(
      field => field.fieldName === 'finalVerification.date',
    );
    if (finalVerificationDateField) {
      finalVerificationDateField.minDate = today;
    }
  }, [initialData, formElementsOfRTEdit]);

  useEffect(() => {
    const circutListField = formElementsOfRTDetailsFields.find(
      field => field.fieldName === 'circuits',
    );
    if (circutListField) {
      circutListField.circuits = initialData?.circuits;
      circutListField.editMode = false;
    }
    setFormElementsOfRTDetailsFields([...formElementsOfRTDetailsFields]);
  }, [initialData, updateRTMode, formElementsOfRTDetails, isUpdateSuccess]);

  useEffect(() => {
    if (updateRTMode) {
      const circuitListField = formElementsOfRTEdit?.fields?.find(
        field => field.fieldName === 'circuits',
      );

      if (circuitListField) {
        circuitListField.circuits = initialData?.circuits?.map(circuit => {
          const match = circuit?.charge?.match(/(\d+)\s*lbs\s*(\d+)\s*oz/);
          const chargelbs = match?.[1] ? parseInt(match[1], 10) : '';
          const chargeoz = match?.[2] ? parseInt(match[2], 10) : '';

          return {
            ...circuit,
            repairOnThisCircuit: !!circuit.repaired,
            chargelbs,
            chargeoz,
          };
        });

        circuitListField.editMode = true;
      }
    }
  }, [updateRTMode, initialData, formElementsOfRTEdit, isUpdateSuccess]);

  const useUpdate = useCallback(() => {
    const doUpdate = async data => {
      if (data?.refrigerantUsedOnWorkOrder) {
        const dataCircuits = data?.circuits
          ?.filter(circuit => circuit?.changed)
          .map(
            ({ id, chargelbs, chargeoz, repaired, repairOnThisCircuit }) => ({
              id,
              chargePounds:
                repaired && !repairOnThisCircuit
                  ? ''
                  : _.isNil(chargelbs)
                    ? undefined
                    : Number(chargelbs),
              chargeOunces:
                repaired && !repairOnThisCircuit
                  ? ''
                  : _.isNil(chargeoz)
                    ? undefined
                    : Number(chargeoz),
              repaired: repaired && !repairOnThisCircuit ? 0 : 1,
            }),
          );
        delete data?.refrigerantUsedOnWorkOrder;
        doUpdateWORefrigerantTracking({
          ...data,
          id: woId,
          circuits: dataCircuits,
        });
      } else {
        const update = { id: 0 };
        doUpdateWORefrigerantTracking({
          ...update,
          id: woId,
        });
      }
      setRefrigerantUsedOnWorkOrder(false);
      onButtonClick?.(false);
    };

    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset,
      },
    ];
  }, [
    initialData,
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    reset,
    doUpdateWORefrigerantTracking,
  ]);
  const handleChange = useCallback(
    (output, fieldName) => {
      if (fieldName !== 'refrigerantUsedOnWorkOrder') return;

      const refrigerantField = output?.find(
        field => field.fieldName === 'refrigerantUsedOnWorkOrder',
      );

      setFormElementsOfRTEditFields(
        refrigerantField?.value
          ? [...formElementsOfRTEdit?.fields]
          : [...offRefrigerantFields],
      );
    },
    [formElementsOfRTEditFields],
  );

  return (
    <ECBox p={1} position="relative" sx={{ marginTop: '10px' }}>
      {(isCustomer
        ? true
        : (isEditButtonCentered && updateRTMode) ||
          isVerificationAndTechnicianCompleted) && (
        <ECBox display="flex" justifyContent="space-between">
          <EditForm2
            row={newData}
            showTitle={false}
            detailsConfig={formElementsOfRTDetails.config}
            detailsFields={formElementsOfRTDetailsFields}
            formConfig={formElementsOfRTEdit.config}
            formFields={formElementsOfRTEditFields}
            hideToolBar
            checkEqualFields={false}
            showEditButton={false}
            stickyFooter
            onlyEdit={updateRTMode}
            onClose={() => {
              setUpdateRTMode(false);
              setRefrigerantUsedOnWorkOrder(false);
              onButtonClick?.(false);
            }}
            useUpdateMutation={useUpdate}
            onChange={handleChange}
            showSaveButton
          />
        </ECBox>
      )}
      {shouldShowEditButton && (
        <ECBox
          position="absolute"
          top={isEditButtonCentered && !updateRTMode ? '500px' : 0}
          right={isEditButtonCentered && !updateRTMode ? '39%' : 0}
          zIndex={1}
        >
          <ECButton
            variant="contained"
            startIcon={<Edit />}
            onClick={() => {
              setRefrigerantUsedOnWorkOrder(true);
              setUpdateRTMode(true);
              setFormElementsOfRTEditFields(
                isVerificationAndTechnicianCompleted
                  ? [...formElementsOfRTEdit?.fields]
                  : [...offRefrigerantFields],
              );
              onButtonClick?.(true);
            }}
            disabled={updateRTMode}
            sx={{
              marginRight: 2,
              height: '42px',
              width: '300px',
              marginTop: '10px',
              whiteSpace: 'nowrap',
            }}
          >
            Update Refrigerant Details
          </ECButton>
        </ECBox>
      )}
    </ECBox>
  );
};
