import { Close } from '@mui/icons-material';
import { Popover } from '@mui/material';
import { ECBox, ECIconButton, ECTypography } from 'app/components';
import { ECAttachmentDetailsModal } from 'app/components/ECDynamicPageTemplate/components/ECAttachmentDetailsModal';
import { useState } from 'react';
import { useGetAttachmentQuery } from 'services/attachmentApi';
import { Attachment, AttachmentCategory } from 'types/Attachment';
import { AttachmentsListPopoverItem } from './AttachmentsListPopoverItem';

interface Props {
  moduleName: string;
  row?: any;
  isAttachmentOpen: boolean;
  anchorElAttachment: HTMLElement | null;
  onCloseAttachmentPopover: () => void;
  moduleId?: string;
  label?: string;
}

export const ECAttachmentsListPopover = ({
  row,
  isAttachmentOpen,
  anchorElAttachment,
  onCloseAttachmentPopover,
  moduleName,
  moduleId,
  label = 'Documents',
}: Props) => {
  const [editingAttachment, setEditingAttachment] = useState<Attachment | null>(
    null,
  );
  const [isAttachmentViewOpen, setIsAttachmentViewOpen] = useState(false);

  const idAttachment = isAttachmentOpen ? 'attachment-popover' : undefined;

  const { data: attachments } = useGetAttachmentQuery(
    {
      module: moduleName,
      moduleId: row?.id,
      category: AttachmentCategory.All,
    },
    {
      skip: !row?.id,
    },
  );

  const handleViewAttachment = attachment => {
    setEditingAttachment(attachment);
    setIsAttachmentViewOpen(true);
  };

  const handleCloseAttachmentModal = () => {
    setIsAttachmentViewOpen(false);
  };

  return (
    <>
      <Popover
        id={idAttachment}
        open={isAttachmentOpen}
        anchorEl={anchorElAttachment}
        onClose={onCloseAttachmentPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          minHeight: '300px',
          minWidth: '300px',
          maxHeight: '544px',
        }}
      >
        <ECBox
          minHeight={150}
          minWidth={550}
          sx={{
            '&::before': {
              backgroundColor: 'white',
              content: '""',
              display: 'block',
              position: 'absolute',
              width: 12,
              height: 12,
              top: -6,
              transform: 'rotate(45deg)',
              left: 'calc(50% - 6px)',
            },
          }}
          p={2}
        >
          <ECBox display="flex" flexDirection="column">
            <ECBox
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <ECTypography fontWeight="bold" variant="h6">
                {label} ({attachments?.others?.length})
              </ECTypography>

              <ECIconButton
                size="medium"
                withBorder
                onClick={onCloseAttachmentPopover}
              >
                <Close sx={theme => ({ color: theme.palette.grey[600] })} />
              </ECIconButton>
            </ECBox>
            {attachments?.others?.map((attachment: Attachment) => (
              <AttachmentsListPopoverItem
                attachment={attachment}
                onView={handleViewAttachment}
                moduleName={moduleName}
              />
            ))}
          </ECBox>
        </ECBox>
      </Popover>

      <ECAttachmentDetailsModal
        moduleName={moduleName}
        moduleId={typeof moduleId === 'string' ? moduleId : row?.id}
        attachment={editingAttachment!}
        isOpen={isAttachmentViewOpen}
        onCloseModal={handleCloseAttachmentModal}
      />
    </>
  );
};
