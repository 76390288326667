export const AmericanExpress = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
    >
      <rect
        x="1"
        y="0.5"
        width="34"
        height="23"
        rx="3.5"
        fill="#1F72CD"
        stroke="#D9D9D9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.18111 8.5L4 15.7467H7.80824L8.28035 14.5913H9.35949L9.8316 15.7467H14.0234V14.8649L14.3969 15.7467H16.5652L16.9387 14.8462V15.7467H25.6566L26.7166 14.6213L27.7092 15.7467L32.1868 15.7561L28.9957 12.1436L32.1868 8.5H27.7786L26.7467 9.60463L25.7854 8.5H16.3016L15.4872 10.3704L14.6537 8.5H10.8534V9.35186L10.4307 8.5H7.18111ZM20.1994 9.52905H25.2055L26.7367 11.2316L28.3172 9.52905H29.8484L27.5219 12.1426L29.8484 14.726H28.2477L26.7166 13.0036L25.128 14.726H20.1994V9.52905ZM21.4356 11.555V10.6057V10.6048H24.5593L25.9223 12.1229L24.4989 13.6493H21.4356V12.613H24.1667V11.555H21.4356ZM7.91799 9.52905H9.7743L11.8843 14.4431V9.52905H13.9178L15.5476 13.0524L17.0496 9.52905H19.073V14.7291H17.8418L17.8317 10.6544L16.0368 14.7291H14.9355L13.1305 10.6544V14.7291H10.5977L10.1176 13.5633H7.52338L7.04421 14.728H5.68717L7.91799 9.52905ZM7.96634 12.4856L8.82101 10.4089L9.6747 12.4856H7.96634Z"
        fill="white"
      />
    </svg>
  );
};
