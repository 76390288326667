import { ECTextField } from 'app/components';
import { useCallback, useEffect, useState } from 'react';

type CreditCardExpirationDateProps = {
  onChange?: (value: any) => void;
  value?: any | null;
  validationMessage?: string | null;
  fieldName: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
};

export const CreditCardExpirationDate = ({
  onChange,
  value,
  validationMessage: validationMessageProps,
  fieldName,
  label,
  placeholder,
  required,
}: CreditCardExpirationDateProps) => {
  const [expirationDate, setExpirationDate] = useState<string>(value);

  const [validationMessage, setValidationMessage] = useState<
    string | null | undefined
  >(validationMessageProps);

  const handleValidateExpirationDate = useCallback(
    value => {
      onChange?.(value);
      setExpirationDate(value);
      if (value?.length === 2) {
        const expirationMonth = Number(value);
        if (expirationMonth < 1 || expirationMonth > 12) {
          setValidationMessage('Month is invalid');
          return;
        }
      }
      if (value?.length === 5) {
        const expirationMonth = Number(value?.substring(0, 2));
        const expirationYear = Number(value?.substring(3, 5));

        if (expirationMonth < 1 || expirationMonth > 12) {
          setValidationMessage('Month is invalid');
          return;
        }
        if (expirationYear + 2000 < new Date().getFullYear()) {
          setValidationMessage(
            'Looks like your card is Expired. Add another date or card.',
          );
          return;
        }
        if (expirationMonth < new Date().getMonth() + 1) {
          setValidationMessage(
            'Looks like your card is Expired. Add another date or card.',
          );
          return;
        }
      }
      setValidationMessage(null);
    },
    [expirationDate],
  );

  useEffect(() => {
    setExpirationDate(value);
  }, [value]);

  useEffect(() => {
    setValidationMessage(validationMessageProps);
  }, [validationMessageProps]);

  return (
    <ECTextField
      inputProps={{
        maxLength: 4,
        minLength: 4,
      }}
      id={fieldName}
      name={fieldName}
      value={expirationDate}
      error={!!validationMessage}
      helperText={validationMessage}
      label={required ? `${label}*` : label}
      placeholder={placeholder}
      variant="filled"
      onChange={event => {
        let valueChanged: string = event.target.value;
        const formattedValue = valueChanged.replace('/', '');
        const isNumeric = /^[0-9]+$/.test(formattedValue);

        if (!isNumeric && valueChanged) {
          setValidationMessage('Invalid Date Format');
          return;
        }
        setValidationMessage(null);
        handleValidateExpirationDate(valueChanged);
      }}
      mask={'##/##'}
    />
  );
};
