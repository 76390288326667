import { ECBox, ECTypography } from 'app/components';
import { Visa } from './icons/visa';
import { MasterCard } from './icons/mastercard';
import { Maestro } from './icons/maestro';
import { Discover } from './icons/discover';
import { AmericanExpress } from './icons/americanexpress';
import { useEffect, useState } from 'react';
import { ECToggleButton } from 'app/components/ECToggleButton';
import { useMediaQuery, useTheme } from '@mui/material';
import { AchTransfer } from './icons/achTransfer';

const sectorTypes = { CreditCard: 'creditcard', ACH: 'ach' };

type PaymentSelectorProps = {
  onChange?: (value: any) => void;
  value?: any | null;
};

export const PaymentSelector = ({ onChange }: PaymentSelectorProps) => {
  const theme = useTheme();

  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const [selectedPayment, setSelectedPayment] = useState<string | null>(
    sectorTypes.CreditCard,
  );

  useEffect(() => {
    onChange?.(selectedPayment);
  }, [selectedPayment]);

  const handleSelectPaymentChange = (payment: string) => {
    setSelectedPayment(payment);
  };

  const ToggleWrapper = ({ children, value, onClick, selected }) => (
    <ECToggleButton
      sx={theme => ({
        flexDirection: 'column',
        display: 'flex',
        gap: '8px',
        width: '216px',
        paddingY: '16px',
        borderRadius: '4px',
        height: '92px',
        border: `1px solid ${theme.palette.other.divider}`,
        '&.Mui-selected': {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          border: `2px solid ${theme.palette.primary.main}`,
          '&:hover': {
            backgroundColor: theme.palette.background.default,
          },
        },
      })}
      value={value}
      onClick={onClick}
      selected={selected}
    >
      {children}
    </ECToggleButton>
  );

  return (
    <ECBox
      flexDirection={isMobileSize ? 'column' : 'row'}
      display={'flex'}
      gap={2}
      marginLeft={isMobileSize ? 4 : ''}
    >
      <ToggleWrapper
        onClick={() => handleSelectPaymentChange(sectorTypes.CreditCard)}
        selected={selectedPayment === sectorTypes.CreditCard}
        value={sectorTypes.CreditCard}
      >
        <ECBox
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ECTypography variant="h6">Credit Card</ECTypography>
        </ECBox>
        <ECBox
          gap={'4px'}
          display={`flex`}
          flexDirection={`row`}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Visa />
          <MasterCard />
          <Maestro />
          <Discover />
          <AmericanExpress />
        </ECBox>
      </ToggleWrapper>

      <ToggleWrapper
        selected={selectedPayment === sectorTypes.ACH}
        value={sectorTypes.ACH}
        onClick={() => handleSelectPaymentChange(sectorTypes.ACH)}
      >
        <ECBox
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ECTypography variant="h6">ACH</ECTypography>
        </ECBox>
        <ECBox
          gap={'4px'}
          display={`flex`}
          flexDirection={`row`}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <AchTransfer />
        </ECBox>
      </ToggleWrapper>
    </ECBox>
  );
};
