export enum StatusGroupNames {
  Pending = 'PENDING',
  Unassigned = 'UNASSIGNED',
  Completed = 'COMPLETE',
  SoftCompleted = 'SOFT_COMPLETED',
  Awaiting = 'AWAITING_INVOICE',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Rejected = 'REJECTED',
  NotFixed = 'NOT_FIXED',
}
