import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { formatDateForHistory } from 'utils/strings/formatDate';
import { ECGrid } from '../ECGrid';
import { camelCaseToWords } from 'utils/common';
import { P } from 'types/Permission';
import {
  getHistoryEntry,
  isValidHistoryEntry,
} from '../ECHistoryEntry/historyUtil';
import { ApprovalWorkflowActivityList } from 'types/Activity';
import { ArrowForward, KeyboardArrowDown } from '@mui/icons-material';
import { ECCollapse } from '../ECCollapse';
import { ECButton } from '../ECButton';
import * as _ from 'lodash';
import { useMediaQuery, useTheme } from '@mui/material';

const CARD_MAX_HEIGHT_COLLAPSED = 110;

export interface ApprovalWorkflowActivityProps {
  entry: ApprovalWorkflowActivityList;
  key?: string;
}

const ApprovalWorkflowActivity = ({ entry }: ApprovalWorkflowActivityProps) => {
  const {
    beforeBody,
    createdAt: date,
    fullName: author,
    jobTitle,
    email,
    company,
    body,
    targetPermission,
    id,
  } = _.clone(entry);

  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldShowSeeMoreButton, setShouldShowSeeMoreButton] = useState(false);
  const [cardHeight, setCardHeight] = useState(1);
  const cardRef = useRef<any>(null);

  useLayoutEffect(() => {
    if (cardRef.current?.clientHeight > CARD_MAX_HEIGHT_COLLAPSED) {
      setShouldShowSeeMoreButton(true);
      setCardHeight(cardRef.current?.clientHeight);
    } else {
      setCardHeight(cardRef.current?.clientHeight);
    }
  }, [body]);

  const parseValue = useCallback((value: any, bodyKey: string) => {
    if (bodyKey === 'adminOnly') {
      if (typeof value === 'string') {
        return value === 'true' ? 'Yes' : 'No';
      } else {
        return value ? 'Yes' : 'No';
      }
    }
    return value;
  }, []);

  const isBodyFieldAvailable = !!Object.keys(body).length;

  const memoizedActivityBody = useMemo(() => {
    return Object.keys(isBodyFieldAvailable ? body : beforeBody)
      ?.filter(bodyKey => bodyKey?.includes('Steps'))
      ?.map((bodyKey, index) => {
        return (
          <>
            {isValidHistoryEntry(body, bodyKey) && (
              <>
                <ECBox mt={3} />
                <ECTypography
                  variant="subtitle2"
                  width="fit-content"
                  color={theme => theme.palette.text.secondary}
                  fontSize={13}
                  sx={{
                    background: theme => theme.palette.other.divider,
                    paddingX: 2,
                    paddingY: 0.5,
                    mb: 1,
                  }}
                >
                  {bodyKey?.includes('proposal') ? 'Proposal' : 'Invoices'}
                </ECTypography>
                {Object.keys(body[bodyKey])?.map(stepKey => {
                  return (
                    <>
                      {(stepKey === 'superApprovals' ||
                        stepKey === 'steps') && (
                        <ECTypography
                          variant="subtitle2"
                          minWidth={isXl ? '12vw' : '15vw'}
                          maxWidth={isXl ? '12vw' : '20vw'}
                          color={theme => theme.palette.text.secondary}
                        >
                          {camelCaseToWords(
                            stepKey === 'steps' ? 'Workflow' : stepKey,
                          )}
                          :&nbsp;
                        </ECTypography>
                      )}
                      {stepKey === 'superApprovals' &&
                        Object.keys(body[bodyKey][stepKey])?.map(
                          stepBodyKey => {
                            return (
                              <>
                                <ECBox display="flex" width="100%" ml={2}>
                                  <ECBox display="flex" flex={1} width={0}>
                                    <ECTypography
                                      variant="subtitle2"
                                      minWidth={isXl ? '12vw' : '15vw'}
                                      maxWidth={isXl ? '12vw' : '20vw'}
                                      color={theme =>
                                        theme.palette.text.secondary
                                      }
                                    >
                                      {camelCaseToWords(stepBodyKey)}
                                      :&nbsp;
                                    </ECTypography>
                                    <ECBox
                                      display="flex"
                                      flex={1}
                                      justifyContent="center"
                                    >
                                      <ActivityBodyComponent
                                        body={body[bodyKey][stepKey]}
                                        beforeBody={
                                          beforeBody?.[bodyKey]?.[stepKey]
                                        }
                                        bodyKey={stepBodyKey}
                                        isBodyFieldAvailable={
                                          isBodyFieldAvailable
                                        }
                                      />
                                    </ECBox>
                                  </ECBox>
                                </ECBox>
                              </>
                            );
                          },
                        )}

                      {(stepKey === 'steps' ||
                        stepKey === 'specialApprovals') &&
                        Array.isArray(body[bodyKey][stepKey]) &&
                        body[bodyKey][stepKey]?.map((step, index) => (
                          <>
                            <ECTypography
                              variant="subtitle2"
                              minWidth={isXl ? '12vw' : '15vw'}
                              maxWidth={isXl ? '12vw' : '20vw'}
                              color={theme => theme.palette.text.secondary}
                            >
                              {stepKey === 'steps'
                                ? camelCaseToWords(`Step ${index + 1}`)
                                : camelCaseToWords(stepKey)}
                              :&nbsp;
                            </ECTypography>
                            {Object.keys(step)?.map(stepBodyKey => (
                              <>
                                <ECBox display="flex" width="100%" ml={2}>
                                  <ECBox display="flex" flex={1} width={0}>
                                    <ECTypography
                                      variant="subtitle2"
                                      minWidth={isXl ? '12vw' : '15vw'}
                                      maxWidth={isXl ? '12vw' : '20vw'}
                                      color={theme =>
                                        theme.palette.text.secondary
                                      }
                                    >
                                      {camelCaseToWords(stepBodyKey)}
                                      :&nbsp;
                                    </ECTypography>
                                    <ECBox
                                      display="flex"
                                      flex={1}
                                      justifyContent="center"
                                    >
                                      <ActivityBodyComponent
                                        body={
                                          body?.[bodyKey]?.[stepKey]?.[index]
                                        }
                                        beforeBody={
                                          beforeBody?.[bodyKey]?.[stepKey]?.[
                                            index
                                          ]
                                        }
                                        bodyKey={stepBodyKey}
                                        isBodyFieldAvailable={
                                          isBodyFieldAvailable
                                        }
                                      />
                                    </ECBox>
                                  </ECBox>
                                </ECBox>
                              </>
                            ))}
                          </>
                        ))}

                      {stepKey !== 'steps' &&
                        stepKey !== 'specialApprovals' &&
                        stepKey !== 'superApprovals' && (
                          <ECBox display="flex" width="100%">
                            <ECBox display="flex" flex={1} width={0}>
                              <ECTypography
                                variant="subtitle2"
                                minWidth={isXl ? '12vw' : '15vw'}
                                maxWidth={isXl ? '12vw' : '20vw'}
                                color={theme => theme.palette.text.secondary}
                              >
                                {camelCaseToWords(stepKey)}
                                :&nbsp;
                              </ECTypography>
                              <ECBox
                                display="flex"
                                flex={1}
                                justifyContent="center"
                                ml={4}
                              >
                                <ActivityBodyComponent
                                  body={body?.[bodyKey]}
                                  beforeBody={beforeBody?.[bodyKey]}
                                  bodyKey={stepKey}
                                  isBodyFieldAvailable={isBodyFieldAvailable}
                                />
                              </ECBox>
                            </ECBox>
                          </ECBox>
                        )}
                    </>
                  );
                })}
              </>
            )}
          </>
        );
      });
  }, [body, beforeBody, isBodyFieldAvailable]);

  const renderChanges = useCallback(() => {
    return (
      <>
        {body && (
          <>
            <ECCollapse
              in={isExpanded}
              collapsedSize={
                shouldShowSeeMoreButton ? CARD_MAX_HEIGHT_COLLAPSED : cardHeight
              }
              key={Date.now()}
              sx={{ overflow: 'visible' }}
            >
              <ECBox ref={cardRef} overflow="visible">
                {
                  <>
                    <ECTypography
                      variant="body1"
                      width="fit-content"
                      fontSize={14}
                      fontWeight="bold"
                    >
                      AWD ID: {id}
                    </ECTypography>
                    <ECTypography
                      variant="subtitle2"
                      width="fit-content"
                      color={theme => theme.palette.text.secondary}
                      fontSize={13}
                      sx={{
                        background: theme => theme.palette.other.divider,
                        paddingX: 2,
                        paddingY: 0.5,
                        mb: 1,
                      }}
                    >
                      Approval Workflow {!beforeBody ? 'Created' : 'Updated'}
                    </ECTypography>
                    {Object.keys(isBodyFieldAvailable ? body : beforeBody)
                      ?.filter(bodyKey => !bodyKey?.includes('Steps'))
                      ?.map((bodyKey, index) => {
                        const _bodyKey = bodyKey.replace('Name', '');

                        return (
                          <>
                            {isValidHistoryEntry(body, bodyKey) && (
                              <ECBox display="flex" width="100%">
                                <ECBox display="flex" flex={1} width={0}>
                                  <ECTypography
                                    variant="subtitle2"
                                    minWidth={isXl ? '12vw' : '15vw'}
                                    maxWidth={isXl ? '12vw' : '20vw'}
                                    color={theme =>
                                      theme.palette.text.secondary
                                    }
                                  >
                                    {camelCaseToWords(_bodyKey)}
                                    :&nbsp;
                                  </ECTypography>

                                  <ECBox
                                    display="flex"
                                    flex={1}
                                    justifyContent="center"
                                  >
                                    {!Array.isArray(beforeBody?.[bodyKey]) && (
                                      <ECBox
                                        display="flex"
                                        flex={2}
                                        width={0}
                                        visibility={
                                          beforeBody && bodyKey === 'adminOnly'
                                            ? 'visible'
                                            : beforeBody?.[bodyKey]
                                              ? 'visible'
                                              : 'hidden'
                                        }
                                        marginLeft={2}
                                      >
                                        <ECTypography
                                          variant="subtitle2"
                                          maxWidth="100%"
                                          color={theme =>
                                            theme.palette.error.dark
                                          }
                                          sx={{
                                            textDecoration: 'line-through',
                                          }}
                                        >
                                          {body[bodyKey] !==
                                            beforeBody?.[bodyKey] &&
                                            parseValue(
                                              JSON.stringify(
                                                beforeBody?.[bodyKey],
                                              ),
                                              bodyKey,
                                            )}
                                        </ECTypography>
                                      </ECBox>
                                    )}

                                    {!Array.isArray(beforeBody?.[bodyKey]) && (
                                      <ECBox display="flex">
                                        <ArrowForward
                                          sx={theme => ({
                                            color:
                                              index === 2
                                                ? theme.palette.action.active
                                                : 'transparent',
                                            mx: 1,
                                            visibility: isBodyFieldAvailable
                                              ? 'visible'
                                              : 'hidden',
                                          })}
                                          fontSize="small"
                                        />
                                      </ECBox>
                                    )}

                                    <ECBox
                                      display="flex"
                                      flex={2}
                                      width={0}
                                      justifyContent={'flex-start'}
                                      visibility={
                                        isBodyFieldAvailable
                                          ? 'visible'
                                          : 'hidden'
                                      }
                                    >
                                      <ECTypography
                                        variant="subtitle2"
                                        maxWidth="100%"
                                        color={theme =>
                                          bodyKey
                                            ?.toLowerCase()
                                            .includes('removed')
                                            ? theme.palette.error.dark
                                            : theme.palette.success.dark
                                        }
                                      >
                                        {parseValue(
                                          getHistoryEntry(
                                            isBodyFieldAvailable
                                              ? body
                                              : beforeBody,
                                            bodyKey,
                                          ),
                                          bodyKey,
                                        )}
                                      </ECTypography>
                                    </ECBox>
                                  </ECBox>
                                </ECBox>
                              </ECBox>
                            )}
                          </>
                        );
                      })}
                    {memoizedActivityBody}
                  </>
                }
              </ECBox>
            </ECCollapse>
            {shouldShowSeeMoreButton && (
              <>
                <ECBox
                  display="flex"
                  width="100%"
                  flexDirection="column"
                  mt={-9}
                  mb={-2}
                >
                  <ECBox
                    width="100%"
                    height={96}
                    sx={{
                      background: isExpanded
                        ? 'transparent'
                        : 'linear-gradient(to bottom, #FFFFFF00, #FFFFFFFF)',
                    }}
                    justifyContent="center"
                    alignItems="flex-end"
                    display="flex"
                  />
                  <ECBox
                    display="flex"
                    bgcolor="white"
                    width="100%"
                    justifyContent="center"
                  >
                    <ECButton
                      variant="text"
                      onClick={() => {
                        setIsExpanded(prevIsExpanded => !prevIsExpanded);
                      }}
                      endIcon={
                        <KeyboardArrowDown
                          sx={{
                            transform: isExpanded
                              ? 'rotate(180deg)'
                              : 'rotate(0deg)',
                            transition: '100ms linear all',
                          }}
                        />
                      }
                    >
                      {isExpanded ? 'See less' : 'See More'}
                    </ECButton>
                  </ECBox>
                </ECBox>
              </>
            )}
          </>
        )}
      </>
    );
  }, [
    body,
    beforeBody,
    entry,
    targetPermission,
    isExpanded,
    shouldShowSeeMoreButton,
    cardHeight,
  ]);

  return (
    <ECBox display="flex" flexDirection="column" flexGrow={1}>
      <ECBox sx={{ flexGrow: 1 }}>
        <ECGrid container spacing={2}>
          <ECGrid item xs>
            <ECBox display="flex" alignItems="center">
              <ECBox
                height={12}
                width={12}
                borderRadius={6}
                bgcolor={theme => theme.palette.primary.dark}
                mr={2}
              />
              <ECBox display="flex" flexDirection="column">
                <ECTypography variant="subtitle2" fontWeight="bold">
                  {author}
                </ECTypography>

                <ECTypography
                  variant="body2"
                  color={theme => theme.palette.primary.main}
                >
                  {email}
                </ECTypography>
              </ECBox>
            </ECBox>
          </ECGrid>

          <ECGrid item xs>
            <ECBox display="flex" flexDirection="column">
              <ECTypography variant="body2" fontWeight="bold">
                {jobTitle}
              </ECTypography>

              <ECTypography
                variant="subtitle2"
                color={theme => theme.palette.text.secondary}
              >
                {company}
              </ECTypography>
            </ECBox>
          </ECGrid>

          <ECGrid item xs>
            <ECBox display="flex" flexDirection="column">
              <ECTypography
                variant="body2"
                color={theme => theme.palette.text.secondary}
              >
                {formatDateForHistory(date)}
              </ECTypography>
            </ECBox>
          </ECGrid>
        </ECGrid>
      </ECBox>

      <ECBox display="flex" gap={4}>
        <ECBox
          bgcolor={theme => theme.palette.grey[400]}
          width="1px"
          display="flex"
          sx={{ marginLeft: '6px !important' }}
        />
        <ECBox
          display="flex"
          flexDirection="column"
          boxShadow={2}
          mt={3}
          borderRadius="md"
          minWidth={0}
          width="100%"
          p={2}
          position="relative"
          overflow="hidden"
          height="auto"
        >
          {renderChanges()}
        </ECBox>
      </ECBox>
    </ECBox>
  );
};

const ActivityBody = ({ body, beforeBody, bodyKey, isBodyFieldAvailable }) => {
  const parseValue = useCallback((value: any, bodyKey: string) => {
    if (bodyKey === 'adminOnly') {
      if (typeof value === 'string') {
        return value === 'true' ? 'Yes' : 'No';
      } else {
        return value ? 'Yes' : 'No';
      }
    }
    return value;
  }, []);

  return (
    <>
      <ECBox
        display="flex"
        flex={2}
        width={0}
        visibility={
          beforeBody && bodyKey === 'adminOnly'
            ? 'visible'
            : beforeBody?.[bodyKey]
              ? 'visible'
              : 'hidden'
        }
        marginLeft={2}
      >
        <ECTypography
          variant="subtitle2"
          maxWidth="100%"
          color={theme => theme.palette.error.dark}
          sx={{
            textDecoration: 'line-through',
          }}
        >
          {body[bodyKey] !== beforeBody?.[bodyKey] &&
            parseValue(JSON.stringify(beforeBody?.[bodyKey]), bodyKey)}
        </ECTypography>
      </ECBox>

      <ECBox
        display="flex"
        flex={2}
        width={0}
        justifyContent={'flex-start'}
        visibility={isBodyFieldAvailable ? 'visible' : 'hidden'}
      >
        <ECTypography
          variant="subtitle2"
          maxWidth="100%"
          color={theme =>
            bodyKey?.toLowerCase().includes('removed')
              ? theme.palette.error.dark
              : theme.palette.success.dark
          }
        >
          {parseValue(
            getHistoryEntry(isBodyFieldAvailable ? body : beforeBody, bodyKey),
            bodyKey,
          )}
        </ECTypography>
      </ECBox>
    </>
  );
};

const ActivityBodyComponent = React.memo(ActivityBody);
export default React.memo(ApprovalWorkflowActivity);
