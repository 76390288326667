import { ECBox, ECButton, ECImage, ECTypography } from 'app/components';

export default function CustomerLogoHeader({ userFullName }) {
  return (
    <ECBox
      gap={2}
      py={2}
      px={3}
      border="1px solid rgba(0, 0, 0, .26)"
      borderRadius={1}
      position="relative"
      width={'100%'}
    >
      {/* removed on DEV-13527 <ECImage
        alt="costumer-logo"
        sx={{
          width: 115,
          height: 115,
          objectFit: 'cover',
          display: 'inline-block',
        }}
      /> */}
      <ECBox display="inline-block" ml={3}>
        <ECTypography
          sx={{ fontSize: '1.75rem' }}
          variant="h2"
          fontWeight="bold"
        >
          Hi {userFullName}, Welcome to Ecotrak!
        </ECTypography>
      </ECBox>
      {/* removed on DEV-13527 <ECButton
        sx={{ position: 'absolute', bottom: 0, right: 0, mb: 2, mr: 3 }}
        variant="contained"
      >
        + Add Widget
      </ECButton> */}
    </ECBox>
  );
}
