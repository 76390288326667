import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import {
  ECBox,
  ECButton,
  ECEasyTable,
  ECWorkflowTemplate,
} from 'app/components';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import {
  useGetPurchaseOrderByIdQuery,
  usePurchaseOrderActionClickMutation,
  useUpdatePurchaseOrderMutation,
} from 'services/purchaseOrderApi';
import ContactInfoIcon from '@mui/icons-material/PermContactCalendar';
import { SupplierInfoModal } from './supplier-info-modal';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { P } from 'types/Permission';
import { NewPOInvoice } from './NewPOInvoice';
import {
  ECPurchaseOrderItemsTable,
  PurchaseOrderItemsHeaderButton,
} from 'app/components/ECPurchaseOrderItemsTable';
import { SetPOToComplete } from './SetPOToComplete';
import { PODownloadPDF } from './PODownloadPDF';
import { useGetPurchaseOrderNextStatusListQuery } from 'services/purchaseOrderApi';
import { WorkflowStatusGroupName } from 'app/components/ECWorkflowStatusBadge';
import { ECCancelPOButton } from './ECCancelPOButton';
import { ECRejectPOButton } from './ECRejectPOButton';
import { ECApprovePOButton } from './ECApprovePOButton';
import { useGetInventoryStorageLocationsListForDropdownQuery } from 'services/inventoryApi';
import { useGetUsersListForDropdownQuery } from 'services/userApi';
import { isEqual } from 'lodash';
import { isValidEmail } from 'utils/validate-fields';
import { validatePhoneNumber } from 'utils/strings/phone';

const tableConfig = require('./purchase_order_details_table_config.json');

export const PurchaseOrderDetailsPage = () => {
  const { id } = useParams();

  const [isEditMode, setIsEditMode] = useState(false);
  const [removedItems, setRemovedItems] = useState([]);

  const [isSupplierInfoModalOpen, setIsSupplierInfoModalOpen] =
    useState<boolean>(false);

  const { data: nextStatusActions } = useGetPurchaseOrderNextStatusListQuery(
    Number(id),
    { skip: !id },
  );

  const approveStatusId = useMemo(() => {
    return nextStatusActions?.find(
      action =>
        action.statusGroupName ===
        WorkflowStatusGroupName.PurchaseOrderApproved,
    )?.statusTo;
  }, [nextStatusActions]);

  const cancelStatusToId = useMemo(() => {
    return nextStatusActions?.find(
      action =>
        action.statusGroupName ===
        WorkflowStatusGroupName.PurchaseOrderCanceled,
    )?.statusTo;
  }, [nextStatusActions]);

  const rejectStatusToId = useMemo(() => {
    return nextStatusActions?.find(
      action =>
        action.statusGroupName ===
        WorkflowStatusGroupName.PurchaseOrderRejected,
    )?.statusTo;
  }, [nextStatusActions]);

  const isDetailsTab =
    !window.location.href.includes('attachments') &&
    !window.location.href.includes('notes') &&
    !window.location.href.includes('activity');

  const [invalidFields, setInvalidFields] = useState<any[]>([]);
  const [summaryFieldsValues, setSummaryFieldsValues] = useState<any>({});
  const hasEditPurchaseOrderPermission = useHasPermission([
    P.EditPurchaseOrder,
  ]);

  const [
    doUpdatePurchaseOrderStatus,
    { isLoading: isLoadingAction, isSuccess: isSuccessUpdateAction },
  ] = usePurchaseOrderActionClickMutation();

  const { data: purchaseOrder, isLoading: isPurchaseOrderLoading } =
    useGetPurchaseOrderByIdQuery(Number(id), {
      skip: !id,
    });

  const [purchaseOrderItems, setPurchaseOrderItems] = useState(
    purchaseOrder?.purchaseOrderItems || [],
  );

  const purchaseOrderEditTableRef = useRef<any>();
  const createInvoiceRef = useRef<any>(null);

  const handleSummaryFieldChange = useCallback(
    (fieldName: string) => (value: any) => {
      if (fieldName === 'assignedTo') {
        setSummaryFieldsValues(prevSummaryFieldsValues => ({
          ...prevSummaryFieldsValues,
          shipToUser: `${value?.firstName ? value?.firstName : ''}${value?.lastName ? ` ${value?.lastName}` : ''}`,
          shipToUserPhone: value?.phone,
          shipToUserEmail: value?.email,
          [fieldName]: value,
        }));
      }

      setSummaryFieldsValues(prevSummaryFieldsValues => ({
        ...prevSummaryFieldsValues,
        [fieldName]: value,
      }));
    },
    [],
  );

  const initializeSummaryFields = () => {
    const initSummaryFields = {
      ...purchaseOrder,
    };

    setSummaryFieldsValues(initSummaryFields);
  };

  useEffect(() => {
    if (purchaseOrder) {
      initializeSummaryFields();
    }
  }, [purchaseOrder]);

  const summaryData = useMemo(() => {
    const _summaryData = [
      {
        id: 'progressBar',
        label: 'Progress Bar',
        data: purchaseOrder,
        dataPath: 'reconcileShipment',
        type: SummaryFieldTypes.ProgressBar,
      },
      {
        id: 'status',
        label: 'PO Status',
        data: purchaseOrder?.workflowStatusName,
        type: SummaryFieldTypes.Status,
      },
      {
        id: 'purchaseOrderNumber',
        label: 'Purchase Order Number',
        data: summaryFieldsValues?.purchaseOrderNumber ?? '-',
        type: SummaryFieldTypes.Text,
        onChange: handleSummaryFieldChange('purchaseOrderNumber'),
        isEditable: purchaseOrder?.mutableFields?.includes(
          'purchaseOrderNumber',
        ),
      },
      {
        id: 'shippingLocation',
        label: 'Storage Location',
        data: summaryFieldsValues?.shippingLocation ?? '-',
        type: SummaryFieldTypes.SelectPaginated,
        useQuery: useGetInventoryStorageLocationsListForDropdownQuery,
        obAlias: 'invntrystrg.name',
        queryParams: {
          sb: 'usr.firsName',
          ob: 'usr.firstName',
          o: 'a',
        },
        onChange: handleSummaryFieldChange('shippingLocation'),
        isValid: !!summaryFieldsValues?.shippingLocation,
        validationMessage: invalidFields.find(
          field => field.fieldName === 'shippingLocation',
        )?.message,
        required: true,
        isEditable:
          purchaseOrder?.mutableFields?.includes('inventoryStorageId'),
      },
      {
        id: 'shippingAddress',
        label: 'Shipping Address',
        data: summaryFieldsValues?.shippingAddress ?? '-',
        type: SummaryFieldTypes.Text,
        isEditable: purchaseOrder?.mutableFields?.includes('shippingAddress'),
      },
      {
        id: 'supplier',
        label: 'Supplier',
        data: summaryFieldsValues?.supplier?.name ?? '-',
        icon: <ContactInfoIcon fontSize="large" />,
        actionTitle: 'Contact Info',
        onIconClick: () => {
          setIsSupplierInfoModalOpen(true);
        },
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'assignedTo',
        label: 'Assigned To',
        data: summaryFieldsValues?.assignedTo ?? '-',
        type: SummaryFieldTypes.SelectPaginated,
        useQuery: useGetUsersListForDropdownQuery,
        obAlias: 'usr.firstName',
        queryParams: {
          sb: 'usr.firsName',
          ob: 'usr.firstName',
          o: 'a',
        },
        onChange: handleSummaryFieldChange('assignedTo'),
        isValid: !!summaryFieldsValues?.assignedTo,
        validationMessage: invalidFields.find(
          field => field.fieldName === 'assignedTo',
        )?.message,
        required: true,
        isEditable: purchaseOrder?.mutableFields?.includes('assigneeUserId'),
      },
      {
        id: 'shipToUser',
        label: 'Ship To User',
        data: summaryFieldsValues?.shipToUser ?? '-',
        type: SummaryFieldTypes.Text,
        onChange: handleSummaryFieldChange('shipToUser'),
        isValid: !!summaryFieldsValues?.shipToUser,
        validationMessage: invalidFields.find(
          field => field.fieldName === 'shipToUser',
        )?.message,
        isEditable: purchaseOrder?.mutableFields?.includes('shipToUser'),
      },
      {
        id: 'shipToUserPhone',
        label: 'Ship To User Phone #',
        data: summaryFieldsValues?.shipToUserPhone ?? '-',
        type: SummaryFieldTypes.Phone,
        onChange: handleSummaryFieldChange('shipToUserPhone'),
        isValid:
          !!summaryFieldsValues?.shipToUserPhone ||
          !invalidFields.find(field => field.fieldName === 'shipToUserPhone'),
        validationMessage: invalidFields.find(
          field => field.fieldName === 'shipToUserPhone',
        )?.message,
        isEditable: purchaseOrder?.mutableFields?.includes('shipToUserPhone'),
      },
      {
        id: 'shipToUserEmail',
        label: 'Ship To User Email',
        data: summaryFieldsValues?.shipToUserEmail ?? '-',
        type: SummaryFieldTypes.EmailField,
        onChange: handleSummaryFieldChange('shipToUserEmail'),
        isValid: !!summaryFieldsValues?.shipToUserEmail,
        validationMessage: invalidFields.find(
          field => field.fieldName === 'shipToUserEmail',
        )?.message,
        isEditable: purchaseOrder?.mutableFields?.includes('shipToUserEmail'),
      },
      {
        id: 'dueDate',
        label: 'Due Date',
        data: summaryFieldsValues?.dueDate,
        dateValueFormat: 'DD/MM/YYYY hh:mm a',
        type: SummaryFieldTypes.Date,
        onChange: handleSummaryFieldChange('dueDate'),
        isValid: !!summaryFieldsValues?.dueDate,
        validationMessage: invalidFields.find(
          field => field.fieldName === 'dueDate',
        )?.message,
        isEditable: purchaseOrder?.mutableFields?.includes('dueDate'),
      },
      {
        id: 'approveDate',
        label: 'Approve Date',
        data: summaryFieldsValues?.approvedDate,
        dateValueFormat: 'DD/MM/YYYY hh:mm a',
        type: SummaryFieldTypes.Date,
        isEditable: purchaseOrder?.mutableFields?.includes('approvedDate'),
      },
      {
        id: 'updatedDate',
        label: 'Updated Date',
        data: summaryFieldsValues?.updatedAt,
        dateValueFormat: 'DD/MM/YYYY hh:mm a',
        type: SummaryFieldTypes.Date,
        isEditable: purchaseOrder?.mutableFields?.includes('updatedAt'),
      },
      {
        id: 'createdDate',
        label: 'Created Date',
        data: summaryFieldsValues?.createdAt,
        dateValueFormat: 'DD/MM/YYYY hh:mm a',
        type: SummaryFieldTypes.Date,
        isEditable: purchaseOrder?.mutableFields?.includes('createdAt'),
      },
      {
        id: 'submittedBy',
        label: 'Submitted By',
        data: summaryFieldsValues?.submittedBy,
        type: SummaryFieldTypes.Text,
        isEditable: purchaseOrder?.mutableFields?.includes('submittedBy'),
      },
      {
        id: 'description',
        label: 'Description',
        data: summaryFieldsValues?.description ?? '-',
        type: SummaryFieldTypes.TextArea,
        onChange: handleSummaryFieldChange('description'),
        isEditable: purchaseOrder?.mutableFields?.includes('description'),
      },
    ];

    return _summaryData;
  }, [
    purchaseOrder,
    summaryFieldsValues,
    handleSummaryFieldChange,
    invalidFields,
  ]);

  const [
    doUpdatePurchaseOrder,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset: resetUpdateWorkOrder,
    },
  ] = useUpdatePurchaseOrderMutation();

  useEffect(() => {
    if (isUpdateSuccess && !isUpdateLoading) {
      setIsEditMode(false);
      resetUpdateWorkOrder();
    }
  }, [isUpdateSuccess, isUpdateLoading]);

  const useUpdatePurchaseOrder = useCallback(() => {
    const doUpdate = async data => {
      if (!purchaseOrder?.id) return;

      let isSummaryFieldsValid = true;
      const isShipToUserEmailEditable = summaryData.find(
        item => item.id === 'shipToUserEmail',
      )?.isEditable;
      if (!summaryFieldsValues?.shipToUserEmail && isShipToUserEmailEditable) {
        setInvalidFields(prev => [
          ...prev,
          {
            fieldName: 'shipToUserEmail',
            message: 'Email is required',
          },
        ]);
        isSummaryFieldsValid = false;
      } else if (
        !isValidEmail(summaryFieldsValues?.shipToUserEmail) &&
        isShipToUserEmailEditable
      ) {
        setInvalidFields(prev => [
          ...prev,
          {
            fieldName: 'shipToUserEmail',
            message: 'Invalid email',
          },
        ]);
        isSummaryFieldsValid = false;
      } else {
        setInvalidFields(prev =>
          prev.filter(field => field.fieldName !== 'shipToUserEmail'),
        );
      }

      if (
        !summaryFieldsValues?.shippingLocation?.id &&
        summaryData.find(item => item.id === 'shippingLocation')?.isEditable
      ) {
        isSummaryFieldsValid = false;
        setInvalidFields(prev => [
          ...prev,
          {
            fieldName: 'shippingLocation',
            message: 'Storage Location is required',
          },
        ]);
      } else {
        setInvalidFields(prev =>
          prev.filter(field => field.fieldName !== 'shippingLocation'),
        );
      }

      if (
        !summaryFieldsValues?.assignedTo?.id &&
        summaryData.find(item => item.id === 'assignedTo')?.isEditable
      ) {
        isSummaryFieldsValid = false;
        setInvalidFields(prev => [
          ...prev,
          {
            fieldName: 'assignedTo',
            message: 'Assigned To is required',
          },
        ]);
      } else {
        setInvalidFields(prev =>
          prev.filter(field => field.fieldName !== 'assignedTo'),
        );
      }

      if (
        !summaryFieldsValues?.dueDate &&
        summaryData.find(item => item.id === 'dueDate')?.isEditable
      ) {
        isSummaryFieldsValid = false;
        setInvalidFields(prev => [
          ...prev,
          {
            fieldName: 'dueDate',
            message: 'Due Date is required',
          },
        ]);
      } else {
        setInvalidFields(prev =>
          prev.filter(field => field.fieldName !== 'dueDate'),
        );
      }

      if (
        !summaryFieldsValues?.shipToUser &&
        summaryData.find(item => item.id === 'shipToUser')?.isEditable
      ) {
        isSummaryFieldsValid = false;
        setInvalidFields(prev => [
          ...prev,
          {
            fieldName: 'shipToUser',
            message: 'Ship To User is required',
          },
        ]);
      } else {
        setInvalidFields(prev =>
          prev.filter(field => field.fieldName !== 'shipToUser'),
        );
      }

      if (
        !validatePhoneNumber(summaryFieldsValues?.shipToUserPhone) &&
        summaryData.find(item => item.id === 'shipToUserPhone')?.isEditable
      ) {
        isSummaryFieldsValid = false;
        setInvalidFields(prev => [
          ...prev,
          {
            fieldName: 'shipToUserPhone',
            message: 'Ship To Phone is required',
          },
        ]);
      } else {
        setInvalidFields(prev =>
          prev.filter(field => field.fieldName !== 'shipToUserPhone'),
        );
      }

      if (!isSummaryFieldsValid) return;

      if (!purchaseOrderEditTableRef.current.validatePOItems()) return;

      const updatedData = {
        ...summaryFieldsValues,
        inventoryStorageId: summaryFieldsValues?.shippingLocation?.id,
        assigneeUserId: summaryFieldsValues?.assignedTo?.id,
      };

      if (!isEqual(purchaseOrderItems, purchaseOrder?.purchaseOrderItems)) {
        updatedData.purchaseOrderItems = purchaseOrderItems.map(item => ({
          id: item.id,
          inventoryItemId: item.inventoryItemId,
          measurementUnitId: item.measurementUnit?.id,
          count: Number(item.quantity),
          countDelivered: Number(item.quantityReceived),
          price: Number(item.costPerUnit),
        }));
      } else {
        delete updatedData.purchaseOrderItems;
      }

      if (!!removedItems.length) {
        updatedData.removedItems = removedItems;
      }

      doUpdatePurchaseOrder(updatedData);
    };
    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset: resetUpdateWorkOrder,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    resetUpdateWorkOrder,
    purchaseOrder,
    purchaseOrderItems,
    doUpdatePurchaseOrder,
    summaryFieldsValues,
  ]);

  useEffect(() => {
    if (isEditMode && !!removedItems.length) {
      setRemovedItems([]);
    }
  }, [isEditMode]);

  const additionalActionsStatus = useMemo(() => {
    const customActions = [
      WorkflowStatusGroupName.PurchaseOrderApproved,
      WorkflowStatusGroupName.PurchaseOrderRejected,
      WorkflowStatusGroupName.PurchaseOrderCanceled,
    ];
    const regularActions = nextStatusActions?.filter(
      action =>
        action.statusGroupName &&
        !customActions.includes(
          action.statusGroupName as WorkflowStatusGroupName,
        ),
    );
    const customActionsButtons: any[] = [];

    if (
      nextStatusActions?.some(
        action =>
          action.statusGroupName ===
          WorkflowStatusGroupName.PurchaseOrderApproved,
      ) &&
      customActions.includes(WorkflowStatusGroupName.PurchaseOrderApproved)
    ) {
      customActionsButtons.push(
        <ECApprovePOButton id={id} approveStatusId={approveStatusId} />,
      );
    }

    if (
      nextStatusActions?.some(
        action =>
          action.statusGroupName ===
          WorkflowStatusGroupName.PurchaseOrderRejected,
      ) &&
      customActions.includes(WorkflowStatusGroupName.PurchaseOrderRejected)
    ) {
      customActionsButtons.push(
        <ECRejectPOButton id={id} rejectStatusId={rejectStatusToId} />,
      );
    }

    if (
      nextStatusActions?.some(
        action =>
          action.statusGroupName ===
          WorkflowStatusGroupName.PurchaseOrderCanceled,
      ) &&
      customActions.includes(WorkflowStatusGroupName.PurchaseOrderCanceled)
    ) {
      customActionsButtons.push(
        <ECCancelPOButton id={id} cancelStatusId={cancelStatusToId} />,
      );
    }

    return [
      ...(regularActions?.map(action => (
        <ECButton
          variant="outlined"
          onClick={() => {
            doUpdatePurchaseOrderStatus({
              id: +(id || 0),
              statusTo: action.statusTo,
            });
          }}
        >
          {action.name}
        </ECButton>
      )) || []),
      ...customActionsButtons,
    ];
  }, [
    id,
    cancelStatusToId,
    approveStatusId,
    rejectStatusToId,
    nextStatusActions,
  ]);

  const invoices = useMemo(() => {
    return !!purchaseOrder?.request?.invoice
      ? {
          content: [
            {
              href: purchaseOrder?.request?.invoice.id,
              text: purchaseOrder?.request?.invoice.id,
            },
          ],
        }
      : undefined;
  }, [purchaseOrder]);

  const po = useMemo(() => {
    return { href: purchaseOrder?.id, text: purchaseOrder?.id };
  }, [purchaseOrder]);

  const poSupplier = useMemo(() => {
    return {
      href: purchaseOrder?.supplier?.id,
      text: purchaseOrder?.supplier?.name,
    };
  }, [purchaseOrder]);

  return (
    <>
      <ECWorkflowTemplate
        tabsAsLinks
        summaryData={summaryData}
        moduleName="purchase-order"
        title={purchaseOrder?.name || `Purchase Order #${id}`}
        showEditTitleStartAdornment={true}
        detailsData={purchaseOrder}
        editConfig={{
          submitTitle: 'Save',
        }}
        onCancelEdit={() => {
          setIsEditMode(false);
        }}
        editFields={[]}
        detailsConfig={{}}
        detailsFields={[]}
        useUpdateMutation={useUpdatePurchaseOrder}
        showEditForm={false}
        showLogo={false}
        additionalActions={[
          ...additionalActionsStatus,
          <SetPOToComplete
            purchaseOrder={purchaseOrder}
            openCreateInvoiceDrawer={() => {
              createInvoiceRef.current.openDrawer();
            }}
          />,
          <NewPOInvoice ref={createInvoiceRef} purchaseOrder={purchaseOrder} />,
          <PODownloadPDF purchaseOrder={purchaseOrder} />,
        ]}
        invoices={invoices}
        po={po}
        supplier={poSupplier}
        onExitEditModeWithoutSave={initializeSummaryFields}
        isEditAllowed={
          hasEditPurchaseOrderPermission[0] &&
          !!purchaseOrder?.mutableFields?.length
        }
        onEditButtonClick={setIsEditMode}
        isEditMode={isEditMode}
        showSkeletonWithTableLoader={isPurchaseOrderLoading}
      />
      {isDetailsTab && (
        <>
          <ECBox
            display="flex"
            flexDirection="column"
            height="100%"
            width="100%"
            px={2}
          >
            {!isPurchaseOrderLoading && (
              <>
                <PurchaseOrderItemsHeaderButton
                  POStatus={purchaseOrder?.workflowStatusName}
                  onClick={() => {
                    setIsEditMode(prev => !prev);
                  }}
                  isEditMode={isEditMode}
                />
                {isEditMode ? (
                  <ECPurchaseOrderItemsTable
                    ref={purchaseOrderEditTableRef}
                    editableFields={purchaseOrder?.mutableFields}
                    items={purchaseOrder?.purchaseOrderItems}
                    purchaseOrderStatus={purchaseOrder?.workflowStatusName}
                    setRemovedItems={setRemovedItems}
                    onChange={setPurchaseOrderItems}
                  />
                ) : (
                  <ECEasyTable
                    sx={{ width: '100%' }}
                    config={tableConfig}
                    data={purchaseOrder?.purchaseOrderItems}
                    isLoading={false}
                    headerHeight={5}
                    hideSearchHeadContent
                    shouldNotUseActiveFilter
                  />
                )}
              </>
            )}
          </ECBox>
        </>
      )}

      <SupplierInfoModal
        isOpen={isSupplierInfoModalOpen}
        onClose={() => setIsSupplierInfoModalOpen(false)}
        id={purchaseOrder?.supplier?.id}
      />
    </>
  );
};
