import {
  AssetTypeMutate,
  AssetTypeList,
  AssetTypeEditFormData,
  AssetTypeQueryParams,
  AssetTypeProblemTroubleShoot,
} from 'types/AssetType';
import { Priority } from 'types/Priority';
import { BaseType } from 'types/BaseType';
import { CoreAssetType } from 'types/CoreAssetType';
import { EULPeriod } from 'types/EULPeriod';

import { emptyApi } from './emptyApi';
import { QueryParams } from 'types/QueryParams';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['AssetType'],
});

export const assetTypeApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getAssetTypesList: builder.query<
      BaseType<AssetTypeList[]>,
      AssetTypeQueryParams
    >({
      query: params => {
        return {
          url: 'asset-group',
          params,
        };
      },
      transformResponse: (response: BaseType<AssetTypeList[]>) => {
        const dataWithEulWithPeriod = response.data.map(row => ({
          ...row,
          eulWithPeriod:
            row.eul && row.period
              ? `${row.eul} ${row?.period?.description}`
              : '',
        }));

        return {
          ...response,
          data: dataWithEulWithPeriod,
        };
      },
      providesTags: ['AssetType'],
    }),
    getAssetTypesForInventoryList: builder.query<
      BaseType<AssetTypeList[]>,
      AssetTypeQueryParams
    >({
      query: params => {
        return {
          url: 'asset-group/inventory',
          params,
        };
      },
      transformResponse: (response: BaseType<AssetTypeList[]>) => {
        const dataWithEulWithPeriod = response.data.map(row => ({
          ...row,
          eulWithPeriod:
            row.eul && row.period
              ? `${row.eul} ${row?.period?.description}`
              : '',
        }));

        return {
          ...response,
          data: dataWithEulWithPeriod,
        };
      },
      providesTags: ['AssetType'],
    }),
    getAssetTypesListById: builder.query<AssetTypeList, any>({
      query: ({ id }) => {
        return {
          url: `asset-group/${id}`,
        };
      },
    }),
    getAssetTypesProblemTroubleshootsById: builder.query<
      AssetTypeProblemTroubleShoot[],
      any
    >({
      query: ({ assetId, problemId }) => {
        return {
          url: `asset-group/${assetId}/problem/${problemId}/troubleshoot`,
        };
      },
    }),
    createAssetType: builder.mutation<void, AssetTypeMutate>({
      query: (body: AssetTypeEditFormData) => {
        const abody: AssetTypeMutate = {
          name: body.name,
          nte: Number(body.nte),
          status: 1,
          coreAssetTypeId: body.coreAssetType.id,
          priorityId: body.priority.id,
          warrantyNte: Number(body.warrantyNte),
          eul: Number(body.eul),
          periodCode: body.periodCode,
          refrigerantTracking: body.refrigerantTracking ? 1 : 0,
        };
        return {
          url: 'asset-group',
          method: 'post',
          body: abody,
        };
      },
      invalidatesTags: ['AssetType'],
    }),
    updateAssetType: builder.mutation<void, AssetTypeMutate>({
      query: (assetType: AssetTypeEditFormData) => {
        const reducedAssetType: AssetTypeMutate = {
          name: assetType.name,
          nte: Number(assetType.nte),
          status: assetType.status,
          coreAssetTypeId: assetType.coreAssetType.id,
          priorityId: assetType.priority.id,
          warrantyNte: Number(assetType.warrantyNte),
          eul: Number(assetType.eul),
          periodCode: assetType.periodCode,
          refrigerantTracking: assetType.refrigerantTracking ? 1 : 0,
        };
        return {
          url: `asset-group/${assetType.id}`,
          method: 'put',
          body: reducedAssetType,
        };
      },
      invalidatesTags: ['AssetType'],
    }),
    deleteAssetType: builder.mutation<void, AssetTypeMutate>({
      query: assetType => ({
        url: 'asset-group',
        method: 'delete',
        body: assetType,
      }),
      invalidatesTags: ['AssetType'],
    }),
    getCoreAssetTypesList: builder.query<CoreAssetType[], any>({
      query: params => {
        return {
          url: '/lookup/coreassettype',
          params: {
            ...params,
            t: 50,
          },
        };
      },
    }),
    getPeriodEULList: builder.query<EULPeriod[], void>({
      query: () => {
        return {
          url: '/lookup/period/eul',
        };
      },
    }),
    getPriorityList: builder.query<BaseType<Priority[]>, QueryParams>({
      query: params => {
        return {
          url: `priority`,
          params,
        };
      },
    }),
  }),
});

export const {
  useGetAssetTypesListQuery,
  useGetAssetTypesForInventoryListQuery,
  useLazyGetAssetTypesListQuery,
  useGetAssetTypesListByIdQuery,
  useLazyGetAssetTypesListByIdQuery,
  useLazyGetAssetTypesProblemTroubleshootsByIdQuery,
  useCreateAssetTypeMutation,
  useUpdateAssetTypeMutation,
  useDeleteAssetTypeMutation,
  useGetCoreAssetTypesListQuery,
  useGetPeriodEULListQuery,
  useGetPriorityListQuery,
} = assetTypeApi;
