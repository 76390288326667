import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme, useMediaQuery } from '@mui/material';
import { ECSignUpWizardTemplate } from 'app/components';
import { ECStepsContainer } from 'app/components/ECSteps';
import { Step2 } from './step-2';
import { Step1 } from './step-1';
import { useSignUpUserMutation } from 'services/userApi';
import _ from 'lodash';
import { Step3 } from './step-3';
import { Step4 } from './step-4';
import { useNavigate } from 'react-router';

export function SignUpWizardPage() {
  const theme = useTheme();
  const [data, setData] = useState<any>(null);
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();
  const previousStep = () => {
    if (currentStep > 1) setCurrentStep(prev => prev - 1);
  };

  const [doSignUpUser, { isSuccess }] = useSignUpUserMutation();

  useEffect(() => {
    if (isSuccess) navigate('/signup/completion');
  }, [isSuccess]);

  const renderTabs = useMemo(
    () => [
      {
        value: 1,
        label: '1',
        subLabel: 'Your Info',
        subLabelBold: true,
        hasBreakLineName: true,
        content: (
          <Step1
            existingData={data}
            onSubmit={data => {
              setData(data);
              setCurrentStep(2);
            }}
          />
        ),
      },
      {
        value: 2,
        label: '2',
        subLabel: 'Company Info',
        subLabelBold: true,
        hasBreakLineName: true,
        content: (
          <Step2
            existingData={data}
            onSubmit={formFields => {
              const body = {
                user: {
                  ...data.user,
                  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
                company: formFields.company,
                address: formFields.company.address,
              };
              setData(body);
              setCurrentStep(3);
            }}
          />
        ),
      },
      {
        value: 3,
        label: '3',
        subLabel: 'Industry',
        subLabelBold: true,
        hasBreakLineName: true,
        content: (
          <Step3
            onSubmit={() => {
              setCurrentStep(4);
            }}
          />
        ),
      },
      {
        value: 4,
        label: '4',
        subLabel: 'Payment',
        subLabelBold: true,
        content: (
          <Step4
            existingData={data}
            onSubmit={async (formBody, fields) => {
              const countryCode = formBody.company.address.countryCode.value;
              const body = {
                user: formBody.user,
                company: {
                  ...formBody.company,
                  address: {
                    ...formBody.company.address,
                    countryCode,
                  },
                },
                paymentInfo: formBody.payment,
              };

              doSignUpUser(body);
            }}
          />
        ),
        hasBreakLineName: true,
      },
    ],
    [data, doSignUpUser],
  );

  return (
    <>
      <Helmet>
        <title>Sign In Page</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECSignUpWizardTemplate>
        <ECStepsContainer
          showBackButton
          onClickBackButton={previousStep}
          stepsContainerProps={{
            paddingLeft: isMobileSize ? 0 : 18,
            paddingTop: isMobileSize ? 10 : 4,
            paddingRight: isMobileSize ? 4 : 33,
          }}
          tabs={renderTabs}
          currentStep={currentStep}
          onChangeStep={setCurrentStep}
        />
      </ECSignUpWizardTemplate>
    </>
  );
}
