import { EditForm2 } from 'app/components/ECDynamicPageTemplate/EditForm2';
import { useCallback, useEffect, useState } from 'react';
import { useCopyTradeAssignmentToBranchIdsMutation } from 'services/tradeApi';
import * as _ from 'lodash';
import { useGetBranchesListQuery } from 'services/branchApi';
import { setSnackbar } from 'store/slice/page';
import { useDispatch } from 'react-redux';

interface CopyProps {
  row: any;
  onClose?: () => void;
}

const formConfig = require('./fancy_form_config_copy.json');

export const Copy = ({ row, onClose }: CopyProps) => {
  const dispatch = useDispatch();

  const [
    doCopyTradeAssignments,
    {
      isError: isCopyError,
      error: copyError,
      isSuccess: isCopySuccess,
      isLoading: isCopyLoading,
      reset,
    },
  ] = useCopyTradeAssignmentToBranchIdsMutation();

  const [formFields, setFormFields] = useState(formConfig.fields);

  useEffect(() => {
    const toLocationsField = formFields.find(
      field => field.fieldName === 'toLocations',
    );
    if (toLocationsField) {
      toLocationsField.useQuery = useGetBranchesListQuery;
      toLocationsField.obAlias = 'brnch.name';
    }
  }, [row]);

  const useUpdate = useCallback(() => {
    const doCopy = async data => {
      const branchIds = _.map(data.toLocations, 'id');

      const isCurrentBranchIncluded = _.some(
        branchIds,
        id => id === row.branch.id,
      );
      if (isCurrentBranchIncluded) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: 'Cannot copy to the same location',
          }),
        );
        return;
      }

      doCopyTradeAssignments({
        tradeId: row.trade.id,
        branchIds,
        spRanks: row.sp.map(sp => ({
          companyId: sp.id,
          position: sp.position,
        })),
      });
    };

    return [
      doCopy,
      {
        isError: isCopyError,
        error: copyError,
        isLoading: isCopyLoading,
        isSuccess: isCopySuccess,
        reset,
      },
    ];
  }, [
    row,
    isCopyError,
    copyError,
    isCopyLoading,
    isCopySuccess,
    reset,
    doCopyTradeAssignments,
  ]);

  return (
    <EditForm2
      row={row}
      formConfig={formConfig.config}
      formFields={formFields}
      useUpdateMutation={useUpdate}
      onClose={onClose}
      isLoading={false}
      hideToolBar
      onlyEdit
    />
  );
};
