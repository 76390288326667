import {
  ECBox,
  ECDynamicPageTemplate,
  ECEasyFormFieldType,
} from 'app/components';
import { useTranslation } from 'react-i18next';
import { loadPageScopes } from 'utils/pageScopes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint } from 'store/slice/page';
import { Popover } from '@mui/material';
import { AssigneeUsersTable } from './AssigneeUsersTable';
import {
  useLazyGetAssigneeUsersByInventoryStorageIdQuery,
  useLazyGetInventoryStorageLocationsListQuery,
  useLazyGetInventoryStorageByIdQuery,
  useUpdateInventoryStorageLocationMutation,
  useCreateInventoryStorageLocationMutation,
} from 'services/inventoryApi';
import { populateDropDownFields } from 'utils/pageUtils';
import { themes } from 'styles/theme/themes';
import { useGetServiceRequestBranchesListQuery } from 'services/branchApi';
import { getAddressDataFromFieldValues } from 'utils/address';
import { useGetInventoryStorageTypesQuery } from 'services/lookupApi';
import { Lookup } from 'types/Lookup';
import { useGetAllCompanyUsersQuery } from 'services/userApi';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { P } from 'types/Permission';

const fancyFormVehicleElementsDetails = require('./fancy_form_config_vehicle_details.json');
const fancyFormStoreLocationElementsDetails = require('./fancy_form_config_store_location_details.json');
const fancyFormWarehouseElementsDetails = require('./fancy_form_config_warehouse_details.json');
const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const fancyFormElementsCreate = require('./fancy_form_config_create.json');

const addressFields = [
  'address.line1',
  'address.line2',
  'address.zipCodePostalCode',
  'address.cityName',
  'address.stateProvinceCode',
  'address.countryCode',
];
const addressValues = [
  'line1',
  'line2',
  'zipCodePostalCode',
  'cityName',
  'stateProvinceCode',
  'countryCode',
];

export const StorageType = {
  Warehouse: { id: 1, name: 'Warehouse' },
  Vehicle: { id: 2, name: 'Vehicle' },
  StoreLocation: { id: 3, name: 'Store Location' },
};

export const InventoryStorageLocations = ({ marginTop = true }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isEditDrawerType, setIsEditDrawerType] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [formElementsEditFields, setFormElementsEditFields] = useState<any>(
    fancyFormElementsEdit.fields,
  );

  const [fancyFormElementsCreateFields, setFancyFormElementsCreateFields] =
    useState<any>(fancyFormElementsCreate.fields);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [row, setSelectedRow] = useState<any>(null);
  const [customTitle, setCustomTitle] = useState<string>('');
  const [hasEditInventoryStoragePermission] = useHasPermission([
    P.EditInventoryStorage,
  ]);

  useEffect(() => {
    loadPageScopes('inventoryStorage');
    dispatch(setFilterEndpoint('/filter/inventory'));
  }, []);

  const { data: userProfile } = useGetUserProfileQuery();

  // populate assignedUsers dropdown query EDIT
  useEffect(() => {
    if (userProfile?.company?.id) {
      const assignedTo = formElementsEditFields.find(
        field => field.fieldName === 'assignedUsers',
      );
      if (assignedTo) {
        assignedTo.useQuery = useGetAllCompanyUsersQuery;
        assignedTo.obAlias = 'firstName';
        assignedTo.sbAlias = 'usr.firstName';
        assignedTo.queryParams = { companyId: userProfile?.company?.id };
        assignedTo.extraSearchField = 'usr.lastName';
        assignedTo.extraSearchFieldGroup = 'or';
      }
    }
  }, [userProfile?.company?.id]);

  // populate assignedUsers dropdown query CREATE
  useEffect(() => {
    if (userProfile?.company?.id) {
      const assignedTo = fancyFormElementsCreateFields.find(
        field => field.fieldName === 'assignedUsers',
      );
      if (assignedTo) {
        assignedTo.useQuery = useGetAllCompanyUsersQuery;
        assignedTo.obAlias = 'firstName';
        assignedTo.sbAlias = 'usr.firstName';
        assignedTo.queryParams = { companyId: userProfile?.company?.id };
        assignedTo.extraSearchField = 'usr.lastName';
        assignedTo.extraSearchFieldGroup = 'or';
      }
    }
  }, [userProfile?.company?.id]);

  useEffect(() => {
    if (row && formElementsEditFields) {
      const branchField = formElementsEditFields?.find(
        field => field.fieldName === 'branch.name',
      );
      branchField.queryParams = { st: 1 };
      branchField.useQuery = useGetServiceRequestBranchesListQuery;
      branchField.obAlias = 'brnch.name';
      branchField.value = row?.branch
        ? { ...row?.branch, id: row?.branchId }
        : undefined;

      populateDropDownFields({
        responseData: Object.keys(StorageType).map(
          key => StorageType[key].name,
        ),
        createFormFields: formElementsEditFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'inventoryStorageType.name',
      });
      const inventoryStorageTypeField = formElementsEditFields.find(
        field => field.fieldName === 'inventoryStorageType.name',
      );
      const licenseField = formElementsEditFields.find(
        field => field.fieldName === 'licensePlate',
      );
      inventoryStorageTypeField.value = row.inventoryStorageType.name;

      const addressDetailGroup = formElementsEditFields.find(
        field => field.groupName === 'addressDetailGroup',
      );
      if (row.inventoryStorageType.name === StorageType.StoreLocation.name) {
        const nameField = formElementsEditFields?.find(
          field => field.fieldName === 'name',
        );
        nameField.disabled = true;
        branchField.visible = true;
        branchField.required = true;
        licenseField.visible = false;
        addressFields.forEach((fieldName, index) => {
          const field =
            formElementsEditFields.find(
              field => field.fieldName === fieldName,
            ) ??
            addressDetailGroup?.subFields?.find(
              field => field.fieldName === fieldName,
            );
          if (field) {
            field.value = row?.branch?.mailingAddress?.[addressValues[index]];
            field.readOnly = true;
            field.visible = true;
            field.required = false;
          }
        });
        licenseField.visible = false;
        addressDetailGroup?.subFields?.forEach(subField => {
          subField.visible = true;
        });
      } else if (row.inventoryStorageType.name === StorageType.Vehicle.name) {
        branchField.visible = false;
        branchField.required = false;
        licenseField.visible = true;
        addressFields.forEach(fieldName => {
          const field =
            formElementsEditFields.find(
              field => field.fieldName === fieldName,
            ) ??
            addressDetailGroup?.subFields?.find(
              field => field.fieldName === fieldName,
            );
          if (field) {
            field.visible = false;
            field.required = false;
          }
        });
      } else {
        branchField.visible = false;
        branchField.required = false;
        licenseField.visible = false;
        addressFields.forEach((fieldName, index) => {
          const field =
            formElementsEditFields.find(
              field => field.fieldName === fieldName,
            ) ??
            addressDetailGroup?.subFields?.find(
              field => field.fieldName === fieldName,
            );
          if (field) {
            field.value = row?.address?.[addressValues[index]];
            field.visible = true;
            field.readOnly = false;
            if (
              fieldName !== 'address.line2' &&
              row.inventoryStorageType.name === StorageType.Warehouse.name
            ) {
              field.required = true;
            }
          }
        });
      }
    }
  }, [row]);

  const handleOutputChange = useCallback(
    (output: ECEasyFormFieldType[], fieldName?: string) => {
      if (
        fieldName !== 'branch.name' &&
        fieldName !== 'inventoryStorageType.name'
      ) {
        return;
      }
      const addressDetailGroup = output.find(
        field => field.groupName === 'addressDetailGroup',
      );

      if (fieldName === 'branch.name') {
        const locationField = output.find(
          field => field.fieldName === 'branch.name',
        );

        // if storage type is store location, NAME field should be the branch name. I hide name field and show locationName becase it was not working using the same field
        const locationNameField = output.find(
          field => field.fieldName === 'locationName',
        );
        const inventoryStorageTypeField = output.find(
          field => field.fieldName === 'inventoryStorageType.name',
        );
        if (
          inventoryStorageTypeField?.value === StorageType.StoreLocation.name &&
          locationNameField
        ) {
          locationNameField.value = (locationField?.value as any)?.name;
        }
        const mailingAddress = (locationField?.value as any)?.mailingAddress;
        if (mailingAddress) {
          addressFields.forEach((fieldName, index) => {
            const field =
              output.find(field => field.fieldName === fieldName) ??
              addressDetailGroup?.subFields?.find(
                field => field.fieldName === fieldName,
              );
            if (field) {
              field.value = mailingAddress[addressValues[index]];
              field.visible = true;
              field.readOnly = true;
              if (
                fieldName !== 'address.line2' &&
                row.inventoryStorageType.name === StorageType.Warehouse.name
              ) {
                field.required = true;
              }
            }
          });
          setFormElementsEditFields(output);
        }
      } else if (fieldName === 'inventoryStorageType.name') {
        const inventoryStorageTypeField = output.find(
          field => field.fieldName === 'inventoryStorageType.name',
        );
        const licenseField = output.find(
          field => field.fieldName === 'licensePlate',
        );
        const branchField = output.find(
          field => field.fieldName === 'branch.name',
        );

        if (inventoryStorageTypeField && licenseField && branchField) {
          if (inventoryStorageTypeField.value === StorageType.Vehicle.name) {
            branchField.visible = false;
            branchField.required = false;
            licenseField.visible = true;
            addressFields.forEach(fieldName => {
              const field =
                output.find(field => field.fieldName === fieldName) ??
                addressDetailGroup?.subFields?.find(
                  field => field.fieldName === fieldName,
                );
              if (field) {
                field.visible = false;
                field.readOnly = false;
                field.value = '';
                field.required = false;
                field.isValid = true;
                field.validationMessage = undefined;
              }
            });

            // if storage type is store location, NAME field should be the branch name. I hide name field and show locationName becase it was not working using the same field
            const nameField = output.find(field => field.fieldName === 'name');
            const locationNameField = output.find(
              field => field.fieldName === 'locationName',
            );
            if (nameField && locationNameField) {
              nameField.visible = true;
              nameField.disabled = false;
              locationNameField.visible = false;
            }
          } else if (
            inventoryStorageTypeField.value === StorageType.StoreLocation.name
          ) {
            // if storage type is store location, NAME field should be the branch name. I hide name field and show locationName becase it was not working using the same field
            const nameField = output.find(field => field.fieldName === 'name');
            const locationNameField = output.find(
              field => field.fieldName === 'locationName',
            );
            if (nameField && locationNameField) {
              nameField.visible = false;
              locationNameField.visible = true;
            }
            branchField.visible = true;
            branchField.required = true;
            licenseField.visible = false;
            addressFields.forEach((fieldName, index) => {
              const field =
                output.find(field => field.fieldName === fieldName) ??
                addressDetailGroup?.subFields?.find(
                  field => field.fieldName === fieldName,
                );
              if (field) {
                field.visible = true;
                field.readOnly = true;
                field.value =
                  row?.branch?.mailingAddress?.[addressValues[index]];
                field.required = false;
                field.isValid = true;
                field.validationMessage = undefined;
              }
            });
          } else {
            branchField.visible = false;
            branchField.required = false;
            licenseField.visible = false;
            addressFields.forEach((fieldName, index) => {
              const field =
                output.find(field => field.fieldName === fieldName) ??
                addressDetailGroup?.subFields?.find(
                  field => field.fieldName === fieldName,
                );
              if (field) {
                field.visible = true;
                field.readOnly = false;
                field.value = row?.address?.[addressValues[index]];
                field.isValid = true;
                field.validationMessage = undefined;
                if (
                  fieldName !== 'address.line2' &&
                  inventoryStorageTypeField.value === StorageType.Warehouse.name
                ) {
                  field.required = true;
                }
              }
            });

            // if storage type is store location, NAME field should be the branch name. I hide name field and show locationName becase it was not working using the same field
            const nameField = output.find(field => field.fieldName === 'name');
            const locationNameField = output.find(
              field => field.fieldName === 'locationName',
            );
            if (nameField && locationNameField) {
              nameField.visible = true;
              nameField.disabled = false;
              locationNameField.visible = false;
            }
          }
          setFormElementsEditFields(output);
        }
      }
    },
    [row],
  );

  const handleCreateFormOutputChange = useCallback(
    (output: ECEasyFormFieldType[], fieldName?: string) => {
      if (
        fieldName !== 'branch.name' &&
        fieldName !== 'inventoryStorageType.name'
      ) {
        return;
      }
      const addressDetailGroup = output.find(
        field => field.groupName === 'addressDetailGroup',
      );

      const nameField = output.find(field => field.fieldName === 'name');

      if (fieldName === 'inventoryStorageType.name') {
        const inventoryStorageTypeField = output.find(
          field => field.fieldName === 'inventoryStorageType.name',
        );
        const licenseField = output.find(
          field => field.fieldName === 'licensePlate',
        );

        const address1Field = output.find(
          field => field.fieldName === 'address.line1',
        );
        const address2Field = output.find(
          field => field.fieldName === 'address.line2',
        );
        const branchField = output.find(
          field => field.fieldName === 'branch.name',
        );

        // set name field disabled as false because we are setting it disabled when changing to store location
        if (nameField) {
          nameField.disabled = false;
        }

        if (
          licenseField &&
          address1Field &&
          address2Field &&
          branchField &&
          (inventoryStorageTypeField?.value as Lookup)?.name ===
            StorageType.Vehicle.name
        ) {
          licenseField.visible = true;
          addressDetailGroup?.subFields?.forEach(subField => {
            subField.visible = false;
          });
          address1Field.visible = false;
          address2Field.visible = false;
          branchField.visible = false;

          // set name field disabled as false because we are setting it disabled when changing to store location
          if (nameField) {
            nameField.disabled = false;
          }
        } else if (
          licenseField &&
          address1Field &&
          address2Field &&
          branchField
        ) {
          const isStorageLocationType =
            (inventoryStorageTypeField?.value as Lookup)?.name ===
            StorageType.StoreLocation.name;

          // if storage type is store location, NAME field should be the branch name
          if (isStorageLocationType && nameField) {
            nameField.disabled = true;
            nameField.value = (branchField.value as any)?.name ?? '';
          }
          branchField.visible = isStorageLocationType;
          branchField.required = isStorageLocationType;
          licenseField.visible = false;
          addressDetailGroup?.subFields?.forEach(subField => {
            subField.visible = true;
          });
          address1Field.visible = true;
          address2Field.visible = true;
        }

        addressFields.forEach((fieldName, index) => {
          const field =
            output.find(field => field.fieldName === fieldName) ??
            addressDetailGroup?.subFields?.find(
              field => field.fieldName === fieldName,
            );
          if (field) {
            const isWarehouse =
              (inventoryStorageTypeField?.value as Lookup)?.name ===
              StorageType.Warehouse.name;
            field.isValid = true;
            field.validationMessage = undefined;
            field.disabled = !isWarehouse;
            field.readOnly = !isWarehouse;
            field.required = isWarehouse && fieldName !== 'address.line2';
          }
        });

        setFancyFormElementsCreateFields(output);
      } else if (fieldName === 'branch.name') {
        const locationField = output.find(
          field => field.fieldName === 'branch.name',
        );

        // if storage type is store location, NAME field should be the branch name
        const inventoryStorageTypeField = output.find(
          field => field.fieldName === 'inventoryStorageType.name',
        );
        if (
          (inventoryStorageTypeField?.value as any)?.name ===
            StorageType.StoreLocation.name &&
          nameField
        ) {
          nameField.disabled = true;
          nameField.value = (locationField?.value as any)?.name ?? '';
        } else {
          // set name field disabled as false because we are setting it disabled when changing to store location
          if (nameField) {
            nameField.disabled = false;
          }
        }
        const mailingAddress = (locationField?.value as any)?.mailingAddress;
        if (mailingAddress) {
          addressFields.forEach((fieldName, index) => {
            const field =
              output.find(field => field.fieldName === fieldName) ??
              addressDetailGroup?.subFields?.find(
                field => field.fieldName === fieldName,
              );
            if (field) {
              field.value = mailingAddress[addressValues[index]];
              field.visible = true;
              field.readOnly = true;
              if (
                (inventoryStorageTypeField?.value as any)?.name ===
                StorageType.Warehouse.name
              ) {
                field.required = true;
              }
            }
          });
          setFancyFormElementsCreateFields(output);
        }
      }
    },
    [],
  );

  const resetFields = useCallback(() => {
    setIsDrawerOpen(false);
    if (isEditDrawerType) {
      resetUpdate();
      setIsEditDrawerType(false);
      setFormElementsEditFields(fancyFormElementsEdit.fields);
      return;
    }
    resetCreateInventoryStorage();
    setFancyFormElementsCreateFields(fancyFormElementsCreate.fields);
  }, []);

  const onCloseModal = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);

    setSelectedRow(null);
  }, []);

  const [triggerAssigneeUsers, assigneeUsersList] =
    useLazyGetAssigneeUsersByInventoryStorageIdQuery();

  const {
    data: assigneeUsers,
    isFetching: isFetchingAssigneeUsers,
    isLoading: isLoadingAssigneeUsers,
    isSuccess: isSuccessAssigneeUsers,
  } = assigneeUsersList;

  const [triggerById, resultById] = useLazyGetInventoryStorageByIdQuery();

  const { data: result, isFetching, isLoading, isSuccess } = resultById;

  const handleRowClick = useCallback((row: any) => {
    setIsDrawerOpen(true);
    setSelectedRow(row);
    setIsEditDrawerType(true);
    triggerAssigneeUsers({ storageId: row?.id });
  }, []);

  useEffect(() => {
    if (row?.id) {
      triggerAssigneeUsers({ storageId: row.id });
    }
  }, [row?.id, triggerAssigneeUsers]);

  const onClickViewUsersTable = useCallback((row, event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setSelectedRow(row);
  }, []);

  useEffect(() => {
    if (row) {
      setCustomTitle(row?.name);

      // need to reset name and locationName fields from EDIT when opening the drawer based on row.inventoryStorageType.name
      const nameField = formElementsEditFields.find(
        field => field.fieldName === 'name',
      );
      const locationNameField = formElementsEditFields.find(
        field => field.fieldName === 'locationName',
      );
      if (nameField && locationNameField) {
        if (row.inventoryStorageType.name === StorageType.StoreLocation.name) {
          nameField.visible = false;
          locationNameField.visible = true;
          locationNameField.value = row?.branch?.name ?? '';
        } else {
          nameField.visible = true;
          nameField.disabled = false;
          locationNameField.visible = false;
          locationNameField.value = '';
        }
      }
    }
  }, [row]);

  const useGetById = useCallback(() => {
    const doGetById = async row => {
      if (!row) return false;
      await triggerById({ id: row.id });
      await triggerAssigneeUsers({ storageId: row.id });
    };

    const mappedRes = !resultById.data
      ? null
      : {
          ...result,
          assignedUsers: assigneeUsers?.map(user => ({
            id: user.id,
            name: user.fullName,
          })),
        };

    return [
      doGetById,
      {
        data: mappedRes,
        loading: isLoading || isLoadingAssigneeUsers,
        isSuccess: isSuccess || isSuccessAssigneeUsers,
        isFetching: isFetching || isFetchingAssigneeUsers,
      },
    ];
  }, [
    resultById.data,
    result,
    assigneeUsers,
    isLoading,
    isLoadingAssigneeUsers,
    isSuccess,
    isSuccessAssigneeUsers,
    isFetching,
    isFetchingAssigneeUsers,
    triggerById,
    triggerAssigneeUsers,
  ]);

  const [
    doUpdateInventoryStorageLocation,
    {
      data: updateData,
      isError: isUpdateError,
      error: updateError,
      isLoading: isLoadingUpdate,
      isSuccess: isUpdateSuccess,
      reset: resetUpdate,
    },
  ] = useUpdateInventoryStorageLocationMutation();

  const useUpdateStorageLocation = useCallback(() => {
    const doUpdate = async data => {
      const body: any = {
        id: data.id,
        name: data.name,
        assigneeUserIds: data.assignedUsers?.map(user => user.id),
        deleteAssigneeUsers: data?.assignedUsers?.length === 0,
      };

      if (
        data.inventoryStorageType &&
        data.inventoryStorageType.name === StorageType.Vehicle.name
      ) {
        body.licensePlate = data.licensePlate;
        body.inventoryStorageType = StorageType.Vehicle;
        body.address = null;
        body.addressId = null;
        body.branchId = null;
      } else if (
        data.inventoryStorageType &&
        data.inventoryStorageType.name === StorageType.StoreLocation.name
      ) {
        if (data?.address?.line1) {
          body.address = data.address;
        } else if (row?.address?.line1) {
          body.deleteAddress = true;
        }
        body.branchId = data?.branch?.name?.id ?? data?.branch?.id;
        body.inventoryStorageType = StorageType.StoreLocation;
        body.licensePlate = '';
        // when its store location send locationName as name
        body.name = data.locationName;
      } else if (
        data.inventoryStorageType &&
        data.inventoryStorageType.name === StorageType.Warehouse.name
      ) {
        if (data?.address?.line1) {
          body.address = data.address;
        } else if (row?.address?.line1) {
          body.deleteAddress = true;
        }
        body.branchId = null;
        body.licensePlate = '';
        body.inventoryStorageType = StorageType.Warehouse;
      }
      doUpdateInventoryStorageLocation(body);
    };
    return [
      doUpdate,
      {
        data: updateData,
        isError: isUpdateError,
        error: updateError,
        isLoading: isLoadingUpdate,
        isSuccess: isUpdateSuccess,
        reset: resetUpdate,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isLoadingUpdate,
    isUpdateSuccess,
    resetUpdate,
    updateData,
    row,
  ]);

  const [
    doCreateInventoryStorageLocation,
    {
      data: createInventoryStorageData,
      isError: isCreateInventoryStorageError,
      error: createInventoryStorageError,
      isLoading: isCreateInventoryStorageLoading,
      isSuccess: isCreateInventoryStorageSuccess,
      reset: resetCreateInventoryStorage,
    },
  ] = useCreateInventoryStorageLocationMutation();

  const useCreateStorageLocation = useCallback(() => {
    const doCreateStorage = async data => {
      data['address.countryCode'] = data['address.countryCode']?.value?.toUpperCase() || data['address.countryCode'];
      const selectedInventoryStorageTypeId =
        data['inventoryStorageType.name']?.id;
      const body: any = {
        name: data.name,
        inventoryStorageTypeId: selectedInventoryStorageTypeId,
        assigneeUserIds: data.assignedUsers?.map(user => user.id),
      };

      if (selectedInventoryStorageTypeId === StorageType.Vehicle.id) {
        body.licensePlate = data.licensePlate;
      } else if (
        selectedInventoryStorageTypeId === StorageType.StoreLocation.id
      ) {
        body.address = getAddressDataFromFieldValues(data);
        body.branchId = data['branch.name'] ? data['branch.name'].id : null;
      } else {
        body.address = getAddressDataFromFieldValues(data);
      }
      doCreateInventoryStorageLocation(body);
    };
    return [
      doCreateStorage,
      {
        data: createInventoryStorageData,
        isError: isCreateInventoryStorageError,
        error: createInventoryStorageError,
        isLoading: isCreateInventoryStorageLoading,
        isSuccess: isCreateInventoryStorageSuccess,
        reset: resetCreateInventoryStorage,
      },
    ];
  }, [
    isCreateInventoryStorageError,
    createInventoryStorageError,
    isCreateInventoryStorageLoading,
    isCreateInventoryStorageSuccess,
    resetCreateInventoryStorage,
    createInventoryStorageData,
  ]);

  useEffect(() => {
    if (isDrawerOpen && !isEditDrawerType) {
      const inventoryStorageTypeField = fancyFormElementsCreateFields.find(
        field => field.fieldName === 'inventoryStorageType.name',
      );
      const branchField = fancyFormElementsCreateFields.find(
        field => field.fieldName === 'branch.name',
      );

      inventoryStorageTypeField.useQuery = useGetInventoryStorageTypesQuery;
      branchField.queryParams = { st: 1 };
      branchField.useQuery = useGetServiceRequestBranchesListQuery;
      branchField.obAlias = 'brnch.name';
    }
  }, [isDrawerOpen, isEditDrawerType]);

  const formDetailsFields = useMemo(() => {
    if (row?.inventoryStorageType?.name === StorageType.StoreLocation.name)
      return fancyFormStoreLocationElementsDetails?.fields;
    if (row?.inventoryStorageType?.name === StorageType.Vehicle.name)
      return fancyFormVehicleElementsDetails?.fields;
    return fancyFormWarehouseElementsDetails?.fields;
  }, [
    row?.inventoryStorageType?.name,
    fancyFormStoreLocationElementsDetails,
    fancyFormVehicleElementsDetails,
    fancyFormWarehouseElementsDetails,
  ]);

  return (
    <>
      <ECDynamicPageTemplate
        pageTitle={t('translation:pages.inventory.title')}
        showStatusActiveFilter={false}
        enableExport
        showEditButton={hasEditInventoryStoragePermission}
        useLazyGetListQuery={useLazyGetInventoryStorageLocationsListQuery}
        useCreateMutation={useCreateStorageLocation}
        useUpdateMutation={useUpdateStorageLocation}
        detailsConfig={fancyFormWarehouseElementsDetails.config}
        detailsFields={formDetailsFields}
        onRowClick={handleRowClick}
        createFormConfig={fancyFormElementsCreate.config}
        createFormFields={fancyFormElementsCreateFields}
        editFormConfig={fancyFormElementsEdit.config}
        editFormFields={formElementsEditFields}
        onChange={
          isEditDrawerType ? handleOutputChange : handleCreateFormOutputChange
        }
        onDrawerClose={resetFields}
        moduleName="inventory"
        marginTop={marginTop}
        onClickViewUsersTable={onClickViewUsersTable}
        hasUsersTablePreviewData={true}
        selectedChipUsersTable={row?.id}
        customDrawerTitle={customTitle}
        withDrawer
        isDraggableColumns
        useLazyGetByIdQuery={useGetById}
        drawerTitleBarBGColor={themes.light.palette.other.divider}
        onAddNewClick={() => {
          isEditDrawerType && setIsEditDrawerType(false);
          setIsDrawerOpen(true);
        }}
        // onCreate={onCreate}
      />
      <Popover
        id={row?.id}
        open={open}
        anchorEl={anchorEl}
        onClose={onCloseModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          minHeight: '300px',
          minWidth: '300px',
        }}
      >
        <ECBox
          minHeight={150}
          minWidth={750}
          sx={{
            '&::before': {
              backgroundColor: 'white',
              content: '""',
              display: 'block',
              position: 'absolute',
              width: 12,
              height: 12,
              top: -6,
              transform: 'rotate(45deg)',
              left: 'calc(50% - 6px)',
            },
          }}
          p={2}
        >
          <AssigneeUsersTable id={row?.id} />
        </ECBox>
      </Popover>
    </>
  );
};
