import { useEffect, useState } from 'react';
import { BoxProps } from '@material-ui/core';
import { ECTextField } from '../ECTextField';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import YouTube from 'react-youtube';
import { getParamsFromUrl } from 'utils/strings/get-params-from-url';
import { useTheme, useMediaQuery } from '@mui/material';

export interface ECVideoFieldProps extends Omit<BoxProps, 'onChange'> {
  label?: string;
  placeholder?: string;
  error?: boolean | null;
  helperText?: string | null;
  fieldName?: string;
  sx?: any;
  defaultValue?: string;
  isLoading?: boolean;
  isReadOnly?: boolean;
  videoUrl?: string;
  onChange?: (url: string) => void;
}

const YOUTUBE_VIDEO_ID_PARAM = 'v';

export const ECVideoField = ({
  label,
  placeholder,
  error,
  helperText,
  fieldName,
  defaultValue,
  isLoading,
  videoUrl,
  isReadOnly,
  onChange,
  ...boxProps
}: ECVideoFieldProps) => {
  const [videoId, setVideoId] = useState<string | null>(null);
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (!videoUrl) {
      setVideoId(null);
      return;
    }

    const params = getParamsFromUrl(videoUrl);
    setVideoId(params.get(YOUTUBE_VIDEO_ID_PARAM));
  }, [videoUrl]);

  if (isReadOnly) {
    if (!videoId) {
      return <></>;
    }

    return (
      <ECBox display="flex" mt={2} justifyContent="center">
        <YouTube
          videoId={videoId}
          opts={isMobileSize ? { height: '180', width: '320' } : undefined}
        />
      </ECBox>
    );
  }

  return (
    <ECBox display="flex" flexDirection="column" {...boxProps}>
      {label && (
        <ECTypography
          variant="caption"
          color={theme => theme.palette.text.secondary}
          mb={2}
        >
          {label}
        </ECTypography>
      )}

      <ECTextField
        id={fieldName}
        name={fieldName}
        label={placeholder}
        placeholder={placeholder}
        value={videoUrl}
        onChange={event => onChange?.(event.target.value)}
      />

      {videoId && (
        <ECBox mt={2}>
          <YouTube
            videoId={videoId}
            opts={isMobileSize ? { height: '180', width: '320' } : undefined}
          />
        </ECBox>
      )}
    </ECBox>
  );
};
