import { useCallback, useEffect, useState } from 'react';
import { ECBox, ECEasyFormCreate, ECEasyFormFieldType } from 'app/components';
import _ from 'lodash';
import { useMediaQuery, useTheme } from '@mui/material';

interface Step3Props {
  existingData?: any;
  onClose?: () => void;
  onSubmit?: (data: any) => void;
}

const fancyFormElementsCreate = require('./fancy_form_config_step3_create.json');

export const Step3 = ({ existingData, onClose, onSubmit }: Step3Props) => {
  const theme = useTheme();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const handleExistingData = useCallback(
    (field: ECEasyFormFieldType) => {
      if (!field) return;

      const value =
        field.fieldName === 'video'
          ? field.value || ''
          : _.get(existingData, field.fieldName) || field.value || '';

      return { ...field, value };
    },
    [existingData],
  );

  const [formFields, setFormFields] = useState<any[]>(
    () => fancyFormElementsCreate.fields?.map(handleExistingData) || [],
  );

  useEffect(() => {
    setFormFields(
      fancyFormElementsCreate.fields?.map(handleExistingData) || [],
    );
  }, [existingData, handleExistingData]);

  const submit = useCallback(() => {
    const doSubmit = async data => {
      delete data.undefined;
      delete data.video;
      onSubmit?.({ ...existingData, ...data });
    };
    return [doSubmit, {}];
  }, [onSubmit, existingData]);

  const handleChangeData = useCallback((formFields, fieldName) => {
    if (fieldName === 'industrialSector') {
      const industrialSectorField = formFields.find(
        field => field.fieldName === 'industrialSector',
      );
      setSaveButtonDisabled(!industrialSectorField?.value);
    }
  }, []);

  return (
    <ECBox
      paddingInline={isMobileSize ? 0 : `15%`}
      alignItems={'center'}
      justifyContent={'center'}
      display={'flex'}
      flexDirection={'column'}
    >
      <ECEasyFormCreate
        useCreateMutation={submit}
        onClose={onClose}
        formConfig={fancyFormElementsCreate.config}
        formFields={formFields}
        existingData={existingData}
        hideCancelButton
        showSaveButton={false}
        onChange={handleChangeData}
        showWideSaveButton
        saveButtonDisabled={saveButtonDisabled}
      />
    </ECBox>
  );
};
