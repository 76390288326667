import Chip, { ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { ForwardedRef, forwardRef, useMemo } from 'react';

export const ChipStyled = styled(Chip)<ChipProps>(({ theme, ref }) => ({
  color: theme.palette.primary.contrastText,
  ref: ref,
}));
interface ECChipOptionsProps extends Omit<ChipProps, 'color'> {
  color?: string;
  textColor?: string;
  borderColor?: string;
}

const MAX_LABEL_LENGTH = 30;

export const ECChipOptions = forwardRef(
  (
    {
      textColor,
      borderColor,
      color,
      variant,
      sx,
      label: labelFromProps,
      ...chipProps
    }: ECChipOptionsProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const label = useMemo(() => {
      if (typeof labelFromProps === 'string' && labelFromProps?.length > 30) {
        const labelSliced = labelFromProps?.slice(0, MAX_LABEL_LENGTH);

        return `${labelSliced}...`;
      }

      return labelFromProps;
    }, [labelFromProps]);

    return (
      <ChipStyled
        ref={ref}
        {...chipProps}
        label={label}
        variant={variant}
        sx={{
          cursor: chipProps?.disabled ? 'not-allowed !important' : 'pointer',
          borderColor: borderColor,
          bgcolor: theme =>
            variant === 'outlined' ? theme.palette.common.white : color,
          color: theme =>
            variant === 'outlined'
              ? textColor ?? theme.palette.primary.main
              : textColor ?? theme.palette.primary.contrastText,
          ...sx,
        }}
      />
    );
  },
);
