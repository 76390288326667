import {
  ECBox,
  ECButton,
  ECEasyFormCreate,
  ECWorkflowTemplate,
} from 'app/components';
import { useParams } from 'react-router';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import { useEffect, useMemo, useCallback, useState } from 'react';
import { useGetAttachmentQuery } from 'services/attachmentApi';
import { AttachmentCategory, DocumentCoreTypes } from 'types/Attachment';
import { useGetGeneralAssetByIdQuery } from 'services/generalAssetApi';
import { useTranslation } from 'react-i18next';
import { CostTableData } from 'app/components/ECWorkflowCostTable';
import { useGetProblemListByCompanyIdAndAssetTypeIdQuery } from 'services/problemApi';
import { populateDropDownFields } from 'utils/pageUtils';
import { useGetCompletionTimeUnitQuery } from 'services/lookupApi';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { StyleConstants } from 'styles/StyleConstants';
import { useGetPrioritiesListForSPQuery } from 'services/prioritiesApi';
import { Add } from '@mui/icons-material';
import { useCreateProposalMutation } from 'services/proposalApi';
import { useGetCustomersBranchListByGeneralAssetForSpQuery } from 'services/branchApi';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { useGetServiceProviderRatesAndTermsQuery } from 'services/serviceProviderApi';

const formElementsDetails = require('./fancy_form_config_details.json');
const formElementsProposalGeneralAsset = require('./fancy_form_config_proposal_general_asset.json');

export function SPGeneralAssetDetailsPage() {
  const { t } = useTranslation();
  const { id } = useParams();

  const [isOpen, setIsOpen] = useState(false);

  const { data: generalAsset, isSuccess: isSuccessGeneralAsset } =
    useGetGeneralAssetByIdQuery(id ? Number(id) : 0);

  const { data: attachments } = useGetAttachmentQuery({
    module: 'general-asset',
    moduleId: generalAsset?.id ?? 0,
    category: AttachmentCategory.Photo,
  });

  const assetProfileAttachment = useMemo(() => {
    const matchingPhotos = attachments?.others?.filter(
      attachment =>
        attachment?.documentCoreType?.code ===
        DocumentCoreTypes.assetProfilePhoto,
    );
    if (matchingPhotos && matchingPhotos.length > 0) {
      return matchingPhotos[matchingPhotos.length - 1];
    }
  }, [attachments]);

  const handleClose = useCallback(() => setIsOpen(false), []);
  const handleOpen = useCallback(() => setIsOpen(true), []);

  const { data: userProfile } = useGetUserProfileQuery();
  const { data: ratesAndTerms } = useGetServiceProviderRatesAndTermsQuery(
    {
      spId: Number(userProfile?.company?.id),
      companyId: generalAsset?.company?.id,
    },
    {
      skip: !(userProfile?.company?.id && generalAsset?.company?.id),
    },
  );

  const costTableData = useMemo(
    (): CostTableData | undefined =>
      generalAsset && {
        showIncurred: true,
        itemsRepair: [
          {
            label: 'Labor',
            dataPath: 'costLabor',
            readOnly: true,
          },
          {
            label: 'Labor Charge',
            dataPath: 'costLaborItems',
            extraData: ratesAndTerms,
          },
          {
            label: 'Material',
            dataPath: 'costMaterial',
          },
          {
            label: 'Freight',
            dataPath: 'costFreight',
          },
          {
            label: 'Tax',
            dataPath: 'costTax',
          },
          {
            label: 'Other',
            dataPath: 'costOther',
          },
          {
            label: 'Incurred',
            dataPath: 'Incurred',
            hidden: true,
          },
        ],
      },
    [generalAsset, ratesAndTerms],
  );

  useEffect(() => {
    if (isSuccessGeneralAsset && generalAsset) {
      if (generalAsset?.requiredFields?.proposalDocumentRequired) {
        const attachmentField = formElementsProposalGeneralAsset?.fields?.find(
          field => field.fieldName === 'files',
        );
        attachmentField.required = true;
      }

      const branchField = formElementsProposalGeneralAsset.fields
        ?.find(field => field.fieldName === 'group')
        ?.subFields?.find(field => field.fieldName === 'branch');

      branchField.useQuery = useGetCustomersBranchListByGeneralAssetForSpQuery;
      branchField.queryParams = {
        generalAssetId: generalAsset?.id ?? 0,
        customerId: generalAsset?.company.id ?? 0,
        withoutOnHold: 1,
      };
    }
  }, [generalAsset, isSuccessGeneralAsset]);

  const { data: problems, isSuccess: isSuccessProblems } =
    useGetProblemListByCompanyIdAndAssetTypeIdQuery(
      {
        companyId: generalAsset?.company.id ?? 0,
        assetTypeId: generalAsset?.assetType.id ?? 0,
      },
      {
        skip: !generalAsset?.company?.id || !generalAsset?.assetType?.id,
      },
    );

  useEffect(() => {
    if (problems && isSuccessProblems) {
      populateDropDownFields({
        responseData: problems,
        createFormFields: formElementsProposalGeneralAsset.fields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'problem',
      });
    }
  }, [problems, isSuccessProblems]);

  const { data: priorities, isSuccess: isSuccessPriorities } =
    useGetPrioritiesListForSPQuery(
      {
        moduleName: 'general-asset',
        moduleId: generalAsset?.id ?? 0,
        companyId: generalAsset?.company.id ?? 0,
      },
      {
        skip: !generalAsset?.id,
      },
    );

  useEffect(() => {
    if (priorities && isSuccessPriorities) {
      populateDropDownFields({
        responseData: priorities,
        createFormFields: formElementsProposalGeneralAsset.fields?.find(
          field => field.fieldName === 'group2',
        )?.subFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'priority',
      });
    }
  }, [priorities, isSuccessPriorities]);

  const { data: period, isSuccess: isSuccessPeriod } =
    useGetCompletionTimeUnitQuery();

  useEffect(() => {
    if (period && isSuccessPeriod) {
      populateDropDownFields({
        responseData: period,
        createFormFields: formElementsProposalGeneralAsset.fields?.find(
          field => field.fieldName === 'group2',
        )?.subFields,
        dataOptionsFieldName: 'description',
        dataValuesFieldName: 'code',
        formFieldName: 'completionTimeUnit',
        defaultSelectedOption: 0,
      });
    }
  }, [period, isSuccessPeriod]);

  const existingData = useMemo(() => {
    return {
      ...generalAsset,
      description: undefined,
    };
  }, [generalAsset]);

  const [
    doCrateProposal,
    {
      isError: isErrorCreate,
      error: errorCreate,
      isSuccess: isSuccessCreate,
      isLoading: isLoadingCreate,
      reset: resetCreate,
    },
  ] = useCreateProposalMutation();

  const useCreateProposal = useCallback(() => {
    const doCreate = async data => {
      const proposalDto: any = {
        ...data,
        costLaborItems: data.costLaborItems?.map(item => ({
          ...item,
          costTypeId: item?.costType?.id,
          unitCount: Number(item?.unitCount),
          unitCost: Number(item?.unitCost),
          totalCost: Number(item?.totalCost),
        })),
      };

      doCrateProposal(proposalDto);
    };
    return [
      doCreate,
      {
        isError: isErrorCreate,
        error: errorCreate,
        isSuccess: isSuccessCreate,
        isLoading: isLoadingCreate,
        reset: resetCreate,
      },
    ];
  }, [
    doCrateProposal,
    isErrorCreate,
    isSuccessCreate,
    isLoadingCreate,
    errorCreate,
    resetCreate,
  ]);

  return (
    <>
      {generalAsset && (
        <ECWorkflowTemplate
          tabsAsLinks
          title={`${t('translation:workflow.sidebar.generalAsset')} ${
            generalAsset.name
          }`}
          showEditTitleStartAdornment={true}
          checkNothingToUpdateEditForm={false}
          summaryData={[
            {
              id: 'asset',
              label: 'Asset',
              data: generalAsset?.name ?? '',
              type: SummaryFieldTypes.Text,
            },
            {
              id: 'state',
              label: 'State',
              data: generalAsset?.status ?? 0,
              type: SummaryFieldTypes.State,
            },
            {
              id: 'assetGroup',
              label: 'Asset Group',
              type: SummaryFieldTypes.Text,
              data: generalAsset?.assetType?.name,
            },
            {
              id: 'area',
              label: 'Area',
              type: SummaryFieldTypes.Text,
              data: generalAsset?.area?.name,
            },
            {
              id: 'priority',
              label: 'Priority',
              type: SummaryFieldTypes.Text,
              data: generalAsset?.priority?.name,
            },
            {
              id: 'nte',
              label: 'NTE',
              data: generalAsset?.nte,
              type: SummaryFieldTypes.Currency,
            },
            {
              id: 'tagId',
              label: 'Tag ID',
              data: generalAsset?.tagId ?? '',
              type: SummaryFieldTypes.Text,
            },
            {
              id: 'concept',
              label: 'Concept',
              type: SummaryFieldTypes.AutocompleteChips,
              data: generalAsset?.branchType,
              limitTags: 3,
            },
          ]}
          additionalActions={[
            <ECButton
              variant="contained"
              startIcon={<Add />}
              onClick={handleOpen}
            >
              {t('translation:managedAsset.createProposal')}
            </ECButton>,
          ]}
          fullHeight={false}
          editConfig={{}}
          editFields={{}}
          detailsConfig={formElementsDetails.data.config}
          detailsFields={formElementsDetails.data.fields}
          detailsData={generalAsset}
          imgSrc={assetProfileAttachment?.url}
          profileAttachment={assetProfileAttachment}
          moduleName={'general-asset'}
          moduleId={id}
          isEditAllowed={false}
          hideNoteTab
          hideActivityTab
        />
      )}
      <ECDrawerDetails open={isOpen} anchor="right" onClose={handleClose}>
        <ECBox display="flex" pt={StyleConstants.NAV_BAR_HEIGHT}>
          <ECEasyFormCreate
            useCreateMutation={useCreateProposal}
            formConfig={formElementsProposalGeneralAsset.config}
            formFields={formElementsProposalGeneralAsset.fields}
            existingData={existingData}
            costTableData={costTableData}
            onClose={handleClose}
          />
        </ECBox>
      </ECDrawerDetails>
    </>
  );
}
