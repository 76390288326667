import { Person } from '@mui/icons-material';
import {
  ECBox,
  ECButton,
  ECEasyTable,
  ECGrid,
  ECStack,
  ECTypography,
  ECSPCard,
  NAV_BAR_HEIGHT_PX,
  ECSPForm,
  TradeOption,
  ECImage,
} from 'app/components';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import { AutocompleteCityResult } from 'app/components/ECAutocomplete';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import {
  useLazyGetServiceProviderDirectoryListQuery,
  useLazyGetServiceProviderDirectoryTradeAreasQuery,
  useLazySendInviteQuery,
} from 'services/serviceProviderApi';
import { StyleConstants } from 'styles/StyleConstants';
import { ServiceProviderDirectoryTypes } from 'types/ServiceProviderTypes';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { useInView } from 'react-intersection-observer';

const tableConfig = require('./tableConfig.json');

const SEARCH_BAR_HEIGHT = 100;
const PAGE_SIZE = 10;

export function SPDirectoryResults() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    trade,
    location,
    name,
  }: { trade: TradeOption; location: AutocompleteCityResult; name: string } =
    state as any;

  const dispatch = useDispatch();

  const { ref, inView } = useInView();
  const [page, setPage] = useState(0);
  const [isHelpOpened, setIsHelpOpened] = useState(false);
  const [spDirectories, setSpDirectories] = useState<
    ServiceProviderDirectoryTypes[]
  >([]);

  const [triggerList, resultList] =
    useLazyGetServiceProviderDirectoryListQuery();
  const [triggerTradeAreas, resultTradeAreas] =
    useLazyGetServiceProviderDirectoryTradeAreasQuery();
  const [triggerInvite, resultInvite] = useLazySendInviteQuery();

  const {
    data: spDirectoriesData,
    isSuccess,
    isLoading,
    isFetching: isFetchingList,
  } = resultList;
  const { data: tradeAreasData, isFetching } = resultTradeAreas;

  const {
    isSuccess: isInviteSuccess,
    isError: isInviteError,
    isLoading: isLoadingInvite,
  } = resultInvite;

  useEffect(() => {
    if (isInviteSuccess) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Invitation sent succesfully!',
        }),
      );
    }
  }, [isInviteSuccess]);

  const [selectedSP, setSelectedSP] = useState<
    ServiceProviderDirectoryTypes | undefined
  >(spDirectories?.[0]);

  useEffect(() => {
    if (spDirectoriesData && isSuccess && !isFetchingList) {
      if (!spDirectories.length) {
        setSelectedSP(spDirectoriesData?.data?.[0]);
      }
      setSpDirectories(prev => [...prev, ...spDirectoriesData?.data]);

      if (spDirectoriesData?.data?.length === 0) {
        setSelectedSP(undefined);
      }
    }
  }, [spDirectoriesData, isSuccess, isFetchingList]);

  useEffect(() => {
    triggerList({
      tradeId: trade?.id,
      city: location?.city,
      state: location?.state,
      county: location?.county,
      country: location?.country,
      zipCode: location?.zipCode,
      name,
      p: page,
      t: PAGE_SIZE,
    });
  }, [triggerList, trade, location, name, page]);

  useEffect(() => {
    if (inView) {
      setPage(prevPage => prevPage + 1);
    }
  }, [inView]);

  useEffect(() => {
    if (selectedSP?.serviceProviderId) {
      triggerTradeAreas({
        id: selectedSP?.serviceProviderId,
      });
    }
  }, [selectedSP, triggerTradeAreas]);

  const handleSPSelect = (id: number) => () => {
    setSelectedSP(spDirectories?.find(sp => sp.serviceProviderId === id));
  };

  const handleInviteClick = (data: {
    spName: string;
    spEmail: string;
    spId: number;
  }) => {
    triggerInvite(data);
  };

  const handleSearch = (trade, location, name) => () => {
    setSpDirectories([]);
    setPage(0);
    navigate('.', { state: { trade, location, name } });
  };

  const tradesSummary = useMemo(() => {
    return (
      tradeAreasData?.data
        ?.slice(0, 4)
        ?.map(trade => trade.name)
        ?.join(' • ') || ''
    );
  }, [tradeAreasData?.data]);

  return (
    <ECBox display="flex" flexDirection="column" height="100%" flex={1}>
      <ECBox display="flex" flexDirection="column">
        <ECBox
          display="flex"
          alignItems="center"
          pt={3}
          pb={2}
          px={2}
          bgcolor={theme => theme.palette.primary.main}
        >
          <ECSPForm
            initialData={{ trade, location, name }}
            onSearch={handleSearch}
            p={2}
            variant="standard"
            bgcolor="white"
            borderRadius={2}
            boxShadow={5}
          />
        </ECBox>
      </ECBox>

      {selectedSP?.serviceProviderId ? (
        <ECGrid
          mt={0}
          maxHeight="100vh"
          overflow="hidden"
          container
          spacing={1}
        >
          <ECGrid mt={0} pt={0} item xs={4}>
            <ECTypography
              variant="body1"
              color={theme => theme.palette.grey[400]}
              sx={{
                p: 1,
              }}
            >
              {`${spDirectoriesData?.config.pagination.totalCount} result${spDirectoriesData?.config.pagination.totalCount !== 1 ? 's' : ''} found`}
            </ECTypography>
            <ECStack
              maxHeight={
                window.innerHeight - NAV_BAR_HEIGHT_PX - SEARCH_BAR_HEIGHT
              }
              overflow="auto"
              direction="column"
              spacing={2}
              p={2}
              sx={{
                '&::-webkit-scrollbar': { display: 'none' },
                '-ms-overflow-style': 'none',
                'scrollbar-width': 'none',
              }}
            >
              {spDirectories?.map(sp => {
                return (
                  <ECSPCard
                    key={sp?.serviceProviderId}
                    id={sp?.serviceProviderId}
                    name={sp?.serviceProviderName}
                    customersCount={sp?.totalCustomers}
                    workOrdersCount={sp?.workOrdersCompleted}
                    workOrdersCompletedInArea={sp?.workOrdersCompletedInArea}
                    onCardClick={handleSPSelect}
                    onInviteClick={handleInviteClick}
                    spEmail={sp?.email}
                    isSelected={
                      sp.serviceProviderId === selectedSP?.serviceProviderId
                    }
                    isLoadingInvite={isLoadingInvite}
                    tradesSummary={tradesSummary}
                    selfPerform={sp?.selfPerform}
                  />
                );
              })}
              {!!spDirectoriesData?.data &&
                spDirectories?.length > 0 &&
                spDirectoriesData?.data?.length > 0 &&
                (page + 1) * PAGE_SIZE <=
                  spDirectoriesData?.config?.pagination?.totalCount && (
                  <ECBox
                    display="flex"
                    justifyContent="center"
                    ref={ref}
                    height={70}
                  >
                    <ECCircularProgress sx={{ margin: 1 }} size="2rem" />
                  </ECBox>
                )}
            </ECStack>
          </ECGrid>

          <ECGrid
            item
            xs
            boxShadow={5}
            sx={{ paddingTop: '0 !important;' }}
            bgcolor={theme => theme.palette.marketingSuggestion.mainBackground}
          >
            <ECBox display="flex">
              <ECBox
                px={4}
                py={4}
                display="flex"
                flexDirection="column"
                flex={4}
              >
                <ECBox display="flex" alignItems="center" mb={2}>
                  <ECImage
                    src={selectedSP?.logo}
                    alt={selectedSP?.serviceProviderName}
                    sx={{ width: '96px', height: '96px', mr: 2 }}
                  />

                  <ECTypography variant="h4">
                    {selectedSP?.serviceProviderName}
                  </ECTypography>
                </ECBox>

                <ECBox display={'flex'} flexDirection={'column'}>
                  <ECTypography variant="h6" mb={1}>
                    {t('translation:spDirectory.results.companyInformation')}
                  </ECTypography>

                  <ECBox display="flex" alignItems="center">
                    <ECTypography
                      variant="body1"
                      color={theme => theme.palette.grey[600]}
                    >
                      {t('translation:spDirectory.results.spEmail')}&nbsp;
                    </ECTypography>
                    <ECTypography variant="body1">
                      {selectedSP?.email}
                    </ECTypography>
                  </ECBox>

                  <ECBox display="flex" alignItems="center">
                    <ECTypography
                      variant="body1"
                      color={theme => theme.palette.grey[600]}
                    >
                      {t('translation:spDirectory.results.spPhone')}&nbsp;
                    </ECTypography>
                    <ECTypography variant="body1">
                      {selectedSP?.phone}
                    </ECTypography>
                  </ECBox>

                  <ECBox display="flex" alignItems="center">
                    <ECTypography
                      variant="body1"
                      color={theme => theme.palette.grey[600]}
                    >
                      {t('translation:spDirectory.results.spBusinessAddress')}
                      &nbsp;
                    </ECTypography>
                    <ECTypography variant="body1">
                      {selectedSP?.businessAddress}
                    </ECTypography>
                  </ECBox>
                </ECBox>

                <ECBox width="40%">
                  <ECButton
                    fullWidth
                    variant="contained"
                    onClick={() =>
                      handleInviteClick({
                        spName: selectedSP.serviceProviderName,
                        spEmail: selectedSP.email,
                        spId: selectedSP.serviceProviderId,
                      })
                    }
                    disabled={isLoadingInvite}
                    sx={{ mt: 2 }}
                  >
                    {isLoadingInvite ? (
                      <ECCircularProgress size={'1rem'} />
                    ) : (
                      t('translation:spDirectory.results.invite')
                    )}
                  </ECButton>
                </ECBox>
              </ECBox>

              <ECBox
                display="flex"
                flex={1}
                flexDirection="column"
                px={3}
                py={3}
                gap={2}
                sx={theme => ({
                  bgcolor: theme.palette.primary.outlinedHoverBackground,
                })}
              >
                <ECTypography variant="h6" fontWeight="bold">
                  {t('translation:spDirectory.results.overview')}
                </ECTypography>

                <ECBox display="flex" flexDirection={'column'}>
                  <ECTypography
                    variant="body1"
                    color={theme => theme.palette.grey[600]}
                  >
                    {t('translation:spDirectory.results.customers')}&nbsp;
                  </ECTypography>
                  <ECTypography variant="h6" fontWeight="bold">
                    {selectedSP?.totalCustomers}
                  </ECTypography>
                </ECBox>

                <ECBox display="flex" flexDirection={'column'}>
                  <ECTypography
                    variant="body1"
                    color={theme => theme.palette.grey[600]}
                  >
                    {t(
                      'translation:spDirectory.results.workOrdersCompletedInEcotrak',
                    )}
                    &nbsp;
                  </ECTypography>
                  <ECTypography variant="h6" fontWeight="bold">
                    {selectedSP?.workOrdersCompleted}
                  </ECTypography>
                </ECBox>

                <ECBox display="flex" flexDirection={'column'}>
                  <ECTypography
                    variant="body1"
                    color={theme => theme.palette.grey[600]}
                  >
                    {t(
                      'translation:spDirectory.results.completedInSearchedArea',
                    )}
                    &nbsp;
                  </ECTypography>
                  <ECTypography variant="h6" fontWeight="bold">
                    {selectedSP?.workOrdersCompletedInArea}
                  </ECTypography>
                </ECBox>
              </ECBox>
            </ECBox>

            <ECEasyTable
              config={tableConfig.data.config}
              data={tradeAreasData?.data}
              isLoading={isFetching}
              shouldNotUseActiveFilter
              shouldUseSearchParams={false}
              hideSimpleSearchBar
            />
          </ECGrid>
        </ECGrid>
      ) : isLoading || isFetching ? (
        <ECBox
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          width={'100%'}
          p={4}
        >
          <ECCircularProgress size={'2rem'} />
          <ECTypography ml={2} sx={{ opacity: 0.7 }} variant={'body1'}>
            {t('translation:table.loading')}
          </ECTypography>
        </ECBox>
      ) : (
        isSuccess &&
        !isLoading &&
        !isFetching && (
          <ECBox
            display="flex"
            flex={1}
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <ECTypography variant="h5">No results found.</ECTypography>
          </ECBox>
        )
      )}
    </ECBox>
  );
}
