import { TypographyProps } from '@mui/material/Typography';
import { ECAlert } from '..';
import { useGetSettingsBulletinQuery } from 'services/lookupApi';
import { useMemo } from 'react';
import { AlertColor } from '@mui/material';
import { Banner } from 'types/Lookup';

interface ECLoginBannerProps
  extends Omit<TypographyProps, 'variant' | 'align' | 'color'> {}

export const ECLoginBanner = ({ ...props }: ECLoginBannerProps) => {
  const { data, isSuccess, isError } = useGetSettingsBulletinQuery();

  const banner: Banner | undefined = useMemo(() => {
    if (isSuccess && data.length > 0) {
      return {
        bannerText: data.find(b => b.settingName === 'LOGIN_BANNER_TEXT')
          ?.settingValue,
        bannerBackgroundColor: data.find(
          b => b.settingName === 'LOGIN_BANNER_BACKGROUND_COLOR',
        )?.settingValue,
        bannerType: data
          .find(b => b.settingName === 'LOGIN_BANNER_TYPE')
          ?.settingValue?.toLocaleLowerCase(),
      };
    }
    return undefined;
  }, [data, isSuccess]);

  const bannerSeverity: AlertColor = useMemo(() => {
    if (isSuccess && banner?.bannerType) {
      return ['info', 'warning', 'error', 'success'].includes(
        banner?.bannerType,
      )
        ? (banner?.bannerType as AlertColor)
        : 'info';
    }
    return 'info';
  }, [banner]);

  return (
    (banner?.bannerText && (
      <ECAlert
        severity={bannerSeverity}
        sx={{
          alignItems: 'center',
          width: '40rem',
          maxWidth: '80%',
          fontWeight: 'bold',
          backgroundColor: banner?.bannerBackgroundColor || undefined,
        }}
      >
        {banner?.bannerText}
      </ECAlert>
    )) ||
    null
  );
};

// if its error, show default error banner
/* isError ? (
    <ECAlert
      severity="warning"
      sx={{
        fontWeight: 'bold',
        width: '40rem',
        maxWidth: '80%',
        alignItems: 'center',
      }}
    >
      Apologies, we are experiencing an issue when users try to login. We are
      working hard to fix the issue and will be back online shortly.
    </ECAlert>
  ) :  */
