import { ECEasyFormCreate, ECPaper } from 'app/components';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { useCallback, useEffect, useState } from 'react';
import { PaymentSelector } from '../SignUpWizardPage/paymentSelector';

const createFormElements = require('./fancy_form_config_create.json');

export function PaymentMethodCreateDrawer({
  openPaymentMethodDrawer,
  handleClose,
}) {
  const [paymentMethod, setPaymentMethod] = useState<'creditcard' | 'ach'>(
    'creditcard',
  );
  const [createFormElementsFields, setCreateFormElementsFields] = useState(
    createFormElements.fields,
  );
  const useEmptyCreation = useCallback(() => {
    return [
      () => {
        handleClose();
      },
      {
        data: null,
        isError: false,
        error: null,
        isLoading: false,
        isSuccess: false,
      },
    ];
  }, []);

  const changePaymentSection = (section: 'creditcard' | 'ach') => {
    const fieldVisibilityMap = {
      creditcard: {
        visibleFields: [
          'credit_card',
          'credit_card_field_number',
          'credit_card_fields_cvv_expire_date',
          'credit_card_field_name',
        ],
        hiddenFields: ['ach', 'ach_routine_number', 'ach_account_number'],
      },
      ach: {
        visibleFields: ['ach', 'ach_routine_number', 'ach_account_number'],
        hiddenFields: [
          'credit_card',
          'credit_card_field_number',
          'credit_card_fields_cvv_expire_date',
          'credit_card_field_name',
        ],
      },
    };

    const { visibleFields, hiddenFields } = fieldVisibilityMap[section];

    createFormElementsFields.forEach(field => {
      if (visibleFields.includes(field.fieldName)) {
        field.visible = true;
      } else if (hiddenFields.includes(field.fieldName)) {
        field.visible = false;
      }
    });

    setPaymentMethod(section);
    setCreateFormElementsFields([...createFormElementsFields]);
  };

  useEffect(() => {
    if (!openPaymentMethodDrawer) return;

    const paymentSelectorField = createFormElements.fields
      .find(field => field.groupName === 'paymentSelectorGroup')
      ?.subFields?.find(subField => subField.fieldName === 'paymentSelector');
    if (paymentSelectorField) {
      paymentSelectorField.value = (
        <PaymentSelector onChange={changePaymentSection} />
      );
    }
  }, [openPaymentMethodDrawer]);

  return (
    <ECDrawerDetails
      open={openPaymentMethodDrawer}
      anchor="right"
      onClose={handleClose}
    >
      <ECPaper
        sx={{
          height: '100%',
          paddingTop: '80px',
          boxShadow: 'none',
        }}
        role="presentation"
      >
        <ECEasyFormCreate
          useCreateMutation={useEmptyCreation}
          onClose={handleClose}
          formConfig={createFormElements.config}
          formFields={createFormElementsFields}
          noTopMargin
        />
      </ECPaper>
    </ECDrawerDetails>
  );
}
