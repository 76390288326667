/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ReactElement,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { StyleConstants } from 'styles/StyleConstants';
import { ECBox } from '../ECBox';
import { ECWorkflowSummary } from '../ECWorkflowSummary';
import { SummaryFieldsData } from '../ECWorkflowSummaryField';
import { ECWorkflowToolbar } from '../ECWorkflowToolbar';
import {
  ECWorkflowSidebar,
  LinkItem,
  LinkItemsList,
} from '../ECWorkflowSidebar';
import { ECDrawerDetailsContent, TabsType } from '../ECDrawerDetailsContent';
import { EditForm2 } from '../ECDynamicPageTemplate/EditForm2';
import { ECModal } from '../ECModal';
import { useGetWorkflowGraphByIdQuery } from 'services/workflowApi';
import { ECGraph } from '../ECGraph';
import { CostTableData } from '../ECWorkflowCostTable';
import { WorkflowNextStatus } from 'types/WorkflowStatus';
import { ECEasyFormFieldType } from '../ECForm';
import { WarrantyBadgeTypes } from '../ECWarrantyBadge';
import { SubmissionConfirmationModal } from '../ECForm';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { setSnackbar, setTitle } from 'store/slice/page';
import { useNavigate } from 'react-router';
import { ECStack } from '../ECStack';
import { ECButton } from '../ECButton';
import { usePanelDrawerExpandedItem } from 'app/sections/PanelDrawer/context/panel-drawer-expanded-item.context';
import { drawerWidth, drawerWidthClosed } from '../ECDrawer';
import { ECTypography } from '../ECTypography';
import { useTranslation } from 'react-i18next';
import { Attachment } from 'types/Attachment';
import { WorkflowStatusGroupName } from '../ECWorkflowStatusBadge';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { useGetProfileQuery } from 'services/profileApi';
import { startCase } from 'lodash';
import { Skeleton } from '@mui/material';
export interface ECWorkflowTemplateProps {
  pageTitle?: string;
  title: string;
  location?: LinkItem;
  asset?: LinkItem;
  generalAsset?: LinkItem;
  replacedAsset?: LinkItem;
  replacedByAsset?: LinkItem;
  workOrder?: LinkItem;
  invoices?: LinkItemsList;
  proposals?: LinkItemsList;
  rfps?: LinkItemsList;
  pm?: LinkItem;
  po?: LinkItem;
  supplier?: LinkItem;
  linkedWO?: LinkItemsList;
  summaryData: SummaryFieldsData[];
  imgSrc?: string;
  profileAttachment?: Attachment;
  workflowStatus?: string;
  workflowStatusGroupName?: WorkflowStatusGroupName;
  isFeedbackAlreadySubmitted?: boolean;
  actions?: WorkflowNextStatus[];
  onActionClick?: (data: any) => Promise<void>;
  onCancelEdit?: () => void;
  additionalActions?: (ReactElement | null)[];
  detailsData: any;
  editConfig: any;
  editFields: any;
  detailsConfig: any;
  detailsFields: any;
  useUpdateMutation?: any;
  notesTabname?: string;
  historyTabname?: string;
  costTableData?: CostTableData;
  onCopyClick?: () => void;
  onLinkedWOClick?: () => void;
  onLinkedWODeleteClick?: (string) => void;
  showAddCommentButton?: boolean;
  titleProvided?: boolean;
  titleNotProvidedLabel?: string;
  showLogoOnError?: boolean;
  additionalTabs?: TabsType[];
  isEditAllowed?: boolean;
  onChangeEditForm?: (
    output: ECEasyFormFieldType[],
    fieldname?: string,
    value?: any,
  ) => void;
  onChange?: (
    output: ECEasyFormFieldType[],
    fieldName?: string,
    valueChange?: any,
  ) => void;
  onEditButtonClick?: (boolean) => void;
  onPrintButtonClick?: (e?: any) => void;
  onEditClose?: () => void;
  onChangeTitle?: (title: string) => void;
  onExitEditModeWithoutSave?: () => void;
  isEditMode?: boolean;
  outsideData?: ECEasyFormFieldType[];
  selectedTab?: string;
  disableTabsOnEdit?: boolean;
  disableSidebarOnEdit?: boolean;
  disableAditionalActionWhenEditing?: boolean;
  fullHeight?: boolean;
  warrantyBadgeType?: WarrantyBadgeTypes;
  showEditTitleStartAdornment?: boolean;
  checkNothingToUpdateEditForm?: boolean;
  submissionConfirmationModal?: SubmissionConfirmationModal;
  cancelEdit?: boolean;
  isBookmarkVisible?: boolean;
  showEditForm?: boolean;
  showLogo?: boolean;
  showPrintButton?: boolean;
  closeIconAsX?: boolean;
  ignoredActionNames?: string[];
  historyTabIdAttribute?: string;
  attachmentsTitle?: string;
  attachmentTabIdAttribute?: string;
  tabsAsLinks?: boolean;
  hideNoteTab?: boolean;
  hideActivityTab?: boolean;
  hideAttachmentTab?: boolean;
  showAdditionalButtons?: boolean;
  shouldShowWorkflowGraphButton?: boolean;
  isLoadingActions?: boolean;
  readEditModeStatus?: (isEditMode: boolean) => void;
  beInEditMode?: boolean;
  showAddLinkedWOButton?: boolean;
  showWarrantyAndLogo?: boolean;
  customDetailsTabLabel?: string;
  moduleName?: string;
  moduleId?: string;
  customSummaryComponents?: ReactElement;
  detailTabsOrder?: any;
  disableChangeTab?: boolean;
  showSkeletonLoader?: boolean;
  showSkeletonWithTableLoader?: boolean;
}

export const ECWorkflowTemplate = ({
  pageTitle,
  title,
  location,
  asset,
  workOrder,
  invoices,
  proposals,
  replacedAsset,
  replacedByAsset,
  rfps,
  pm,
  po,
  supplier,
  linkedWO,
  summaryData,
  imgSrc,
  profileAttachment,
  actions,
  detailsData,
  editConfig,
  editFields,
  detailsConfig,
  detailsFields,
  useUpdateMutation,
  notesTabname,
  historyTabname,
  costTableData,
  additionalActions,
  workflowStatus,
  workflowStatusGroupName,
  isFeedbackAlreadySubmitted,
  onActionClick,
  onCopyClick,
  showAddCommentButton,
  onLinkedWOClick,
  onLinkedWODeleteClick,
  titleProvided,
  titleNotProvidedLabel,
  onChange,
  onEditButtonClick,
  onPrintButtonClick,
  onEditClose,
  onCancelEdit,
  showLogoOnError,
  additionalTabs,
  isEditAllowed = true,
  onChangeTitle,
  onExitEditModeWithoutSave,
  isEditMode: isEditModeFromProps,
  outsideData,
  disableTabsOnEdit,
  disableSidebarOnEdit,
  generalAsset,
  disableAditionalActionWhenEditing,
  fullHeight = false,
  warrantyBadgeType,
  showEditTitleStartAdornment,
  checkNothingToUpdateEditForm,
  submissionConfirmationModal,
  cancelEdit,
  isBookmarkVisible,
  ignoredActionNames,
  showEditForm = true,
  showLogo = true,
  showPrintButton = false,
  closeIconAsX = false,
  showAddLinkedWOButton = false,
  historyTabIdAttribute,
  attachmentsTitle,
  attachmentTabIdAttribute = 'id',
  tabsAsLinks = false,
  hideNoteTab,
  hideActivityTab,
  hideAttachmentTab,
  showAdditionalButtons,
  shouldShowWorkflowGraphButton = false,
  isLoadingActions,
  readEditModeStatus,
  beInEditMode,
  showWarrantyAndLogo,
  customDetailsTabLabel,
  moduleName,
  moduleId,
  customSummaryComponents,
  detailTabsOrder,
  disableChangeTab,
  showSkeletonLoader,
  showSkeletonWithTableLoader,
}: ECWorkflowTemplateProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    state: { isDrawerOpen },
  } = usePanelDrawerExpandedItem();

  const isDetailsTab =
    !window.location.href.includes('attachments') &&
    !window.location.href.includes('notes') &&
    !window.location.href.includes('activity');

  const { data: companyProfile } = useGetProfileQuery();
  const { data: userProfile } = useGetUserProfileQuery();

  const [isEditMode, setEditMode] = useState(false);
  const [isWorkflowGraphVisible, setIsWorkflowGraphVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>('0');
  const [confirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false);

  const handleOpenModal = () => setIsWorkflowGraphVisible(true);
  const handleCloseModal = () => setIsWorkflowGraphVisible(false);

  const toggleEdit = () => setEditMode(!isEditMode);

  useEffect(() => {
    if (beInEditMode) {
      setEditMode(true);
    }
  }, [beInEditMode, setEditMode]);

  const { data: workflow } = useGetWorkflowGraphByIdQuery(
    detailsData?.workflowId,
    {
      skip: !isDetailsTab || !detailsData?.workflowId,
    },
  );

  useEffect(() => {
    if (pageTitle) {
      dispatch(setTitle(pageTitle));
    }
  }, [pageTitle, dispatch]);

  const handleAddCommentClick = () => {
    navigate('notes');
  };

  const handleLeaveFeedbackClick = () => {
    navigate('feedback');
  };

  const handleSelectedTabChange = (tabValue: string) => {
    setSelectedTab(tabValue);
  };

  useEffect(() => {
    if (
      isEditModeFromProps !== undefined &&
      isEditModeFromProps !== isEditMode
    ) {
      setEditMode(isEditModeFromProps);
    }
  }, [isEditModeFromProps]);

  useEffect(() => {
    if (cancelEdit) {
      onExitEditModeWithoutSave?.();
      setEditMode(false);
    }
  }, [cancelEdit]);

  const handleEditButtonClick = useCallback(() => {
    if (isEditMode) {
      onExitEditModeWithoutSave?.();
    }
    onEditButtonClick?.(!isEditMode);
    toggleEdit();
  }, [isEditMode, onExitEditModeWithoutSave]);

  const handleCancelButtonClick = useCallback(() => {
    if (isEditMode) {
      onExitEditModeWithoutSave?.();
    }
    onCancelEdit?.();
    setEditMode(false);
  }, [isEditMode, onExitEditModeWithoutSave]);

  useEffect(() => {
    readEditModeStatus?.(isEditMode);
  }, [isEditMode]);

  const currentTab = (additionalTabs || []).find(
    tab => String(tab.value) === selectedTab,
  );

  const [
    doUpdate,
    {
      isLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
      reset: resetMutation,
    },
  ] = useUpdateMutation?.() || [undefined, {}];

  const handleCloseConfirmationModal = useCallback(() => {
    submissionConfirmationModal?.onClose?.();
    setConfirmationModalOpen(false);
  }, [submissionConfirmationModal]);

  const handleConfirmSubmit = useCallback(() => {
    submissionConfirmationModal?.onSubmit?.();
    doUpdate();
    setConfirmationModalOpen(false);
  }, [submissionConfirmationModal]);

  useEffect(() => {
    if (isUpdateSuccess && !showEditForm) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: moduleName
            ? `${startCase(moduleName)} ${t('translation:dynamicForm.updateSuccess')}`
            : t('translation:dynamicForm.updateSuccess'),
        }),
      );
      setEditMode(false);
      resetMutation?.();
    }
  }, [isUpdateSuccess, dispatch, t, resetMutation]);

  useEffect(() => {
    if (isUpdateError && !showEditForm) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: (updateError as any)?.data?.message,
        }),
      );
    }
  }, [isUpdateError, updateError, dispatch, t]);

  const editForm = useMemo(() => {
    return showEditForm ? (
      <EditForm2
        fullHeight={fullHeight}
        row={detailsData}
        formConfig={editConfig}
        formFields={editFields}
        detailsConfig={detailsConfig}
        detailsFields={detailsFields}
        onlyEdit={isEditMode}
        useUpdateMutation={useUpdateMutation}
        hideToolBar
        costTable={costTableData}
        onClose={handleCancelButtonClick}
        onChange={onChange}
        onEditClose={onEditClose}
        outsideData={outsideData}
        onEditButtonClick={onEditButtonClick}
        checkNothingToUpdate={checkNothingToUpdateEditForm}
        submissionConfirmationModal={submissionConfirmationModal}
        showTitle={false}
      />
    ) : (
      <></>
    );
  }, [
    showEditForm,
    fullHeight,
    detailsData,
    editConfig,
    editFields,
    detailsConfig,
    detailsFields,
    isEditMode,
    useUpdateMutation,
    handleCancelButtonClick,
    onChange,
    onEditClose,
    outsideData,
    onEditButtonClick,
    checkNothingToUpdateEditForm,
    submissionConfirmationModal,
    costTableData,
  ]);

  const isFeedbackTabVisible =
    (workflowStatusGroupName === WorkflowStatusGroupName.SoftCompleted ||
      workflowStatusGroupName === WorkflowStatusGroupName.Complete) &&
    companyProfile?.workorderSurvey;

  const isLeaveFeedbackButtonVisible =
    isFeedbackTabVisible &&
    !isFeedbackAlreadySubmitted &&
    detailsData?.createdByUserId === userProfile?.userId;

  if ((showSkeletonLoader || showSkeletonWithTableLoader) && isDetailsTab) {
    return (
      <ECBox
        sx={{
          mt: StyleConstants.NAV_BAR_HEIGHT,
          width: '100%',
          p: '18.4px',
        }}
      >
        <ECBox sx={{ display: 'flex', gap: '37px' }}>
          <Skeleton
            sx={theme => ({ backgroundColor: theme.palette.grey[200] })}
            variant="rectangular"
            height={591}
            width={253}
          />
          <Skeleton
            sx={theme => ({ backgroundColor: theme.palette.grey[200] })}
            variant="rectangular"
            height={591}
            width={403}
          />
        </ECBox>
        {showSkeletonWithTableLoader && (
          <ECBox
            sx={{
              mt: '86px',
              width: '100%',
            }}
          >
            <Skeleton
              sx={theme => ({
                backgroundColor: theme.palette.other.loaderTableHeader,
              })}
              variant="rectangular"
              height={32}
              width="100%"
            />
            <Skeleton
              sx={theme => ({ backgroundColor: theme.palette.grey[100] })}
              variant="rectangular"
              height={66}
              width="100%"
            />
          </ECBox>
        )}
      </ECBox>
    );
  }

  return (
    <>
      {/* a helmet that completely overrides the parent element */}
      <Helmet titleTemplate={`%s`}>
        <title>{title}</title>
      </Helmet>
      <ECBox
        display="flex"
        flexDirection="column"
        flex={1}
        width="100%"
        pt={StyleConstants.NAV_BAR_HEIGHT}
      >
        <ECWorkflowToolbar
          title={title}
          isBookmarkVisible={isBookmarkVisible}
          showEditTitleStartAdornment={showEditTitleStartAdornment}
          onChangeTitle={onChangeTitle}
          onEditClick={isEditAllowed ? handleEditButtonClick : undefined}
          onCopyClick={onCopyClick}
          titleProvided={titleProvided}
          onLinkedWOClick={onLinkedWOClick}
          showAddLinkedWOButton={showAddLinkedWOButton}
          titleNotProvidedLabel={titleNotProvidedLabel}
          editSelected={isEditMode}
          showPrintButton={showPrintButton}
          onPrintButtonClick={onPrintButtonClick}
          closeIconAsX={closeIconAsX}
          showEditButton={
            isEditAllowed && (currentTab ? !!currentTab.editable : true)
          }
        />
        <ECBox display="flex" width="100%" height="100%">
          <ECBox display="flex" width="100%" flexDirection="column" flex={1}>
            <ECDrawerDetailsContent
              data={detailsData}
              tabsAsLinks={tabsAsLinks}
              hasTopPadding={false}
              selectedTab={selectedTab}
              onSelectedTabChange={handleSelectedTabChange}
              disableTabs={isEditMode && disableTabsOnEdit}
              historyTabIdAttribute={historyTabIdAttribute}
              attachmentTabIdAttribute={attachmentTabIdAttribute}
              hideNoteTab={hideNoteTab}
              notesTabname={notesTabname}
              hideActivityTab={hideActivityTab}
              hideAttachmentTab={hideAttachmentTab}
              hideFeedbackTab={!isFeedbackTabVisible}
              SummaryDataForm={
                <ECBox>
                  <ECWorkflowSummary
                    customSummaryComponents={customSummaryComponents}
                    summaryFieldsData={summaryData}
                    imgSrc={imgSrc}
                    moduleName={moduleName}
                    moduleId={moduleId}
                    profileAttachment={profileAttachment}
                    warrantyBadgeType={warrantyBadgeType}
                    actions={actions}
                    ignoredActionNames={ignoredActionNames}
                    onShowWorkflowGraph={
                      shouldShowWorkflowGraphButton && workflow
                        ? handleOpenModal
                        : undefined
                    }
                    showWarrantyAndLogo={showWarrantyAndLogo}
                    onActionClick={onActionClick}
                    onAddCommentClick={
                      workflow ? handleAddCommentClick : undefined
                    }
                    onLeaveFeedbackClick={
                      isLeaveFeedbackButtonVisible
                        ? handleLeaveFeedbackClick
                        : undefined
                    }
                    showAdditionalButtons={showAdditionalButtons}
                    disableAditionalActionWhenEditing={
                      disableAditionalActionWhenEditing
                    }
                    additionalActions={additionalActions}
                    workflowStatus={workflowStatus}
                    showLogoOnError={showLogoOnError}
                    isEditMode={isEditMode}
                    showLogo={showLogo}
                    isLoadingActions={isLoadingActions}
                  />
                </ECBox>
              }
              EditForm={editForm}
              additionalTabs={additionalTabs?.map(tab => {
                return {
                  ...tab,
                  content: React.cloneElement(tab.content, {
                    onlyEdit: isEditMode && tab.editable,
                    onClose: handleCancelButtonClick,
                    context: {
                      ...tab.content.props.context,
                      onlyEdit: isEditMode && tab.editable,
                      onClose: handleCancelButtonClick,
                    },
                  }),
                  disabled: disableTabsOnEdit ? isEditMode : tab.disabled,
                };
              })}
              attachmentsTitle={attachmentsTitle}
              historyTabname={historyTabname}
              hideHeader
              customDetailsTabLabel={customDetailsTabLabel}
              detailTabsOrder={detailTabsOrder}
              disableChangeTab={disableChangeTab}
            />
          </ECBox>
          {(location ||
            asset ||
            generalAsset ||
            workOrder ||
            invoices ||
            proposals ||
            rfps ||
            pm ||
            po ||
            supplier ||
            replacedAsset ||
            replacedByAsset ||
            linkedWO) && (
            <ECBox display="flex">
              <ECWorkflowSidebar
                location={location}
                asset={asset}
                replacedAsset={replacedAsset}
                replacedByAsset={replacedByAsset}
                workOrder={workOrder}
                invoices={invoices}
                proposals={proposals}
                rfps={rfps}
                pm={pm}
                po={po}
                supplier={supplier}
                linkedWO={linkedWO}
                onLinkedWODeleteClick={onLinkedWODeleteClick}
                generalAsset={generalAsset}
                disableSidebarOnEdit={disableSidebarOnEdit && isEditMode}
              />
            </ECBox>
          )}
        </ECBox>
        {!showEditForm && doUpdate && isEditMode && (
          <ECStack
            px={4}
            py={2}
            zIndex={10}
            bgcolor={theme => theme.palette.common.white}
            width={
              window.innerWidth -
              (isDrawerOpen ? drawerWidth : drawerWidthClosed)
            }
            position="fixed"
            direction="row"
            justifyContent="right"
            spacing={2}
            bottom={0}
          >
            <ECButton variant="text" onClick={handleCancelButtonClick}>
              Cancel
            </ECButton>

            <ECButton
              variant="contained"
              onClick={() => {
                if (submissionConfirmationModal?.isVisible && !showEditForm) {
                  setConfirmationModalOpen(true);
                } else {
                  doUpdate();
                }
              }}
              isLoading={isLoading}
            >
              {editConfig.submitTitle}
            </ECButton>
          </ECStack>
        )}
      </ECBox>
      <ECModal
        isOpen={isWorkflowGraphVisible}
        onClose={handleCloseModal}
        noPadding
      >
        <ECGraph
          width="75vw"
          height="75vh"
          nodes={
            workflow?.nodes?.map(node => ({
              id: node.node.id?.toString(),
              label: node.node.description,
            })) ?? []
          }
          edges={
            workflow?.edges?.map(edge => ({
              id: edge.id?.toString(),
              label: `${edge.name?.toString()} ${
                edge.roles?.length > 0 ? '-' : ''
              } ${edge.roles.map(role => role.name).join(',')}`,
              source: edge.statusFrom.id?.toString(),
              target: edge.statusTo.id?.toString(),
            })) ?? []
          }
          initialSelectedNodeId={detailsData?.workflowStatusId?.toString()}
        />
      </ECModal>

      {submissionConfirmationModal?.isVisible && !showEditForm && (
        <ECModal
          isOpen={confirmationModalOpen}
          onClose={handleCloseConfirmationModal}
        >
          <ECBox p={2}>
            <ECTypography variant="h6">
              {submissionConfirmationModal?.title}
            </ECTypography>

            <ECTypography>{submissionConfirmationModal?.bodyText}</ECTypography>

            <ECBox mt={2} display="flex" gap={2} justifyContent="flex-end">
              <ECButton variant="text" onClick={handleCloseConfirmationModal}>
                Cancel
              </ECButton>
              <ECButton
                variant="contained"
                onClick={handleConfirmSubmit}
                isLoading={isLoading}
              >
                {submissionConfirmationModal?.buttonText ?? 'Confirm'}
              </ECButton>
            </ECBox>
          </ECBox>
        </ECModal>
      )}
    </>
  );
};
