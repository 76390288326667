import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ECButton,
  ECEasyFormFieldType,
  ECWorkflowTemplate,
} from 'app/components';
import {
  SummaryFieldTypes,
  SummaryFieldsData,
} from 'app/components/ECWorkflowSummaryField';
import { Outlet, useParams } from 'react-router';
import {
  useGetBranchByIdQuery,
  useGetBranchTypesQuery,
  useUpdateBranchMutation,
} from 'services/branchApi';
import { useCustomerUser } from 'app/hooks';
import { ForbiddenPage } from '../ForbiddenPage';
import { ContentCopy } from '@mui/icons-material';
import { P } from 'types/Permission';
import { useGetAttachmentQuery } from 'services/attachmentApi';
import { AttachmentCategory } from 'types/Attachment';
import { CopyAssets } from './CopyAssets';
import { concatAddress } from 'utils/strings/concat-address';
import { useGetOrganizationParentListQuery } from 'services/organizationApi';
import { Address } from 'types/Address';
import _ from 'lodash';
import { useGetOnHoldReasonsQuery } from 'services/lookupApi';
import { OnHoldModalButton } from './onHoldModalButton/OnHoldModalButton';
import { LiveModalButton } from './liveModalButton/LiveModalButton';
import moment from 'moment';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { formatDateOnlyForTable } from 'utils/strings/formatDate';

const formElementsDetails = require('./fancy_form_config_details.json');
const formElementsEdit = require('./fancy_form_config_edit.json');

export function BranchDetailsPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [hasEditLocationPermission] = useHasPermission([P.EditBranch]);

  const isDetailsTab =
    !window.location.href.includes('attachments') &&
    !window.location.href.includes('lease');

  const { data: branch } = useGetBranchByIdQuery(
    { id: Number(id) },
    { skip: !id },
  );

  const OnHoldStatusToString = (status?: number) => {
    if (status && status === 1) {
      return 'On Hold';
    } else {
      return 'Live';
    }
  };

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(branch?.status === 1);

  // reset summary fields when isEditMode is set to false
  useEffect(() => {
    if (!isEditMode) {
      initializeFields();
    }
  }, [isEditMode]);

  // select_paginated queries for EDIT form
  useEffect(() => {
    const rightGroupField = formElementsEdit.fields.find(
      field => field.groupName === 'rightSideFields',
    );
    if (rightGroupField) {
      const organizationField = rightGroupField.subFields.find(
        field => field.fieldName === 'org',
      );
      if (organizationField) {
        organizationField.useQuery = useGetOrganizationParentListQuery;
      }

      const onHoldReasonsField = rightGroupField.subFields.find(
        field => field.fieldName === 'onHoldReason',
      );
      if (onHoldReasonsField) {
        onHoldReasonsField.useQuery = useGetOnHoldReasonsQuery;
      }
    }
  }, []);

  // useEffect to set the billing address fields to disabled if the mailingEqualBilling checkbox is checked
  // when first loading the page
  useEffect(() => {
    if (!branch) return;

    const leftSideGroupField = formElementsEdit.fields.find(
      field => field.groupName === 'leftSideFields',
    )?.subFields;
    // if there is mailingEqualBilling, set all billing address fields to disabled
    const billingAddressFields: any[] = leftSideGroupField?.filter(
      field =>
        field?.fieldName?.includes('billingAddress') &&
        field?.fieldName !== 'mailingEqualBilling',
    );
    const billingAddressGroupFields = leftSideGroupField?.find(
      field => field?.groupName === 'groupBillingAddressCodes',
    );

    billingAddressFields.forEach(billingField => {
      billingField.disabled = Boolean(branch?.mailingEqualBilling);
      billingField.required =
        billingField.fieldName !== 'billingAddress.line2' &&
        !Boolean(branch?.mailingEqualBilling);
    });

    billingAddressGroupFields?.subFields?.forEach(billingSubField => {
      billingSubField.disabled = Boolean(branch?.mailingEqualBilling);
      billingSubField.required =
        billingSubField.fieldName !== 'billingAddress.line2' &&
        !Boolean(branch?.mailingEqualBilling);
    });
  }, [branch?.mailingEqualBilling]);

  // useEffect to hide or show the onHold fields based on the onHold status
  useEffect(() => {
    if (!branch) return;

    const rightSideFields = formElementsEdit.fields.find(
      field => field.groupName === 'rightSideFields',
    )?.subFields;
    const onHoldFields = rightSideFields?.filter(field =>
      field.fieldName.includes('onHold'),
    );
    onHoldFields.forEach(field => {
      // hide and show the OTHER REASON field based on onHoldReason selection
      if (field.fieldName === 'onHoldReason.reason') {
        field.visible =
          branch?.onHold === 1 && branch?.onHoldReason?.name === 'Other';
      } else {
        field.visible = branch?.onHold === 1;
      }
    });

    const newDetailsFields = [...formElementsDetails.fields];
    const rightSideDetailsFields = newDetailsFields.find(
      field => field.groupName === 'rightSideFields',
    )?.subFields;
    const onHoldFieldsDetails = rightSideDetailsFields?.filter(field =>
      field.fieldName.includes('onHold'),
    );
    onHoldFieldsDetails.forEach(field => {
      // hide and show the OTHER REASON field based on onHoldReason selection
      if (field.fieldName === 'onHoldReason.reason') {
        field.visible =
          branch?.onHold === 1 && branch?.onHoldReason?.name === 'Other';
      } else {
        field.visible = branch?.onHold === 1;
      }
    });
    formElementsDetails.fields = newDetailsFields;
  }, [branch?.onHold, branch?.onHoldReason]);

  const handleChange = (output: ECEasyFormFieldType[], fieldname, value) => {
    if (fieldname !== 'mailingEqualBilling' && fieldname !== 'onHoldReason')
      return;

    if (fieldname === 'onHoldReason') {
      // hide and show the OTHER REASON field based on onHoldReason selection
      const rightSideFields = output.find(
        field => field.groupName === 'rightSideFields',
      )?.subFields;
      const onHoldOtherReasonField = rightSideFields?.find(
        field => field.fieldName === 'onHoldReason.reason',
      );

      if (onHoldOtherReasonField) {
        if (value?.name === 'Other') {
          onHoldOtherReasonField.visible = true;
          onHoldOtherReasonField.required = true;
        } else {
          onHoldOtherReasonField.visible = false;
          onHoldOtherReasonField.required = false;
        }
      }
    }

    const leftSideGroupField = output.find(
      field => field.groupName === 'leftSideFields',
    )?.subFields;
    const isBillingSameAsAddressCheckboxSelected = (
      leftSideGroupField?.find(
        field => field.fieldName === 'mailingEqualBilling',
      )?.value as string[]
    )?.includes('Use same for Billing Address');

    const newFields = _.clone(leftSideGroupField) || [];

    const billingAddressFields: any[] = newFields.filter(
      field =>
        field?.fieldName?.includes('billingAddress') &&
        field?.fieldName !== 'mailingEqualBilling',
    );

    const billingAddressGroupFields = newFields.find(
      field => field?.groupName === 'groupBillingAddressCodes',
    );
    //billingAddressFields.push(billingAddressGroupFields);

    const addressCountryCode = newFields?.find(
      data => data?.fieldName === 'mailingAddress.countryCode',
    );

    if (
      isBillingSameAsAddressCheckboxSelected &&
      Array.isArray(addressCountryCode?.value) &&
      addressCountryCode?.value?.length > 0
    ) {
      addressCountryCode.value = addressCountryCode.value[0];
    }

    if (isBillingSameAsAddressCheckboxSelected) {
      billingAddressFields.forEach(billingField => {
        billingField.value = newFields?.find(
          field =>
            field.fieldName ===
            billingField.fieldName.replace('billingAddress', 'mailingAddress'),
        )?.value;
        billingField.overrideRowValue = true;
        billingField.disabled = true;
        billingField.required = false;
      });

      const mailingGroupField = newFields.find(
        field => field?.groupName === 'groupMailingAddressCodes',
      );
      // logic for the group field of city, state and zip
      billingAddressGroupFields?.subFields?.forEach(billingSubField => {
        billingSubField.value = mailingGroupField?.subFields?.find(
          mailingSubField =>
            mailingSubField?.fieldName ===
            billingSubField?.fieldName?.replace(
              'billingAddress',
              'mailingAddress',
            ),
        )?.value;
        billingSubField.overrideRowValue = true;
        billingSubField.disabled = true;
        billingSubField.required = false;
      });
    } else {
      billingAddressFields.forEach(fieldToCopy => {
        fieldToCopy.disabled = false;

        // address 2 should not be required
        if (fieldToCopy.fieldName !== 'billingAddress.line2')
          fieldToCopy.required = true;
      });

      billingAddressGroupFields?.subFields?.forEach(billingSubField => {
        billingSubField.disabled = false;
        if (billingSubField.fieldName !== 'billingAddress.line2')
          billingSubField.required = true;
      });
    }

    let countryCode =
      newFields?.find(data => data?.fieldName === 'mailingAddress.countryCode')
        ?.value || branch?.mailingAddress?.countryCode;

    const postalCodeFields = newFields.filter(field =>
      field?.fieldName?.includes('mailingAddress.zipCodePostalCode'),
    );

    countryCode = Array.isArray(countryCode)
      ? countryCode[0]
      : countryCode?.value || countryCode;

    if (countryCode)
      postalCodeFields.forEach(postalCodeField => {
        postalCodeField.required = !(countryCode?.toLowerCase() === 'jm');
      });

    let countryCodeBilling =
      newFields?.find(data => data?.fieldName === 'billingAddress.countryCode')
        ?.value || branch?.billingAddress?.countryCode;

    const postalCodeFieldsBilling = newFields.filter(field =>
      field?.fieldName?.includes('billingAddress.zipCodePostalCode'),
    );

    countryCodeBilling = Array.isArray(countryCodeBilling)
      ? countryCodeBilling[0]
      : countryCodeBilling?.value || countryCodeBilling;

    if (countryCodeBilling)
      postalCodeFieldsBilling.forEach(postalCodeField => {
        postalCodeField.required = !(
          countryCodeBilling?.toLowerCase() === 'jm' ||
          isBillingSameAsAddressCheckboxSelected
        );
      });
  };

  const [validations, setValidations] = useState<any>([]);

  const [summaryFieldsValues, setSummaryFieldsValues] = useState<any>({});

  const initializeFields = useCallback(() => {
    const newSummaryFieldsValues = {
      status: branch?.status,
      name: branch?.name,
      storeNumber: branch?.storeNumber,
      branchType: { id: branch?.branchTypeId, name: branch?.branchType?.name },
      phone: branch?.phone,
      phoneExt: branch?.phoneExt,
    };
    setSummaryFieldsValues(newSummaryFieldsValues);
    setValidations([]);
  }, [branch]);

  useEffect(() => {
    initializeFields();
  }, [branch]);

  const handleSummaryFieldChange = (fieldName: string) => (value: any) => {
    setSummaryFieldsValues(prev => ({ ...prev, [fieldName]: value }));
  };

  const summaryData: SummaryFieldsData[] = useMemo(() => {
    const mailingAddress = branch?.mailingAddress;
    const newSummaryData: SummaryFieldsData[] = [
      {
        id: 'status',
        label: 'State',
        data: isActive,
        type: SummaryFieldTypes.State,
        isEditable: true,
        onChange: setIsActive,
      },
      {
        id: 'onHold',
        label: 'On Hold',
        data: {
          label: OnHoldStatusToString(branch?.onHold),
          severity: branch?.onHold === 1 ? 'error' : 'success',
        },
        type: SummaryFieldTypes.SingleChip,
      },
      {
        id: 'id',
        label: 'Location ID',
        data: id,
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'name',
        label: 'Location',
        data: summaryFieldsValues?.name,
        type: SummaryFieldTypes.Text,
        isEditable: true,
        required: true,
        onChange: handleSummaryFieldChange('name'),
        validationMessage: validations?.find(v => v.field === 'name')
          ?.validationMessage,
        isValid: !validations?.some(v => v.field === 'name'),
      },
      {
        id: 'storeNumber',
        label: 'Store Number',
        data: summaryFieldsValues?.storeNumber,
        type: SummaryFieldTypes.Text,
        isEditable: true,
        isNumeric: false,
        onChange: handleSummaryFieldChange('storeNumber'),
      },
      {
        id: 'branchType',
        label: 'Concept',
        data: summaryFieldsValues?.branchType,
        type: SummaryFieldTypes.SelectPaginated,
        isEditable: true,
        useQuery: useGetBranchTypesQuery,
        obAlias: 'name',
        required: true,
        onChange: handleSummaryFieldChange('branchType'),
        validationMessage: validations?.find(v => v.field === 'branchType')
          ?.validationMessage,
      },
      {
        id: 'phone',
        label: 'Phone',
        data: summaryFieldsValues?.phone,
        type: SummaryFieldTypes.Phone,
        isEditable: true,
        onChange: handleSummaryFieldChange('phone'),
      },
      {
        id: 'address',
        label: 'Address',
        data: concatAddress(
          [
            mailingAddress?.line1,
            mailingAddress?.line2,
            mailingAddress?.cityName,
            mailingAddress?.stateProvinceCode,
            mailingAddress?.zipCodePostalCode,
            mailingAddress?.countryCode,
          ].filter(Boolean),
        ),
        type: SummaryFieldTypes.Address,
      },
      {
        id: 'banner',
        label: '',
        data: {
          description: (
            <>
              This location is on hold due to the following reason:&nbsp;
              <b>'{branch?.onHoldReason?.name}'</b>&nbsp;
              {branch?.onHoldActiveDate && 'until'}
              <b>
                &nbsp;
                {(branch?.onHoldActiveDate &&
                  formatDateOnlyForTable(
                    branch?.onHoldActiveDate,
                    true,
                  )?.toUpperCase()) ||
                  ''}
              </b>
              . To enable operations, please click the 'Live' button.
            </>
          ),
          title: 'The Location is On Hold Status',
          severity: 'info',
        },
        type: SummaryFieldTypes.CustomAlert,
        isVisible: branch?.onHold === 1,
      },
    ];
    return newSummaryData;
  }, [branch, id, validations, summaryFieldsValues, isActive]);

  const [isCopyAssetsModalOpen, setIsCopyAssetsModalOpen] = useState(false);
  const handleCopyAssetClick = useCallback(() => {
    setIsCopyAssetsModalOpen(true);
  }, []);

  const handleCloseCopyAssets = useCallback(() => {
    setIsCopyAssetsModalOpen(false);
  }, []);

  const onHoldOrLiveButton = useMemo(() => {
    return branch?.onHold === 1 ? (
      <LiveModalButton branchId={Number(id)} disabled={isEditMode} />
    ) : (
      <OnHoldModalButton branchId={Number(id)} disabled={isEditMode} />
    );
  }, [branch?.onHold, id, isEditMode]);

  const addionalActions: ReactElement[] = useMemo(() => {
    if (!id) return [];
    const newAdditionalActions: ReactElement[] = [
      onHoldOrLiveButton,
      <ECButton
        variant="outlined"
        onClick={handleCopyAssetClick}
        sx={{ alignSelf: 'flex-end' }}
        startIcon={<ContentCopy />}
        scopes={[P.EditAsset]}
        disabled={isEditMode}
      >
        Copy Assets
      </ECButton>,
    ];
    return newAdditionalActions;
  }, [onHoldOrLiveButton, isEditMode, id]);

  const { data: attachments } = useGetAttachmentQuery(
    {
      module: 'branch',
      moduleId: Number(id || 0),
      category: AttachmentCategory.Photo,
    },
    {
      skip: !id || !isDetailsTab,
    },
  );

  const attachmentPreview = useMemo(() => {
    const matchingPhotos = attachments?.others?.filter(
      attachment => attachment?.attachmentType === AttachmentCategory.Photo,
    );
    if (matchingPhotos && matchingPhotos.length > 0) {
      return matchingPhotos[matchingPhotos.length - 1];
    }
  }, [attachments]);

  const isCustomer = useCustomerUser();

  const [
    doUpdateLocation,
    {
      data: updateData,
      isError: isUpdateError,
      error: updateError,
      isLoading: isLoadingUpdate,
      isSuccess: isUpdateSuccess,
      reset: resetUpdate,
    },
  ] = useUpdateBranchMutation();

  const useUpdateLocation = useCallback(() => {
    const doUpdate = async data => {
      const locationMailingAddress: Address = {
        line1: data.mailingAddress?.line1 || '',
        line2: data.mailingAddress?.line2 || '',
        zipCodePostalCode: data?.mailingAddress?.zipCodePostalCode || '',
        cityName: data.mailingAddress.cityName || '',
        stateProvinceCode: data.mailingAddress.stateProvinceCode || '',
        countryCode: data.mailingAddress.countryCode || '',
      };

      // validate required summary fields
      const requiredFields = ['name', 'branchType'];
      const requiredFieldsNamesEnum = {
        name: 'Location',
        branchType: 'Concept',
      };
      const missingFields = requiredFields.filter(
        field =>
          !summaryFieldsValues[field] || summaryFieldsValues[field] === '',
      );
      setValidations(
        missingFields.map(field => ({
          field,
          validationMessage: `${requiredFieldsNamesEnum[field]} is required!`,
        })),
      );
      if (missingFields.length > 0) {
        return;
      }

      const isMailingEqualBilling = data.mailingEqualBilling?.includes(
        'Use same for Billing Address',
      );

      const body: any = {
        id: data.id,
        branchTypeId: summaryFieldsValues.branchType.id,
        organizationId: Number(data.org?.id),
        mailingAddress: locationMailingAddress,
        billingAddress: isMailingEqualBilling
          ? locationMailingAddress
          : data.billingAddress,
        mailingEqualBilling: isMailingEqualBilling ? 1 : 0,
        onHoldReasonId:
          data.onHoldReason?.id &&
          data.onHoldReason?.id !== branch?.onHoldReason?.id
            ? data.onHoldReason?.id
            : undefined,
        reason: data.onHoldReason?.reason || undefined,
        name: summaryFieldsValues.name,
        storeNumber: summaryFieldsValues.storeNumber,
        phone: summaryFieldsValues.phone,
        phoneExt: summaryFieldsValues.phoneExt,
        averageWeeklySales: data.averageWeeklySales,
        status: isActive ? 1 : 0,
        legalEntityName: data.legalEntityName,
        openDate: data.openDate,
      };
      const { onHoldActiveDate } = data;
      if (_.isNil(onHoldActiveDate)) {
        body.onHoldExpirationDate = undefined;
        body.deleteOnHoldExpirationDate = true;
      } else if (onHoldActiveDate !== branch?.onHoldActiveDate) {
        const formattedDate = moment(onHoldActiveDate).isValid()
          ? moment(onHoldActiveDate).format('MM/DD/YYYY')
          : undefined;

        if (formattedDate) {
          body.onHoldExpirationDate = formattedDate;
          body.deleteOnHoldExpirationDate = false;
        } else {
          body.onHoldExpirationDate = undefined;
          body.deleteOnHoldExpirationDate = true;
        }
      }

      doUpdateLocation(body);
    };
    return [
      doUpdate,
      {
        data: updateData,
        isError: isUpdateError,
        error: updateError,
        isLoading: isLoadingUpdate,
        isSuccess: isUpdateSuccess,
        reset: resetUpdate,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isLoadingUpdate,
    isUpdateSuccess,
    resetUpdate,
    updateData,
    id,
    summaryFieldsValues,
    isActive,
    branch,
  ]);

  useEffect(() => {
    setIsActive(branch?.status === 1);
  }, [branch?.status]);

  const handleSetActive = useCallback(() => {
    setIsActive(true);
  }, []);

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return branch ? (
    <>
      <ECWorkflowTemplate
        tabsAsLinks
        title={branch.name}
        summaryData={summaryData}
        additionalActions={addionalActions}
        useUpdateMutation={useUpdateLocation}
        onChange={handleChange}
        editConfig={formElementsEdit.config}
        editFields={formElementsEdit.fields}
        detailsConfig={formElementsDetails.config}
        detailsFields={formElementsDetails.fields}
        detailsData={branch}
        imgSrc={attachmentPreview?.url}
        profileAttachment={attachmentPreview}
        moduleName="branch"
        moduleId={id}
        hideNoteTab
        onEditButtonClick={setIsEditMode}
        additionalTabs={[
          {
            value: 'lease',
            label: t('translation:pages.locations.lease'),
            content: <Outlet />,
            editable: true,
            link: 'lease',
          },
        ]}
        isEditAllowed={hasEditLocationPermission}
        submissionConfirmationModal={{
          title:
            'Inactivating a location will not allow you to create a future Service Request for this location and will be removed from any pertaining PMs',
          buttonText: 'Inactivate',
          bodyText: '',
          isVisible: !isActive && branch?.status === 1,
          onClose: handleSetActive,
        }}
      />
      <CopyAssets
        branchId={id}
        isCopyAssetsModalOpen={isCopyAssetsModalOpen}
        handleCloseCopyAssets={handleCloseCopyAssets}
      />
    </>
  ) : null;
}
