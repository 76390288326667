import React, { useEffect, useState, useMemo } from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  FormHelperText,
  InputAdornment,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ECTextField } from '../ECTextField';
import { Room, Info } from '@mui/icons-material';
import { throttle } from 'lodash';
import { ECPopper } from '../ECPopper';
import { ECBox } from '../ECBox';
import { ECTooltipNoMaxWidth } from '../ECTooltip';

export const ECAutocompleteStyled = styled(Autocomplete)<
  AutocompleteProps<any, any, any, any, any>
>(() => ({}));

interface ECAutocompleteProps
  extends Omit<AutocompleteProps<any, any, any, any, any>, 'renderInput'> {
  label?: string;
  name?: string;
  error?: boolean;
  errorMessage?: string;
  placeholder?: string;
  required?: boolean;
  hiddenLabel?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  toolTip?: {
    content: string | React.ReactNode;
    icon?: React.ReactNode;
    placement?: 'top' | 'bottom';
  };
  componentsPropsPopper?: any;
  hideValidationMessage?: boolean;
}

export const ECAutocomplete = ({
  options,
  placeholder,
  label,
  error,
  errorMessage,
  variant,
  onChange,
  required,
  toolTip,
  hiddenLabel,
  componentsPropsPopper,
  hideValidationMessage,
  ...autocompleteProps
}: ECAutocompleteProps) => {
  return (
    <ECBox width="100%" display="flex" flexDirection="column">
      <ECAutocompleteStyled
        {...autocompleteProps}
        sx={{
          width: '100%',
          ...autocompleteProps.sx,
        }}
        options={options}
        PopperComponent={props => <ECPopper {...props} />}
        componentsProps={{
          popper: componentsPropsPopper,
        }}
        renderInput={params => (
          <ECTextField
            {...params}
            id={autocompleteProps?.id}
            name={autocompleteProps?.name}
            placeholder={placeholder}
            label={label}
            variant={variant ?? 'filled'}
            error={error}
            helperText={hideValidationMessage ? null : errorMessage}
            hiddenLabel={hiddenLabel}
            FormHelperTextProps={{
              sx: {
                color: errorMessage
                  ? theme => theme.palette.error.main
                  : 'inherit',
              },
            }}
            sx={theme => ({
              '.MuiInputLabel-root': {
                color:
                  errorMessage || error
                    ? theme.palette.error.main
                    : theme.palette.text.secondary,
              },
              '.MuiInputLabel-root:after': {
                color:
                  errorMessage || error
                    ? theme.palette.error.main
                    : theme.palette.text.secondary,
              },
            })}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {toolTip && (
                    <ECTooltipNoMaxWidth
                      placement={toolTip.placement || 'top'}
                      title={
                        typeof toolTip.content === 'string' ? (
                          toolTip.content
                        ) : (
                          <div>{toolTip.content}</div>
                        )
                      }
                      arrow
                    >
                      {React.isValidElement(toolTip.icon) ? (
                        toolTip.icon
                      ) : (
                        <Info
                          sx={theme => ({
                            color: theme.palette.action.active,
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '2.25rem',
                            top: '50%',
                            transform: 'translateY(-50%)',
                          })}
                        />
                      )}
                    </ECTooltipNoMaxWidth>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        onChange={onChange}
      />
    </ECBox>
  );
};

export const ECAutocompleteWhiteBorders = styled(Autocomplete)<
  AutocompleteProps<any, any, any, any, any>
>(() => ({
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    color: 'black',
    '& fieldset': {
      borderColor: 'white',
      color: 'black',
    },
    '&:hover fieldset': {
      borderColor: 'white',
      color: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      color: 'black',
    },
  },
}));

const autocompleteService: { current: any } = { current: null };
const detailsService: { current: any } = { current: null };

interface Terms {
  offset: number;
  value: string;
}
interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  place_id: string;
  description: string;
  structured_formatting: StructuredFormatting;
  terms: Terms[];
}

interface AddressComponents {
  long_name: string;
  short_name: string;
  types: string[];
}

interface DetailsPlace {
  address_components: AddressComponents[];
  formatted_address: string;
}

export interface AutocompleteCityResult {
  full: string;
  city?: string;
  state?: string;
  county?: string;
  country?: string;
  zipCode?: string;
}
interface ECAutcompleteCityProps
  extends Omit<
    AutocompleteProps<any, any, any, any, any>,
    'onChange' | 'options' | 'renderInput'
  > {
  sx?: any;
  placeholder?: string;
  label?: string;
  defaultValue?: AutocompleteCityResult;
  variant?: 'outlined' | 'standard';
  onChange?: (value: AutocompleteCityResult) => void;
  error?: boolean;
  helperText?: string;
}

export const ECAutcompleteCity = ({
  sx,
  placeholder,
  label,
  defaultValue,
  variant,
  onChange,
  ...restOfProps
}: ECAutcompleteCityProps) => {
  const [locationValue, setLocationValue] = useState<AutocompleteCityResult>(
    defaultValue ?? {
      full: '',
    },
  );
  const [results, setResults] = useState<PlaceType[]>([]);
  const [options, setOptions] = useState<string[]>([]);
  const [placeId, setPlaceId] = useState<string>('');

  useEffect(() => {
    onChange?.(locationValue);
  }, [locationValue]);

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: google.maps.places.AutocompletionRequest,
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          autocompleteService.current.getPlacePredictions(request, callback);
        },
        200,
      ),
    [],
  );

  const fetchDetails = useMemo(
    () =>
      throttle(
        (
          request: google.maps.places.PlaceDetailsRequest,
          callback: (results?: DetailsPlace) => void,
        ) => {
          detailsService?.current?.getDetails(request, callback);
        },
        200,
      ),
    [],
  );

  useEffect(() => {
    let active = true;

    if (autocompleteService && !autocompleteService.current) {
      autocompleteService.current =
        new google.maps.places.AutocompleteService();
    }

    if (locationValue.full === '') {
      setOptions([]);
      return;
    }

    fetch(
      {
        input: locationValue.full,
        types: ['(regions)'],
        componentRestrictions: { country: 'us' },
      },
      (results?: readonly PlaceType[]) => {
        if (active && results) {
          setResults([...results]);
          setOptions(results.map(option => option.description));
        }
      },
    );

    return () => {
      active = false;
    };
  }, [locationValue, fetch]);

  useEffect(() => {
    if (!detailsService?.current) {
      detailsService.current = new window.google.maps.places.PlacesService(
        new window.google.maps.Map(document.createElement('div')),
      );
    }

    fetchDetails(
      {
        placeId,
      },
      (results?: DetailsPlace) => {
        onChange?.({
          full: results?.formatted_address ?? '',
          city: results?.address_components.find(addressComponent =>
            addressComponent.types.includes('locality'),
          )?.long_name,
          state: results?.address_components.find(addressComponent =>
            addressComponent.types.includes('administrative_area_level_1'),
          )?.short_name,
          county: results?.address_components.find(addressComponent =>
            addressComponent.types.includes('administrative_area_level_2'),
          )?.short_name,
          country: results?.address_components.find(addressComponent =>
            addressComponent.types.includes('country'),
          )?.short_name,
          zipCode: results?.address_components.find(addressComponent =>
            addressComponent.types.includes('postal_code'),
          )?.long_name,
        });
      },
    );
  }, [placeId]);

  const handleChange = (value: string) => {
    const selectedOption = results.find(result => result.description === value);

    setPlaceId(selectedOption?.place_id || '');
  };

  return (
    <ECAutocompleteWhiteBorders
      {...restOfProps}
      freeSolo
      sx={sx}
      options={options}
      defaultValue={defaultValue?.full}
      renderInput={params => (
        <ECTextField
          {...params}
          variant={variant}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Room sx={theme => ({ color: theme.palette.grey[400] })} />
              </InputAdornment>
            ),
          }}
          helperText={restOfProps.helperText}
          error={restOfProps.error}
          onChange={e => setLocationValue({ full: e.target.value })}
          placeholder={placeholder}
          label={label}
        />
      )}
      onChange={(_event, newValue) => {
        if (!newValue) {
          setLocationValue({ full: '' });
        }
        handleChange(newValue);
      }}
    />
  );
};
