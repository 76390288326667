import { useCallback, useEffect, useState, memo } from 'react';
import { ECFormControlLabel, ECRadio, ECRadioGroup } from '..';
import { ECBox } from '../ECBox';
import { ECBasicDatePicker } from '../ECDatePicker';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export interface RadioWarrantyOption {
  fieldName: string;
  label: string;
  readOnly?: boolean;
  isValid?: boolean;
  value: Date;
  errorMessage?: string;
  required?: boolean;
  otherOptions?: any[];
}

export interface ECRadioWarrantyStartDateProps {
  options: RadioWarrantyOption[];
  id: string;
  name: string;
  placeholder?: string;
  variant: 'standard' | 'filled' | 'outlined';
  validationMessage?: string;
  readOnly?: boolean;
  label?: string;
  orientation?: 'horizontal' | 'vertical';
  defaultValue?: RadioWarrantyOption;
  isLoading?: boolean;
  onChange: (output: any) => void;
  onChangeOptionValue?: (
    newOption: RadioWarrantyOption,
    newOutput?: RadioWarrantyOption,
  ) => void;
}

export const ECRadioWarrantyStartDate = memo(
  ({
    id,
    options,
    name,
    orientation = 'horizontal',
    defaultValue,
    onChange,
    onChangeOptionValue,
  }: ECRadioWarrantyStartDateProps) => {
    const { t } = useTranslation();

    const [output, setOutput] = useState<RadioWarrantyOption | undefined>(
      defaultValue,
    );

    useEffect(() => {
      if (defaultValue) {
        const selectedOption = options?.find(
          option => option.fieldName === defaultValue.fieldName,
        );
        if (selectedOption) {
          const others: { fieldName: string; value: Date }[] = [];
          options?.forEach(option => {
            if (option?.fieldName !== selectedOption.fieldName) {
              others.push({
                fieldName: option?.fieldName,
                value: option?.value,
              });
            }
          });
          selectedOption.otherOptions = others;
        }
        onChange?.(selectedOption);
      }
    }, [options, defaultValue]);

    useEffect(() => {
      options.forEach(index => onChangeOptionValue?.(index));
      const newSelectedOption = options.find(
        option => option.fieldName === output?.fieldName,
      );
      if (newSelectedOption) {
        onChange?.(newSelectedOption);
      }
    }, [output, options]);

    const handleOptionValueChange = useCallback(
      (newOption: RadioWarrantyOption, index: number) => {
        const newOptions = [...options];
        if (moment(newOption.value).isValid() || newOption.value === null) {
          newOptions[index] = newOption;
        } else {
          newOption.value = moment('').toDate();
          newOptions[index] = newOption;
        }
        const newOutput = newOptions.find(
          option => option.fieldName === output?.fieldName,
        );
        onChangeOptionValue?.(newOption, newOutput);
      },
      [options, output, onChangeOptionValue],
    );

    return (
      <ECRadioGroup
        row
        id={id}
        name={name}
        value={output?.fieldName}
        defaultValue={output?.fieldName}
        onChange={event => {
          const eventValue = (event.target as HTMLInputElement).value;
          const selectedValue = options.find(
            option => option.fieldName === eventValue,
          );

          setOutput(selectedValue);
          onChange(selectedValue);
        }}
      >
        <ECBox
          display="flex"
          flexDirection={orientation === 'horizontal' ? 'row' : 'column'}
        >
          {options.map((option, index) => {
            return (
              <ECFormControlLabel
                control={<ECRadio value={option.fieldName} />}
                sx={{
                  my: 1,
                  '& .MuiFormControlLabel-label': { width: '100%' },
                }}
                value={option.fieldName}
                label={
                  <ECBasicDatePicker
                    readOnly={
                      option.readOnly || option.fieldName !== output?.fieldName
                    }
                    fieldName={option.fieldName}
                    label={option.label}
                    value={option.value}
                    error={option.required && option.value === null}
                    errorMessage={t('translation:form.validation.required', {
                      name: option.label,
                    })}
                    required={option.required}
                    onChange={date => {
                      handleOptionValueChange?.(
                        {
                          ...option,
                          value: date,
                        },
                        index,
                      );
                    }}
                  />
                }
              />
            );
          })}
        </ECBox>
      </ECRadioGroup>
    );
  },
);
