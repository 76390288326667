import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Helmet } from 'react-helmet-async';
import {
  ECButton,
  ECCard,
  ECEasyFormConfigType,
  ECEasyFormCreate,
  ECEasyFormFieldType,
  ECEasyTable,
  ECEasyTableConfigType,
  ECEasyTableSortConfigType,
  ECGrid,
  ECRequirePermission,
  ECTypography,
  FilterFieldOption,
} from 'app/components';
import { Sorting } from 'utils/ECEasyTableUtils';
import { EditForm2 } from './EditForm2';
import { Add, ArrowBackIos } from '@mui/icons-material';
import { ECDrawerDetails } from '../ECDrawerDetails';
import { ECDrawerDetailsContent, TabsType } from '../ECDrawerDetailsContent';
import { ECBox } from '../ECBox';
import { isEmptyValue, SubmissionConfirmationModal } from '../ECForm';
import { RootState } from 'index';
import { useSelector } from 'react-redux';
import { AutoCompleteDropDownOption } from 'types/User';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setTitle } from 'store/slice/page';
import { StyleConstants } from 'styles/StyleConstants';
import { getConfigStateFromApiOrMock } from 'utils/pageUtils';
import { FormHelperText, Skeleton } from '@mui/material';
import { useGetMetadataQuery } from 'services/metadataApi';
import { ECCircularProgress } from '../ECCircularProgress';
import { MultiSelectTableConfigType } from 'app/components';
import { useNavigate } from 'react-router';
import * as _ from 'lodash';
import moment from 'moment';
import { EmptyType } from 'app/pages/EmptyStatePage';
import { initialState, setActiveFilter } from 'store/slice/page';
import { useMatch } from 'react-router';
import { OrganizationIdTypeEnum } from 'types/Organization';
import { ActivityType } from 'types/Activity';

export interface ECDynamicPageTemplateProps {
  pageTitle: string;
  useLazyGetListQuery: any;
  useLazyGetByIdQuery?: any;
  tableConfig?: ECEasyTableConfigType;
  defaultSortConfig?: ECEasyTableSortConfigType;
  editFormConfig?: ECEasyFormConfigType;
  editFormFields?: ECEasyFormFieldType[];
  createFormConfig?: ECEasyFormConfigType;
  createFormFields?: ECEasyFormFieldType[];
  detailsConfig?: ECEasyFormConfigType;
  detailsFields?: ECEasyFormFieldType[];
  deleteConfirmationTitle?: string;
  deleteConfirmationBody?: string;
  searchFormConfig?: ECEasyFormConfigType;
  searchFormFields?: ECEasyFormFieldType[];
  useCreateMutation: any;
  useUpdateMutation?: any;
  useDeleteMutation?: any;
  additionalDeleteAction?: () => void;
  editFormAdditionalActions?: React.ReactNode;
  editFormAdditionalElements?: React.ReactNode;
  downloadFileName?: string;
  enableExport?: boolean;
  hasDetails?: boolean;
  updatedRow?: any;
  marginTop?: boolean;
  removeFilter?: boolean;
  disclaimer?: string;
  moduleName?: string;
  showOnlyHistoryTab?: boolean;
  historyTabIdAttribute?: string | string[];
  showOnlyDetailsTab?: boolean;
  showOnlyAttachmentsTab?: boolean;
  showImageAttachment?: boolean;
  showSteps?: boolean;
  showSelfPerformingFilter?: boolean;
  showStatusActiveFilter?: boolean;
  showMissedVendorIdFilter?: boolean;
  statusFilterAlias?: string;
  categoryFilterAlias?: string;
  customersFilterAlias?: string;
  showCategoryFilter?: boolean;
  showStatusFilter?: boolean;
  showCustomFilter?: boolean;
  customSimpleSelectFilterOptions?: FilterFieldOption[];
  shouldJoinCustomParams?: boolean;
  customSimpleSelectFilterLabel?: string;
  customSimpleSelectFilterName?: string;
  showDateFilter?: boolean;
  showAssignedToMeFilter?: boolean;
  showAssignedLocationsFilter?: boolean;
  showAssetTypeNameFilter?: boolean;
  showConceptsFilter?: boolean;
  showAssetGroupSingleFilter?: boolean;
  showSetByFilter?: boolean;
  setByFilterOptions?: string[];
  showLocationFilter?: boolean;
  showManufacturerFilter?: boolean;
  userCompany?: any;
  showAwaitingInvoiceFilter?: boolean;
  showAssetTypeFilter?: boolean;
  showEtaFilter?: boolean;
  showTechAssginedFilter?: boolean;
  showCalendarViewFilter?: boolean;
  showCustomersFilter?: boolean;
  showTechNotAssignedToggleFilter?: boolean;
  dateRangeDefaultSelectedOption?: string;
  dateFilterFieldsOptions?: FilterFieldOption[];
  categoryFilterOptions?: FilterFieldOption[];
  statusFilterOptions?: FilterFieldOption[];
  assetTypeFilterOptions?: FilterFieldOption[];
  locationFilterOptions?: FilterFieldOption[];
  useLocationFilterQuery?: any;
  useManufacturerFilterQuery?: any;
  useGetAssetTypesFilterQuery?: any;
  locationFilterQueryParams?: any;
  manufacturerFilterQueryParams?: any;
  statusFilterInitialSelected?: boolean;
  activeStatusFilterActiveLabel?: string;
  activeStatusFilterInactiveLabel?: string;
  showColumnVisibilityFilter?: boolean;
  isDraggableColumns?: boolean;
  dateAtAlias?: string;
  dateAfterAlias?: string;
  dateBeforeAlias?: string;
  statusAlias?: string;
  showBadgeExpirationDate?: boolean;
  withDrawer?: boolean;
  idToGetListBy?: any;
  renderCustomActionComponent?: (row: any, parentRow?: any) => React.ReactNode;
  renderCustomAttachmentComponent?: (row: any) => React.ReactNode;
  onClickViewUsersTable?: (row: any, event: any) => void;
  hasUsersTablePreviewData?: boolean;
  onClickRecipientsNotificationEmail?: (row: any, event: any) => void;
  onClickViewNotificationEmail?: (row: any, event: any) => void;
  onClickRecipientsNotificationMobile?: (row: any, event: any) => void;
  onClickViewNotificationMobile?: (row: any, event: any) => void;
  onClickRecipientsNotificationText?: (row: any, event: any) => void;
  onClickViewNotificationText?: (row: any, event: any) => void;
  selectedChipUsersTable?: string | number;
  onCreate?: (returnedData?: any) => void;
  onDuplicateChip?: () => void;
  openDrawerOnEditMode?: (row: any) => boolean;
  onDrawerClose?: () => void;
  onAddNewClick?: () => void;
  onEditButtonClick?: (value) => void;
  editFormAdditionalProcess?: ({ row, formConfig, formFields }) => void;
  onRowClick?: (row: any) => void;
  onDuplicatePress?: (row: any) => void;
  onNewDataFetched?: (data: any) => void;
  onChange?: (output: ECEasyFormFieldType[], fieldName?: string) => void;
  mustTriggerListQuery?: boolean;
  usingGlobalSearch?: boolean;
  additionalTabs?: TabsType[];
  showOnlyadditionalTabs?: boolean;
  module?: string;
  moduleRedirectElement?: React.ReactNode;
  ignoreHyperlink?: boolean;
  customContent?: { header?: React.ReactNode; children?: React.ReactNode };
  showTotal?: boolean;
  showTotalHelperText?: string;
  showTotalResults?: boolean;
  customParams?: any;
  customActiveFilter?: { paramName: string; value: string };
  shouldUpdateCreateFormField?: boolean;
  multiSelectTable?: boolean;
  showSelectAllPageRows?: boolean;
  emptyType?: EmptyType;
  multiSelectTableConfig?: MultiSelectTableConfigType;
  preSelectRowsFieldname?: string;
  onMultiTableSelect?: (
    selectedRows: any[],
    isUnselecting?: boolean,
    row?: any,
  ) => void;
  customMultiSelectActionButton?: (rows?: any[]) => React.ReactNode;
  multiSelectedRows?: ((rows: any) => void) | undefined;
  allChecked?: boolean;
  clearSelection?: boolean;
  customDrawerTitle?: string;
  outsideCustomParam?: boolean;
  customTableBodyMaxHeight?: string;
  disableTableContent?: boolean;
  drawerTitleBarBGColor?: string;
  shouldCacheLazyQuery?: boolean;
  showEditButton?: boolean;
  shouldTriggerLazyQuery?: boolean;
  dateAlias?: string;
  customTableHeader?: string;
  shouldUseSearchParams?: boolean;
  dateSearchGroup?: string;
  selectRowsMaxCount?: number;
  shouldNotUseActiveFilter?: boolean;
  submissionConfirmationModal?: SubmissionConfirmationModal;
  customDetailsTabLabel?: string;
  selectedRows?: any[];
  exportModuleName?: string;
  exportTradeId?: number;
  exportBranchId?: number;
  exportUserId?: number;
  enableTableCsvExport?: boolean;
  createSaveButtonDisabled?: boolean;
  showHierarchyFilter?: boolean;
  showActivityButton?: boolean;
  searchBarCustomElements?: React.ReactNode;
  initialApprovalData?: any;
  setInitialApprovalData?: any;
  activityType?: ActivityType;
  hideSimpleSearchBar?: boolean;
  hideSaveFilterButton?: boolean;
  hidePagination?: boolean;
  hidePerPageSelector?: boolean;
  renderTableCustomLink?: (url: string) => React.ReactNode;
  showDrawerDetailTitle?: boolean;
  conditionalLinearProgressColor?: boolean;
  customPadding?: string;
  useSkeletonLoading?: boolean;
}

export function ECDynamicPageTemplate({
  pageTitle,
  useLazyGetListQuery,
  useLazyGetByIdQuery,
  tableConfig,
  defaultSortConfig,
  editFormConfig,
  editFormFields,
  createFormConfig,
  createFormFields: initialCreateFormFields,
  detailsConfig,
  detailsFields,
  useCreateMutation,
  useUpdateMutation,
  useDeleteMutation,
  additionalDeleteAction,
  deleteConfirmationTitle,
  deleteConfirmationBody,
  editFormAdditionalActions,
  editFormAdditionalElements,
  downloadFileName,
  enableExport,
  hasDetails,
  moduleRedirectElement,
  ignoreHyperlink = false,
  customContent,
  updatedRow,
  marginTop = true,
  disclaimer,
  showOnlyHistoryTab,
  historyTabIdAttribute,
  showOnlyDetailsTab,
  showOnlyAttachmentsTab,
  showImageAttachment = true,
  showSelfPerformingFilter,
  showStatusActiveFilter,
  showMissedVendorIdFilter,
  statusFilterAlias,
  categoryFilterAlias,
  customersFilterAlias,
  showCategoryFilter,
  showStatusFilter,
  showCustomFilter,
  customSimpleSelectFilterOptions,
  shouldJoinCustomParams = true,
  customSimpleSelectFilterLabel,
  customSimpleSelectFilterName,
  showDateFilter,
  showAssignedToMeFilter,
  showAssignedLocationsFilter,
  showAssetTypeNameFilter,
  showConceptsFilter,
  showAssetGroupSingleFilter,
  showSetByFilter,
  setByFilterOptions,
  showLocationFilter,
  showManufacturerFilter,
  userCompany,
  showAwaitingInvoiceFilter,
  showAssetTypeFilter,
  showEtaFilter,
  showTechAssginedFilter,
  showCalendarViewFilter,
  showCustomersFilter,
  showTechNotAssignedToggleFilter,
  dateRangeDefaultSelectedOption,
  dateFilterFieldsOptions,
  categoryFilterOptions,
  statusFilterOptions,
  assetTypeFilterOptions,
  locationFilterOptions,
  useLocationFilterQuery,
  useManufacturerFilterQuery,
  useGetAssetTypesFilterQuery,
  locationFilterQueryParams,
  manufacturerFilterQueryParams,
  statusFilterInitialSelected,
  activeStatusFilterActiveLabel,
  activeStatusFilterInactiveLabel,
  dateAtAlias,
  dateAfterAlias,
  dateBeforeAlias,
  statusAlias,
  showBadgeExpirationDate,
  showSteps = false,
  withDrawer = true,
  idToGetListBy,
  renderCustomActionComponent,
  renderCustomAttachmentComponent,
  onClickViewUsersTable,
  hasUsersTablePreviewData,
  onClickRecipientsNotificationEmail,
  onClickViewNotificationEmail,
  onClickRecipientsNotificationMobile,
  onClickViewNotificationMobile,
  onClickRecipientsNotificationText,
  onClickViewNotificationText,
  selectedChipUsersTable,
  onCreate,
  onDuplicateChip,
  onDrawerClose,
  onAddNewClick,
  onEditButtonClick,
  openDrawerOnEditMode,
  editFormAdditionalProcess,
  onDuplicatePress,
  onRowClick,
  onNewDataFetched,
  onChange,
  shouldUpdateCreateFormField = true,
  additionalTabs,
  moduleName = '',
  mustTriggerListQuery,
  usingGlobalSearch,
  showOnlyadditionalTabs,
  module,
  showTotal,
  showTotalHelperText,
  showTotalResults,
  hidePagination,
  hidePerPageSelector,
  customParams,
  customActiveFilter,
  multiSelectTable,
  showSelectAllPageRows = true,
  emptyType,
  multiSelectTableConfig,
  preSelectRowsFieldname,
  onMultiTableSelect,
  customMultiSelectActionButton,
  multiSelectedRows,
  allChecked,
  clearSelection,
  customDrawerTitle,
  outsideCustomParam,
  drawerTitleBarBGColor,
  customTableBodyMaxHeight,
  disableTableContent,
  shouldCacheLazyQuery = false,
  showEditButton = true,
  shouldTriggerLazyQuery = true,
  showColumnVisibilityFilter,
  isDraggableColumns,
  dateAlias: dateAliasFromProp,
  customTableHeader,
  shouldUseSearchParams,
  dateSearchGroup,
  selectRowsMaxCount,
  shouldNotUseActiveFilter,
  submissionConfirmationModal,
  customDetailsTabLabel,
  selectedRows,
  exportModuleName,
  exportTradeId,
  exportBranchId,
  exportUserId,
  enableTableCsvExport = false,
  createSaveButtonDisabled,
  showHierarchyFilter,
  showActivityButton,
  searchBarCustomElements,
  initialApprovalData,
  setInitialApprovalData,
  activityType,
  hideSimpleSearchBar,
  hideSaveFilterButton = false,
  renderTableCustomLink,
  showDrawerDetailTitle,
  conditionalLinearProgressColor = false,
  customPadding,
  useSkeletonLoading,
}: ECDynamicPageTemplateProps) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [trigger, result] = useLazyGetListQuery();
  const [open, setOpen] = useState(false);
  const [detailData, setDetailData] = useState<any>();
  const [whichModal, setWhichModal] = useState<'create' | 'edit'>('create');
  const [row, setRow] = useState<object | null>({});
  const [showEditForm, setShowEditForm] = useState(false);
  const shouldShowCreateForm = createFormConfig && initialCreateFormFields;
  const [createFormFields, setCreateFormFields] = useState(
    initialCreateFormFields || [],
  );
  const id = useRef(idToGetListBy);

  const outsideCustomParamRef = useRef(outsideCustomParam);

  useEffect(() => {
    id.current = idToGetListBy;
  }, [idToGetListBy]);

  useEffect(() => {
    outsideCustomParamRef.current = outsideCustomParam;
  }, [outsideCustomParam]);

  useEffect(() => {
    if (initialCreateFormFields && shouldUpdateCreateFormField) {
      setCreateFormFields(initialCreateFormFields);
    }
  }, [initialCreateFormFields, shouldUpdateCreateFormField]);

  const activeFilterFromSelector = useSelector(
    (state: RootState) => state.page.filter.activeFilter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  const { canAdd, canGetById } = useSelector(
    (state: RootState) => state.page.scopes,
  );

  const { globalQuery } = useSelector(
    (state: RootState) => state.page.globalFilter,
  );

  const { data: metadata, isLoading: isLoadingMetadata } = useGetMetadataQuery(
    moduleName,
    { skip: !moduleName.length },
  );

  const [configState, setConfigState] = useState<ECEasyTableConfigType>(
    tableConfig ?? {
      cols: [],
      pagination: {
        isPaginated: true,
        totalResults: 0,
        currentPage: 0,
        perPage: 50,
      },
      sort: defaultSortConfig ?? {
        fieldName: '',
        value: '',
      },
      header: {
        variant: 'fancy',
        title: '',
        subtitle: '',
        searchable: false,
        searchFieldName: '',
      },
    },
  );

  const [triggerGetById, resultGetById] = useLazyGetByIdQuery?.() || [
    undefined,
    {},
  ];

  const [currentQueryParams, setCurrentQueryParams] = useState({});

  const isUsersOrJobTitleModule = useMemo(() => {
    return moduleName === 'users' || moduleName === 'jobtitle';
  }, [moduleName]);

  const fetchList = useCallback(
    input => {
      const {
        page,
        perPage,
        sortOrder,
        sortBy,
        dateAfter,
        dateBefore,
        dateAt,
        dateAlias,
        activeStatus,
        statuses,
        categories,
        selfPerforming,
        assetTypes,
        isSelectAllAssetTypes,
        locations,
        manufacturer,
        internalTechs,
        isSelectAllLocations,
        isSelectAllManufacturers,
        isSelectAllInternalTechs,
        isSelectAllConcepts,
        isSelectedAllHierarchy,
        concepts,
        setBy,
        assignedToMe,
        assignedLocations,
        missingVendorId,
        awaitingInvoice,
        assetType,
        eta,
        hierarchy,
        techNotAssigned,
        isAdvanced: isAdvancedSearch,
        filterFields = [],
        customers,
      } = input;
      const queryParams: any = {
        p: page,
        t: perPage,
        o: sortOrder === 'ASC' ? Sorting.ASC : Sorting.DESC,
        ob: sortBy,
      };

      if (missingVendorId && showMissedVendorIdFilter) {
        queryParams['missingVendorId'] = missingVendorId;
      }
      if (showStatusActiveFilter && !customActiveFilter) {
        queryParams['st'] = activeStatus || activeStatus === undefined ? 1 : 0;
      }
      if (statusAlias && !customActiveFilter) {
        queryParams['stal'] = statusAlias;
      }
      if (isAdvancedSearch || usingGlobalSearch) {
        queryParams['so'] = 1;
      }
      if (showSelfPerformingFilter) {
        queryParams['self-perform'] = selfPerforming ? 1 : 0;
      }
      if (showAssignedToMeFilter && assignedToMe) {
        queryParams['assignedToMe'] = 1;
      }
      if (showAssignedLocationsFilter && !assignedLocations) {
        queryParams['ignoreAssignedLocations'] = 1;
      }
      if (showAwaitingInvoiceFilter && awaitingInvoice) {
        queryParams['awaitingInvoice'] = 1;
      }
      if (showAssetTypeFilter && assetType) {
        queryParams['assetTypeAssignedToMe'] = 1;
      }
      if (showSetByFilter) {
        const activeSetBy = setBy || activeFilter?.setBy;
        if (activeSetBy) {
          queryParams['setBy'] = activeSetBy;
        }
      }

      let searchIndex = 0;
      let searchGroupIndex = 0;
      if (
        (showAssetTypeNameFilter || showAssetGroupSingleFilter) &&
        !isSelectAllAssetTypes &&
        assetTypes?.length
      ) {
        const assetTypeParam = showAssetGroupSingleFilter
          ? [assetTypes[0]]
          : assetTypes;
        queryParams[`sb${searchIndex || ''}`] =
          moduleName === 'glcode' ? 'gnrlldgr.assetTypeId' : 'assttp.id';
        queryParams[`s${searchIndex || ''}`] = assetTypeParam
          ?.map(assetType => assetType?.id)
          ?.join(',');
        queryParams[`sg${searchIndex || ''}`] = searchGroupIndex;
        queryParams[`sbo${searchIndex || ''}`] = 'in';
        queryParams[`sglo${searchIndex || ''}`] = 'or';
        searchIndex += 1;
        searchGroupIndex += 1;
      }
      if (showLocationFilter && !!locations?.length && !isSelectAllLocations) {
        queryParams[`sb${searchIndex || ''}`] =
          moduleName === 'inventory'
            ? 'invntryitmstrg.inventoryStorageId'
            : moduleName === 'glcode'
              ? 'gnrlldgr.branchId'
              : 'brnch.id';
        queryParams[`s${searchIndex || ''}`] = locations
          ?.map(location => location?.id)
          ?.join(',');
        queryParams[`sg${searchIndex || ''}`] = searchGroupIndex;
        queryParams[`sbo${searchIndex || ''}`] = 'in';
        queryParams[`sglo${searchIndex || ''}`] = 'or';
        searchIndex += 1;
        searchGroupIndex += 1;
      }

      if (showConceptsFilter && !!concepts?.length && !isSelectAllConcepts) {
        queryParams[`sb${searchIndex || ''}`] =
          moduleName === 'glcode' ? 'gnrlldgr.branchTypeId' : 'brnchtp.id';
        queryParams[`s${searchIndex || ''}`] = concepts
          ?.map(concept => concept?.id)
          ?.join(',');
        queryParams[`sg${searchIndex || ''}`] = searchGroupIndex;
        queryParams[`sbo${searchIndex || ''}`] = 'in';
        queryParams[`sglo${searchIndex || ''}`] = 'or';
        searchIndex += 1;
        searchGroupIndex += 1;
      }

      if (showManufacturerFilter && !!manufacturer?.length) {
        if (isUsersOrJobTitleModule) {
          queryParams[`cf${searchIndex || ''}`] = manufacturer
            ?.map(manufacture => manufacture?.id)
            ?.join(',');
        }
        if (!isSelectAllManufacturers && !isUsersOrJobTitleModule) {
          queryParams[`sb${searchIndex || ''}`] = 'mnfctr.id';
          queryParams[`s${searchIndex || ''}`] = manufacturer
            ?.map(manufacture => manufacture?.id)
            ?.join(',');
          queryParams[`sg${searchIndex || ''}`] = searchGroupIndex;
          queryParams[`sbo${searchIndex || ''}`] = 'in';
          queryParams[`sglo${searchIndex || ''}`] = 'or';
          searchIndex += 1;
          searchGroupIndex += 1;
        }
      }

      if (
        (showTechAssginedFilter || showCalendarViewFilter) &&
        !!internalTechs?.length &&
        !isSelectAllInternalTechs
      ) {
        queryParams[`sb${searchIndex || ''}`] = 'wrkordr.spAssigneeUserId';
        queryParams[`s${searchIndex || ''}`] = internalTechs
          ?.map(internalTech => internalTech?.id)
          ?.join(',');
        queryParams[`sg${searchIndex || ''}`] = searchGroupIndex;
        queryParams[`sbo${searchIndex || ''}`] = 'in';
        queryParams[`sglo${searchIndex || ''}`] = 'or';
        searchIndex += 1;
        searchGroupIndex += 1;
      }

      if (showEtaFilter && !!eta) {
        queryParams[`sb${searchIndex || ''}`] = 'wrkordr.eta';
        queryParams[`sbo${searchIndex || ''}`] = 'between';
        queryParams[`s${searchIndex || ''}`] =
          eta > 0
            ? `${moment().format(
                'YYYY-MM-DD',
              )},${moment().add(eta, 'days').format('YYYY-MM-DD')}`
            : `${moment()
                .add(eta, 'days')
                .format('YYYY-MM-DD')},${moment().format('YYYY-MM-DD')}`;
        queryParams[`sg${searchIndex || ''}`] =
          dateSearchGroup || searchGroupIndex;
        queryParams[`sglo${searchIndex || ''}`] = 'or';
        searchIndex += 1;
        searchGroupIndex += 1;
      }

      if (showTechNotAssignedToggleFilter && techNotAssigned) {
        queryParams['techNotAssigned'] = 1;
      }
      if (showDateFilter) {
        const isMongoDBSearch = !!dateSearchGroup;
        if (dateAfter && !dateBefore) {
          queryParams[`sb${searchIndex || ''}`] =
            dateAlias || dateAliasFromProp;
          queryParams[`sbo${searchIndex || ''}`] = isMongoDBSearch
            ? 'gt'
            : 'gte';
          queryParams[`s${searchIndex || ''}`] = isMongoDBSearch
            ? moment(dateAfter).set({ hours: 23, minutes: 59 }).unix() * 1000
            : moment(dateAfter).format('YYYY-MM-DD');
          queryParams[`sg${searchIndex || ''}`] =
            dateSearchGroup || searchGroupIndex;
          queryParams[`sglo${searchIndex || ''}`] = 'or';
          searchIndex += 1;
        }
        if (!dateAfter && dateBefore) {
          queryParams[`sb${searchIndex || ''}`] =
            dateAlias || dateAliasFromProp;
          queryParams[`sbo${searchIndex || ''}`] = isMongoDBSearch
            ? 'lt'
            : 'lte';
          queryParams[`s${searchIndex || ''}`] = isMongoDBSearch
            ? moment(dateBefore).set({ hours: 0, minutes: 0 }).unix() * 1000
            : moment(dateBefore).format('YYYY-MM-DD');
          queryParams[`sg${searchIndex || ''}`] =
            dateSearchGroup || searchGroupIndex;
          queryParams[`sglo${searchIndex || ''}`] = 'or';
          searchIndex += 1;
        }
        if (
          dateAfter &&
          dateBefore &&
          !moment(dateAfter).isSame(moment(dateBefore))
        ) {
          if (
            isMongoDBSearch &&
            moment(dateAfter).isValid() &&
            moment(dateBefore).isValid()
          ) {
            queryParams[`sb${searchIndex || ''}`] =
              dateAlias || dateAliasFromProp;
            queryParams[`sbo${searchIndex || ''}`] = 'gt';
            queryParams[`s${searchIndex || ''}`] =
              moment(dateAfter).set({ hours: 0, minutes: 0 }).unix() * 1000;
            queryParams[`sg${searchIndex || ''}`] =
              dateSearchGroup || searchGroupIndex;
            queryParams[`sglo${searchIndex || ''}`] = 'or';
            searchIndex += 1;

            queryParams[`sb${searchIndex || ''}`] =
              dateAlias || dateAliasFromProp;
            queryParams[`sbo${searchIndex || ''}`] = 'lt';
            queryParams[`s${searchIndex || ''}`] =
              moment(dateBefore).set({ hours: 23, minutes: 59 }).unix() * 1000;
            queryParams[`sg${searchIndex || ''}`] =
              dateSearchGroup || searchGroupIndex;
            queryParams[`sglo${searchIndex || ''}`] = 'or';
          } else {
            queryParams[`sb${searchIndex || ''}`] =
              dateAlias || dateAliasFromProp;
            queryParams[`sbo${searchIndex || ''}`] = 'between';
            queryParams[`s${searchIndex || ''}`] = `${moment(dateAfter).format(
              'YYYY-MM-DD',
            )},${moment(dateBefore).format('YYYY-MM-DD')}`;
            queryParams[`sg${searchIndex || ''}`] =
              dateSearchGroup || searchGroupIndex;
            queryParams[`sglo${searchIndex || ''}`] = 'or';
          }
          searchIndex += 1;
        }
        if (
          dateAt ||
          (dateAfter &&
            dateBefore &&
            moment(dateAfter).isSame(moment(dateBefore)))
        ) {
          const date = dateAt || dateAfter || dateBefore;
          if (isMongoDBSearch) {
            queryParams[`sb${searchIndex || ''}`] =
              dateAlias || dateAliasFromProp;
            queryParams[`sbo${searchIndex || ''}`] = 'gt';
            queryParams[`s${searchIndex || ''}`] =
              moment(date).set({ hours: 0, minutes: 0 }).unix() * 1000;
            queryParams[`sg${searchIndex || ''}`] =
              dateSearchGroup || searchGroupIndex;
            queryParams[`sglo${searchIndex || ''}`] = 'or';
            searchIndex += 1;

            queryParams[`sb${searchIndex || ''}`] =
              dateAlias || dateAliasFromProp;
            queryParams[`sbo${searchIndex || ''}`] = 'lt';
            queryParams[`s${searchIndex || ''}`] =
              moment(date).set({ hours: 23, minutes: 59 }).unix() * 1000;
            queryParams[`sg${searchIndex || ''}`] =
              dateSearchGroup || searchGroupIndex;
            queryParams[`sglo${searchIndex || ''}`] = 'or';
          } else {
            queryParams[`sb${searchIndex || ''}`] =
              dateAlias || dateAliasFromProp;
            queryParams[`s${searchIndex || ''}`] = `${moment(date).format(
              'YYYY-MM-DD',
            )},${moment(date).format('YYYY-MM-DD')}`;
            queryParams[`sbo${searchIndex || ''}`] = 'between';
            queryParams[`sg${searchIndex || ''}`] =
              dateSearchGroup || searchGroupIndex;
            queryParams[`sglo${searchIndex || ''}`] = 'or';
          }
          searchIndex += 1;
        }
        searchGroupIndex += 1;
      }

      if (
        showCustomFilter &&
        customSimpleSelectFilterName &&
        shouldJoinCustomParams
      ) {
        queryParams[customSimpleSelectFilterName] =
          input?.[customSimpleSelectFilterName]?.join(',');
      }
      if (showHierarchyFilter) {
        queryParams[`sb${searchIndex || ''}`] = 'prrt.id';

        const regionParentIds = hierarchy
          ?.filter(
            org => org.organizationType === OrganizationIdTypeEnum.Region,
          )
          ?.map(org => org.nodeId);
        const districtParentIds = hierarchy
          ?.filter(
            org => org.organizationType === OrganizationIdTypeEnum.District,
          )
          ?.map(org => org.nodeId);

        if (regionParentIds?.length) {
          queryParams.regionParentIds = regionParentIds;
        }
        if (districtParentIds?.length) {
          queryParams.districtParentIds = districtParentIds;
        }
        if (isSelectedAllHierarchy) {
          queryParams.districtParentIds = [];
          queryParams.regionParentIds = [];
        }
        searchIndex += 1;
      }

      if (
        showCustomFilter &&
        customSimpleSelectFilterName &&
        !shouldJoinCustomParams
      ) {
        input?.[customSimpleSelectFilterName]?.forEach(customValue => {
          queryParams[`sb${searchIndex || ''}`] = customSimpleSelectFilterName;
          queryParams[`s${searchIndex || ''}`] = customValue;
          queryParams[`sg${searchIndex || ''}`] = searchGroupIndex;
          queryParams[`sglo${searchIndex || ''}`] = 'or';
          searchIndex += 1;
        });
        searchGroupIndex += 1;
      }

      if (showStatusFilter) {
        statuses?.forEach(status => {
          const isDigitsOnly = text => /^\d+$/.test(text);
          const isUnshiftedNumbersArray = string => {
            const stringArray = string?.split(',');
            return (
              stringArray?.length > 1 &&
              stringArray?.every(isDigitsOnly) &&
              stringArray
            );
          };
          if (isUnshiftedNumbersArray(status)) {
            status?.split(',').forEach(statusElement => {
              queryParams[`sb${searchIndex || ''}`] = statusFilterAlias;
              queryParams[`s${searchIndex || ''}`] = isDigitsOnly(statusElement)
                ? Number(statusElement)
                : statusElement;
              queryParams[`sg${searchIndex || ''}`] = searchGroupIndex;
              queryParams[`sglo${searchIndex || ''}`] = 'or';
              searchIndex += 1;
            });
            return;
          }

          queryParams[`sb${searchIndex || ''}`] = statusFilterAlias;
          queryParams[`s${searchIndex || ''}`] = isDigitsOnly(status)
            ? Number(status)
            : status;
          queryParams[`sg${searchIndex || ''}`] = searchGroupIndex;
          queryParams[`sglo${searchIndex || ''}`] = 'or';
          searchIndex += 1;
        });
        searchGroupIndex += 1;
      }

      if (showCategoryFilter) {
        categories?.forEach(category => {
          queryParams[`sb${searchIndex || ''}`] = categoryFilterAlias;
          queryParams[`s${searchIndex || ''}`] = category;
          queryParams[`sg${searchIndex || ''}`] = searchGroupIndex;
          queryParams[`sglo${searchIndex || ''}`] = 'or';
          searchIndex += 1;
        });
        searchGroupIndex += 1;
      }

      if (showCustomersFilter) {
        customers?.forEach(customer => {
          queryParams[`sb${searchIndex || ''}`] = customersFilterAlias;
          queryParams[`s${searchIndex || ''}`] = customer.id;
          queryParams[`sg${searchIndex || ''}`] = searchGroupIndex;
          queryParams[`sglo${searchIndex || ''}`] = 'or';
          searchIndex += 1;
        });
        searchGroupIndex += 1;
      }

      if (showCalendarViewFilter && dateAfter && dateBefore) {
        queryParams[`sb${searchIndex || ''}`] = 'wrkordr.eta';
        queryParams[`sbo${searchIndex || ''}`] = 'between';
        queryParams[`s${searchIndex || ''}`] = `${dateBefore},${dateAfter}`;
        queryParams[`sg${searchIndex || ''}`] =
          dateSearchGroup || searchGroupIndex;
        queryParams[`sglo${searchIndex || ''}`] = 'or';
        searchIndex += 1;
        searchGroupIndex += 1;
      }

      const usedFields = [
        ...(metadata?.fields?.filter(field => field.searchable) || []),
        ...filterFields,
      ];

      usedFields?.forEach(
        ({ exactSearch, fieldName, alias, isViewId, value, searchGroup }) => {
          if (
            (usingGlobalSearch && globalQuery) ||
            (value && !isEmptyValue(value))
          ) {
            (Array.isArray(alias) ? alias : [alias])?.forEach(aliasName => {
              queryParams[`sb${searchIndex || ''}`] = aliasName || fieldName;

              // usedValue is the simpleSearchQuery
              let usedValue = String(
                (value as AutoCompleteDropDownOption)?.id ?? value,
              );
              /* If the field is a view id, we need to remove the parts that are not part of the real id. Example: WO-135 -> real id is 13*/
              if (isViewId && usedValue?.match(/^\w+-\d+$/)) {
                usedValue = usedValue.replaceAll(/\D/g, '');
                usedValue = usedValue.substring(0, usedValue.length - 1);
              }
              const isMongoDBSearch = !!searchGroup;
              // If it is not an exact search, will use a partial search (LIKE %value%)
              if (!exactSearch && !isViewId && !isMongoDBSearch) {
                usedValue = encodeURI(`*${usedValue}*`);
              }
              if (isAdvancedSearch && isMongoDBSearch) {
                queryParams['slo'] = 'and';
              }
              if (usingGlobalSearch && globalQuery) {
                usedValue = `*${globalQuery.trim()}*`;
              }

              const SPACE_URL_ENCODING = '%20';
              const usedValueArray =
                usedValue[0] === '*'
                  ? usedValue
                      ?.slice(1, usedValue.length - 1)
                      ?.split(',')
                      ?.filter(val => val)
                  : usedValue
                      ?.replaceAll(';', ',')
                      ?.split(',')
                      ?.filter(val => val);
              if (usedValueArray?.length > 1) {
                usedValueArray.forEach((usedValueElement, index) => {
                  const isOnlyNumbers = usedValueElement
                    ?.replace(SPACE_URL_ENCODING, '')
                    .match(/^\d+$/);
                  if (isOnlyNumbers && !exactSearch) {
                    return;
                  }

                  queryParams[`sb${searchIndex || ''}`] = (
                    value as AutoCompleteDropDownOption
                  )?.id
                    ? `${fieldName}.id`
                    : aliasName || fieldName;
                  const searchValue =
                    usedValueElement?.slice(0, 3) === SPACE_URL_ENCODING
                      ? usedValueElement?.slice(3)
                      : usedValueElement;
                  queryParams[`s${searchIndex || ''}`] =
                    exactSearch || isMongoDBSearch
                      ? `${searchValue?.trim()}`
                      : `*${searchValue?.trim()}*`;
                  queryParams[`sg${searchIndex || ''}`] =
                    searchGroup || searchGroupIndex;
                  queryParams[`sglo${searchIndex || ''}`] = 'or';
                  searchIndex += 1;
                });
              } else {
                // workaround to include fields below in search even if they are all numbers
                // Transforming the values into an array and checking didnt work
                const isStoreNumberSearch = aliasName?.includes('storeNumber'); // hotfix for search while the query performance is not improved in the BE.
                const isInventorySearch = aliasName?.includes('invntry');
                const isInvoiceNumberSearch =
                  aliasName?.includes('invoiceNumber');
                const isPMIdSearch = aliasName?.includes('pmId');
                const isQrCodeSearch = aliasName?.includes('qrCode');
                const isSearialNumberSearch =
                  aliasName?.includes('serialNumber');
                const isPhoneNumberSearch = aliasName?.includes('phone');
                const isGlCodeSearch = aliasName?.includes('glCode');
                const isBranchNameSearch = aliasName?.includes('brnch.name');
                const isOnlyNumbers = usedValue
                  ?.replaceAll('*', '')
                  .match(/^\d+$/);
                if (
                  isOnlyNumbers &&
                  !exactSearch &&
                  !isAdvancedSearch &&
                  !globalQuery &&
                  !isGlCodeSearch &&
                  !isStoreNumberSearch &&
                  !isInventorySearch &&
                  !isInvoiceNumberSearch &&
                  !isPMIdSearch &&
                  !isQrCodeSearch &&
                  !isSearialNumberSearch &&
                  !isPhoneNumberSearch &&
                  !isBranchNameSearch
                ) {
                  return;
                }

                queryParams[`s${searchIndex || ''}`] = usedValue; // checks if it's an value commes from a dropdown list. in that case value is an object
                queryParams[`sg${searchIndex || ''}`] =
                  searchGroup || searchGroupIndex;
                queryParams[`sglo${searchIndex || ''}`] = isAdvancedSearch
                  ? 'and'
                  : 'or';
                searchIndex += 1;
              }
            });
          }
        },
      );

      if (searchIndex === 1) {
        delete queryParams[`sblo`];
      }

      const otherParams =
        customActiveFilter && activeStatus
          ? {
              ...customParams,
              [customActiveFilter?.paramName]: customActiveFilter?.value,
            }
          : customParams;
      if (isLoadingMetadata) {
        return;
      }

      if (id.current) {
        trigger(
          {
            id: id.current,
            queryParams: {
              ...queryParams,
              ...otherParams,
            },
            outsideCustomParam: outsideCustomParamRef.current,
          },
          shouldCacheLazyQuery,
        );
      } else {
        trigger(
          {
            ...queryParams,
            ...otherParams,
            outsideCustomParam: outsideCustomParamRef.current,
          },
          shouldCacheLazyQuery,
        );
      }

      setCurrentQueryParams({
        ...queryParams,
        ...otherParams,
      });
    },
    [
      customActiveFilter,
      customParams,
      metadata?.fields,
      showDateFilter,
      showAssignedToMeFilter,
      showAssignedLocationsFilter,
      showAwaitingInvoiceFilter,
      showAssetTypeFilter,
      showAssetTypeNameFilter,
      showConceptsFilter,
      showAssetGroupSingleFilter,
      showSetByFilter,
      showCustomFilter,
      showLocationFilter,
      showManufacturerFilter,
      showSelfPerformingFilter,
      showStatusActiveFilter,
      statusFilterAlias,
      statusAlias,
      customSimpleSelectFilterName,
      trigger,
      usingGlobalSearch,
      outsideCustomParam,
      isLoadingMetadata,
      idToGetListBy,
      showHierarchyFilter,
    ],
  );

  useEffect(() => {
    if (
      outsideCustomParam !== undefined &&
      outsideCustomParam !== null &&
      shouldTriggerLazyQuery
    ) {
      fetchList({
        page: 0,
        perPage: configState.pagination.perPage,
        sortOrder: configState.sort.value,
        sortBy: configState.sort.fieldName,
      });
    }
  }, [outsideCustomParam, shouldTriggerLazyQuery]);

  useEffect(() => {
    if (idToGetListBy && shouldTriggerLazyQuery) {
      fetchList({
        page: 0,
        perPage: configState.pagination.perPage,
        sortOrder: configState.sort.value,
        sortBy: configState.sort.fieldName,
      });
    }
  }, [idToGetListBy]);

  useEffect(() => {
    if (mustTriggerListQuery && shouldTriggerLazyQuery) {
      fetchList({
        page: 0,
        perPage: configState.pagination.perPage,
        sortOrder: configState.sort.value,
        sortBy: configState.sort.fieldName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mustTriggerListQuery, shouldTriggerLazyQuery]);

  useEffect(() => {
    setRow(updatedRow);
  }, [updatedRow]);

  useEffect(() => {
    dispatch(setTitle(pageTitle));
  }, [pageTitle, dispatch]);

  const handleAddNewClick = () => {
    setWhichModal('create');
    setOpen(true);
    onAddNewClick?.();
  };

  const handleClose = useCallback(() => {
    setShowEditForm(false);
    setOpen(false);
    onDrawerClose?.();
    setDetailData(null);
    setRow(null);
  }, []);

  const handleRowClick = row => {
    setDetailData(null);
    if (triggerGetById) {
      triggerGetById(row);
      onRowClick?.(row);
    } else {
      onRowClick?.(row);
      setRow({ ...row });
    }
    if (openDrawerOnEditMode && openDrawerOnEditMode(row)) {
      setShowEditForm(true);
    }
    setWhichModal('edit');
    setOpen(true);
  };

  const { data: dataById, isFetching: fetchingResultById } = resultGetById;

  const [resultDataById, setResultDataById] = useState({});

  useEffect(() => {
    if (!_.isEqual(dataById, resultDataById)) setResultDataById(dataById);
  }, [dataById]);

  useEffect(() => {
    if (triggerGetById && open && !fetchingResultById && resultDataById) {
      setDetailData({ ...resultDataById });
    }
  }, [resultDataById, open, fetchingResultById]);

  useEffect(() => {
    if (!triggerGetById && row) {
      setDetailData({ ...row });
    }
  }, [row]);

  const onDataRequest = params => {
    if (params) {
      fetchList({
        ...params,
        page: params.pagination.currentPage,
        perPage: params.pagination.perPage,
        sortOrder: params?.sort?.value,
        sortBy: params?.sort?.fieldName,
      });
      setConfigState(prevConfig => ({
        ...prevConfig,
        header: {
          ...prevConfig.header,
        },
        pagination: {
          ...prevConfig.pagination,
          currentPage: params.pagination.currentPage,
          perPage: params.pagination.perPage,
        },
        sort: {
          fieldName: params?.sort?.fieldName,
          value: params?.sort?.value,
        },
      }));
    } else {
      if (!isLoadingMetadata) {
        trigger({});
      }
    }
  };

  const { data, originalArgs, isFetching, isLoading, isSuccess } = result;

  useEffect(() => {
    if (!isFetching && data?.config) {
      setConfigState(getConfigStateFromApiOrMock(data, configState, t));
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (!isLoadingMetadata && metadata && shouldTriggerLazyQuery) {
      setConfigState(getConfigStateFromApiOrMock(metadata, configState, t));
      fetchList({
        page: configState.pagination.currentPage,
        perPage: configState.pagination.perPage,
        sortOrder: configState.sort.value,
        sortBy: configState.sort.fieldName,
        metadataFields: metadata?.fields,
      });
    }
  }, [metadata, isLoadingMetadata, shouldTriggerLazyQuery]);

  useEffect(() => {
    onNewDataFetched?.(data);
  }, [data]);

  const renderadditionalTabs = useCallback(() => {
    const isCreateModal = whichModal === 'create';
    return additionalTabs?.map(tab => {
      const tabData = tab.additionalTabFieldnameData
        ? (triggerGetById && dataById[tab.additionalTabFieldnameData]) ||
          (row && row[tab.additionalTabFieldnameData])
        : detailData;
      return {
        ...tab,
        content: React.cloneElement(tab.content, {
          data: isCreateModal ? [] : tabData,
          isEditMode: isCreateModal || showEditForm,
          context: {
            data: isCreateModal ? [] : tabData,
            isEditMode: isCreateModal || showEditForm,
          },
        }),
      };
    });
  }, [
    additionalTabs,
    detailData,
    row,
    triggerGetById,
    showEditForm,
    dataById,
    whichModal,
  ]);

  const listData = useMemo(
    () => (Array.isArray(data) ? data : (data as any)?.data),
    [data],
  );
  const isEmptyState = useMemo(
    () =>
      isSuccess &&
      !listData?.length &&
      (!originalArgs ||
        originalArgs?.p === 0 ||
        originalArgs?.queryParams?.p === 0),
    [isSuccess, listData, originalArgs],
  );

  const match = useMatch('/panel/*');

  const handleReturnClick = useCallback(() => {
    var currentUrl = window.location.href;
    if (currentUrl && currentUrl.includes('avt-assets')) {
      dispatch(setActiveFilter(initialState?.filter?.activeFilter));
      window.location.href = `${match?.pathnameBase}/asset-verification/`;
    } else {
      navigate(-1);
    }
  }, [navigate]);

  if (isLoadingMetadata) {
    return (
      <ECBox
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        width={'100%'}
        p={4}
      >
        <ECCircularProgress size={'2rem'} />
        <ECTypography ml={2} sx={{ opacity: 0.7 }} variant={'body1'}>
          {t('translation:table.loading')}
        </ECTypography>
      </ECBox>
    );
  }

  if (useSkeletonLoading && isLoading) {
    return (
      <ECBox
        sx={{
          mt: marginTop ? StyleConstants.NAV_BAR_HEIGHT : 0,
          width: '100%',
          p: '18.4px',
        }}
      >
        <Skeleton variant="rectangular" height={36} />
        {Array.from(new Array(9)).map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            height={52}
            sx={{
              backgroundColor:
                index % 2 !== 0
                  ? 'rgba(250, 250, 250, 1)'
                  : 'background.default',
            }}
          />
        ))}
      </ECBox>
    );
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECGrid
        sx={{
          mt: marginTop ? StyleConstants.NAV_BAR_HEIGHT : 0,
          minWidth: 0,
          width: '100%',
        }}
      >
        {hasDetails ? (
          <ECDrawerDetails open={open} anchor="right" onClose={handleClose}>
            <ECBox pt={StyleConstants.NAV_BAR_HEIGHT} minHeight="100vh">
              {whichModal === 'create' && shouldShowCreateForm ? (
                showSteps ? (
                  <ECDrawerDetailsContent
                    title={
                      createFormConfig.title ||
                      editFormConfig?.title ||
                      detailsConfig?.title ||
                      ''
                    }
                    data={detailData}
                    useUpdateMutation={useUpdateMutation}
                    useCreateMutation={useCreateMutation}
                    hasTopPadding={false}
                    EditForm={
                      <ECEasyFormCreate
                        useCreateMutation={useCreateMutation}
                        formConfig={createFormConfig}
                        formFields={createFormFields}
                        disclaimer={disclaimer}
                        onCreate={onCreate}
                        onClose={handleClose}
                        onChange={onChange}
                        onDuplicateChip={onDuplicateChip}
                        showSaveButton={false}
                        showTitle={false}
                        drawerTitleBarBGColor={drawerTitleBarBGColor}
                      />
                    }
                    showOnlyHistoryTab={showOnlyHistoryTab}
                    showOnlyDetailsTab={showOnlyDetailsTab}
                    showOnlyAttachmentsTab={showOnlyAttachmentsTab}
                    showImageAttachment={showImageAttachment}
                    showSteps={showSteps}
                    onClose={handleClose}
                    editSelected={true}
                    showEditButton={false}
                    isCreateNewStep={true}
                    customDetailsTabLabel={customDetailsTabLabel}
                    historyTabIdAttribute={historyTabIdAttribute}
                  />
                ) : additionalTabs ? (
                  <ECRequirePermission
                    scopes={canGetById ? [canGetById] : undefined}
                  >
                    <ECDrawerDetailsContent
                      module={module}
                      title={
                        customDrawerTitle ||
                        createFormConfig.title ||
                        editFormConfig?.title ||
                        detailsConfig?.title ||
                        ''
                      }
                      additionalTabs={
                        additionalTabs ? renderadditionalTabs() : undefined
                      }
                      showOnlyadditionalTabs={showOnlyadditionalTabs}
                      showOnlyAttachmentsTab={showOnlyAttachmentsTab}
                      data={detailData}
                      isCreateForm={true}
                      useCreateMutation={useCreateMutation}
                      hasTopPadding={false}
                      EditForm={
                        <ECEasyFormCreate
                          useCreateMutation={useCreateMutation}
                          formConfig={createFormConfig}
                          formFields={createFormFields}
                          disclaimer={disclaimer}
                          onCreate={onCreate}
                          onClose={handleClose}
                          onChange={onChange}
                          onDuplicateChip={onDuplicateChip}
                          showSaveButton={false}
                          showTitle={false}
                          hideToolBar={true}
                          noPaddingX={true}
                          noPaddingY={true}
                          drawerTitleBarBGColor={drawerTitleBarBGColor}
                        />
                      }
                      showOnlyHistoryTab={showOnlyHistoryTab}
                      showOnlyDetailsTab={showOnlyDetailsTab}
                      showImageAttachment={showImageAttachment}
                      showSteps={showSteps}
                      onClose={handleClose}
                      editSelected={true}
                      showEditButton={false}
                      customDetailsTabLabel={customDetailsTabLabel}
                      historyTabIdAttribute={historyTabIdAttribute}
                    />
                  </ECRequirePermission>
                ) : (
                  <ECEasyFormCreate
                    useCreateMutation={useCreateMutation}
                    formConfig={createFormConfig}
                    formFields={createFormFields}
                    disclaimer={disclaimer}
                    onCreate={onCreate}
                    onClose={handleClose}
                    onChange={onChange}
                    onDuplicateChip={onDuplicateChip}
                    drawerTitleBarBGColor={drawerTitleBarBGColor}
                  />
                )
              ) : detailData && !fetchingResultById ? (
                <ECRequirePermission
                  scopes={canGetById ? [canGetById] : undefined}
                >
                  <ECDrawerDetailsContent
                    title={
                      customDrawerTitle ||
                      (showEditForm
                        ? editFormConfig?.titleFromFieldname
                          ? `${editFormConfig?.title} ${
                              detailData[editFormConfig?.titleFromFieldname]
                            }`
                          : editFormConfig?.title
                        : detailsConfig?.titleFromFieldname
                          ? (detailData[
                              detailsConfig?.titleFromFieldname
                            ] as string) || detailsConfig?.title
                          : detailsConfig?.title)
                    }
                    additionalTabs={
                      additionalTabs ? renderadditionalTabs() : undefined
                    }
                    submissionConfirmationModal={submissionConfirmationModal}
                    module={module}
                    showOnlyadditionalTabs={showOnlyadditionalTabs}
                    showOnlyAttachmentsTab={showOnlyAttachmentsTab}
                    data={detailData}
                    useUpdateMutation={useUpdateMutation}
                    drawerTitleBarBGColor={drawerTitleBarBGColor}
                    hasTopPadding={false}
                    EditForm={
                      <EditForm2
                        additionalDeleteAction={() => {
                          additionalDeleteAction?.();
                          handleClose();
                        }}
                        row={detailData}
                        formConfig={editFormConfig}
                        formFields={editFormFields}
                        detailsConfig={detailsConfig}
                        detailsFields={detailsFields}
                        useUpdateMutation={useUpdateMutation}
                        useDeleteMutation={useDeleteMutation}
                        additionalActions={editFormAdditionalActions}
                        additionalElements={editFormAdditionalElements}
                        editFormAdditionalProcess={editFormAdditionalProcess}
                        onClose={handleClose}
                        onEditClose={() => setShowEditForm(false)}
                        onDuplicateChip={onDuplicateChip}
                        deleteConfirmationTitle={deleteConfirmationTitle}
                        deleteConfirmationBody={deleteConfirmationBody}
                        onlyEdit={showEditForm}
                        hideToolBar={!showEditForm}
                        isLoading={fetchingResultById}
                        showCloseButton={!showSteps}
                        showSaveButton={!showSteps}
                        onChange={onChange}
                        showEditButton={showSteps ? false : showEditButton}
                        onEditButtonClick={onEditButtonClick}
                        drawerTitleBarBGColor={drawerTitleBarBGColor}
                        showTitle={showEditForm ? true : showDrawerDetailTitle}
                      />
                    }
                    showOnlyHistoryTab={showOnlyHistoryTab}
                    showOnlyDetailsTab={showOnlyDetailsTab}
                    showImageAttachment={showImageAttachment}
                    showSteps={showSteps}
                    onClose={handleClose}
                    editSelected={showEditForm}
                    showEditButton={showEditButton}
                    onEditClick={() => setShowEditForm(!showEditForm)}
                    customDetailsTabLabel={customDetailsTabLabel}
                    initialApprovalData={initialApprovalData}
                    setInitialApprovalData={setInitialApprovalData}
                    historyTabIdAttribute={historyTabIdAttribute}
                  />
                </ECRequirePermission>
              ) : (
                fetchingResultById && (
                  <ECCard>
                    <ECGrid
                      container
                      spacing={2}
                      sx={{
                        p: 4,
                        pt: 6,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <ECCircularProgress />
                      <ECTypography
                        ml={4}
                        variant={'subtitle1'}
                        color={theme => theme.palette.text.disabled}
                      >
                        {t('translation:form.loading')}
                      </ECTypography>
                    </ECGrid>
                  </ECCard>
                )
              )}
            </ECBox>
          </ECDrawerDetails>
        ) : (
          <ECDrawerDetails
            open={open}
            anchor="right"
            onClose={handleClose}
            title={
              customDrawerTitle ||
              (showEditForm
                ? editFormConfig?.titleFromFieldname
                  ? `${editFormConfig?.title} ${
                      detailData[editFormConfig?.titleFromFieldname]
                    }`
                  : editFormConfig?.title
                : detailsConfig?.titleFromFieldname
                  ? (detailData[detailsConfig?.titleFromFieldname] as string) ||
                    detailsConfig?.title
                  : detailsConfig?.title)
            }
          >
            <ECBox pt={StyleConstants.NAV_BAR_HEIGHT} minHeight="100vh">
              {whichModal === 'create' && shouldShowCreateForm ? (
                <ECEasyFormCreate
                  useCreateMutation={useCreateMutation}
                  formConfig={createFormConfig}
                  formFields={createFormFields}
                  disclaimer={disclaimer}
                  onCreate={onCreate}
                  onClose={handleClose}
                  onChange={onChange}
                  onDuplicateChip={onDuplicateChip}
                  drawerTitleBarBGColor={drawerTitleBarBGColor}
                  saveButtonDisabled={createSaveButtonDisabled}
                />
              ) : detailData && !fetchingResultById ? (
                <ECRequirePermission
                  scopes={canGetById ? [canGetById] : undefined}
                >
                  <EditForm2
                    row={detailData}
                    formConfig={editFormConfig}
                    formFields={editFormFields}
                    detailsConfig={detailsConfig}
                    detailsFields={detailsFields}
                    useUpdateMutation={useUpdateMutation}
                    useDeleteMutation={useDeleteMutation}
                    additionalDeleteAction={() => {
                      additionalDeleteAction?.();
                      handleClose();
                    }}
                    additionalActions={editFormAdditionalActions}
                    additionalElements={editFormAdditionalElements}
                    editFormAdditionalProcess={editFormAdditionalProcess}
                    onClose={handleClose}
                    onChange={onChange}
                    disclaimer={disclaimer}
                    deleteConfirmationTitle={deleteConfirmationTitle}
                    deleteConfirmationBody={deleteConfirmationBody}
                    drawerTitleBarBGColor={drawerTitleBarBGColor}
                    customDrawerTitle={customDrawerTitle}
                    showEditButton={showEditButton}
                    onEditButtonClick={onEditButtonClick}
                    submissionConfirmationModal={submissionConfirmationModal}
                  />
                </ECRequirePermission>
              ) : (
                fetchingResultById && (
                  <ECCard>
                    <ECGrid
                      container
                      spacing={2}
                      sx={{
                        p: 4,
                        pt: 6,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <ECCircularProgress />
                      <ECTypography
                        ml={4}
                        variant={'subtitle1'}
                        color={theme => theme.palette.text.disabled}
                      >
                        {t('translation:form.loading')}
                      </ECTypography>
                    </ECGrid>
                  </ECCard>
                )
              )}
            </ECBox>
          </ECDrawerDetails>
        )}

        {customTableHeader && (
          <ECBox
            bgcolor={theme => theme.palette.grey[200]}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            display="flex"
            height={'58px'}
          >
            <ECButton
              sx={{ left: 8, position: 'absolute' }}
              startIcon={<ArrowBackIos />}
              onClick={handleReturnClick}
            >
              Back
            </ECButton>
            <ECTypography variant="h4" maxWidth="100%">
              {customTableHeader || ''}
            </ECTypography>
          </ECBox>
        )}
        <ECBox sx={{ padding: customPadding ? customPadding : 2.3 }}>
          <ECEasyTable
            customTableBodyMaxHeight={customTableBodyMaxHeight}
            config={configState}
            isEmptyState={isEmptyState}
            data={listData}
            hidePagination={hidePagination}
            hidePerPageSelector={hidePerPageSelector}
            disableTableContent={disableTableContent}
            multiSelectTable={multiSelectTable}
            selectedRows={selectedRows}
            showSelectAllPageRows={showSelectAllPageRows}
            emptyType={emptyType}
            multiSelectTableConfig={multiSelectTableConfig}
            selectRowsMaxCount={selectRowsMaxCount}
            preSelectRowsFieldname={preSelectRowsFieldname}
            onMultiTableSelect={onMultiTableSelect}
            allChecked={allChecked}
            customMultiSelectActionButton={customMultiSelectActionButton}
            multiSelectedRows={multiSelectedRows}
            clearSelection={clearSelection}
            onDataRequest={onDataRequest}
            showStatusActiveFilter={showStatusActiveFilter}
            showMissedVendorIdFilter={showMissedVendorIdFilter}
            showDateFilter={showDateFilter}
            showAssignedToMeFilter={showAssignedToMeFilter}
            showAssignedLocationsFilter={showAssignedLocationsFilter}
            showAssetTypeNameFilter={showAssetTypeNameFilter}
            showConceptsFilter={showConceptsFilter}
            showAssetGroupSingleFilter={showAssetGroupSingleFilter}
            showSetByFilter={showSetByFilter}
            setByFilterOptions={setByFilterOptions}
            showLocationFilter={showLocationFilter}
            showManufacturerFilter={showManufacturerFilter}
            userCompany={userCompany}
            showAwaitingInvoiceFilter={showAwaitingInvoiceFilter}
            showAssetTypeFilter={showAssetTypeFilter}
            showEtaFilter={showEtaFilter}
            showTechAssginedFilter={showTechAssginedFilter}
            showCalendarViewFilter={showCalendarViewFilter}
            showCustomersFilter={showCustomersFilter}
            showTechNotAssignedToggleFilter={showTechNotAssignedToggleFilter}
            showCategoryFilter={showCategoryFilter}
            showStatusFilter={showStatusFilter}
            showCustomFilter={showCustomFilter}
            customSimpleSelectFilterOptions={customSimpleSelectFilterOptions}
            customSimpleSelectFilterLabel={customSimpleSelectFilterLabel}
            customSimpleSelectFilterName={customSimpleSelectFilterName}
            dateRangeDefaultSelectedOption={dateRangeDefaultSelectedOption}
            dateFilterFieldsOptions={dateFilterFieldsOptions}
            categoryFilterOptions={categoryFilterOptions}
            statusFilterOptions={statusFilterOptions}
            assetTypeFilterOptions={assetTypeFilterOptions}
            locationFilterOptions={locationFilterOptions}
            locationFilterQueryParams={locationFilterQueryParams}
            manufacturerFilterQueryParams={manufacturerFilterQueryParams}
            useLocationFilterQuery={useLocationFilterQuery}
            useManufacturerFilterQuery={useManufacturerFilterQuery}
            useGetAssetTypesFilterQuery={useGetAssetTypesFilterQuery}
            statusFilterInitialSelected={
              statusFilterInitialSelected !== undefined
                ? statusFilterInitialSelected
                : activeFilter?.activeStatus
            }
            showColumnVisibilityFilter={showColumnVisibilityFilter}
            isDraggableColumns={isDraggableColumns}
            activeStatusFilterActiveLabel={activeStatusFilterActiveLabel}
            activeStatusFilterInactiveLabel={activeStatusFilterInactiveLabel}
            showSelfPerformingFilter={showSelfPerformingFilter}
            isLoading={isLoading}
            isSendingRequest={isFetching}
            onRowPress={withDrawer ? handleRowClick : onRowClick}
            usingGlobalSearch={usingGlobalSearch}
            onDuplicatePress={onDuplicatePress}
            renderCustomActionComponent={renderCustomActionComponent}
            renderCustomAttachmentComponent={renderCustomAttachmentComponent}
            onClickViewUsersTable={onClickViewUsersTable}
            hasUsersTablePreviewData={hasUsersTablePreviewData}
            onClickRecipientsNotificationEmail={
              onClickRecipientsNotificationEmail
            }
            onClickViewNotificationEmail={onClickViewNotificationEmail}
            onClickRecipientsNotificationMobile={
              onClickRecipientsNotificationMobile
            }
            onClickViewNotificationMobile={onClickViewNotificationMobile}
            onClickRecipientsNotificationText={
              onClickRecipientsNotificationText
            }
            onClickViewNotificationText={onClickViewNotificationText}
            selectedChipUsersTable={selectedChipUsersTable}
            showBadgeExpirationDate={showBadgeExpirationDate}
            moduleRedirectElement={moduleRedirectElement}
            ignoreHyperlink={ignoreHyperlink}
            headerChildren={
              customContent && customContent.header ? (
                customContent.header
              ) : (
                <ECBox
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {showTotal && data?.total > 0 && (
                    <ECBox display="flex" flexDirection="column">
                      <ECBox display="flex">
                        <ECTypography
                          variant="h6"
                          fontWeight={400}
                          color="rgba(0, 0, 0, 0.6)"
                        >
                          {t(
                            'translation:dynamicPageTemplate.config.totalSpend',
                          )}
                          {':\u00A0'}
                        </ECTypography>
                        <ECTypography
                          variant="h5"
                          fontWeight="bold"
                          color="rgba(0, 0, 0, 0.87)"
                        >
                          ${data.total?.toFixed(2)}
                        </ECTypography>
                      </ECBox>
                      {showTotalHelperText && (
                        <FormHelperText
                          sx={theme => ({ color: theme.palette.action.active })}
                        >
                          <ECTypography fontWeight={400} fontSize={12}>
                            {showTotalHelperText}
                          </ECTypography>
                        </FormHelperText>
                      )}
                    </ECBox>
                  )}
                  {showTotalResults &&
                    data?.config?.pagination?.totalCount > 0 && (
                      <ECBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <FormHelperText
                          sx={theme => ({ color: theme.palette.action.active })}
                        >
                          <ECTypography fontWeight={400} fontSize={16}>
                            Results: {data?.config?.pagination?.totalCount}
                          </ECTypography>
                        </FormHelperText>
                      </ECBox>
                    )}
                  {createFormConfig && createFormFields && (
                    <ECButton
                      onClick={handleAddNewClick}
                      startIcon={<Add />}
                      variant="outlined"
                      scopes={canAdd ? [canAdd] : undefined}
                      sx={{ height: '40px' }}
                    >
                      {createFormConfig.addNewText
                        ? createFormConfig.addNewText
                        : t('translation:dynamicPageTemplate.config.create')}
                    </ECButton>
                  )}
                </ECBox>
              )
            }
            additionalChildren={
              customContent && customContent.children
                ? customContent.children
                : null
            }
            downloadFileName={
              downloadFileName
                ? downloadFileName
                : pageTitle.replace(/\s+/g, '-').toLowerCase() + '.csv'
            }
            enableExport={enableExport}
            marginTop={marginTop}
            shouldUseSearchParams={shouldUseSearchParams}
            shouldNotUseActiveFilter={shouldNotUseActiveFilter}
            moduleName={moduleName}
            currentQueryParams={currentQueryParams}
            exportModuleName={exportModuleName}
            exportUserId={exportUserId}
            enableTableCsvExport={enableTableCsvExport}
            showHierarchyFilter={showHierarchyFilter}
            showActivityButton={showActivityButton}
            searchBarCustomElements={searchBarCustomElements}
            activityType={activityType}
            hideSimpleSearchBar={hideSimpleSearchBar}
            hideSaveFilterButton={hideSaveFilterButton}
            renderTableCustomLink={renderTableCustomLink}
            conditionalLinearProgressColor={conditionalLinearProgressColor}
          />
        </ECBox>
      </ECGrid>
    </>
  );
}
