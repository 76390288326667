import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: ['ModuleDetails'],
});

export type ModuleDetailsParams = {
  moduleName: string;
  id: string;
  includeLastCommentUserInfo?: number;
};

export const moduleApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getModuleDetailsById: builder.query<any, ModuleDetailsParams>({
      query: ({ moduleName, id, includeLastCommentUserInfo }) => {
        if (!moduleName || !id) {
          return {
            url: '',
          };
        }

        return {
          url: `${moduleName}/${id}`,
          params: {
            ...(includeLastCommentUserInfo !== undefined && {
              includeLastCommentUserInfo,
            }),
          },
        };
      },
      providesTags: ['ModuleDetails'],
    }),
  }),
});

export const {
  useGetModuleDetailsByIdQuery,
  useLazyGetModuleDetailsByIdQuery,
} = moduleApi;
