import { ECButton, ECIconButton, ECImage, ECStack, ECTypography } from '..';
import { ECBox } from '../ECBox';
import { Delete, Download, FileCopy } from '@mui/icons-material';
import { BoxProps, Popover } from '@mui/material';
import { ImagePreview } from '../ECFilePicker';
import { AttachmentCategory } from 'types/Attachment';
import { ECWarrantyBadge, WarrantyBadgeTypes } from '../ECWarrantyBadge';
import { useCallback, useState } from 'react';
import { useLazyDownloadAttachmentQuery } from 'services/attachmentApi';
import { ECDropzone } from '../ECDropzone';
import { ECAlert, ECAlertTitle } from '../ECAlert';
import { ECCheckbox } from '../ECCheckbox';
import { ECAttachmentDeleteAlert } from '../ECAttachmentDeleteAlert';

const DEFAULT_MAX_PHOTOS_PER_ROW = 4;

export interface ECPhotoFieldProps extends Omit<BoxProps, 'onClick'> {
  photos?: ImagePreview[];
  title?: string;
  moduleName?: string;
  maxPhotosPerRow?: number;
  isReadOnly?: boolean;
  isDownloadEnabled?: boolean;
  isDeleteEnabled?: boolean;
  isDeletePreviewEnabled?: boolean;
  onDelete?: (photoId: number) => () => void;
  onDeletePreview?: (photoUrl: string) => () => void;
  onClick?: (id: number) => () => void;
  fieldName?: string;
  confirmationDeleteTitle?: string;
  confirmationDeleteMessage?: string;
  showDeleteAlert?: boolean;
  deleteAlertTitle?: string;
  deleteAlertText?: string;
  onFileInput?: (file: any) => void;
  onFileDrop?: (file: File) => void;
  isCustomerOwnRequiredDocs?: boolean;
  disabledUpload?: boolean;
  hideDragDropAreaWhenHaveFilesUploaded?: boolean;
}

export const ECPhotoField = ({
  photos,
  title,
  moduleName,
  maxPhotosPerRow = DEFAULT_MAX_PHOTOS_PER_ROW,
  isReadOnly,
  isDownloadEnabled,
  isDeleteEnabled,
  isDeletePreviewEnabled,
  onDelete,
  onDeletePreview,
  onClick,
  fieldName,
  confirmationDeleteTitle = 'Delete attachment?',
  confirmationDeleteMessage = 'Please, confirm you want to delete attachment',
  showDeleteAlert = false,
  deleteAlertTitle,
  deleteAlertText,
  onFileInput,
  onFileDrop,
  isCustomerOwnRequiredDocs,
  disabledUpload,
  hideDragDropAreaWhenHaveFilesUploaded,
  ...boxProps
}: ECPhotoFieldProps) => {
  const [triggerDownload] = useLazyDownloadAttachmentQuery();
  const handleDownloadAttachment = useCallback(
    (id: number | undefined, fileName: string | undefined) => {
      if (id) {
        triggerDownload({
          module: isCustomerOwnRequiredDocs
            ? 'document-required'
            : moduleName || '',
          moduleId: id,
          category: AttachmentCategory.Photo,
          fileName: fileName,
        });
      }
    },
    [triggerDownload, moduleName, isCustomerOwnRequiredDocs],
  );

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const [selectedPhoto, setSelectedPhoto] = useState<any>(null);

  const handleCloseModal = () => {
    setAnchorEl(null);
  };

  const hideDragDropArea =
    hideDragDropAreaWhenHaveFilesUploaded && disabledUpload;

  return (
    <ECBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      {...boxProps}
    >
      {title && (
        <ECBox mb={3} display="flex" flexDirection="column">
          <ECTypography variant="h4">{title}</ECTypography>
        </ECBox>
      )}
      <ECStack direction="row" flexWrap="wrap">
        {!isReadOnly && (
          <ECDropzone
            hideDragDropArea={hideDragDropArea}
            fieldName={fieldName}
            disabled={disabledUpload}
            onFileInput={onFileInput}
            onFileDrop={onFileDrop}
            sx={{
              width: '50%',
            }}
          />
        )}
        {Array.isArray(photos)
          ? photos?.map((photo, index) => {
              const fileName =
                photo.originalName?.split('.')?.[0] ??
                photo.name?.split('.')?.[0];
              const fileFormat = photo.name?.split('.')?.[1] ?? '';
              return (
                <ECBox
                  key={`${photo.name}-${photo.id}-${photo.lastModified}`}
                  position="relative"
                  sx={{
                    cursor: photo.id ? 'pointer' : 'default',
                    '&:hover': {
                      '.header': {
                        visibility: 'visible',
                      },
                    },
                  }}
                  borderColor={theme => theme.palette.action.active}
                  border={1}
                  borderRadius={1}
                  overflow="hidden"
                  minHeight={200}
                  height={300}
                  my={1.5}
                  mx={1}
                  maxWidth={282}
                  minWidth={250}
                  width="100%"
                >
                  <ECBox
                    display={'flex'}
                    justifyContent="space-between"
                    position="absolute"
                    top={0}
                    p={1}
                    gap={2}
                    height={42}
                    className="header"
                    visibility={
                      anchorEl && selectedPhoto?.id === photo?.id
                        ? 'visible'
                        : 'hidden'
                    }
                    width={'100%'}
                    flexDirection={'row'}
                    sx={theme => ({
                      backgroundColor:
                        photo.attachmentType === AttachmentCategory.Photo ||
                        photo.type === AttachmentCategory.Photo
                          ? 'white'
                          : theme.palette.grey[400],
                    })}
                    zIndex={99}
                  >
                    {!isReadOnly && isDeleteEnabled && (
                      <ECIconButton
                        size="small"
                        withBorder
                        type="error"
                        sx={{
                          backgroundColor: 'white',
                        }}
                        onClick={el => {
                          setAnchorEl(el.currentTarget);
                          setSelectedPhoto(photo);
                        }}
                      >
                        <Delete
                          sx={theme => ({
                            color: theme.palette.error.main,
                            bgcolor: theme.palette.error.main,
                          })}
                        />
                      </ECIconButton>
                    )}

                    {!isReadOnly && isDeletePreviewEnabled && (
                      <ECIconButton
                        size="small"
                        withBorder
                        type="error"
                        sx={{
                          backgroundColor: 'white',
                        }}
                        onClick={() => {
                          if (photo?.preview) {
                            onDeletePreview?.(photo.preview)();
                          }
                        }}
                      >
                        <Delete
                          sx={theme => ({
                            color: theme.palette.error.main,
                            bgcolor: theme.palette.error.main,
                          })}
                        />
                      </ECIconButton>
                    )}

                    {isDownloadEnabled && (
                      <ECIconButton
                        size="small"
                        withBorder
                        sx={{
                          backgroundColor: 'white',
                        }}
                        onClick={() =>
                          handleDownloadAttachment(photo.id, fileName)
                        }
                      >
                        <Download
                          sx={theme => ({
                            color: theme.palette.grey[600],
                          })}
                        />
                      </ECIconButton>
                    )}
                  </ECBox>
                  <ECBox
                    height="100%"
                    width="100%"
                    onClick={photo.id ? onClick?.(photo.id) : undefined}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {(photo.attachmentType === AttachmentCategory.Photo ||
                      photo.type?.includes('image') ||
                      photo.type?.includes('photo')) && (
                      <ECImage
                        sx={{
                          maxHeight: '100%',
                          width: '100%',
                          objectFit: 'cover',
                        }}
                        alt="attachment"
                        src={photo.preview}
                      />
                    )}

                    {photo.attachmentType === AttachmentCategory.Document && (
                      <ECBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={theme => ({
                          backgroundColor: theme.palette.grey[400],
                          height: '100%',
                        })}
                        p={4}
                      >
                        <ECBox
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          gap={2}
                        >
                          <FileCopy
                            sx={theme => ({
                              color: theme.palette.action.active,
                            })}
                          />

                          <ECTypography textAlign="center" variant="body1">
                            {fileName}
                            <br />
                            {fileFormat}
                          </ECTypography>
                        </ECBox>
                      </ECBox>
                    )}
                  </ECBox>
                  {
                    <ECBox
                      justifyContent="space-between"
                      alignItems="center"
                      p={1}
                      height={
                        photo?.uploadedBy ||
                        photo?.uploadDate ||
                        photo?.documentType
                          ? 80
                          : 0
                      }
                      width="100%"
                      bgcolor={theme => theme.palette.common.white}
                      position="absolute"
                      bottom={0}
                      zIndex={99}
                    >
                      <ECBox
                        display="flex"
                        alignItems="baseline"
                        visibility={photo?.uploadedBy ? 'visible' : 'hidden'}
                      >
                        <ECTypography
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          marginRight={1}
                          style={{ fontSize: 14, fontWeight: 'bold' }}
                        >
                          Uploaded By:
                        </ECTypography>
                        <ECTypography
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          marginRight={1}
                          style={{ fontSize: 14, fontWeight: 200 }}
                        >
                          {photo?.uploadedBy}
                        </ECTypography>
                      </ECBox>
                      <ECBox
                        display="flex"
                        alignItems="baseline"
                        visibility={photo?.uploadDate ? 'visible' : 'hidden'}
                      >
                        <ECTypography
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          marginRight={1}
                          style={{ fontSize: 14, fontWeight: 'bold' }}
                        >
                          Upload Date:
                        </ECTypography>
                        <ECTypography
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          marginRight={1}
                          style={{ fontSize: 14, fontWeight: 200 }}
                        >
                          {photo?.uploadDate}
                        </ECTypography>
                      </ECBox>
                      <ECBox
                        display="flex"
                        alignItems="baseline"
                        visibility={photo?.documentType ? 'visible' : 'hidden'}
                      >
                        <ECTypography
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          marginRight={1}
                          style={{ fontSize: 14, fontWeight: 'bold' }}
                        >
                          Document Type:
                        </ECTypography>
                        <ECTypography
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          marginRight={1}
                          style={{ fontSize: 14, fontWeight: 200 }}
                        >
                          {photo?.documentType}
                        </ECTypography>
                      </ECBox>
                      {photo.documentRequiredId && (
                        <ECWarrantyBadge
                          customizedLabel="Required"
                          warrantyBadgeType={WarrantyBadgeTypes.Info}
                          hideIcon={true}
                        />
                      )}
                      {photo.expiresOn &&
                        new Date(photo.expiresOn).toISOString() <
                          new Date().toISOString() && (
                          <ECWarrantyBadge
                            customizedLabel="Expired"
                            warrantyBadgeType={WarrantyBadgeTypes.Inactive}
                            hideIcon={true}
                          />
                        )}
                    </ECBox>
                  }
                </ECBox>
              );
            })
          : null}
        {showDeleteAlert ? (
          <ECAttachmentDeleteAlert
            open={open}
            title={deleteAlertTitle!}
            text={deleteAlertText!}
            onDelete={() => {
              onDelete?.(
                isCustomerOwnRequiredDocs
                  ? selectedPhoto.moduleId
                  : selectedPhoto.id,
              )();
              handleCloseModal();
            }}
            handleCloseModal={handleCloseModal}
          />
        ) : (
          <Popover
            id={popoverId}
            open={open}
            anchorEl={anchorEl}
            onClose={handleCloseModal}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={{
              sx: {
                overflowX: 'unset',
                overflowY: 'unset',
                borderRadius: 1,
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: 17,
                  width: 10,
                  height: 10,
                  backgroundColor: 'white',
                  transform: 'translate(-50%, 50%) rotate(135deg)',
                  clipPath:
                    'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
                },
              },
            }}
          >
            <ECBox
              display="flex"
              flexDirection="column"
              p={2}
              gap={3}
              bgcolor={theme => theme.palette.common.white}
              minWidth="20vw"
              borderRadius={1}
              overflow="hidden"
            >
              <ECTypography variant="body1" fontWeight="bold">
                {confirmationDeleteTitle}
              </ECTypography>
              <ECTypography variant="body2">
                {confirmationDeleteMessage}
              </ECTypography>

              <ECBox
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                gap={1}
              >
                <ECButton variant="text" onClick={handleCloseModal}>
                  No
                </ECButton>

                <ECButton
                  variant="contained"
                  color="error"
                  onClick={() => {
                    onDelete?.(
                      isCustomerOwnRequiredDocs
                        ? selectedPhoto.moduleId
                        : selectedPhoto.id,
                    )();
                    handleCloseModal();
                  }}
                >
                  Inactivate
                </ECButton>
              </ECBox>
            </ECBox>
          </Popover>
        )}
      </ECStack>
    </ECBox>
  );
};
