import { ECTabContainer } from '../../components';
import { StyleConstants } from 'styles/StyleConstants';
import { CompanyConfigurationFields, P } from 'types/Permission';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';
import { InventoryPage } from '../InventoryPage';
import { PurchaseOrders } from '../PurchaseOrders';

export function InventoryAndPartsTabsPage() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet
        titleTemplate={`${t('translation:pages.inventory.title')}`}
        defaultTitle={t('translation:pages.inventory.title')}
      />
      <ECTabContainer
        tabsAsLinks
        mt={StyleConstants.NAV_BAR_HEIGHT}
        p={2}
        variant="standard"
        color="marketingSuggestion"
        noPadding
        tabs={[
          {
            value: 'inventory',
            label: 'Inventory',
            content: <InventoryPage marginTop={false} />,
            scopes: [P.GetInventory],
            companyConfigScopes: [CompanyConfigurationFields.EnableInventory],
            link: 'inventory-list',
          },
          {
            value: 'storage-locations',
            label: 'Storage Locations',
            content: <Outlet />,
            scopes: [P.GetInventoryStorage],
            companyConfigScopes: [CompanyConfigurationFields.EnableInventory],
            link: 'storage-locations',
          },
          {
            value: 'purchase-orders',
            label: 'Purchase Orders',
            content: <PurchaseOrders marginTop={false} />,
            scopes: [P.GetPurchaseOrder],
            link: 'purchase-orders',
          },
          {
            value: 'suppliers',
            label: 'Suppliers',
            content: <Outlet />,
            scopes: [P.GetSupplier],
            link: 'suppliers',
          },
        ]}
      />
    </>
  );
}
