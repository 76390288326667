import { InputLabel } from '@mui/material';
import {
  ECBox,
  ECButton,
  ECFormControl,
  ECGrid,
  ECMenuItem,
  ECSelect,
  ECTypography,
  presetColors,
} from 'app/components';
import { requirePermissionWrapper } from 'app/hoc/require-permission';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useGetAvailableSPsForWorkorderQuery } from 'services/serviceProviderApi';
import {
  useGetWorkOrderByIdQuery,
  useUpdateWorkOrderNoInvalidateMutation,
} from 'services/workOrdersApi';
import { dashboardApi } from 'services/dashboardApi';
import { setSnackbar } from 'store/slice/page';
import { P } from 'types/Permission';
import { DashboardActionProps, QueryTag } from '.';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import * as _ from 'lodash';

function ReassignContent({
  onClose,
  onCancel,
  queryTag,
  selectedRows,
  additionalData,
  invalidateQuery,
  customerId,
  onSubmit,
  onChange,
  customHeaderTitle,
  customInputLabel,
}: DashboardActionProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [doUpdate, { isSuccess, isLoading, isError }] =
    useUpdateWorkOrderNoInvalidateMutation();

  const [serviceProvidersOptions, setServiceProvidersOptions] = useState<any[]>(
    [],
  );

  const [serviceProvidersOptionsValues, setServiceProvidersOptionsValues] =
    useState<any[]>([]);
  const [serviceProviderSelected, setServiceProviderSelected] =
    useState<string>('');

  const { data: woById, isLoading: isLoadingWoById } = useGetWorkOrderByIdQuery(
    { id: selectedRows?.[0]?.id },
    {
      skip: !selectedRows?.[0]?.id,
    },
  );

  const [clearAction, setClearAction] = useState<() => void>();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const {
    data: serviceProviders,
    isSuccess: isSuccessServiceProviders,
    isLoading: isLoadingServiceProviders,
  } = useGetAvailableSPsForWorkorderQuery(
    {
      workorderId: selectedRows[0]?.id,
      customerId: customerId,
    },
    {
      skip: !selectedRows[0]?.id,
    },
  );

  const handleSubmit = useCallback(() => {
    if (!serviceProviderSelected) {
      setErrorMessage('Service Provider is Required');
      return;
    }
    const spId = serviceProviderSelected;

    if (woById && selectedRows?.[0]?.id) {
      doUpdate({
        id: selectedRows[0]?.id,
        categoryId: woById?.categoryId,
        requestId: woById?.requestId,
        priorityId: woById?.priorityId,
        spCompanyId: Number(spId),
      });
    }
  }, [selectedRows, woById, serviceProviderSelected]);

  useEffect(() => {
    if (serviceProviders && isSuccessServiceProviders) {
      const filteredServiceProviders = serviceProviders.filter(
        sp => sp !== null,
      );
      setServiceProvidersOptions(
        filteredServiceProviders.map(sp => ({
          label: sp?.name,
          id: sp?.id,
        })),
      );
      setServiceProvidersOptionsValues(
        filteredServiceProviders.map(sp => sp?.id),
      );
    }
  }, [serviceProviders, isSuccessServiceProviders]);

  // 'update' response handler
  useEffect(() => {
    clearAction?.();
    if (isSuccess) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Successfully reassigned work order',
        }),
      );
      if (queryTag) {
        dispatch(
          dashboardApi.util.invalidateTags(_.flatten(queryTag) as QueryTag[]),
        );
      }
      invalidateQuery?.();
      onSubmit?.();
      onClose();
    } else if (isError) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: `WO ${selectedRows[0]?.id} was not able to be reassigned`,
        }),
      );
      onClose();
    }
  }, [dispatch, isError, isSuccess, onClose]);

  return (
    <ECBox
      display="flex"
      flexDirection="column"
      sx={{
        width: '600px',
      }}
    >
      <ECBox
        sx={{
          padding: '2rem',
        }}
      >
        <ECTypography variant="h6" marginBottom="1rem">
          {/*t('translation:pages.dashboard.reassignWorkOrder')*/}
          {customHeaderTitle
            ? customHeaderTitle
            : 'Confirm you want to Reassign Work Order'}
        </ECTypography>
        {isLoadingServiceProviders ? (
          <ECBox mb={1.5}>
            <ECCircularProgress size={20} />
          </ECBox>
        ) : (
          <ECFormControl sx={{ width: '100%', marginBottom: '1rem' }}>
            <InputLabel id="reassign-select-service-providers">
              {customInputLabel
                ? customInputLabel
                : 'Select Service Providers*'}
            </InputLabel>
            <ECSelect
              sx={{ width: '100%' }}
              id="reassign-service-providers-id"
              value={serviceProviderSelected}
              name="Service Providers"
              placeholder="Service Providers"
              error={errorMessage.length > 0}
              label="Select Service Providers*"
              renderValue={value => {
                const optionIndex =
                  serviceProvidersOptionsValues?.findIndex(
                    optionValue => optionValue === value,
                  ) || 0;

                const optionLabel =
                  serviceProvidersOptions?.[optionIndex]?.label ??
                  serviceProvidersOptions?.[optionIndex];
                const colorName =
                  serviceProvidersOptions?.[optionIndex]?.colorName;

                return (
                  <ECBox display="flex" alignItems="center">
                    {colorName && (
                      <ECBox
                        height="30px"
                        width="3px"
                        bgcolor={
                          presetColors.find(color => color.title === colorName)
                            ?.color
                        }
                        mr={1}
                      />
                    )}
                    <ECTypography>{optionLabel}</ECTypography>
                  </ECBox>
                );
              }}
              onChange={event => {
                const eventValue = event.target.value as string;
                setServiceProviderSelected(eventValue);
                onChange?.(eventValue);
                setErrorMessage('');
              }}
            >
              {serviceProvidersOptions.map((option, index) => {
                return (
                  <ECMenuItem
                    key={`${option.id}-${option.label}`}
                    value={
                      serviceProvidersOptionsValues
                        ? serviceProvidersOptionsValues[index]
                        : option
                    }
                  >
                    <ECBox
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <ECTypography>{option.label ?? option}</ECTypography>
                    </ECBox>
                  </ECMenuItem>
                );
              })}
            </ECSelect>
          </ECFormControl>
        )}
      </ECBox>

      <ECBox
        display="flex"
        flexDirection="column"
        position={'sticky'}
        zIndex={99}
        bottom={0}
      >
        <ECBox display="flex" bgcolor="transparent">
          <ECGrid
            container
            spacing={2}
            sx={{
              flexDirection: 'row',
              justifyContent: 'right',
              p: 1,
            }}
          >
            <ECButton sx={{ height: '42px' }} onClick={onCancel}>
              Cancel
            </ECButton>
            <ECButton
              sx={{ height: '42px', minWidth: '64px', marginLeft: '16px' }}
              onClick={handleSubmit}
              variant="contained"
            >
              {isLoading || isLoadingWoById ? (
                <ECCircularProgress size={24} />
              ) : (
                'CONFIRM'
              )}
            </ECButton>
          </ECGrid>
        </ECBox>
      </ECBox>
    </ECBox>
  );
}

export const reassignAction = {
  buttonLabel: 'Reassign',
  disabledRule: (selection: number, canReassign) =>
    !_.isNil(canReassign) ? canReassign && selection !== 1 : selection !== 1,
  modalContent: requirePermissionWrapper<DashboardActionProps>(ReassignContent),
  scopes: [P.ReassignWorkOrder],
};
