import type { MentionOptions } from '@tiptap/extension-mention';
import { ReactRenderer } from '@tiptap/react';
import tippy, { type Instance as TippyInstance } from 'tippy.js';
import SuggestionList, { SuggestionListRef } from './suggestionList';

export type MentionSuggestion = {
  id: string;
  mentionLabel: string;
  jobTitle?: string;
};

/**
 * Workaround for the current typing incompatibility between Tippy.js and Tiptap
 * Suggestion utility.
 *
 * @see https://github.com/ueberdosis/tiptap/issues/2795#issuecomment-1160623792
 *
 * Adopted from
 * https://github.com/Doist/typist/blob/a1726a6be089e3e1452def641dfcfc622ac3e942/stories/typist-editor/constants/suggestions.ts#L169-L186
 */
const DOM_RECT_FALLBACK: DOMRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
  toJSON() {
    return {};
  },
};

export const mentionSuggestionOptions = (
  users: { id: string; name: string; jobTitle?: string; onSelect?: any }[],
  onSelect?: (user: MentionSuggestion) => void, // Add callback function
): MentionOptions['suggestion'] => {
  return {
    items: async ({ query }): Promise<MentionSuggestion[]> => {
      return users
        ?.map(user => ({
          mentionLabel: user.name,
          id: user.id,
          jobTitle: user.jobTitle,
        }))
        .filter(item =>
          item.mentionLabel.toLowerCase().startsWith(query.toLowerCase()),
        );
    },

    render: () => {
      let component: ReactRenderer<SuggestionListRef> | undefined;
      let popup: TippyInstance | undefined;

      return {
        onStart: props => {
          component = new ReactRenderer(SuggestionList, {
            props: { ...props, onSelect },
            editor: props.editor,
          });

          popup = tippy('body', {
            getReferenceClientRect: () =>
              props.clientRect?.() ?? DOM_RECT_FALLBACK,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          })[0];
        },

        onUpdate(props) {
          component?.updateProps({ ...props, onSelect });

          popup?.setProps({
            getReferenceClientRect: () =>
              props.clientRect?.() ?? DOM_RECT_FALLBACK,
          });
        },

        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            popup?.hide();
            return true;
          }

          if (!component?.ref) {
            return false;
          }

          return component.ref.onKeyDown(props);
        },

        onExit() {
          popup?.destroy();
          component?.destroy();
          popup = undefined;
          component = undefined;
        },
      };
    },
  };
};
