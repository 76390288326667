import { Breakpoint } from '@mui/material';
import {
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ECBox,
  ECButton,
  ECEasyForm,
  ECEasyFormConfigType,
  ECEasyFormFieldType,
  ECTypography,
  FieldTypes,
  ECIconButton,
  ECStack,
  isEmptyValue,
  ECDivider,
  ECRequirePermission,
  ECChip,
  ChipSearchOptions,
} from '..';
import { ECDialog, ECDialogContent } from '../ECDialog';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Close,
  Delete,
  Edit,
  Favorite,
  FavoriteBorder,
} from '@mui/icons-material';
import { ECToggleButton } from '../ECToggleButton';
import { get, set, isEqualWith, cloneDeep, startCase } from 'lodash';
import { RootState, store } from 'index';
import { lookupApi } from 'services/lookupApi';
import { Lookup } from 'types/Lookup';
import { AutoCompleteDropDownOption } from 'types/User';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveFilter, setSnackbar } from 'store/slice/page';
import {
  ECEasyTableColsProps,
  ECTable,
  ECTableBody,
  ECTableCell,
  ECTableContainer,
  ECTableRow,
} from '../ECTable';
import {
  useDeleteFilterMutation,
  useGetAllSavedFiltersQuery,
  useUpdateFavoriteFilterMutation,
} from 'services/filterApi';
import { Filter } from 'types/Filter';
import { useTranslation } from 'react-i18next';
import { ECPopover } from '../ECPopover';
import { CostTableData } from '../ECWorkflowCostTable';
import { HTTPError } from 'types';
import { filterableFields } from 'utils/filters';
import { addErrorToFieldsConfig, equalCustomizer } from 'utils/validate-fields';
import { SubmissionConfirmationModal } from '..';
import { cleanedPhoneNumber } from 'utils/strings/phone';
import { useLocation } from 'react-router-dom';
import { ECDrawerActivities } from '../ECDrawerActivities';
import { ActivityType } from 'types/Activity';

interface ECEasyFormCreateProps {
  useCreateMutation: any;
  formConfig: ECEasyFormConfigType;
  formFields: ECEasyFormFieldType[];
  disclaimer?: string;
  existingData?: any;
  onChange?: (
    output: ECEasyFormFieldType[],
    fieldName?: string,
    valueChange?: any,
  ) => void;
  onCreate?: (returnedData?: any) => void;
  onClose?: () => void;
  onDuplicateChip?: () => void;
  onLoadMoreData?: (fieldName: string, newValue?: string) => void;
  onChangeSearch?: (value: string) => void;
  showSaveButton?: boolean;
  showWideSaveButton?: boolean;
  saveButtonDisabled?: boolean;
  nteSaveAlertObject?: any;
  costTableData?: CostTableData;
  showTitle?: boolean;
  outsideData?: ECEasyFormFieldType[];
  hideToolBar?: boolean;
  noPadding?: boolean;
  noPaddingX?: boolean;
  noPaddingY?: boolean;
  isLoading?: boolean;
  hideCancelButton?: boolean;
  drawerTitleBarBGColor?: string;
  noTopMargin?: boolean;
  hideActions?: boolean;
  stickyFooter?: boolean;
  shouldShowSubmitSubtitle?: boolean;
  customDrawerTitle?: string;
  searchOptionsState?: ChipSearchOptions;
  CustomSuccessScreen?: ReactElement;
  CustomErrorScreen?: ReactElement;
  CustomLoadingScreen?: ReactElement;
  showSuccessSnackBar?: boolean;
  pattern?: string;
  id?: string;
  defaultRequestCategory?: any;
}

export function ECEasyFormCreate({
  useCreateMutation,
  formConfig,
  formFields,
  disclaimer,
  existingData,
  costTableData,
  onChange,
  onCreate,
  onClose,
  onDuplicateChip,
  onLoadMoreData,
  onChangeSearch,
  showSaveButton = true,
  showWideSaveButton,
  nteSaveAlertObject,
  saveButtonDisabled = false,
  showTitle = true,
  outsideData,
  hideToolBar,
  noPadding,
  noPaddingX,
  noPaddingY,
  isLoading,
  hideCancelButton,
  stickyFooter = true,
  shouldShowSubmitSubtitle,
  customDrawerTitle,
  searchOptionsState,
  CustomSuccessScreen,
  CustomErrorScreen,
  CustomLoadingScreen,
  drawerTitleBarBGColor,
  noTopMargin,
  hideActions,
  showSuccessSnackBar = true,
  pattern,
  id,
  defaultRequestCategory,
}: ECEasyFormCreateProps) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [doRevertChanges, setDoRevertChanges] = useState(false);
  const [
    doCreate,
    {
      data,
      isError,
      error,
      isLoading: isSendingRequest,
      isSuccess,
      reset: resetAdd,
    },
  ] = useCreateMutation();

  const [fieldsState, setFieldsState] = useState<ECEasyFormFieldType[]>([]);

  const handleFieldState = useCallback(
    (field: ECEasyFormFieldType) => ({
      ...field,
      value:
        field.value ??
        fieldsState.find(fieldState => fieldState.fieldName === field.fieldName)
          ?.value,
      variant: field.readOnly ? 'standard' : field?.variant || 'filled',
      subFields:
        field.type === FieldTypes.Group
          ? field.subFields?.map(handleFieldState)
          : undefined,
    }),
    [],
  );

  useEffect(() => {
    const fieldsCopy = formFields.map(handleFieldState);
    setFieldsState(fieldsCopy);
  }, [formFields, doRevertChanges, handleFieldState]);

  const handleCancelClick = () => {
    setDoRevertChanges(!doRevertChanges);
    onClose?.();
  };

  const handleField = useCallback(
    (field: ECEasyFormFieldType, created: any) => {
      if (field.fieldName === 'status') {
        created[field.fieldName] = field.value === 'Active' ? 1 : 0;
      } else if (field.type === FieldTypes.Select) {
        const fieldName = field.fieldName?.split('.')?.[0] ?? field.fieldName;

        created[fieldName] = {
          ...created[fieldName],
          id: field.value,
        };
      } else if (field.type === FieldTypes.Group) {
        field.subFields?.forEach(field => handleField(field, created));
      } else if (field.type === FieldTypes.RadioWithCustomOptions) {
        const selectedOption = field.options?.find(
          option => option.id === field.value,
        );
        selectedOption?.fields?.forEach(field => {
          if (field?.visible !== false) {
            created[field.fieldName] = field?.value;
          }
        });
      } else if (field.type === FieldTypes.sectionWithCustomOptions) {
        const selectedOption = field.options?.find(
          option => option.id === field.value,
        );
        selectedOption?.fields?.forEach(field => {
          if (field?.visible !== false) {
            created[field.fieldName] = field?.value;
          }
        });
      } else if (field.type === FieldTypes.checkboxWithCustomOptions) {
        const fieldValuesToExtract = field?.value || [];

        fieldValuesToExtract.forEach(fieldValue => {
          const selectedOption = field.options?.find(
            option => option.id === fieldValue,
          );
          selectedOption?.fields?.forEach(field => {
            if (field?.visible !== false && field?.value !== null) {
              created[field.fieldName] = field?.value;
            }
          });
        });
      } else {
        created[field.fieldName] = field.value;
      }
    },
    [],
  );

  const handleSubmit = (formData: FormData, fields: ECEasyFormFieldType[]) => {
    const created = {};
    setFieldsState(fields);
    fields.forEach(field => handleField(field, created));
    doCreate(created);
  };

  useEffect(() => {
    if (isSuccess) {
      if (showSuccessSnackBar) {
        dispatch(
          setSnackbar({
            severity: 'success',
            message: formConfig?.name
              ? `New ${formConfig?.name} added successfully`
              : 'Added successfully',
          }),
        );
      }
      onCreate?.(data);
      if (!CustomSuccessScreen) {
        onClose?.();
        resetAdd?.();
      }
    }
  }, [
    isSuccess,
    formConfig?.name,
    dispatch,
    resetAdd,
    onCreate,
    data,
    onClose,
  ]);

  useEffect(() => {
    if (isError) {
      const { data: errorData } = error as HTTPError;
      const message = Array.isArray(errorData?.description)
        ? errorData?.description?.[0]
        : errorData?.description ?? errorData?.message;

      if (errorData?.fieldNameWithError) {
        const newFieldsState = addErrorToFieldsConfig(
          fieldsState,
          errorData.fieldNameWithError,
          message,
        );
        setFieldsState(newFieldsState);
      } else if (message) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: message,
          }),
        );
      }
      if (!CustomErrorScreen) {
        resetAdd?.();
      }
    }
  }, [isError, error, dispatch]);

  return (
    <>
      {(CustomSuccessScreen && isSuccess && CustomSuccessScreen) ||
        (CustomErrorScreen && isError && CustomErrorScreen) ||
        (CustomLoadingScreen && isLoading && CustomLoadingScreen) || (
          <ECEasyForm
            id={id}
            config={formConfig}
            fields={fieldsState}
            isSendingData={isSendingRequest}
            isLoadingForm={false}
            onFormSubmit={handleSubmit}
            disclaimer={disclaimer}
            existingData={existingData}
            costTable={costTableData}
            fullHeight
            additionalActions={
              showSaveButton &&
              (!hideCancelButton ? (
                <ECButton
                  type="button"
                  variant="text"
                  sx={{ height: 42 }}
                  onClick={handleCancelClick}
                >
                  {t('translation:dynamicForm.cancel')}
                </ECButton>
              ) : (
                <ECBox></ECBox>
              ))
            }
            showSaveButton={showSaveButton}
            showWideSaveButton={showWideSaveButton}
            nteSaveAlertObject={nteSaveAlertObject}
            saveButtonDisabled={saveButtonDisabled}
            onChange={onChange}
            onChangeSearch={onChangeSearch}
            showTitle={showTitle}
            onDuplicateChip={onDuplicateChip}
            outsideData={outsideData}
            hideToolBar={hideToolBar}
            noPaddingX={noPaddingX}
            noPaddingY={noPaddingY}
            onLoadMoreData={onLoadMoreData}
            isLoading={isLoading}
            stickyFooter={stickyFooter}
            shouldShowSubmitSubtitle={shouldShowSubmitSubtitle}
            customDrawerTitle={customDrawerTitle}
            searchOptionsState={searchOptionsState}
            drawerTitleBarBGColor={drawerTitleBarBGColor}
            pattern={pattern}
            noTopMargin={noTopMargin}
            hideActions={hideActions}
            defaultRequestCategory={defaultRequestCategory}
          />
        )}
    </>
  );
}

interface ECEasyFormEdit2Props<DataObject> {
  row: DataObject;
  config: ECEasyFormConfigType;
  fields: ECEasyFormFieldType[];
  detailsConfig?: ECEasyFormConfigType;
  detailsFields?: ECEasyFormFieldType[];
  useUpdateMutation?: any;
  useDeleteMutation?: any;
  additionalDeleteAction?: () => void;
  deleteConfirmationTitle?: string;
  deleteConfirmationBody?: string;
  additionalActions?: React.ReactNode;
  additionalElements?: React.ReactNode;
  onlyEdit?: boolean;
  hideToolBar?: boolean;
  errorOnLoading?: any;
  isLoading?: boolean;
  disclaimer?: string;
  costTable?: CostTableData;
  onEditClose?: () => void;
  onClose?: () => void;
  showEditButton?: boolean;
  showCloseButton?: boolean;
  showSaveButton?: boolean;
  onChange?: (
    output: ECEasyFormFieldType[],
    fieldName?: string,
    valueChange?: any,
  ) => void;
  onEditButtonClick?: (boolean) => void;
  showTitle?: boolean;
  onDuplicateChip?: () => void;
  outsideData?: ECEasyFormFieldType[];
  noPaddingX?: boolean;
  noPaddingY?: boolean;
  fullHeight?: boolean;
  checkNothingToUpdate?: boolean;
  checkEqualFields?: boolean;
  stickyFooter?: boolean;
  submissionConfirmationModal?: SubmissionConfirmationModal;
  drawerTitleBarBGColor?: string;
  shouldShowSubmitSubtitle?: boolean;
  customDrawerTitle?: string;
  showActivityButton?: boolean;
  activityPermissionScopes?: Array<any>;
  activityType?: ActivityType;
}

export const ECFancyFormEdit = forwardRef(
  (props: ECEasyFormEdit2Props<any>, ref) => {
    const { t } = useTranslation();
    const {
      row,
      config: initialConfig,
      fields: initialFields,
      detailsConfig,
      detailsFields,
      useUpdateMutation,
      useDeleteMutation,
      additionalDeleteAction,
      deleteConfirmationTitle,
      deleteConfirmationBody,
      additionalElements,
      onlyEdit,
      hideToolBar,
      errorOnLoading,
      isLoading,
      disclaimer,
      costTable,
      onEditClose,
      onClose,
      showEditButton = true,
      showCloseButton = true,
      showSaveButton = true,
      onChange,
      onEditButtonClick,
      showTitle = true,
      outsideData,
      noPaddingX,
      noPaddingY,
      fullHeight,
      checkNothingToUpdate = true,
      checkEqualFields = true,
      stickyFooter,
      submissionConfirmationModal,
      drawerTitleBarBGColor,
      shouldShowSubmitSubtitle,
      customDrawerTitle,
      showActivityButton,
      activityPermissionScopes = [],
      activityType,
    } = props;

    const dispatch = useDispatch();

    const { canEdit, canDelete } = useSelector(
      (state: RootState) => state.page.scopes,
    );

    const [config, setConfig] = useState<ECEasyFormConfigType>(initialConfig);
    const [fields, setFields] = useState<ECEasyFormFieldType[]>([]);
    const [doRevertChanges, setDoRevertChanges] = useState(false);

    const [editSelected, setEditSelected] = useState(onlyEdit);
    const [nothingToUpdate, setNothingToUpdate] = useState(false);
    const [showDeletePopover, setShowDeletePopover] = useState(false);

    const handleEditClick = () => {
      setEditSelected(!editSelected);
    };

    const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event?.currentTarget);
      setShowDeletePopover(!showDeletePopover);
    };

    const handleDeleteDeclineClick = () => {
      setShowDeletePopover(false);
      setAnchorEl(null);
    };

    const handleDeleteConfirmationClick = () => {
      setShowDeletePopover(false);
      setAnchorEl(null);
      handleDelete();
    };

    useEffect(() => {
      if (!editSelected) {
        onEditClose?.();
      }
      onEditButtonClick?.(editSelected);
    }, [editSelected, onEditClose]);

    useEffect(() => {
      setEditSelected(onlyEdit);
    }, [onlyEdit]);

    const initializeFields = useCallback(
      fieldsToUpdate => {
        return fieldsToUpdate.map(field => {
          if (field.type === FieldTypes.Checkbox) {
            return {
              ...field,
              value: field.overrideRowValue
                ? field.value
                : get(row, field.fieldName, field.value),
            };
          } else if (
            field.type === FieldTypes.Text &&
            (field.fieldName === 'status' || field.fieldName === 'active') &&
            field.readOnly
          ) {
            return {
              ...field,
              value:
                row[field.fieldName] === 1 || row[field.fieldName] === 'Active'
                  ? t('translation:switch.active')
                  : t('translation:switch.inactive'),
              variant: 'standard',
            };
          } else if (field.type === FieldTypes.Switch) {
            const switchValue = !!(field.dataPath
              ? get(row, field.dataPath, field.value)
              : row?.[field.fieldName]);
            return {
              ...field,
              value: field.overrideRowValue ? field.value : switchValue,
            };
          } else if (field.type === FieldTypes.Radio) {
            return {
              ...field,
              value: field.overrideRowValue
                ? field.value
                : field.options?.find(
                    option =>
                      option?.label === get(row, field.fieldName) ||
                      option?.id === get(row, field.fieldName) ||
                      option === get(row, field.fieldName),
                  ),
            };
          } else if (field.type === FieldTypes.ChipsList) {
            return {
              ...field,
              value: row[field.fieldName]?.map(fieldValue => fieldValue.name),
              chips: row[field.fieldName]?.map(fieldValue => fieldValue.name),
            };
          } else if (field.type === FieldTypes.ChipAutocomplete) {
            const { fieldName, dataPath, chipsNamePath } = field || {};
            return {
              ...field,
              value: field.overrideRowValue
                ? field.value
                : get(row, dataPath || fieldName)?.map(fieldValue => ({
                    label: fieldValue[`${chipsNamePath}`] || fieldValue,
                    color: fieldValue['color'],
                    id: fieldValue['id'],
                  })),
              chips: get(row, dataPath || fieldName)?.map(
                fieldValue => fieldValue[`${chipsNamePath}`] ?? fieldValue,
              ),
              variant: field.readOnly ? 'standard' : 'filled',
            };
          } else if (field.type === FieldTypes.Select) {
            return {
              ...field,
              value: field.overrideRowValue
                ? field.value
                : field.optionValues?.[
                    field.options.findIndex(option => {
                      if (
                        get(row, field.dataPath || field.fieldName) ===
                        undefined
                      ) {
                        return false;
                      }
                      return (
                        option?.label ===
                          get(row, field.dataPath || field.fieldName) ||
                        option ===
                          get(row, field.dataPath || field.fieldName) ||
                        option?.value ===
                          get(row, field.dataPath || field.fieldName)
                      );
                    })
                  ],
              variant: field.readOnly ? 'standard' : 'filled',
            };
          } else if (field.type === FieldTypes.Rank) {
            return {
              ...field,
              value: get(row, field.fieldName).map(fieldValue => ({
                ...fieldValue,
                data: fieldValue.name,
                position: fieldValue.position,
                adminOnly: fieldValue.adminOnly,
              })),
              variant: field.readOnly ? 'standard' : 'filled',
            };
          } else if (field.type === FieldTypes.CostTable) {
            return field;
          } else if (field.type === FieldTypes.Group) {
            return {
              ...field,
              subFields: initializeFields(field.subFields),
            };
          } else {
            return {
              ...field,
              value: field.overrideRowValue
                ? field.value
                : field.dataPath
                  ? get(row, field.dataPath, field.value)
                  : get(row, field.fieldName, field.value) ?? '',
              variant: field.readOnly ? 'standard' : 'filled',
            };
          }
        });
      },
      [row, t],
    );

    useEffect(() => {
      if (editSelected) {
        setConfig(initialConfig);
        setFields(initializeFields(initialFields));
      } else {
        if (detailsConfig) {
          setConfig(detailsConfig);
        }
        if (detailsFields) {
          setFields(initializeFields(detailsFields));
        }
      }
    }, [
      editSelected,
      doRevertChanges,
      initialFields,
      detailsFields,
      detailsConfig,
      initializeFields,
    ]);

    const [
      doUpdate,
      {
        isUninitialized: isUninitializedUpdate,
        isError: isUpdateError,
        error: updateError,
        isLoading: isLoadingUpdate,
        isSuccess: isUpdateSuccess,
        reset: resetMutation,
      },
    ] = useUpdateMutation?.(row) || [undefined, {}];

    const [
      doDelete,
      {
        isUninitialized: isUninitializedDelete,
        isSuccess: isDeleteSuccess,
        isError: isDeleteError,
        isLoading: isLoadingDelete,
        error: deleteError,
        reset: resetDelete,
      },
    ] = useDeleteMutation?.(row) || [undefined, {}];

    const handleDelete = () => {
      doDelete(row);
    };

    const handleCancelClick = () => {
      setDoRevertChanges(!doRevertChanges);
      onClose?.();
    };

    const handleField = useCallback(
      (field: ECEasyFormFieldType, updated: any) => {
        if (field.type === FieldTypes.Switch) {
          const switchFieldName =
            field.fieldName?.split('.')?.[0] ?? field.fieldName;
          const switchNestedFieldName = field.fieldName?.split('.')?.[1];
          if (switchNestedFieldName) {
            updated[switchFieldName] = {
              ...updated[switchFieldName],
              [switchNestedFieldName]: field.value ? 1 : 0,
            };
          } else {
            updated[field.fieldName] = field.value ? 1 : 0;
          }
        } else if (field.fieldName === 'status') {
          updated['status'] = field.value === 'Active' ? 1 : 0;
        } else {
          const fieldName = field.fieldName?.split('.')?.[0] ?? field.fieldName;
          const nestedFieldName = field.fieldName?.split('.')?.[1];

          if (
            (field.type === FieldTypes.Text ||
              field.type === FieldTypes.ChipAutocomplete ||
              field.type === FieldTypes.ChipsList ||
              field.type === FieldTypes.AddressAutocomplete ||
              field.type === FieldTypes.FilePicker ||
              field.type === FieldTypes.BannerFilePicker ||
              field.type === FieldTypes.ColorPicker ||
              field.type === FieldTypes.RichText ||
              field.type === FieldTypes.Video ||
              field.type === FieldTypes.ZipCode ||
              field.type === FieldTypes.Autocomplete) &&
            field.fieldName
          ) {
            if (nestedFieldName) {
              updated[fieldName] = {
                ...updated[fieldName],
                [nestedFieldName]: field.value,
              };
            } else {
              updated[fieldName] = field.value;
            }
            updated = set(updated, field.fieldName, field.value);
          } else if (field.type === FieldTypes.Country) {
            if (nestedFieldName) {
              updated[fieldName] = {
                ...updated[fieldName],
                [nestedFieldName]:
                  (field.value as Lookup)?.value || field?.value,
              };
            } else {
              updated[fieldName] =
                (field.value as Lookup)?.value || field?.value;
            }
          } else if (field.type === FieldTypes.Select) {
            updated = set(updated, fieldName, {
              ...updated[fieldName],
              id: field.value,
            }); // Kept this line to not break previous code that relies on the first part of the fieldName split
            updated = set(updated, field.fieldName, field.value);
          } else if (field.type === FieldTypes.Group) {
            field.subFields?.forEach(field => handleField(field, updated));
          } else if (field.type === FieldTypes.Currency) {
            updated[fieldName] = Number(field.value);
          } else if (field.type === FieldTypes.Phone) {
            if (nestedFieldName) {
              updated[fieldName] = {
                ...updated[fieldName],
                [nestedFieldName]: cleanedPhoneNumber(field.value?.toString()),
              };
            } else {
              updated[fieldName] = cleanedPhoneNumber(field.value?.toString());
            }
          } else {
            if (fieldName !== undefined) {
              if (nestedFieldName) {
                updated[fieldName] = {
                  ...updated[fieldName],
                  [nestedFieldName]: field.value,
                };
              } else {
                updated[fieldName] = field.value;
              }
            }
          }
        }
      },
      [],
    );

    const handleSubmit = (
      formData: FormData,
      fields: ECEasyFormFieldType[],
    ) => {
      const updated = {};

      fields.forEach(field => handleField(field, updated));
      let costTableChanged = false;

      if (costTable) {
        costTable.itemsRepair.forEach(item => {
          const changedItem = fields.find(field => field.label === item.label);
          if (!changedItem || !item.dataPath) return false;
          const isChanged = item.value !== changedItem.value;
          if (isChanged) {
            updated[item.dataPath] = changedItem.value;
            costTableChanged = true;
          }
        });
      }

      if (checkNothingToUpdate) {
        let isEqual = true;
        Object.keys(updated).forEach(key => {
          if (!isEqualWith(row[key], updated[key], equalCustomizer)) {
            isEqual = false;
          }
        });

        if (fields.length > 0 && isEqual && !costTableChanged) {
          setNothingToUpdate(true);
          return;
        } else {
          setNothingToUpdate(false);
        }
      }
      updated['id'] = row?.['id'];
      updated['nodeId'] = row?.['nodeId'];
      doUpdate(updated);
    };

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const id = showDeletePopover ? 'simple-popover' : undefined;

    useEffect(() => {
      if (isDeleteSuccess) {
        dispatch(
          setSnackbar({
            severity: 'success',
            message: config.name
              ? `${config.name} ${t('translation:dynamicForm.deleteSuccess')}`
              : t('translation:dynamicForm.deleteSuccess'),
          }),
        );
        onClose?.();
        resetDelete?.();
      }
    }, [isDeleteSuccess, onClose, resetDelete]);

    useEffect(() => {
      if (isUpdateSuccess) {
        dispatch(
          setSnackbar({
            severity: 'success',
            message: config.name
              ? `${startCase(config.name)} ${t('translation:dynamicForm.updateSuccess')}`
              : t('translation:dynamicForm.updateSuccess'),
          }),
        );
        onClose?.();
        resetMutation?.();
      }
    }, [isUpdateSuccess, dispatch, t, resetMutation]);

    useEffect(() => {
      if (isUpdateError) {
        const newFieldsState = addErrorToFieldsConfig(
          fields,
          (updateError as any)?.data?.fieldNameWithError,
          (updateError as any)?.data?.message,
        );

        const isFormDataError = (updateError as any)?.data?.fieldNameWithError;

        if (!isFormDataError) {
          dispatch(
            setSnackbar({
              severity: 'error',
              message: (updateError as any)?.data?.message,
            }),
          );
        }

        setFields(newFieldsState);
      } else if (isDeleteError) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: (deleteError as any)?.data?.message,
          }),
        );
      } else if (errorOnLoading) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: (errorOnLoading as any)?.data?.message,
          }),
        );
      } else if (nothingToUpdate) {
        dispatch(
          setSnackbar({
            severity: 'success',
            message: t('translation:dynamicForm.updateNothing'),
          }),
        );
        setNothingToUpdate(false);
      }
      resetMutation?.();
    }, [
      isUpdateError,
      isDeleteError,
      errorOnLoading,
      nothingToUpdate,
      updateError,
      deleteError,
      dispatch,
      t,
    ]);

    return (
      <ECEasyForm
        ref={ref}
        config={config}
        fields={fields}
        isSendingData={isLoadingUpdate || isLoadingUpdate}
        isLoadingForm={
          (isLoadingUpdate && isUninitializedUpdate) ||
          (isLoadingDelete && isUninitializedDelete) ||
          isLoading
        }
        customDrawerTitle={customDrawerTitle}
        drawerTitleBarBGColor={drawerTitleBarBGColor}
        disclaimer={disclaimer}
        onFormSubmit={handleSubmit}
        additionalActions={
          showSaveButton && (
            <ECButton
              type="button"
              variant="text"
              sx={{ height: 42 }}
              onClick={handleCancelClick}
            >
              {t('translation:dynamicForm.cancel')}
            </ECButton>
          )
        }
        showSaveButton={showSaveButton}
        additionalElements={additionalElements}
        isReadOnlyForm={!editSelected}
        additionalDeleteAction={additionalDeleteAction}
        toolbarChildren={
          <ECStack direction="row" spacing={1} alignItems="center">
            {showActivityButton && (
              <ECRequirePermission scopes={activityPermissionScopes}>
                <ECDrawerActivities activityType={activityType} />
              </ECRequirePermission>
            )}
            {showEditButton && (
              <ECRequirePermission scopes={canEdit ? [canEdit] : undefined}>
                <ECToggleButton
                  value="edit"
                  selected={editSelected}
                  onChange={handleEditClick}
                >
                  <Edit />
                </ECToggleButton>
              </ECRequirePermission>
            )}

            {doDelete && editSelected && (
              <>
                <ECIconButton
                  squared
                  onClick={handleDeleteClick}
                  scopes={canDelete ? [canDelete] : undefined}
                >
                  <Delete />
                </ECIconButton>
                <ECPopover
                  id={id}
                  anchorEl={anchorEl}
                  open={showDeletePopover}
                  onClose={handleDeleteDeclineClick}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <ECBox display="flex" flexDirection="column" p={2}>
                    <ECTypography variant="h6">
                      {deleteConfirmationTitle ||
                        t('translation:dynamicForm.deleteMessageTitle')}
                    </ECTypography>

                    <ECTypography variant="body1">
                      {deleteConfirmationBody ||
                        t('translation:dynamicForm.deleteMessageBody')}
                    </ECTypography>

                    <ECBox mt={2} display="flex" justifyContent="flex-end">
                      <ECButton
                        variant="text"
                        onClick={handleDeleteDeclineClick}
                      >
                        No
                      </ECButton>
                      <ECButton
                        variant="contained"
                        onClick={handleDeleteConfirmationClick}
                      >
                        Yes
                      </ECButton>
                    </ECBox>
                  </ECBox>
                </ECPopover>
              </>
            )}

            {showCloseButton && (
              <ECIconButton squared onClick={onClose}>
                <Close />
              </ECIconButton>
            )}
          </ECStack>
        }
        noPaddingX={noPaddingX}
        noPaddingY={noPaddingY}
        hideToolBar={hideToolBar}
        hideActions={!editSelected}
        costTable={costTable}
        onChange={onChange}
        showTitle={showTitle}
        outsideData={outsideData}
        fullHeight={fullHeight}
        stickyFooter={stickyFooter}
        submissionConfirmationModal={submissionConfirmationModal}
        shouldShowSubmitSubtitle={shouldShowSubmitSubtitle}
      />
    );
  },
);

export interface SearchConfigStateType {
  config: ECEasyFormConfigType;
  fields: ECEasyFormFieldType[];
}
interface ECSearchFormProps {
  open: boolean;
  cols: ECEasyTableColsProps[];
  onClose: () => void;
  filterEndPoint: string;
}
export function ECSearchForm(props: ECSearchFormProps) {
  const { open, cols, onClose, filterEndPoint } = props;
  const { t } = useTranslation();
  const location = useLocation();

  const { data: allSavedFilters } = useGetAllSavedFiltersQuery(filterEndPoint, {
    skip: !filterEndPoint,
  });

  const moduleName = useMemo(
    () => filterEndPoint.split('/')[filterEndPoint?.split('/')?.length - 1],
    [filterEndPoint],
  );

  const urlsToLoadFavoriteFilter = [
    '/panel/work-orders',
    '/panel/sp/work-orders',
    '/panel/invoices',
    '/panel/sp/invoices',
    '/panel/proposals',
    '/panel/sp/proposals',
  ];

  const [doDelete] = useDeleteFilterMutation(); // , { data, isSuccess, isError, error, isLoading }]
  const [doUpdateFavoriteFilter] = useUpdateFavoriteFilterMutation();

  const dispatch = useDispatch();
  const initialSearchConfigState = {
    config: {
      variant: '',
      title: 'New Filter',
      subtitle: '',
      cols: 1,
      submitTitle: 'Apply',
    },
    fields: [],
  };
  const [searchConfigState, setSearchConfigState] =
    useState<SearchConfigStateType>(initialSearchConfigState);
  const [maxWidth, setMaxWidth] = useState<Breakpoint>('md');

  useEffect(() => {
    const getGetSearchConfigStateFromApi = () => {
      const newSearchConfigState: SearchConfigStateType = cloneDeep(
        initialSearchConfigState,
      );
      if (Array.isArray(cols)) {
        let numOfSearchableFields = 0;
        cols.forEach(async field => {
          if (field.searchable) {
            numOfSearchableFields++;

            const fieldType = filterableFields(
              field!.type as FieldTypes,
              !!field.endpoint,
            );
            if (fieldType == null) return;

            const newSearchFormField: ECEasyFormFieldType = {
              type: fieldType,
              label: field.title ?? field.label,
              placeholder: field.title ?? field.label,
              fieldName: field.searchAlias || field.alias || field.fieldName,
              variant: 'standard',
              searchGroup: field.searchGroup,
              exactSearch: field.exactSearch,
              isViewId: field.isViewId,
            };
            if (field.endpoint) {
              const { data, isSuccess } = await store.dispatch(
                lookupApi.endpoints.getOptions.initiate(field.endpoint),
              );
              if (isSuccess && data.data) {
                newSearchFormField.options =
                  data.data.map(
                    (item: Lookup): AutoCompleteDropDownOption => ({
                      id: item.id,
                      label: item.name,
                    }),
                  ) ?? [];
              }
            }
            newSearchConfigState.fields.push(newSearchFormField);
          }
        });
        newSearchConfigState.config.cols = Math.min(numOfSearchableFields, 3);
        const searchDialogMaxWidthMap = {
          1: 'sm',
          2: 'md',
          3: 'lg',
        };
        const searchDialogMaxWidth =
          searchDialogMaxWidthMap[newSearchConfigState.config.cols] ?? 'md';
        setMaxWidth(searchDialogMaxWidth);
      }
      return newSearchConfigState;
    };
    setSearchConfigState(getGetSearchConfigStateFromApi());
  }, [cols]);

  const handleSubmit = (formData: FormData, fields: ECEasyFormFieldType[]) => {
    const searchFields: Filter[] = [];

    fields.forEach(field => {
      const searchField: Filter = {
        searchGroup: field.searchGroup,
        label: '',
        fieldName: '',
        value: '',
        type: FieldTypes.Text,
        exactSearch: field.exactSearch,
        isViewId: field.isViewId,
      };
      if (field.type === FieldTypes.Switch) {
        searchField.value = field.value === true ? 1 : 0;
      } else {
        if (
          (field.type === FieldTypes.Text ||
            field.type === FieldTypes.Select ||
            field.type === FieldTypes.ChipsList ||
            field.type === FieldTypes.Autocomplete ||
            field.type === FieldTypes.ChipAutocomplete ||
            field.type === FieldTypes.ColorPicker ||
            field.type === FieldTypes.Video ||
            field.type === FieldTypes.Rank) &&
          field.fieldName &&
          field.value
        ) {
          searchField.value = field.value;
        }
      }
      if (field.type !== FieldTypes.Section) {
        searchField.fieldName = field.fieldName;
        searchField.type = field.type;
        searchField.label = field.label ?? '';
        searchFields.push(searchField);
      }
    });
    const filters: Filter[] = searchFields.filter(
      ({ value }) => !isEmptyValue(value),
    );
    dispatch(
      setActiveFilter({
        filterFields: filters,
        name: '',
        isAdvanced: true,
        simpleSearchQuery: '',
      }),
    );
    onClose();
  };

  const favoriteSavedFilter = allSavedFilters?.find(
    savedFilter => savedFilter?.favorite,
  );

  useEffect(() => {
    if (
      !!favoriteSavedFilter &&
      favoriteSavedFilter?.module === moduleName &&
      urlsToLoadFavoriteFilter?.includes(location?.pathname)
    ) {
      dispatch(
        setActiveFilter({
          name: favoriteSavedFilter?.name,
          filterFields: favoriteSavedFilter?.body,
          isAdvanced: true,
        }),
      );
    }
  }, [favoriteSavedFilter, moduleName]);

  const handleCancelClick = useCallback(() => {
    const resetedFields = searchConfigState.fields.map(field => ({
      ...field,
      value: '',
    }));
    setSearchConfigState({ ...searchConfigState, fields: resetedFields });
    onClose();
  }, [searchConfigState, onClose]);

  return (
    <ECDialog
      open={open}
      container={() =>
        document.getElementsByTagName('main')[0] ?? document.body
      }
      sx={{
        position: 'absolute',
      }}
      onClose={onClose}
      fullWidth={true}
      maxWidth={maxWidth}
      PaperProps={{
        variant: 'outlined',
        sx: { alignSelf: 'start' },
      }}
    >
      <ECDialogContent>
        <ECEasyForm
          config={{
            ...searchConfigState.config,
            titleStyle: {
              fontSize: '20px',
            },
            actionButtonsContainerStyle: {
              bgcolor: theme => theme.palette.background.paper,
            },
          }}
          fields={searchConfigState.fields}
          onFormSubmit={handleSubmit}
          isSendingData={false}
          isLoadingForm={!cols}
          toolbarChildren={
            <ECIconButton squared onClick={onClose}>
              <Close />
            </ECIconButton>
          }
          additionalActions={
            <ECButton
              type="button"
              variant="text"
              sx={{ height: 42 }}
              onClick={handleCancelClick}
            >
              {t('translation:dynamicForm.cancel')}
            </ECButton>
          }
        />
        <ECDivider />
        <ECBox padding={'24px'}>
          <ECTypography sx={{ fontSize: '20px' }}>Saved Filters</ECTypography>

          {Array.isArray(allSavedFilters) && (
            <ECTableContainer>
              <ECTable size="small" aria-label="a dense table">
                <ECTableBody>
                  {allSavedFilters
                    ?.filter(filter =>
                      filter?.body?.every(body => !body.isTableLayout),
                    )
                    .map(({ name, body, favorite, _id }, index) => {
                      return (
                        <ECTableRow
                          key={`${index}-${_id}`}
                          hover={true}
                          sx={{
                            alignItems: 'center',
                            '&:hover': {
                              backgroundColor: theme =>
                                theme.palette.background.default,
                              cursor: 'pointer',
                            },
                          }}
                          onClick={() => {
                            dispatch(
                              setActiveFilter({
                                name,
                                filterFields: body,
                                isAdvanced: true,
                              }),
                            );
                            onClose();
                          }}
                        >
                          <ECTableCell sx={{ border: 0 }}>{name}</ECTableCell>
                          <ECTableCell sx={{ border: 0 }}>
                            {body?.map?.((t, index) => {
                              let chipLabel = '';
                              switch (t.type) {
                                case FieldTypes.Switch:
                                  chipLabel =
                                    Number(t.value) === 1
                                      ? 'Active'
                                      : 'Inactive';
                                  break;
                                case FieldTypes.Autocomplete:
                                  chipLabel = (
                                    t.value as AutoCompleteDropDownOption
                                  ).label;
                                  break;
                                default:
                                  chipLabel = t.value?.toString() || '';
                                  break;
                              }
                              return (
                                <ECChip
                                  variant="filled"
                                  label={`${t.label}: ${chipLabel}`}
                                  key={`tag ${index}`}
                                  color="Dark Blue"
                                  sx={{ ml: 1 }}
                                />
                              );
                            })}
                          </ECTableCell>
                          <ECTableCell sx={{ border: 0 }}>
                            <ECBox display="flex" gap={2}>
                              <ECIconButton
                                onClick={e => {
                                  e.stopPropagation();
                                  doDelete(`${filterEndPoint}/${_id}`);
                                }}
                              >
                                <DeleteForeverIcon
                                  fontSize="small"
                                  color="error"
                                />
                              </ECIconButton>

                              {favorite ? (
                                <ECIconButton
                                  onClick={e => {
                                    e.stopPropagation();
                                    doUpdateFavoriteFilter({
                                      endpoint: filterEndPoint,
                                      filterId: _id,
                                      isFavorite: false,
                                    });
                                  }}
                                >
                                  <Favorite
                                    fontSize="small"
                                    style={{ fill: 'black' }}
                                  />
                                </ECIconButton>
                              ) : (
                                <ECIconButton
                                  onClick={e => {
                                    e.stopPropagation();
                                    doUpdateFavoriteFilter({
                                      endpoint: filterEndPoint,
                                      filterId: _id,
                                      isFavorite: true,
                                    });
                                  }}
                                >
                                  <FavoriteBorder
                                    fontSize="small"
                                    style={{ fill: 'black' }}
                                  />
                                </ECIconButton>
                              )}
                            </ECBox>
                          </ECTableCell>
                        </ECTableRow>
                      );
                    })}
                </ECTableBody>
              </ECTable>
            </ECTableContainer>
          )}
        </ECBox>
      </ECDialogContent>
    </ECDialog>
  );
}
