import { VisibilityOff, Visibility } from '@mui/icons-material';
import { FormHelperText, InputAdornment, TextFieldProps } from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import {
  ECFormControl,
  ECIconButton,
  ECStack,
  ECTextField,
  FieldTypes,
} from 'app/components';
import CreditCardIcon from '@mui/icons-material/CreditCard';

interface Props extends Omit<TextFieldProps, 'onChange'> {
  type?: FieldTypes;
  label?: string;
  fieldName?: string;
  sx?: any;
  inputProps?: any;
  error?: boolean;
  onChange?: (value?: any) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: any;
  helperText?: string | null;
  isNumber?: boolean;
  isValid?: boolean;
  validationMessage?: string;
}

export const CreditCardNumberField = ({
  type,
  fieldName,
  label,
  inputProps,
  error,
  helperText,
  sx,
  value: valueFromProps,
  onChange,
  onBlur,
  required,
  isValid,
  validationMessage: validationMessageProps,
}: Props) => {
  const [value, setValue] = useState(valueFromProps);
  const [showPassword, setShowPassword] = useState(false);
  const [validationMessage, setValidationMessage] = useState<
    string | null | undefined
  >(helperText || validationMessageProps);

  useEffect(() => {
    setValue(valueFromProps);
  }, [valueFromProps]);

  useEffect(() => {
    setValidationMessage(validationMessageProps);
  }, [validationMessageProps]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value
        .replace(/\D/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim();

      onChange && onChange(inputValue);
      setValue(inputValue);
      if (inputValue?.length < 18)
        setValidationMessage('Incorrect card format');
      else setValidationMessage(null);
    },
    [onChange],
  );

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(event);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <ECFormControl variant="filled" sx={sx}>
      <ECTextField
        {...inputProps}
        variant="filled"
        inputMode="numeric"
        type={showPassword ? 'text' : 'password'}
        label={required ? `${label} *` : label}
        id={fieldName}
        name={fieldName}
        value={value?.toString()}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!validationMessage || !!helperText || !!isValid}
        helperText={validationMessage || helperText}
        inputProps={{
          inputMode: 'numeric',
          maxLength: 19,
          minLength: 18,
        }}
        placeholder="#### #### #### ####"
        InputLabelProps={value ? { shrink: true } : {}}
        InputProps={{
          ...inputProps,
          endAdornment: (
            <InputAdornment position="end">
              <ECStack direction="row" spacing={1} alignItems="center">
                <ECIconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </ECIconButton>
                <CreditCardIcon />
              </ECStack>
            </InputAdornment>
          ),
        }}
        sx={{
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
        }}
      />

      {error && helperText && (
        <FormHelperText
          sx={theme => ({
            bgcolor: 'transparent',
            color: theme.palette.error.main,
          })}
        >
          {helperText}
        </FormHelperText>
      )}
    </ECFormControl>
  );
};
