import React from 'react';
import { ECButton } from 'app/components/ECButton';
import { themes } from 'styles/theme/themes';

const statusColors = {
  error: {
    borderColor: themes.light.palette.error.outlinedRestingBackground,
    textColor: themes.light.palette.error.main,
  },
  warning: {
    borderColor: themes.light.palette.warning.outlinedRestingBackground,
    textColor: themes.light.palette.warning.light,
  },
  success: {
    borderColor: themes.light.palette.success.outlinedRestingBackground,
    textColor: themes.light.palette.success.main,
  },
  notSpecified: {
    borderColor: themes.light.palette.grey[600],
    textColor: themes.light.palette.grey[600],
  },
};

interface BookValue {
  percentage: string;
}

interface ECInsightsButtonProps {
  status?: BookValue;
  disabled?: boolean;
  onClick: () => void;
  icon?: React.ReactNode;
  title: string;
  sx?: any;
}

export const ECInsightsButton = (props: ECInsightsButtonProps) => {
  const { status, disabled, onClick, icon, title, sx } = props;

  const getStatusColor = (status: BookValue) => {
    const percentage = parseFloat(status?.percentage?.replace('%', ''));
    if (percentage <= 80) {
      return statusColors.success;
    } else if (percentage <= 100) {
      return statusColors.warning;
    } else {
      return statusColors.error;
    }
  };

  const color = status ? getStatusColor(status) : statusColors.notSpecified;

  return (
    <ECButton
      disabled={disabled}
      onClick={onClick}
      sx={{
        ...sx,
        margin: '0 1rem',
        borderColor: `${color.borderColor} !important`,
        color: `${color.textColor} !important`,
        textTransform: 'capitalize',
      }}
      startIcon={icon}
      variant="text"
    >
      {title}
    </ECButton>
  );
};
