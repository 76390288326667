export const Maestro = () => {
  return (
    <svg
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="0.5"
        width="34"
        height="23"
        rx="3.5"
        fill="white"
        stroke="#D9D9D9"
      />
      <path
        d="M20.5949 12.1269C20.5949 15.9393 17.5398 19.0298 13.7712 19.0298C10.0025 19.0298 6.94739 15.9393 6.94739 12.1269C6.94739 8.31445 10.0025 5.22388 13.7712 5.22388C17.5398 5.22388 20.5949 8.31445 20.5949 12.1269Z"
        fill="#ED0006"
      />
      <path
        d="M29.4474 12.1269C29.4474 15.9393 26.3923 19.0298 22.6236 19.0298C18.855 19.0298 15.7999 15.9393 15.7999 12.1269C15.7999 8.31445 18.855 5.22388 22.6236 5.22388C26.3923 5.22388 29.4474 8.31445 29.4474 12.1269Z"
        fill="#0099DF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.1974 6.87305C19.6646 8.13918 20.5949 10.0232 20.5949 12.127C20.5949 14.2308 19.6646 16.1148 18.1974 17.3809C16.7302 16.1148 15.7999 14.2308 15.7999 12.127C15.7999 10.0232 16.7302 8.13918 18.1974 6.87305Z"
        fill="#6C6BBD"
      />
    </svg>
  );
};
