import { useCallback } from 'react';

import { ECDynamicPageTemplate } from 'app/components';
import { useEffect, useState } from 'react';
import {
  useUpdateAssetTypeToRepairMutation,
  useDeleteAssetTypeToRepairMutation,
  useLazyGetAssetTypesToRepairListQuery,
} from 'services/assetTypeToRepairApi';
import { AssetTypeToRepairList } from 'types/AssetTypeToRepair';
import { loadPageScopes } from 'utils/pageScopes';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { useGetRepairsListQuery } from 'services/repairApi';
import { useGetAssetTypesListQuery } from 'services/assetTypeApi';
import { populateDropDownFields } from 'utils/pageUtils';

const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const formElementsDetails = require('./fancy_form_config_details.json');
const fancyFormElementsCreate = require('./fancy_form_config_create.json');

export function AssetTypeToRepairPage() {
  const { t } = useTranslation();
  const [row, setRow] = useState<AssetTypeToRepairList | null>();

  useEffect(() => {
    loadPageScopes('assetTypeRepair');
  }, []);

  const { data: repairs, isSuccess: isSuccessRepair } = useGetRepairsListQuery({
    t: 2000,
    ob: 'name',
    o: 'desc',
  });

  const { data: assetTypes, isSuccess: isSuccessAssetTypes } =
    useGetAssetTypesListQuery({ t: 1000 });

  // Dropdown lists on drawers
  useEffect(() => {
    if (repairs && isSuccessRepair) {
      let editSelectField = fancyFormElementsEdit.data.fields.find(
        field => field.fieldName === 'repairs',
      );
      editSelectField.options =
        repairs?.data.map(d => ({ label: d.name })) || [];
      editSelectField.optionValues = repairs?.data.map(d => d.name) || [];

      let createSelectField = fancyFormElementsCreate.data.fields.find(
        field => field.fieldName === 'repairs',
      );
      createSelectField.options =
        repairs?.data.map(d => ({ label: d.name })) || [];
      createSelectField.optionValues = repairs?.data.map(d => d.name) || [];
    }
  }, [repairs, isSuccessRepair]);

  useEffect(() => {
    if (assetTypes && isSuccessAssetTypes) {
      populateDropDownFields({
        responseData: assetTypes,
        createFormFields: fancyFormElementsCreate.data.fields,
        editFormFields: fancyFormElementsEdit.data.fields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'name',
        formFieldName: 'assetType.name',
      });
    }
  }, [assetTypes, isSuccessAssetTypes]);

  useEffect(() => {
    let assetTypeSelectField = fancyFormElementsEdit.data.fields.find(
      field => field.fieldName === 'name',
    );
    assetTypeSelectField.value = row?.name;
    assetTypeSelectField.options = [row?.name];
    assetTypeSelectField.optionValues = [row?.name];
  }, [row]);

  const handleRowClick = row => {
    setRow(row);
  };

  const editOnOpenHandler = useCallback(
    (selectedRow: AssetTypeToRepairList) => {
      return selectedRow.repairs.length === 0;
    },
    [],
  );

  const [
    doCreate,
    {
      data: createData,
      isError: isCreateError,
      error: createError,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      reset: updateReset,
    },
  ] = useUpdateAssetTypeToRepairMutation(row as any);

  const [
    doDelete,
    {
      data: deleteData,
      isError: isDeleteError,
      error: deleteError,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      reset: deleteReset,
    },
  ] = useDeleteAssetTypeToRepairMutation(row as any);

  const useUpdate = useCallback(() => {
    const doUpdate = async data => {
      const assetTypeId = assetTypes?.data.find(
        assetType => assetType.name === data?.assetType?.id,
      )?.id;

      const oldRepairsNames = _.map(row?.repairs, 'name');
      const updatedRepairsNames = _.map(data.repairs, 'label');

      const deletedRepairsNames = _.difference(
        oldRepairsNames,
        updatedRepairsNames,
      );

      const deletedRepairsIds = deletedRepairsNames?.map(
        repairName =>
          row?.repairs.find(repair => repairName === repair.name)?.id,
      );

      if (deletedRepairsIds.length > 0) {
        await doDelete({
          assetTypeId: row?.id || assetTypeId || 0,
          repairIds: _.compact(deletedRepairsIds),
        });
      }

      const newRepairsNames = _.difference(
        updatedRepairsNames,
        oldRepairsNames,
      );

      const newRepairsIds = newRepairsNames?.map(
        repairName =>
          repairs?.data?.find(repair => repairName === repair.name)?.id,
      );

      if (newRepairsIds.length > 0) {
        await doCreate({
          assetTypeId: row?.id || assetTypeId || 0,
          repairIds: _.compact(newRepairsIds),
        });
      }
    };

    return [
      doUpdate,
      {
        data: createData,
        isError: isCreateError || isDeleteError,
        error: createError || deleteError,
        isLoading: isCreateLoading || isDeleteLoading,
        isSuccess:
          (isCreateSuccess && isDeleteSuccess) ||
          (isCreateSuccess && (!isDeleteLoading || !isDeleteError)) ||
          (isDeleteSuccess && (!isCreateLoading || !isCreateError)),
        reset: updateReset,
      },
    ];
  }, [
    createData,
    createError,
    deleteError,
    doCreate,
    doDelete,
    isCreateError,
    isCreateLoading,
    isCreateSuccess,
    isDeleteError,
    isDeleteLoading,
    isDeleteSuccess,
    repairs?.data,
    row,
    updateReset,
    assetTypes?.data,
  ]);

  const useDeleteAll = useCallback(() => {
    const doDeleteAll = async () => {
      await doDelete({
        assetTypeId: row?.id || 0,
        repairIds: _.map(row?.repairs, 'id'),
      });
    };

    return [
      doDeleteAll,
      {
        data: deleteData,
        isError: isDeleteError,
        error: deleteError,
        isLoading: isDeleteLoading,
        isSuccess: isDeleteSuccess,
        reset: deleteReset,
      },
    ];
  }, [
    deleteData,
    deleteError,
    doDelete,
    isDeleteError,
    isDeleteLoading,
    isDeleteSuccess,
    row,
    deleteReset,
  ]);

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:pages.assetTypeToRepair.title')}
      useLazyGetListQuery={useLazyGetAssetTypesToRepairListQuery}
      useCreateMutation={useUpdate}
      useUpdateMutation={useUpdate}
      onDrawerClose={() => setRow(undefined)}
      useDeleteMutation={useDeleteAll}
      createFormConfig={fancyFormElementsCreate.data.config}
      createFormFields={fancyFormElementsCreate.data.fields}
      editFormConfig={fancyFormElementsEdit.data.config}
      editFormFields={fancyFormElementsEdit.data.fields}
      detailsConfig={formElementsDetails.data.config}
      detailsFields={formElementsDetails.data.fields}
      onRowClick={handleRowClick}
      marginTop={false}
      openDrawerOnEditMode={editOnOpenHandler}
      disclaimer={t('translation:pages.assetTypeToRepair.disclaimer')}
      exportModuleName="assetrepair"
      enableExport
      shouldNotUseActiveFilter
    />
  );
}
