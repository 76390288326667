import { ECAutocompleteStyled } from '../ECAutocomplete';
import { ECBox } from '../ECBox';
import { ECTextField } from '../ECTextField';
import { ECTypography } from '../ECTypography';
import { ECAutocompleteAssetItem } from '../ECAutocompleteAssetItem';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ECButton } from '../ECButton';
import { Close, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import * as _ from 'lodash';
import { ECCircularProgress } from '../ECCircularProgress';
import { Alert } from '@mui/material';
import { ECIconButton } from '../ECIconButton';
import { useGetAssetOpenWorkOrdersListQuery } from 'services/assetApi';
import { ECCollapse } from '../ECCollapse';
import { ECAssetWorkOrderCard } from '../ECAssetWorkOrderCard';

interface ECAutocompleteAssetProps {
  fieldName: string;
  value: any;
  disabled?: boolean;
  options: any[];
  isReadOnlyForm?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  variant?: any;
  sx?: any;
  isLoading?: boolean;
  noMoreDataToFetch?: boolean;
  onLoadMoreData?: (fieldName: string, newValue?: string) => void;
  onChange?: (newValue) => void;
}

const DEBOUNCE_TIME = 500;

export const ECAutocompleteAsset = ({
  fieldName,
  value,
  disabled,
  options,
  isReadOnlyForm,
  readOnly,
  placeholder,
  variant,
  isLoading,
  sx,
  noMoreDataToFetch,
  onLoadMoreData,
  onChange,
}: ECAutocompleteAssetProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenWo, setIsOpenWo] = useState(false);
  const [text, setText] = useState('');

  const {
    data: openWoData,
    isFetching: isFetchingOpenWo,
    refetch,
    isUninitialized,
  } = useGetAssetOpenWorkOrdersListQuery(
    {
      assetId: value?.id,
      generalAssetId: value?.isGeneralAsset ? value?.id : null,
      branchId: value?.branchId,
    },
    { skip: !value?.id || !value?.branchId },
  );

  useEffect(() => {
    if (value?.id && !isUninitialized) {
      refetch();
    }
    setIsOpenWo(false);
  }, [value?.id, refetch, isUninitialized]);

  useEffect(() => {
    setIsOpenWo(false);
  }, [value?.id]);

  const handleClick = asset => {
    onChange?.(asset);
    closeOptions();
  };

  const optionsWithLoading = useMemo(() => {
    if (!options?.length) {
      return [{ id: 'empty', name: 'No options' }];
    }

    return noMoreDataToFetch
      ? options
      : [...options, { id: 'loading', name: 'Loading...' }];
  }, [options, noMoreDataToFetch]);

  const renderAutocompleteAssetOption = useCallback(
    (props, option, state) => {
      if (option.id === 'empty') {
        return (
          <ECBox
            key={`asset-autocomplete-option-${option?.id}`}
            id={`asset-autocomplete-id-${option?.id}-${option?.name}`}
            bgcolor={theme => theme.palette.action.hover}
            height={42}
          >
            <ECBox display="flex" p={1}>
              <ECTypography variant="body1">No options</ECTypography>
            </ECBox>
          </ECBox>
        );
      }

      if (option.id === 'loading') {
        return (
          <ECBox
            key={`asset-autocomplete-option-${option?.id}`}
            id={`asset-autocomplete-id-${option?.id}-${option?.name}`}
            bgcolor={theme => theme.palette.action.hover}
            height={42}
          >
            <ECBox display="flex" justifyContent="center" p={1}>
              <ECCircularProgress size={20} />
            </ECBox>
          </ECBox>
        );
      }

      return (
        <ECBox
          data-name={option?.id === optionsWithLoading?.[0]?.id ? 'first' : ''}
          key={`asset-autocomplete-option-${option?.id}`}
          id={`asset-autocomplete-id-${option?.id}-${option?.name}`}
          bgcolor={theme => theme.palette.action.hover}
        >
          <ECAutocompleteAssetItem asset={option} onClick={handleClick} />
        </ECBox>
      );
    },
    [optionsWithLoading],
  );

  const openOptions = () => setIsOpen(true);
  const closeOptions = () => setIsOpen(false);

  const handleRemove = () => {
    onChange?.(null);
  };

  const debouncedHandleSearchChange = useRef(
    _.debounce((e, newValue) => {
      const optionEl = document.querySelector(`[data-name="first"]`);
      optionEl?.scrollIntoView();

      setText(newValue);
      onLoadMoreData?.(fieldName, newValue);
    }, DEBOUNCE_TIME),
  ).current;

  const openWoClick = useCallback(() => {
    setIsOpenWo(prev => !prev);
  }, []);

  if (!!value) {
    return (
      <ECBox display="flex" flexDirection="column">
        <ECTypography variant="h6" mb={1}>
          Asset
        </ECTypography>
        <ECBox
          display="fle3x"
          flexDirection="column"
          bgcolor={theme => theme.palette.action.hover}
          borderRadius={1}
        >
          {!readOnly && (
            <ECBox display="flex" justifyContent="flex-end" p={1}>
              <ECButton
                color="error"
                variant="text"
                startIcon={
                  <Close sx={theme => ({ color: theme.palette.error.dark })} />
                }
                onClick={handleRemove}
              >
                Remove
              </ECButton>
            </ECBox>
          )}

          <ECAutocompleteAssetItem asset={value} />
          {(value?.openWoCount > 0 || openWoData?.length > 0) && (
            <>
              <ECBox
                my={1}
                border={1}
                borderRadius={1}
                sx={theme => ({
                  width: '100%',
                  textAlign: 'center',
                  alignItems: 'center',
                  borderColor: theme.palette.warning.dark,
                })}
                display={'flex'}
              >
                <Alert
                  severity={'warning'}
                  sx={{
                    width: '100%',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                  }}
                  action={
                    <ECBox
                      display={'flex'}
                      sx={{ alignItems: 'center', height: '30px' }}
                    >
                      <ECTypography
                        variant="body2"
                        marginRight={'10px'}
                        fontWeight={'bold'}
                      >
                        VIEW
                      </ECTypography>

                      <ECIconButton size="medium" onClick={openWoClick}>
                        {!isOpenWo ? (
                          <KeyboardArrowDown
                            sx={theme => ({ color: theme.palette.grey[600] })}
                          />
                        ) : (
                          <KeyboardArrowUp
                            sx={theme => ({ color: theme.palette.grey[600] })}
                          />
                        )}
                      </ECIconButton>
                    </ECBox>
                  }
                >
                  <ECBox
                    display={'flex'}
                    sx={{
                      alignItems: 'center',
                      height: '30px',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <ECTypography
                      variant="body2"
                      margin={'0 5px'}
                      fontWeight="bold"
                    >
                      {value.openWoCount === 1 || openWoData?.length === 1
                        ? `This Asset has 1 open Work Order`
                        : `This Asset has ${value.openWoCount || openWoData?.length} open Work Orders`}
                    </ECTypography>
                  </ECBox>
                </Alert>
              </ECBox>
              <ECCollapse in={isOpenWo}>
                {isFetchingOpenWo ? (
                  <ECCircularProgress />
                ) : (
                  openWoData?.map(wo => (
                    <ECAssetWorkOrderCard workOrder={wo} key={wo.id} />
                  ))
                )}
              </ECCollapse>
            </>
          )}
        </ECBox>
      </ECBox>
    );
  }

  const handleChange = (event, value, reason) => {
    if (reason === 'clear') {
      onChange?.(null);
    }

    onChange?.(value);
  };

  return (
    <ECAutocompleteStyled
      id={fieldName}
      disablePortal
      filterOptions={x => x}
      sx={sx}
      value={value}
      disabled={disabled}
      options={optionsWithLoading}
      readOnly={isReadOnlyForm || readOnly}
      getOptionLabel={option => option?.name ?? ''}
      onInputChange={debouncedHandleSearchChange}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          if (
            listboxNode.scrollTop + listboxNode.clientHeight >=
              listboxNode.scrollHeight - 10 &&
            !noMoreDataToFetch &&
            !isLoading
          ) {
            onLoadMoreData?.(fieldName, text);
          }
        },
        role: 'list-box',
      }}
      renderInput={params => (
        <ECTextField
          {...params}
          placeholder={placeholder}
          label={placeholder}
          variant={variant}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <ECBox mb={1.5}>
                    <ECCircularProgress size={20} />
                  </ECBox>
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onOpen={openOptions}
      onClose={closeOptions}
      open={isOpen}
      renderOption={renderAutocompleteAssetOption}
      onChange={handleChange}
    />
  );
};
