import { styled } from '@mui/material/styles';
import { MenuItem, MenuItemProps, Select, SelectProps } from '@mui/material';
import { StyleConstants } from 'styles/StyleConstants';
import { Info } from '@mui/icons-material';
import React from 'react';
import { ECTooltipNoMaxWidth } from '../ECTooltip';

interface ECSelectProps extends SelectProps {
  hiddenLabel?: boolean;
  toolTip?: {
    content: string | React.ReactNode;
    icon?: React.ReactNode;
    placement?: 'top' | 'bottom';
  };
}

export const ECSelect = styled(({ ...props }: ECSelectProps) => (
  <Select
    {...props}
    endAdornment={
      props.toolTip && (
        <ECTooltipNoMaxWidth
          placement={props.toolTip.placement || 'top'}
          title={
            typeof props.toolTip.content === 'string' ? (
              props.toolTip.content
            ) : (
              <div>{props.toolTip.content}</div>
            )
          }
          arrow
        >
          {React.isValidElement(props.toolTip.icon) ? (
            props.toolTip.icon
          ) : (
            <Info
              sx={theme => ({
                color: theme.palette.action.active,
                cursor: 'pointer',
                position: 'absolute',
                right: '2.25rem',
                top: '50%',
                transform: 'translateY(-50%)',
              })}
            />
          )}
        </ECTooltipNoMaxWidth>
      )
    }
    MenuProps={{
      PaperProps: {
        style: {
          maxHeight: StyleConstants.DROPDOWN_MENU_MAX_HEIGHT,
          width: '350px',
        },
      },
      ...props.MenuProps,
    }}
  />
))(({ hiddenLabel }) => ({
  '.MuiSelect-iconFilled': {
    color: 'gray',
  },
  '.MuiSelect-filled': {
    color: 'black',
  },
  '.MuiFilledInput-input': {
    paddingTop: hiddenLabel ? 16 : 25,
    paddingBottom: hiddenLabel ? 16 : 8,
  },
}));

export const ECMenuItem = styled(MenuItem)<MenuItemProps>(() => ({
  '&:hover': {
    '& .hoverText': {
      display: 'block',
      backgroundColor: '#0090FF29',
      color: '#0072CE',
      borderRadius: '16px',
      padding: '4px 10px',

      '&:hover': {
        backgroundColor: '#0090FF40',
      },
    },
  },
}));

export const ECSelectWhiteBorders = styled(Select)<SelectProps>(
  ({ theme }) => ({
    select: {
      '&:before': {
        borderColor: theme.palette.background.default,
      },
      '&:after': {
        borderColor: theme.palette.background.default,
      },
      '&:focused': {
        borderColor: theme.palette.background.default,
      },
    },
    icon: {
      fill: theme.palette.background.default,
    },
  }),
);
