import {
  ECBox,
  ECButton,
  ECMenuItem,
  ECPopper,
  ECTable,
  ECTableBody,
  ECTableCell,
  ECTableContainer,
  ECTableHead,
  ECTableRow,
  ECTextField,
  ECTypography,
} from '..';
import AddIcon from '@mui/icons-material/Add';
import { useCallback, useEffect, useState } from 'react';
import { Popover } from '@mui/material';
import { ECAutocompleteStyled } from '../ECAutocomplete';
import {
  ECPurchaseOrderItem,
  PurchaseOrderItemValue,
} from './ECPurchaseOrderItem';
import { useGetPurchaseOrderItemsForDropdownQuery } from 'services/purchaseOrderApi';
import { currencyFormatter } from 'utils/strings/currency-formatter';

interface ECPurchaseOrderItemsProps {
  onChange?: (value: PurchaseOrderItemValue[]) => void;
  options?: any;
  value?: PurchaseOrderItemValue[];
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  errorMessage?: string;
}

export const ECPurchaseOrderItems: React.FC<ECPurchaseOrderItemsProps> = ({
  value = [],
  disabled,
  readOnly,
  errorMessage,
  onChange,
}) => {
  const { data: inventoryItems } = useGetPurchaseOrderItemsForDropdownQuery(
    {
      ob: 'name',
      o: 'a',
    },
    {
      skip: readOnly,
    },
  );

  const [items, setItems] = useState<PurchaseOrderItemValue[]>(value);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const filterOptionsIds = items?.map(item => item?.id);

  useEffect(() => {
    onChange?.(items);
  }, [items]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const renderPopper = useCallback(props => {
    return (
      <ECPopper
        {...props}
        sx={{
          transform: 'translate3d(8px, 53px, 0px) !important',
          minWidth: 'fit-content !important',
          mt: '8px !important',
          '& .MuiPopover-paper': {
            boxShadow: 'none',
          },
          '.MuiAutocomplete-listbox': {
            boxShadow: 'none',
          },
          '.MuiPaper-elevation1': {
            boxShadow: 'none',
          },
          '.MuiPaper-elevation': {
            boxShadow: 'none',
          },
        }}
      />
    );
  }, []);

  const total = items.reduce((acc, item) => {
    return acc + (item?.subTotal || 0);
  }, 0);

  if (readOnly) {
    return (
      <ECBox display="flex" flexDirection="column">
        <ECTableContainer>
          <ECTable>
            <ECTableHead>
              <ECTableRow>
                <ECTableCell>Item</ECTableCell>
                <ECTableCell>Part #</ECTableCell>
                <ECTableCell>Quantity</ECTableCell>
                <ECTableCell>Unit Type</ECTableCell>
                <ECTableCell>Current Cost</ECTableCell>
                <ECTableCell>Total</ECTableCell>
              </ECTableRow>
            </ECTableHead>
            <ECTableBody>
              {items.map((item, index) => (
                <ECTableRow key={`${item?.id}-${index}`}>
                  <ECTableCell>{item?.name}</ECTableCell>
                  <ECTableCell>{item?.partNumber}</ECTableCell>
                  <ECTableCell>{item?.quantity}</ECTableCell>
                  <ECTableCell>{item?.measurementUnit?.name}</ECTableCell>
                  <ECTableCell>{item?.unitCost}</ECTableCell>
                  <ECTableCell>{item?.subTotal}</ECTableCell>
                </ECTableRow>
              ))}
            </ECTableBody>
          </ECTable>
        </ECTableContainer>

        <ECBox
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mt={1}
          gap={2}
        >
          <ECTypography variant="caption">Subtotal:</ECTypography>
          <ECTypography variant="h6" fontWeight="bold">
            {currencyFormatter.format(total)}
          </ECTypography>
        </ECBox>
      </ECBox>
    );
  }

  return (
    <>
      <ECBox>
        <ECButton
          variant="text"
          startIcon={<AddIcon />}
          disabled={disabled}
          onClick={e => setAnchorEl(e.currentTarget)}
        >
          Add item
        </ECButton>
      </ECBox>

      {items.map((item, index) => (
        <ECBox key={item?.id || index} display="flex" my={1} width="100%">
          <ECPurchaseOrderItem
            key={index}
            value={item}
            filterOptionsIds={filterOptionsIds}
            onChange={value => {
              const newItems = [...items];
              newItems[index] = value;
              setItems(newItems);
            }}
            onDelete={() => {
              const newItems = [...items];
              newItems.splice(index, 1);
              setItems(newItems);
            }}
            errorMessage={errorMessage}
          />
        </ECBox>
      ))}

      <ECBox
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gap={2}
      >
        <ECTypography variant="caption">Subtotal:</ECTypography>
        <ECTypography variant="h6" fontWeight="bold">
          {currencyFormatter.format(total)}
        </ECTypography>
      </ECBox>

      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ECBox
          display="flex"
          flexDirection="column"
          height="470px"
          width="700px"
        >
          <ECAutocompleteStyled
            open
            disablePortal
            sx={{
              width: '100%',
              '&.MuiAutocomplete-popper': {
                transform: 'translate3d(8px, 53px, 0px) !important',
              },
            }}
            options={
              inventoryItems?.data?.filter(
                option => !filterOptionsIds.includes(option.id),
              ) || []
            }
            getOptionLabel={option => option?.name || ''}
            isOptionEqualToValue={(option, value) =>
              option?.id && value?.id
                ? option.id === value.id
                : option?.fieldName ===
                  (value?.fieldName || value?.name || value)
            }
            ListboxProps={{
              style: { maxHeight: '100vh', height: '375px' },
            }}
            renderInput={params => (
              <ECBox display="flex" padding={1} bgcolor="white" width="100%">
                <ECTextField
                  {...params}
                  placeholder="Search"
                  sx={{
                    minWidth: 'fit-content !important',
                    width: '100% !important',
                    padding: '0px !important',
                    '.MuiFilledInput-root': {
                      paddingRight: '0px !important',
                    },
                  }}
                  hiddenLabel
                  variant="filled"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <></>,
                  }}
                />
              </ECBox>
            )}
            renderTags={() => {
              return '';
            }}
            onChange={(event, value) => {
              setItems(prevItems => [...prevItems, value]);
              handleClose();
            }}
            PopperComponent={renderPopper}
            renderOption={(props, option, state) => {
              return (
                <ECMenuItem
                  {...props}
                  sx={{ width: '100%' }}
                  key={option?.id || option}
                  id={option?.id || option}
                >
                  <ECBox display="flex" flexDirection="column">
                    <ECTypography>{option?.name}</ECTypography>
                    <ECTypography variant="caption" color="text.secondary">
                      ID: {option?.viewId}
                    </ECTypography>
                  </ECBox>
                </ECMenuItem>
              );
            }}
          />
          {/* zIndex 1301 because MUI autocomplete component uses zIndex 1300 to render the options list and we want to show this button on top of it */}
          <ECBox
            position="absolute"
            bottom={0}
            zIndex={1301}
            width="100%"
            bgcolor={theme => theme.palette.common.white}
          >
            <ECButton
              variant="text"
              sx={{ width: '100%', justifyContent: 'flex-start' }}
              onClick={() => {
                setItems(prevItems => [...prevItems, { isNewItem: true }]);
                handleClose();
              }}
              startIcon={<AddIcon />}
            >
              Add item not in inventory
            </ECButton>
          </ECBox>
        </ECBox>
      </Popover>
    </>
  );
};
