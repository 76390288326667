import { useRef } from 'react';
import { ECIconButton, ECTypography } from '..';
import { ECBox } from '../ECBox';
import { Send } from '@mui/icons-material';
// import htmlToDraft from 'html-to-draftjs';
import parse from 'html-react-parser';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';
import { BoxProps, CircularProgress } from '@material-ui/core';
import { requirePermissionWrapper } from 'app/hoc/require-permission';
import { alpha } from '@material-ui/core';
import { themes } from 'styles/theme/themes';
import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuSelectHeading,
  RichTextEditor,
  MenuButtonBulletedList,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonStrikethrough,
  MenuButtonUnderline,
  MenuButtonUndo,
  // type RichTextEditorRef,
} from 'mui-tiptap';
import useExtensions from './useExtensions';

const defaultTheme = createTheme();

Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        fontFamily: themes.light.typography.fontFamily,
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        color: 'black',
      },
      container: {
        margin: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      toolbar: {
        flexShrink: 0,
      },
      placeHolder: {
        color: alpha('#000000', 0.6),
        position: 'static',
      },
      editor: {
        flexGrow: 1,
        maxWidth: window.innerWidth * 0.6,
        overflowY: 'auto',
        width: '100%',
      },
    },
  },
});

export interface ECRichTextFieldProps extends BoxProps {
  label?: string;
  placeholder?: string;
  onChange?: any;
  error?: boolean | null;
  helperText?: string | null;
  fieldName?: string;
  sx?: any;
  defaultValue?: string;
  isLoading?: boolean;
  isReadOnly?: boolean;
  readOnlyContent?: string;
  isSendButtonVisible?: boolean;
  onSend?: (text: string) => void;
  disableSendButton?: boolean;
  mentionSuggestionUsers?: any;
  moduleId?: number | string;
  mentionOnSelect?: (user: any) => void;
}

export function normalizeTextWithMention(text: string) {
  return text.replace(
    /<span data-type="mention" data-id="(\d+)" data-label="[^"]*">@[^<]*<\/span>/g,
    (_, id) => `@[${id}]`,
  );
}

export const RichTextField = ({
  label,
  placeholder,
  onChange,
  error,
  helperText,
  fieldName,
  defaultValue,
  isLoading,
  readOnlyContent,
  isReadOnly,
  isSendButtonVisible = true,
  onSend,
  disableSendButton = false,
  mentionSuggestionUsers = [],
  moduleId,
  mentionOnSelect,
  ...boxProps
}: ECRichTextFieldProps) => {
  const textFieldRef = useRef<any>();
  const editor = textFieldRef?.current?.editor;

  const handleSave = () => {
    const currentValue = editor?.getHTML();
    // need to normalize html before sending. It should replace any
    // <span data-type="mention" data-id="1522796" with @[1522796]
    const normalizedTextWithMention = normalizeTextWithMention(currentValue);
    onSend?.(normalizedTextWithMention);
    editor?.commands?.clearContent(true);
  };

  const handleChange = data => {
    if (onChange) {
      const htmlContent = data.editor.getHTML();
      onChange?.(htmlContent);
    }
  };

  const extensions = useExtensions({
    placeholder: 'Type here...',
    mentionSuggestionUsers,
    mentionOnSelect,
  });

  if (isReadOnly && readOnlyContent) {
    const content = readOnlyContent
      ?.replaceAll('&amp;', '&')
      ?.replaceAll('&amp', '&')
      ?.replaceAll('&lt;', '<')
      ?.replaceAll('&gt;', '>')
      ?.replaceAll('&quot;', '"')
      ?.replaceAll('\\', '')
      ?.replaceAll('<!DOCTYPE html>', '')
      ?.replace(/"{[\s\S]{1,400}}"/g, '""');

    // Regex to match and preserve existing <a> tags
    const existingATagRegex =
      /<a\s+(?:[^>]*?\s+)?href=['"]([^'"]+)['"][^>]*>(.*?)<\/a>/gi;

    // Extract and preserve the existing <a> tags
    let preservedLinks: string[] = [];

    let contentWithPlaceholders = content.replace(
      existingATagRegex,
      (match, href, text) => {
        preservedLinks.push(match);
        return `##LINK_PLACEHOLDER_${preservedLinks.length - 1}##`;
      },
    );

    // Regex to find URLs that are not part of an <a> tag
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    let parsedContent = contentWithPlaceholders.replace(
      urlRegex,
      '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>',
    );

    // Restore preserved <a> tags, ensuring href starts with http or https
    parsedContent = parsedContent.replace(
      /##LINK_PLACEHOLDER_(\d+)##/g,
      (match, index) => {
        let restoredLink = preservedLinks[parseInt(index)];

        // Extract the href value from the restored link
        const hrefMatch = restoredLink.match(/href=['"]([^'"]+)['"]/i);
        if (hrefMatch) {
          let hrefValue = hrefMatch[1];

          // If the href does not start with http or https, prepend http://
          if (!/^https?:\/\//i.test(hrefValue)) {
            hrefValue = `http://${hrefValue}`;
            // Replace the href value in the original link
            restoredLink = restoredLink.replace(hrefMatch[1], hrefValue);
          }
        }
        return restoredLink;
      },
    );

    return (
      <ECBox
        bgcolor={theme => theme.palette.background.default}
        borderRadius={2}
      >
        <ECTypography variant="body1">{parse(parsedContent)}</ECTypography>
      </ECBox>
    );
  }

  return (
    <ECBox>
      {label && (
        <ECTypography
          variant="caption"
          color={theme => theme.palette.text.disabled}
        >
          {label}
        </ECTypography>
      )}
      <ECBox
        border={1}
        borderRadius={1}
        bgcolor={theme => theme.palette.background.default}
        p={1}
        sx={theme => ({ borderColor: theme.palette.common.black })}
        {...boxProps}
      >
        <ECBox display="flex" justifyContent="space-between">
          <ThemeProvider theme={defaultTheme}>
            <ECBox
              width={'100%'}
              sx={{
                '.MuiTiptap-MenuBar-root': {
                  border: 'none',
                  boxShadow: 'none',
                },
                '.MuiTiptap-RichTextField-content': {
                  padding: '0px 0px 12px 0px !important',
                },
                '.MuiTiptap-RichTextContent-root': {
                  cursor: 'text !important',
                },
                '.MuiTiptap-FieldContainer-root': {
                  border: 'none',
                  boxShadow: 'none',
                  position: 'inherit',
                },
                '.MuiCollapse-wrapperInner': {
                  padding: 0,
                  border: 'none',
                  boxShadow: 'none',
                },
                '.MuiTiptap-FieldContainer-notchedOutline': {
                  border: 'none',
                  boxShadow: 'none',
                },
              }}
            >
              <RichTextEditor
                key={`${moduleId}-${mentionSuggestionUsers?.length}`}
                ref={textFieldRef}
                // line below is because we need to remount the editor when the mentionSuggestionUsers change
                // line below guarantees what the user wrote is not lost
                content={editor?.getHTML() || defaultValue}
                extensions={extensions}
                onUpdate={handleChange}
                renderControls={() => (
                  <MenuControlsContainer>
                    <MenuSelectHeading
                      labels={{
                        empty: 'Change to…',
                        paragraph: 'Normal text',
                        heading1: 'H1',
                        heading2: 'H2',
                        heading3: 'H3',
                        heading4: 'H4',
                        heading5: 'H5',
                        heading6: 'H6',
                      }}
                    />
                    <MenuButtonBold />
                    <MenuButtonItalic />
                    <MenuButtonUnderline />
                    <MenuButtonStrikethrough />
                    <MenuButtonOrderedList />
                    <MenuButtonBulletedList />
                    <MenuButtonUndo />
                    <MenuButtonRedo />
                  </MenuControlsContainer>
                )}
              />
            </ECBox>
          </ThemeProvider>

          {isSendButtonVisible && (
            <ECBox display="flex" alignItems="start">
              <ECBox
                border={1}
                borderRadius={1}
                mx={2}
                my={1}
                sx={{ borderColor: 'primary.main' }}
              >
                <ECIconButton
                  color="primary"
                  onClick={handleSave}
                  size="large"
                  disabled={disableSendButton}
                >
                  {isLoading ? <CircularProgress size={24} /> : <Send />}
                </ECIconButton>
              </ECBox>
            </ECBox>
          )}
        </ECBox>
      </ECBox>
    </ECBox>
  );
};

export const ECRichTextField =
  requirePermissionWrapper<ECRichTextFieldProps>(RichTextField);
