import { Link } from '@mui/material';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { ECLink } from '../ECLink';

export const ECFooter = () => {
  return (
    <ECBox display="flex" flexDirection="column" p={4} pb={2} gap={4} mt={3}>
      <ECTypography variant="body2" align="center">
        By registering on Ecotrak, you are agreeing to <br />
        <Link href="https://ecotrak.com/serviceproviderusagefees/">
          Privacy Policy Terms
        </Link>
        .
      </ECTypography>
      <ECTypography
        variant={'caption'}
        align="center"
        color={theme => theme.palette.text.secondary}
      >
        {`© ${new Date().getFullYear()}, `}
        <ECLink color="inherit" href="https://ecotrak.com">
          Ecotrak, LLC
        </ECLink>
        {'| Ecotrak.com'}
      </ECTypography>
    </ECBox>
  );
};
