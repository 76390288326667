import { useForgotPasswordMutation } from 'services/authApi';
import { useTranslation } from 'react-i18next';

import {
  ECEasyForm,
  FieldTypes,
  ECAlertError,
  ECAlertSuccess,
  ECBox,
} from 'app/components';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { useEffect } from 'react';

export default function SendLinkForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const mustResetPassword = queryParams.get('mustResetPassword') === 'true';

  const [forgotPassword, { isError, error, isLoading, isSuccess }] =
    useForgotPasswordMutation();

  useEffect(() => {
    if (mustResetPassword) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message: 'Please change your password.',
        }),
      );
    }
  }, [mustResetPassword, dispatch]);

  return (
    <>
      <ECEasyForm
        pattern="standard"
        fullHeight={false}
        config={{
          variant: 'standard',
          title: 'Reset Password',
          subtitle: 'We will send an email with a link to reset your password',
          cols: 1,
          submitTitle: 'Reset Password',
        }}
        fields={[
          {
            type: FieldTypes.Text,
            label: 'Email',
            placeholder: 'Email',
            value: null,
            isNumeric: false,
            required: true,
            maxLength: 50,
            minLength: 5,
            fieldName: 'email',
            checkEmail: true,
          },
        ]}
        isSendingData={isLoading}
        isLoadingForm={false}
        onFormSubmit={formData => {
          const email = formData.get('email');
          forgotPassword({
            email: `${email}`?.toLowerCase(),
          });
        }}
      />
      {isSuccess && (
        <ECAlertSuccess>
          <ECBox>
            <ECBox>{t('resetAndUpdatePassword.success.sentCode')}</ECBox>
          </ECBox>
        </ECAlertSuccess>
      )}
      {isError && (
        <ECAlertError>
          {' '}
          {(error as any)?.data?.message ||
            'Failed to send the code. Please try again'}{' '}
        </ECAlertError>
      )}{' '}
    </>
  );
}
