import { Delete } from '@mui/icons-material';
import { ECBox, ECButton, ECGrid, ECImage, ECTypography } from 'app/components';
import EmaHelloImage from './assets/Ema_hello.png';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const FeatureList = ({ features }: { features: string[] }) => {
  return (
    <ECBox>
      {features.map((feature, index) => (
        <ECTypography
          key={index}
          sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}
        >
          <CheckCircleIcon sx={{ marginRight: 1 }} />
          {feature}
        </ECTypography>
      ))}
    </ECBox>
  );
};

const SubscriptionPlanCard = ({ subscription }) => {
  return (
    <ECBox sx={{ paddingLeft: 2, paddingRight: 2, marginTop: -5 }}>
      {/* Header Section */}
      <ECGrid container alignItems="center">
        <ECGrid item xs={9}>
          <ECTypography variant="h6">Subscription Plan</ECTypography>
        </ECGrid>
        <ECGrid
          item
          xs={3}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <ECButton
            variant="text"
            color="error"
            startIcon={<Delete color="error" />}
            onClick={() => {}}
          >
            Cancel Subscription
          </ECButton>
        </ECGrid>
      </ECGrid>

      {/* Content Section */}
      <ECBox
        sx={{
          borderRadius: 5,
          backgroundColor: '#000',
          color: '#fff',
          padding: 4,
        }}
      >
        <ECGrid container alignItems="center" spacing={2}>
          {/* Image Section */}
          <ECGrid item xs={2}>
            <ECImage
              src={EmaHelloImage}
              alt="Ema Hello"
              sx={{ height: '150px', width: '150px' }}
            />
          </ECGrid>

          {/* Subscription Info */}
          <ECGrid item xs={3}>
            <ECBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                height: '100%',
              }}
            >
              <ECTypography variant="h5" sx={{ fontWeight: 'bold' }}>
                Ecotrak Build
              </ECTypography>
              {subscription && Object.keys(subscription).length > 0 ? (
                <>
                  <ECTypography
                    variant="h4"
                    sx={{ fontWeight: 'bold', display: 'inline', marginTop: 2 }}
                  >
                    ${subscription.payPerPeriod}/
                    <ECTypography
                      variant="h5"
                      component="span"
                      sx={{ fontWeight: 'normal', marginLeft: 0.5 }}
                    >
                      mo
                    </ECTypography>
                  </ECTypography>
                  <ECTypography sx={{ marginTop: 1 }}>
                    Subscription Date:{' '}
                    <ECTypography component="span" sx={{ fontWeight: 'bold' }}>
                      {subscription.startTime
                        ? moment(
                            subscription.startTime?.split('T')?.[0],
                          ).format('MMM DD, YYYY')
                        : ''}
                    </ECTypography>
                  </ECTypography>
                  <ECTypography sx={{ marginTop: 1 }}>
                    Next Charge Date:{' '}
                    <ECTypography component="span" sx={{ fontWeight: 'bold' }}>
                      {subscription?.nextPayment
                        ? moment(
                            subscription?.nextPayment?.split('T')?.[0],
                          ).format('MMM DD, YYYY')
                        : ''}
                    </ECTypography>
                  </ECTypography>
                </>
              ) : (
                <ECTypography
                  sx={{
                    height: '50px',
                    marginTop: 2,
                    fontStyle: 'italic',
                    color: 'gray',
                  }}
                >
                  No subscription details available
                </ECTypography>
              )}
            </ECBox>
          </ECGrid>

          {/* Features Section (Left) */}
          <ECGrid item xs={3.5}>
            <FeatureList
              features={[
                'All-in-One Command Center: Stay on top of work orders — all from one easy-to-use dashboard.',
                'Streamlined Workflows: Crush maintenance tasks to save time and headaches.',
              ]}
            />
          </ECGrid>

          {/* Features Section (Right) */}
          <ECGrid item xs={3.5}>
            <FeatureList
              features={[
                'Multi-Site Mastery: Effortlessly manage all your locations without breaking a sweat.',
                'Real-Time Support: Get answers fast and keep things moving.',
              ]}
            />
          </ECGrid>
        </ECGrid>
      </ECBox>
    </ECBox>
  );
};

export default SubscriptionPlanCard;
