import { DashboardTableData } from 'types';
import { BaseType } from 'types/BaseType';
import { InvoiceResponse } from 'types/Invoice';
import { ProposalDetails } from 'types/Proposal';
import { QueryParams } from 'types/QueryParams';
import { StatusGroupNames } from 'types/StatusGroups';
import {
  ApprovalProcessAction,
  BulkApprovalStatusUpdateAction,
} from 'types/WorkflowStatus';
import { WorkOrdersList } from 'types/WorkOrders';
import daysPassedSince from 'utils/datediff';
import { currencyFormatter } from 'utils/strings/currency-formatter';
import sumCurrencyFields from 'utils/sum-currency-fields';

import { emptyApi } from './emptyApi';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: [
    'UnassignedWorkorders',
    'PendingWorkorders',
    'PendingProposals',
    'PendingInvoices',
    'AwaitingInvoice',
  ],
});

//TODO: BE could inform if a group name doesn't exist;
export const dashboardApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getUnassignedWorkOrders: builder.query<DashboardTableData, QueryParams>({
      query: ({ p, t, cat }) => ({
        url: 'workorder/status/data',
        params: {
          t: t || 5,
          p: p || 0,
          gn: StatusGroupNames.Unassigned,
          cat,
          assetTypeAssignedToMe: 1,
        },
      }),
      providesTags: ['UnassignedWorkorders'],
      transformResponse: (response: BaseType<WorkOrdersList[]>) => {
        return {
          title: 'Work Orders Unassigned',
          module: 'workorder',
          moduleLink: 'workOrderID',
          totalCount: response.config.pagination.totalCount,
          cols: ['age', 'workOrderID', 'location', 'asset', 'category'],
          data: response.data.map(entry => ({
            requestId: entry.requestId,
            id: entry.id,
            rowId: entry.id,
            age: daysPassedSince(entry.createdAt),
            workOrderID: entry.viewId,
            location: entry.request?.asset?.branch?.name,
            asset: entry.request?.asset?.name,
            category: entry.request?.category?.name,
            canReassign: entry.canReassign,
          })),
          additionalData: response.data.map(entry => ({
            tradeId: entry.request?.tradeId,
            branchId: entry.request?.asset?.branch?.id,
          })),
        };
      },
    }),
    getPendingWorkOrders: builder.query<DashboardTableData, QueryParams>({
      query: ({ p, t, cat }) => ({
        url: 'workorder/status/data',
        params: {
          t: t || 5,
          p: p || 0,
          gn: StatusGroupNames.Pending,
          cat,
          assetTypeAssignedToMe: 1,
        },
      }),
      providesTags: ['PendingWorkorders'],
      transformResponse: (response: BaseType<WorkOrdersList[]>) => {
        return {
          title: 'Work Orders - Pending Acceptance',
          module: 'workorder',
          moduleLink: 'workOrderID',
          totalCount: response.config.pagination.totalCount,
          cols: [
            'age',
            'workOrderID',
            'location',
            'category',
            'serviceProvider',
          ],
          data: response.data.map(entry => ({
            requestId: entry.requestId,
            id: entry.id,
            rowId: entry.id,
            age: daysPassedSince(entry.createdAt),
            workOrderID: entry.viewId,
            location: entry.request?.asset?.branch?.name,
            category: entry.request?.category?.name,
            asset: entry.request?.asset?.name,
            serviceProvider: entry.serviceProvider?.name,
            canReassign: entry.canReassign,
          })),
          additionalData: response.data.map(entry => ({
            tradeId: entry.request?.tradeId,
            branchId: entry.request?.asset?.branch?.id,
          })),
        };
      },
    }),
    getWorkordersAwaitingInvoice: builder.query<
      DashboardTableData,
      QueryParams
    >({
      query: ({ p, t, cat }) => ({
        url: 'workorder/status/data',
        params: {
          t: t || 5,
          p: p || 0,
          // awaiting invoice status is deprecated
          // we now check completed WO with no invoice
          gn: StatusGroupNames.SoftCompleted,
          cat,
          assetTypeAssignedToMe: 1,
        },
      }),
      providesTags: ['AwaitingInvoice'],
      transformResponse: (response: BaseType<WorkOrdersList[]>) => {
        return {
          title: 'Work Orders - Awaiting Invoice',
          module: 'workorder',
          moduleLink: 'workOrderID',
          totalCount: response.config.pagination.totalCount,
          cols: ['age', 'workOrderID', 'location', 'asset', 'category'],
          data: response.data.map(entry => ({
            requestId: entry.requestId,
            id: entry.id,
            rowId: entry.id,
            age: entry.completedDate
              ? daysPassedSince(entry.completedDate)
              : '-',
            workOrderID: entry.viewId,
            location: entry.request?.asset?.branch?.name,
            asset: entry.request?.asset?.name,
            category: entry.request?.category?.name,
          })),
          additionalData: response.data.map(entry => ({
            tradeId: entry.request?.tradeId,
            branchId: entry.request?.asset?.branch?.id,
          })),
        };
      },
    }),

    getUnassignedWorkOrdersCount: builder.query<number, QueryParams>({
      query: ({ p, t, cat }) => ({
        url: 'workorder/status/count',
        params: {
          t: t || 5,
          p: p || 0,
          gn: StatusGroupNames.Unassigned,
          cat,
          assetTypeAssignedToMe: 1,
        },
      }),
      providesTags: ['UnassignedWorkorders'],
    }),
    getPendingWorkOrdersCount: builder.query<number, QueryParams>({
      query: ({ p, t, cat }) => ({
        url: 'workorder/status/count',
        params: {
          t: t || 5,
          p: p || 0,
          gn: StatusGroupNames.Pending,
          cat,
          assetTypeAssignedToMe: 1,
        },
      }),
      providesTags: ['PendingWorkorders'],
    }),
    getWorkordersAwaitingInvoiceCount: builder.query<number, QueryParams>({
      query: ({ p, t, cat }) => ({
        url: 'workorder/status/count',
        params: {
          t: t || 5,
          p: p || 0,
          // awaiting invoice status is deprecated
          // we now check completed WO with no invoice
          gn: StatusGroupNames.SoftCompleted,
          cat,
          assetTypeAssignedToMe: 1,
        },
      }),
      providesTags: ['AwaitingInvoice'],
    }),

    getPendingProposals: builder.query<DashboardTableData, QueryParams>({
      query: ({ p, t, cat, assignedToMe }) => ({
        url: 'proposal/status/data',
        params: {
          t: t || 5,
          p: p || 0,
          gn: StatusGroupNames.Pending,
          cat,
          assignedToMe,
          assetTypeAssignedToMe: 1,
        },
      }),
      providesTags: ['PendingProposals'],
      transformResponse: (response: BaseType<ProposalDetails[]>) => {
        return {
          title: 'Proposals - Pending Approval',
          module: 'proposal',
          moduleLink: 'proposalID',
          totalCount: response.config.pagination.totalCount,
          cols: ['age', 'proposalID', 'location', 'asset', 'proposalAmount'],
          data: response.data.map(entry => ({
            requestId: entry.requestId,
            id: entry.id,
            rowId: entry.id,
            age: daysPassedSince(entry.createdAt),
            proposalID: entry.viewId,
            location: entry.request?.asset?.branch?.name,
            asset: entry.request?.asset?.name,
            proposalAmount: currencyFormatter.format(
              sumCurrencyFields(
                entry.tax,
                entry.other,
                entry.labor,
                entry.freight,
                entry.material,
              ),
            ),
          })),
        };
      },
    }),
    getPendingProposalsCount: builder.query<number, QueryParams>({
      query: ({ p, t, cat, assignedToMe }) => ({
        url: 'proposal/status/count',
        params: {
          t: t || 5,
          p: p || 0,
          gn: StatusGroupNames.Pending,
          cat,
          assignedToMe,
          assetTypeAssignedToMe: 1,
        },
      }),
      providesTags: ['PendingProposals'],
    }),

    getPendingInvoices: builder.query<DashboardTableData, QueryParams>({
      query: ({ p, t, cat, assignedToMe }) => ({
        url: 'invoice/status/data',
        params: {
          t: t || 5,
          p: p || 0,
          gn: StatusGroupNames.Pending,
          cat,
          assignedToMe,
          assetTypeAssignedToMe: 1,
        },
      }),
      providesTags: ['PendingInvoices'],
      transformResponse: (response: BaseType<InvoiceResponse[]>) => {
        return {
          title: 'Invoices - Pending Approval',
          module: 'invoice',
          moduleLink: 'invoiceID',
          totalCount: response.config.pagination.totalCount,
          cols: ['age', 'invoiceID', 'location', 'invoiceAmount', 'asset'],
          data: response.data.map(entry => ({
            requestId: entry.requestId,
            id: entry.id,
            rowId: entry.id,
            age: daysPassedSince(entry.createdAt),
            invoiceID: entry.viewId,
            location: entry.request?.asset?.branch?.name,
            invoiceAmount: currencyFormatter.format(
              sumCurrencyFields(
                entry.costFreight,
                entry.costLabor,
                entry.costMaterial,
                entry.costOther,
                entry.costTax,
              ),
            ),
            asset: entry.request?.asset?.name,
          })),
        };
      },
    }),
    getPendingInvoicesCount: builder.query<number, QueryParams>({
      query: ({ p, t, cat, assignedToMe }) => ({
        url: 'invoice/status/count',
        params: {
          t: t || 5,
          p: p || 0,
          gn: StatusGroupNames.Pending,
          cat,
          assignedToMe,
          assetTypeAssignedToMe: 1,
        },
      }),
      providesTags: ['PendingInvoices'],
    }),

    // WF Status bulk change
    updateMultipleStatus: builder.mutation<
      any,
      { module: string; statusUpdateList: BulkApprovalStatusUpdateAction }
    >({
      query: ({ module, statusUpdateList }) => ({
        url: `${module}/status/change`,
        method: 'put',
        body: statusUpdateList,
      }),
      invalidatesTags: [
        'AwaitingInvoice',
        'PendingWorkorders',
        'UnassignedWorkorders',
      ],
    }),
    // WF Approve/Reject bulk change
    updateMultipleApprovalProcess: builder.mutation<
      any,
      { module: string; statusUpdateList: ApprovalProcessAction[] }
    >({
      query: ({ module, statusUpdateList }) => ({
        url: `${module}/apply/action`,
        method: 'put',
        body: statusUpdateList,
      }),
    }),
  }),
});

export const {
  useGetUnassignedWorkOrdersQuery,
  useGetPendingWorkOrdersQuery,
  useGetWorkordersAwaitingInvoiceQuery,
  useGetPendingWorkOrdersCountQuery,
  useGetUnassignedWorkOrdersCountQuery,
  useGetWorkordersAwaitingInvoiceCountQuery,

  useGetPendingInvoicesQuery,
  useGetPendingInvoicesCountQuery,

  useGetPendingProposalsQuery,
  useGetPendingProposalsCountQuery,

  useUpdateMultipleStatusMutation,
  useUpdateMultipleApprovalProcessMutation,
} = dashboardApi;
