import { Close } from '@mui/icons-material';
import {
  ECBox,
  ECEasyForm,
  ECIconButton,
  ECImage,
  ECTypography,
  FieldTypes,
} from 'app/components';
import { ECModal } from 'app/components/ECModal';
import { useGetSupplierByIdQuery } from 'services/supplierApi';
import { DocumentCoreTypes } from 'types/Attachment';
import { Supplier, Contact } from 'types/Supplier';
import { buildAddress } from 'utils/address';
import { formatPhone } from 'utils/strings/phone';

interface Props {
  data?: Supplier;
  id?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const SupplierInfoModal = ({
  data: supplierFromProp,
  id,
  isOpen,
  onClose,
}: Props) => {
  const { data: supplierFromQuery, isLoading: isLoadingSupplier } =
    useGetSupplierByIdQuery(Number(id), {
      skip: !!supplierFromProp || !id,
    });

  const supplier = supplierFromProp || supplierFromQuery;

  const supplierLogoUrl = supplier?.attachments?.find(
    attachment =>
      attachment?.documentCoreType?.code ===
      DocumentCoreTypes.SupplierProfilePhoto,
  )?.thumbnailURL;

  return (
    <ECModal isOpen={isOpen} onClose={onClose} noPadding>
      <ECBox
        bgcolor={theme => theme.palette.common.white}
        sx={{
          position: 'relative',
          borderRadius: '8px',
        }}
      >
        <ECIconButton
          onClick={onClose}
          withBorder
          style={{ position: 'absolute', top: 10, right: 10 }}
        >
          <Close />
        </ECIconButton>

        <ECBox
          display="flex"
          position="absolute"
          top={10}
          left={10}
          alignItems="center"
        >
          <ECImage
            sx={{
              width: '100px',
              height: '50px',
              objectFit: 'cover',
              mr: 2,
            }}
            alt="supplier logo"
            src={supplierLogoUrl}
          />

          <ECTypography fontWeight="bold">{supplier?.name}</ECTypography>
        </ECBox>

        <ECBox
          display="flex"
          flexDirection="column"
          sx={{
            minWidth: '600px',
            width: '33vw',
          }}
          p={1}
        >
          <ECEasyForm
            pattern="filled"
            fullHeight={false}
            config={{
              variant: 'filled',
              title: '',
              subtitle: '',
              titleStyle: {
                fontSize: '24px',
                fontWeight: 'bold',
              },
              cols: 1,
            }}
            fields={[
              {
                type: FieldTypes.Link,
                label: 'Website',
                readOnly: true,
                fieldName: 'website',
                variant: 'standard',
                overrideRowValue: true,
                withIcon: true,
                value: supplier?.website,
                optionalSx: {
                  mb: 1,
                },
              },
              {
                type: FieldTypes.Text,
                label: 'Address',
                isNumeric: false,
                fieldName: 'serviceProviderName',
                value: buildAddress(supplier?.address),
                variant: 'standard',
                overrideRowValue: true,
                readOnly: true,
              },
              {
                type: FieldTypes.Phone,
                label: 'Phone',
                readOnly: true,
                fieldName: 'phone',
                variant: 'standard',
                overrideRowValue: true,
                value: supplier?.phone,
              },
              {
                type: FieldTypes.Text,
                label: 'Email',
                placeholder: 'Email',
                variant: 'standard',
                overrideRowValue: true,
                value: supplier?.email,
                readOnly: true,
                fieldName: 'email',
              },
            ]}
            isSendingData={false}
            isLoadingForm={isLoadingSupplier}
            showSaveButton={false}
          />

          <ECBox display="flex" flexDirection="column" p={3} gap={3} mt={-3}>
            {supplier?.contacts?.map(contact => (
              <SupplierContactInfo
                key={contact.id}
                supplierCountryCode={supplier?.address?.countryCode}
                {...contact}
              />
            ))}
          </ECBox>
        </ECBox>
      </ECBox>
    </ECModal>
  );
};

export const SupplierContactInfo = ({
  firstName,
  lastName,
  email,
  phone,
  supplierCountryCode,
}: Contact & { supplierCountryCode?: string }) => {
  return (
    <ECBox display="flex" flexDirection="column">
      <ECTypography>
        {firstName} {lastName}
      </ECTypography>

      <ECTypography variant="caption" color="text.secondary">
        {email}
      </ECTypography>
      <ECTypography variant="caption" color="text.secondary">
        {formatPhone(phone, supplierCountryCode)}
      </ECTypography>
    </ECBox>
  );
};
