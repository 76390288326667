import { useGetAssetTypesForInventoryListQuery } from 'services/assetTypeApi';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveFilter } from 'store/slice/page';
import { RootState } from 'index';
import { useSelector } from 'react-redux';
import { ECAutoCompletePaginatedFilters } from '../ECAutoCompletePaginatedFilters';

const ECAssetTypeNameFilter = ({
  assetTypeFilterOptions,
  endpoint,
  onFilterSubmit,
  useGetAssetTypesListQuery,
  totalCount,
}) => {
  const dispatch = useDispatch();
  const { activeFilter: activeFilterFromSelector } = useSelector(
    (state: RootState) => state.page.filter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  const handleChangeAssetTypeName = useCallback(
    value => {
      if (value[value?.length - 1]?.fieldName === 'all') {
        dispatch(
          setActiveFilter({
            assetTypes:
              activeFilter?.assetTypes?.length ===
              assetTypeFilterOptions?.length
                ? []
                : assetTypeFilterOptions?.map(option => ({
                    id: option?.id,
                    name: option?.fieldName,
                  })),
            isSelectAllAssetTypes: !(
              activeFilter?.assetTypes?.length ===
              assetTypeFilterOptions?.length
            ),
            endpoint,
          }),
        );
        onFilterSubmit?.();
        return;
      }
      dispatch(
        setActiveFilter({
          assetTypes: value?.map(v => ({
            id: v.id,
            name: v.fieldName || v.name,
          })),
          isSelectAllAssetTypes:
            value?.length === assetTypeFilterOptions?.length,
          endpoint,
        }),
      );
      onFilterSubmit?.();
    },
    [
      assetTypeFilterOptions,
      activeFilter?.assetTypes,
      endpoint,
      dispatch,
      onFilterSubmit,
    ],
  );

  return (
    <ECAutoCompletePaginatedFilters
      fieldName="assetType"
      filterKey="assetTypes"
      label="Asset Group:"
      variant="outlined"
      useQuery={useGetAssetTypesListQuery}
      obAlias="assttp.name"
      onChange={handleChangeAssetTypeName}
      multiple={true}
      size="small"
      value={activeFilter?.assetTypes || []}
      filterOptions={assetTypeFilterOptions}
      totalCount={totalCount}
      endpoint={endpoint}
      onFilterSubmit={onFilterSubmit}
    />
  );
};

export default ECAssetTypeNameFilter;
