import {
  ECEasyFormConfigType,
  ECEasyFormCreate,
  ECEasyFormFieldType,
  ECPaper,
  FieldTypes,
} from 'app/components';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { CostTableData } from 'app/components/ECWorkflowCostTable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUploadAttachmentMutation } from 'services/attachmentApi';
import { useCreateInvoiceMutation } from 'services/invoiceApi';
import { useGetRepairsListForAssetQuery } from 'services/repairApi';
import { workOrdersApi } from 'services/workOrdersApi';
import { InvoiceBase } from 'types/Invoice';
import { useMatch, useNavigate } from 'react-router';
import { InventoryItemShape } from 'types/Inventory';
import sumCurrencyFields from 'utils/sum-currency-fields';
import { StyleConstants } from 'styles/StyleConstants';
import { isoDateWithTimezoneAtZero } from 'utils/strings/formatDate';
import { useGetRejectWarrantyReasonsQuery } from 'services/lookupApi';
import { useGetServiceProviderRatesAndTermsQuery } from 'services/serviceProviderApi';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  existingData?: any;
  allocatedInventoryItems?: InventoryItemShape[];
}

const config: ECEasyFormConfigType = {
  variant: 'simple',
  title: 'Add Invoice',
  cols: 1,
  submitTitle: 'Save',
  subtitle: '',
};

export function CreateInvoiceDrawer({
  onClose,
  existingData,
  isOpen,
  allocatedInventoryItems,
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const match = useMatch('/panel/*');

  const { data: failures, isLoading } = useGetRepairsListForAssetQuery(
    existingData.assetId,
  );

  const [uploadAttachment] = useUploadAttachmentMutation();

  const [openZeroTotalInfo, setOpenZeroTotalInfo] = useState<boolean>(false);

  const [failureTypesReadOnly, setFailureTypesReadOnly] =
    useState<boolean>(false);

  const [
    doCreateInvoice,
    {
      data,
      isError,
      isLoading: isLoadingCreateInvoice,
      error,
      isSuccess,
      reset,
    },
  ] = useCreateInvoiceMutation();

  const [isInvoiceRequired, setIsInvoiceRequired] = useState(true);

  useEffect(() => {
    setFailureTypesReadOnly(
      existingData.pmId || existingData.requestCategoryName === 'Maintenance',
    );
  }, [existingData]);

  const onCreate = useCallback(
    (newInvoice: InvoiceBase) => {
      if (newInvoice.images) {
        uploadAttachment({
          id: newInvoice.id,
          module: 'invoice',
          thumbnailIndex: 0,
          files: newInvoice.images.map(
            file => file.rawFile ?? file.processedFile ?? file,
          ),
        });
      }
    },
    [uploadAttachment],
  );

  useEffect(() => {
    if (isSuccess && data) {
      reset();
      dispatch(workOrdersApi.util.invalidateTags(['WorkOrderById']));
      navigate(`${match?.pathnameBase}/invoice/${data.id}`);
    }
  }, [isSuccess, dispatch]);

  const isFailureTypeRequired = useMemo(() => {
    return existingData?.requiredFields?.inventoryFailureTypeRequired &&
      isInvoiceRequired
      ? true
      : false;
  }, [
    existingData?.requiredFields?.inventoryFailureTypeRequired,
    isInvoiceRequired,
  ]);

  const fields = useMemo(
    () =>
      [
        {
          fieldName: 'requestId',
          visible: false,
        },
        {
          type: FieldTypes.Group,
          groupColumns: 2,
          subFields: [
            {
              type: FieldTypes.Text,
              label: 'Problem Type',
              readOnly: true,
              fieldName: 'problem',
            },
            {
              type: FieldTypes.Text,
              label: 'Customer',
              readOnly: true,
              fieldName: 'customer',
            },
            {
              type: FieldTypes.Text,
              label: 'Work Order ID',
              readOnly: true,
              fieldName: 'workorderId',
            },
            {
              type: FieldTypes.Text,
              label: 'Asset Name',
              readOnly: true,
              fieldName: 'assetName',
            },
          ],
        },
        {
          type: FieldTypes.Text,
          label: 'Location',
          readOnly: true,
          fieldName: 'branchName',
        },
        {
          type: FieldTypes.Checkbox,
          options: ['Invoice is not Required'],
          fieldName: 'notRequired',
          value: isInvoiceRequired ? [] : ['Invoice is not Required'],
          onChange: () => {
            setIsInvoiceRequired(prev => !prev);
          },
        },
        {
          type: FieldTypes.Group,
          groupColumns: 2,
          subFields: [
            {
              type: FieldTypes.Text,
              label: 'Invoice Number',
              fieldName: 'invoiceNumber',
              required: isInvoiceRequired,
            },
            {
              type: FieldTypes.DatePicker,
              label: 'Invoice Date',
              fieldName: 'invoiceDate',
              required:
                isInvoiceRequired &&
                !!existingData?.requiredFields?.invoiceDateRequired,
            },
          ],
        },

        {
          type: FieldTypes.FailureWarranty,
          label: `Failure Type${isFailureTypeRequired ? ' *' : ' '}`,
          placeholder: `Failure Type${isFailureTypeRequired ? ' *' : ' '}`,
          fieldName: 'failures',
          visible: !failureTypesReadOnly,
          options:
            failures && failures?.length
              ? failures?.map(failure => ({
                  name: failure.name,
                  id: failure.id,
                }))
              : [],
          isLoading,
          required: isFailureTypeRequired,
        },
        {
          type: FieldTypes.Text,
          label: `Failure Type`,
          placeholder: `Failure Type`,
          readOnly: true,
          value: 'Scheduled Service',
          fieldName: 'failures',
          required: false,
          visible: failureTypesReadOnly,
        },
        {
          type: FieldTypes.CostTable,
        },
        {
          type: FieldTypes.Text,
          label: 'Note',
          fieldName: 'description',
          maxLength: 1000,
        },
        {
          type: FieldTypes.Switch,
          label: 'Warranty Used in Repair',
          fieldName: 'warrantyUsed',
          value: existingData?.isActiveWarranty,
          visible: !existingData.pmId,
          helperText:
            'Any parts, labor, or components covered under warranty while completing this work order?',
        },
        {
          type: FieldTypes.SelectPaginated,
          label: 'Reason for not utilizing the warranty in the repair',
          placeholder: 'Reason for not utilizing the warranty in the repair',
          fieldName: 'rejectWarrantyReason',
          visible: !existingData.pmId,
          hideIfOtherFieldPopulated: 'warrantyUsed',
          useQuery: useGetRejectWarrantyReasonsQuery,
          required: false,
        },
        {
          type: FieldTypes.Text,
          label: 'Other Reason for not utilizing the warranty in the repair',
          placeholder:
            'Other Reason for not utilizing the warranty in the repair',
          fieldName: 'rejectWarrantyReasonOther',
          visible: false,
          hideIfOtherFieldPopulated: 'warrantyUsed',
          required: false,
        },
        {
          type: FieldTypes.FilePicker,
          label: 'Attatchments',
          value: null,
          options: [],
          fieldName: 'images',
          fileType: 'photo',
          required:
            isInvoiceRequired &&
            !!existingData?.requiredFields?.invoiceDocumentRequired,
        },
      ] as ECEasyFormFieldType[],
    [
      failureTypesReadOnly,
      isLoading,
      failures,
      isInvoiceRequired,
      existingData?.requiredFields?.invoiceDocumentRequired,
    ],
  );

  const { data: ratesAndTerms } = useGetServiceProviderRatesAndTermsQuery(
    { spId: existingData?.spCompanyId, companyId: existingData?.companyId },
    {
      skip: !existingData?.spCompanyId,
    },
  );

  const costTableData = useMemo(
    (): CostTableData => ({
      nte: existingData.nte,
      openZeroTotalInfo,
      onClickAwayZeroTotalInfo: () => {
        if (openZeroTotalInfo) {
          setOpenZeroTotalInfo(false);
        }
      },
      itemsRepair: [
        {
          label: 'Labor',
          dataPath: 'costLabor',
          readOnly: true,
        },
        {
          label: 'Labor Charge',
          dataPath: 'costLaborItems',
          extraData: ratesAndTerms,
          required: true,
        },
        {
          label: 'Material',
          dataPath: 'costMaterial',
        },
        {
          label: 'Tax',
          dataPath: 'costTax',
        },
        {
          label: 'Freight',
          dataPath: 'costFreight',
        },
        {
          label: 'Other',
          dataPath: 'costOther',
        },
      ],
      itemsInventory: allocatedInventoryItems?.map(inventory => ({
        ...inventory,
        readOnly: true,
      })),
      shouldHideCostTable: !isInvoiceRequired,
    }),
    [
      existingData.nte,
      openZeroTotalInfo,
      allocatedInventoryItems,
      isInvoiceRequired,
      ratesAndTerms,
    ],
  );

  const createInvoiceMutation = useCallback(() => {
    const create = (invoice: any) => {
      const params = {
        ...invoice,
        workorderId: existingData.workorderId,
        spCompanyId: existingData.spCompanyId,
        invoiceDate: isoDateWithTimezoneAtZero(invoice.invoiceDate),
        costFreight:
          invoice.notRequired?.length || !invoice.costFreight
            ? 0
            : invoice.costFreight,
        costLabor:
          invoice.notRequired?.length || !invoice.costLabor
            ? 0
            : invoice.costLabor,
        costLaborItems: invoice.costLaborItems?.map(item => ({
          ...item,
          costTypeId: item?.costType?.id,
          unitCount: Number(item?.unitCount),
          unitCost: Number(item?.unitCost),
          totalCost: Number(item?.totalCost),
        })),
        costMaterial:
          invoice.notRequired?.length || !invoice.costMaterial
            ? 0
            : invoice.costMaterial,
        costOther:
          invoice.notRequired?.length || !invoice.costOther
            ? 0
            : invoice.costOther,
        costTax:
          invoice.notRequired?.length || !invoice.costTax ? 0 : invoice.costTax,
        isFileAttached:
          Array.isArray(invoice.images) && !!invoice.images.length,
        warrantyUsed: invoice.warrantyUsed ? 1 : 0,
        rejectWarrantyReasonId: invoice.warrantyUsed
          ? undefined
          : invoice.rejectWarrantyReason?.id,
        rejectWarrantyReasonOther:
          invoice.warrantyUsed || invoice.rejectWarrantyReason?.name !== 'Other'
            ? undefined
            : invoice.rejectWarrantyReasonOther,
      };

      if (!failureTypesReadOnly && typeof invoice?.failures !== 'string') {
        params.warrantyComponents = invoice?.failures?.map(failure => ({
          noWarranty: failure.warranty.noWarranty ? 1 : 0,
          assetId: existingData.assetId,
          repairId: failure.repairId,
          resolutionId: failure.warranty.resolutionId,
          periodCode: failure.warranty.periodCode,
          duration: failure.warranty.period,
        }));
      }

      if (!invoice.notRequired?.length && !existingData.enableCoupa) {
        const totalCost = costTableData
          ? sumCurrencyFields(
              ...costTableData?.itemsRepair.map(
                entry => params[entry.dataPath as string] ?? 0,
              ),
            )
          : 0;
        const subtotalForInventoryCost =
          allocatedInventoryItems?.reduce((acc, item) => acc + item.price, 0) ||
          0;

        if (totalCost + subtotalForInventoryCost === 0) {
          const zeroInfoBox = document.querySelector('#ec-costtable-total');
          // If the total cost is not visible, scroll to the element for the pop-up to be visible
          if (zeroInfoBox && zeroInfoBox.getBoundingClientRect()?.top < 0) {
            zeroInfoBox?.scrollIntoView();
          }
          setOpenZeroTotalInfo(true);
          return;
        }
      }
      if (invoice.images) {
        params.files = invoice.images.map(
          file => file.rawFile ?? file.processedFile ?? file,
        );
      }

      doCreateInvoice(params);
    };

    return [
      create,
      {
        data,
        isError,
        error,
        isLoading: isLoadingCreateInvoice,
        isSuccess,
        reset,
      },
    ];
  }, [
    doCreateInvoice,
    data,
    isError,
    error,
    isSuccess,
    reset,
    isLoadingCreateInvoice,
    failureTypesReadOnly,
    existingData,
  ]);

  const handleOnChange = useCallback(
    (output, fieldname, value) => {
      // if user changes warrantyUsed toggle, show or hide the rejectWarrantyReason field
      if (fieldname === 'warrantyUsed') {
        const rejectWarrantyReasonField = output.find(
          field => field.fieldName === 'rejectWarrantyReason',
        );
        rejectWarrantyReasonField.visible = !existingData.pmId && !value;
        rejectWarrantyReasonField.required =
          !existingData.pmId && !value && existingData?.isActiveWarranty;

        const rejectWarrantyReasonOtherField = output.find(
          field => field.fieldName === 'rejectWarrantyReasonOther',
        );
        rejectWarrantyReasonOtherField.visible = !existingData.pmId && !value;
        rejectWarrantyReasonOtherField.required =
          !existingData.pmId && !value && existingData?.isActiveWarranty;
      }

      // show or hide the rejectWarrantyReasonOther field based on rejectWarrantyReason value
      if (fieldname === 'rejectWarrantyReason') {
        const rejectWarrantyReasonOtherField = output.find(
          field => field.fieldName === 'rejectWarrantyReasonOther',
        );
        rejectWarrantyReasonOtherField.visible =
          !existingData.pmId && value.name?.toLowerCase() === 'other';
        rejectWarrantyReasonOtherField.required =
          !existingData.pmId && value.name?.toLowerCase() === 'other';
      }
    },
    [existingData?.pmId, existingData?.isActiveWarranty],
  );

  return (
    <ECDrawerDetails open={isOpen} anchor="right" onClose={onClose}>
      <ECPaper
        sx={{ paddingTop: StyleConstants.NAV_BAR_HEIGHT }}
        role="presentation"
      >
        <ECEasyFormCreate
          useCreateMutation={createInvoiceMutation}
          onClose={onClose}
          formConfig={config}
          formFields={fields}
          existingData={existingData}
          costTableData={costTableData}
          onCreate={onCreate}
          onChange={handleOnChange}
        />
      </ECPaper>
    </ECDrawerDetails>
  );
}
