import { useGetProfileQuery } from 'services/profileApi';

export enum CompanySubscriptionStatusCode {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Delinquent = 'DELINQUENT',
  Suspended = 'SUSPENDED',
}

export const useCompanySubscriptionStatusCode = () => {
  const { data: companyDetails } = useGetProfileQuery(undefined, {
    skip: !window.location.pathname.includes('panel'),
  });
  return companyDetails?.subscription?.code;
};
