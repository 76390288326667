import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import {
  ECAlert,
  ECBox,
  ECEasyForm,
  ECEasyFormFieldType,
  ECLink,
  ECTypography,
} from 'app/components';
import _ from 'lodash';
import { useMediaQuery, useTheme } from '@mui/material';

import { isValidEmail } from 'utils/validate-fields';

import { HelpOutlined } from '@mui/icons-material';
import { useLazyGetEmailExistsQuery } from 'services/authApi';
import { useTranslation } from 'react-i18next';
interface Step1Props {
  existingData?: any;
  onSubmit?: (data: any) => void;
}
const fancyFormElementsCreate = require('./fancy_form_config_step1_create.json');

export const Step1 = ({ onSubmit, existingData }: Step1Props) => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [hasCaptchaRendered, setHasCaptchaRendered] = useState(false);
  const [forceRerender, setForceRerender] = useState(false);
  const [isEmailAlreadyInUse, setIsEmailAlreadyInUse] = useState(false);

  const handleExistingData = useCallback(
    (field: ECEasyFormFieldType) => {
      if (!field) return;

      if (field.type === 'group') {
        return {
          ...field,
          subFields: field?.subFields?.map(handleExistingData),
        };
      }

      return { ...field };
    },
    [existingData],
  );

  const [formFields, setFormFields] = useState<any[]>(
    () => fancyFormElementsCreate.fields?.map(handleExistingData) || [],
  );

  useEffect(() => {
    setFormFields(
      fancyFormElementsCreate.fields?.map(handleExistingData) || [],
    );
  }, [existingData, handleExistingData]);

  // force rerender after 1 and a half seconds to ensure captcha is rendered
  useEffect(() => {
    const timeout = setTimeout(() => {
      setForceRerender(true);
    }, 1500);
    return () => clearTimeout(timeout);
  }, []);

  useLayoutEffect(() => {
    window['AwsWafCaptcha']?.renderCaptcha(
      document.getElementById('my-captcha-container'),
      {
        apiKey: process.env.REACT_APP_RECAPTCHA_API_KEY,
        onSuccess: () => setIsCaptchaValid(true),
        onError: () => setIsCaptchaValid(false),
        onLoad: () => setHasCaptchaRendered(true),
      },
    );
  }, [window['AwsWafCaptcha']?.renderCaptcha]);

  const [triggerEmail] = useLazyGetEmailExistsQuery({});

  const handleBlurEmail = async (value: string, field: any) => {
    if (!isValidEmail(value)) {
      field.isValid = false;
      field.validationMessage = t(
        'translation:form.validation.correctFormatEmail',
        {
          name: field.placeholder,
        },
      );
    } else {
      field.isValid = true;
      field.validationMessage = null;
      const responseUserExists = await triggerEmail({ email: value });
      if (responseUserExists?.data?.existed) {
        field.isValid = false;
        field.validationMessage = `Email already exists. Please try again.`;
        setIsEmailAlreadyInUse(true);
      } else {
        setIsEmailAlreadyInUse(false);
      }
    }
  };

  useEffect(() => {
    const emailField = formFields
      .find(field => field.label === 'emails')
      ?.subFields?.find(field => field.fieldName === 'user.email');

    emailField.onBlur = handleBlurEmail;

    const passwordField = formFields
      .find(field => field.label === 'passwords')
      ?.subFields?.find(field => field.fieldName === 'user.password');

    passwordField.onValidatePassword = setIsValidPassword;
    setFormFields([...formFields]);
  }, []);

  const submitForm = useCallback(
    (formData, output) => {
      const emailsFields = output.find(field => field.label === 'emails');

      const emailField = emailsFields.subFields.find(
        field => field.fieldName === 'user.email',
      );

      if (!isValidEmail(emailField?.value)) {
        emailField.validationMessage = 'The email is not valid';
        emailField.isValid = false;
        setFormFields([...output]);
        return;
      }

      const confirmEmailField = emailsFields.subFields.find(
        field => field.fieldName === 'user.confirmEmail',
      );

      if (emailField.value !== confirmEmailField.value) {
        confirmEmailField.validationMessage = `Emails don't match`;
        confirmEmailField.isValid = false;
        setFormFields([...output]);
        return;
      }

      if (isEmailAlreadyInUse) {
        emailField.validationMessage = `Email already exist. Please try again.`;
        emailField.isValid = false;
        setFormFields([...output]);
        return;
      }

      const namesFields = output.find(field => field.label === 'names');

      const firstNameField = namesFields.subFields.find(
        field => field.fieldName === 'user.firstName',
      );

      if (
        firstNameField?.value === null ||
        firstNameField?.value === undefined ||
        firstNameField === ''
      ) {
        firstNameField.isValid = false;
        setFormFields([...output]);
        return;
      }

      const lastNameField = namesFields.subFields.find(
        field => field.fieldName === 'user.lastName',
      );

      if (
        lastNameField?.value === null ||
        lastNameField?.value === undefined ||
        lastNameField === ''
      ) {
        lastNameField.isValid = false;
        setFormFields([...output]);
        return;
      }

      if (!isValidPassword) {
        return;
      }

      const body = {
        firstName: formData.get('user.firstName'),
        lastName: formData.get('user.lastName'),
        email: formData.get('user.email'),
        password: formData.get('user.password'),
      };

      onSubmit?.({
        user: {
          ...body,
        },
      });
    },
    [isEmailAlreadyInUse, isValidPassword, formFields],
  );

  const handleChangeData = useCallback(
    (formFields, fieldName, fieldValue) => {
      const emailField = formFields
        .find(field => field.label === 'emails')
        ?.subFields?.find(field => field.fieldName === 'user.email');

      if (fieldName === 'confirmEmail') {
        if (emailField.value !== fieldValue) {
          const confirmEmailField = formFields
            .find(field => field.label === 'emails')
            ?.subFields?.find(field => field.fieldName === 'user.confirmEmail');
          confirmEmailField.validationMessage = `Email don't match`;
          confirmEmailField.isValid = false;
        }
      }
    },
    [isEmailAlreadyInUse],
  );

  return (
    <ECBox
      paddingInline={isMobileSize ? 0 : `15%`}
      alignItems={'center'}
      justifyContent={'center'}
      display={'flex'}
      flexDirection={'column'}
    >
      <ECEasyForm
        isSendingData={false}
        isLoadingForm={false}
        config={fancyFormElementsCreate.config}
        fields={formFields}
        showSaveButton={false}
        onChange={handleChangeData}
        onFormSubmit={(formData, output) => {
          submitForm(formData, output);
        }}
        showWideSaveButton
        saveButtonDisabled={hasCaptchaRendered && !isCaptchaValid}
        formFooter={
          <>
            <div id="my-captcha-container" style={{ marginBottom: '10px' }} />

            <ECBox marginBottom={isMobileSize ? '35px' : ''}>
              <ECAlert severity="info">
                <ECTypography
                  variant="body2"
                  sx={{
                    color: theme.palette.info.content,
                  }}
                >
                  By completing your registration, you agree to our{' '}
                  <ECLink
                    color="inherit"
                    href="https://ecotrak.com/terms/"
                    target="_blank"
                  >
                    Terms of Service
                  </ECLink>{' '}
                  and{' '}
                  <ECLink
                    color="inherit"
                    target="_blank"
                    href="https://ecotrak.com/serviceproviderusagefees/"
                  >
                    Privacy Policy
                  </ECLink>
                  . This includes consenting to receive marketing
                  communications, updates, and exclusive offers from us. You can
                  manage your communication preferences or unsubscribe at any
                  time.
                </ECTypography>
              </ECAlert>
            </ECBox>
          </>
        }
      />

      <ECBox
        marginTop={'25px'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={'8px'}
      >
        <HelpOutlined color="info" />
        <ECTypography>
          Do you have a Ecotrak Account?{' '}
          <ECLink href="https://radstuff.ecotrak.com/">Sign in here</ECLink>
        </ECTypography>
      </ECBox>
    </ECBox>
  );
};
