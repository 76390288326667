export const Visa = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
    >
      <rect
        x="1"
        y="0.5"
        width="34"
        height="23"
        rx="3.5"
        fill="white"
        stroke="#D9D9D9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.1253 16.2582H9.00494L7.41495 10.1924C7.33949 9.91334 7.17925 9.66667 6.94354 9.5504C6.35531 9.25823 5.70712 9.0257 5 8.90843V8.6749H8.41567C8.88708 8.6749 9.24064 9.0257 9.29957 9.43313L10.1245 13.8086L12.2438 8.6749H14.3052L11.1253 16.2582ZM15.4838 16.2582H13.4813L15.1302 8.6749H17.1327L15.4838 16.2582ZM19.7234 10.7757C19.7823 10.3673 20.1359 10.1337 20.5483 10.1337C21.1965 10.0751 21.9026 10.1924 22.4919 10.4835L22.8454 8.85081C22.2562 8.61727 21.608 8.5 21.0198 8.5C19.0762 8.5 17.662 9.55041 17.662 11.0082C17.662 12.1173 18.6637 12.6996 19.3708 13.0504C20.1359 13.4002 20.4305 13.6338 20.3716 13.9835C20.3716 14.5082 19.7823 14.7418 19.1941 14.7418C18.4869 14.7418 17.7798 14.5669 17.1327 14.2747L16.7791 15.9085C17.4862 16.1996 18.2512 16.3169 18.9584 16.3169C21.1376 16.3745 22.4919 15.3251 22.4919 13.75C22.4919 11.7665 19.7234 11.6502 19.7234 10.7757ZM29.5 16.2582L27.91 8.6749H26.2022C25.8486 8.6749 25.4951 8.90843 25.3772 9.25823L22.4329 16.2582H24.4943L24.9058 15.1502H27.4386L27.6743 16.2582H29.5ZM26.4968 10.7171L27.085 13.5751H25.4361L26.4968 10.7171Z"
        fill="#172B85"
      />
    </svg>
  );
};
