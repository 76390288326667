export interface Lookup {
  openDate?: Date;
  days?: number;
  id: number;
  name: string;
  value?: string;
  rejectWo?: number;
  refrigerantTracking?: number;
}

export interface LookupData {
  data: Lookup[];
}

export interface Timezone {
  timezone: string;
}

export interface Banner {
  bannerText?: string;
  bannerBackgroundColor?: string;
  bannerType?: string;
}

export interface Settings {
  settingName?: string;
  settingValue?: string;
  description?: string;
}

export enum StatusBase {
  'Inactive',
  'Active',
}

export interface LookupWarrantyPeriod {
  code: string;
  description: string;
  paymentUnit: number;
  warranty: number;
  eul: number;
}

export interface LookupPeriod {
  code: string;
  description: string;
  paymentUnit: number;
  warranty: number;
  eul: number;
}

export interface LookupSIC {
  id: number;
  code: string;
  title: string;
  name: string;
}

export interface LookupCountry {
  value: string;
  label: string;
}

export interface LookupMarkupType {
  id: number;
  name: string;
  code: string;
  symbol: string;
  displayName: string;
}
