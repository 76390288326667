import { useCallback, useEffect, useState } from 'react';
import {
  ECBox,
  ECEasyForm,
  ECEasyFormFieldType,
  ECTypography,
} from 'app/components';
import _ from 'lodash';
import { useMediaQuery, useTheme } from '@mui/material';
import { ECCheckbox } from 'app/components/ECCheckbox';
import { PaymentSelector } from './paymentSelector';

interface Step4Props {
  existingData?: any;
  onClose?: () => void;
  onSubmit?: (formBody: any, formFields: any) => void;
}

const fancyFormElementsCreate = require('./fancy_form_config_step4_create.json');

export const Step4 = ({ existingData, onClose, onSubmit }: Step4Props) => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const [paymentMethod, setPaymentMethod] = useState<'creditcard' | 'ach'>(
    'creditcard',
  );
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreeTermError, setAgreeTermsError] = useState(false);

  const changePaymentSection = (section: 'creditcard' | 'ach') => {
    const creditCardSection = formFields.find(
      field => field.fieldName === 'credit_card',
    );
    const creditCardNumber = formFields.find(
      field => field.fieldName === 'credit_card_field_number',
    );
    const creditCardCVVExpirationDate = formFields.find(
      field => field.fieldName === 'credit_card_fields_cvv_expire_date',
    );
    const creditCardNameOnCard = formFields.find(
      field => field.fieldName === 'credit_card_field_name',
    );

    const achSection = formFields.find(field => field.fieldName === 'ach');
    const achRoutineNumber = formFields.find(
      field => field.fieldName === 'ach_routine_number',
    );
    const achAccountNumber = formFields.find(
      field => field.fieldName === 'ach_account_number',
    );

    if (section === 'creditcard') {
      creditCardSection.visible = true;
      creditCardNumber.visible = true;
      creditCardCVVExpirationDate.visible = true;
      creditCardNameOnCard.visible = true;

      achSection.visible = false;
      achRoutineNumber.visible = false;
      achAccountNumber.visible = false;
      setPaymentMethod('creditcard');
    }
    if (section === 'ach') {
      creditCardSection.visible = false;
      creditCardNumber.visible = false;
      creditCardCVVExpirationDate.visible = false;
      creditCardNameOnCard.visible = false;

      achSection.visible = true;
      achRoutineNumber.visible = true;
      achAccountNumber.visible = true;
      setPaymentMethod('ach');
    }
    setFormFields([...formFields]);
  };

  const [formFields, setFormFields] = useState<any[]>(
    fancyFormElementsCreate.fields,
  );

  const handleExistingData = useCallback(
    (field: ECEasyFormFieldType) => {
      if (!field) return;

      if (field.type === 'group') {
        return {
          ...field,
          subFields: field?.subFields?.map(handleExistingData),
        };
      }
      if (field.type === 'custom' && field.fieldName === 'paymentSelector') {
        field.value = <PaymentSelector onChange={changePaymentSection} />;
      }

      return { ...field };
    },
    [existingData],
  );

  useEffect(() => {
    setFormFields(
      fancyFormElementsCreate.fields?.map(handleExistingData) || [],
    );
  }, [existingData, handleExistingData]);

  const submitForm = useCallback(
    (formData, output) => {
      if (!agreeTerms) {
        setAgreeTermsError(true);
        return;
      }
      setAgreeTermsError(false);

      let body: any = {
        paymentType: paymentMethod === 'creditcard' ? 'CC' : 'ACH',
      };

      if (paymentMethod === 'creditcard') {
        const [expirationMonth, expirationYear] = formData
          .get('credit_card_expire_date')
          .split('/');

        body = {
          ...body,
          creditCardName: formData.get('credit_card_name'),
          cardNumber: formData.get('credit_card_number').replaceAll(' ', ''),
          expirationMonth,
          expirationYear,
          cvv: formData.get('credit_card_cvv'),
        };
      }
      if (paymentMethod === 'ach') {
        const routineNumberField = output.find(
          field => field.fieldName === 'ach_routine_number',
        );
        if (routineNumberField.value.length !== 9) {
          routineNumberField.isValid = false;
          routineNumberField.validationMessage =
            'The Routine Number need to 9 characters';
          setFormFields([...output]);
          return;
        }

        const accountNumberField = output.find(
          field => field.fieldName === 'ach_account_number',
        );
        if (
          accountNumberField.value.length < 5 ||
          accountNumberField.value.length > 17
        ) {
          accountNumberField.isValid = false;
          accountNumberField.validationMessage =
            'The Account Number is invalid';
          setFormFields([...output]);
          return;
        }
        body = {
          ...body,
          accountingNumber: formData.get('ach_account_number'),
          routingNumber: formData.get('ach_routine_number'),
        };
      }
      const formBody = {
        user: {
          ...existingData?.user,
          firstName: formData.get('user.firstName'),
          lastName: formData.get('user.lastName'),
        },
        company: {
          ...existingData?.company,
          phone: formData.get('company.phone').trim().replace(/\D/g, ''),
          email: formData.get('company.email'),
          address: {
            ...existingData?.company?.address,
            line1: formData.get('address.line1'),
            line2: formData.get('address.line2'),
            cityName: formData.get('address.cityName'),
            stateProvinceCode: formData.get('address.stateProvinceCode'),
            zipCodePostalCode: formData.get('address.zipCodePostalCode'),
            countryCode: output.find(
              field => field.fieldName === 'address.countryCode',
            )?.value,
          },
        },
        payment: { ...body },
      };
      onSubmit?.(formBody, output);
    },
    [formFields, agreeTerms],
  );

  return (
    <ECBox
      paddingInline={isMobileSize ? 0 : `15%`}
      alignItems={'center'}
      justifyContent={'center'}
      display={'flex'}
      flexDirection={'column'}
    >
      <ECEasyForm
        isLoadingForm={false}
        isSendingData={false}
        onFormSubmit={(formData, output) => {
          submitForm(formData, output);
        }}
        config={fancyFormElementsCreate.config}
        fields={formFields}
        existingData={existingData}
        saveButtonDisabled={!agreeTerms}
        showSaveButton={false}
        showWideSaveButton
        formFooter={
          <ECBox
            marginBottom={isMobileSize ? '35px' : ''}
            display={'flex'}
            gap={'12px'}
            padding={'26px 36px'}
            borderRadius={'4px'}
            marginInline={'20px'}
            sx={theme => ({
              background: agreeTermError
                ? theme.palette.error.background
                : theme.palette.info.background,
            })}
          >
            <ECCheckbox
              value={agreeTerms}
              onClick={() => {
                setAgreeTerms(!agreeTerms);
                setAgreeTermsError(false);
              }}
            />
            <ECTypography variant="body2" color={theme.palette.info.content}>
              By checking this box, I agree to Terms of Service and authorize
              Everyware Worldwide Inc. ("Everyware") to initiate debit and
              credit transactions to my account at the financial institution I
              provided for payment of services, and related fees. This
              authorization will remain in effect until I notify Everyware in
              writing to cancel it, allowing reasonable time to process the
              cancellation.
            </ECTypography>
          </ECBox>
        }
      />
    </ECBox>
  );
};
