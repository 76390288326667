import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useUpdateServiceProviderWorkOrderMutation,
  useGetWorkOrderNextStatusListQuery,
  useWorkOrderActionClickMutation,
  useGetCategoriesByCompanyListQuery,
  workOrdersApi,
} from 'services/workOrdersApi';
import { dashboardApi } from 'services/dashboardApi';
import { useGetDeclineTypesQuery } from 'services/lookupApi';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import {
  ECBox,
  ECButton,
  ECEasyForm,
  ECEasyFormFieldType,
  ECRequirePermission,
  ECWorkflowTemplate,
} from 'app/components';
import {
  SummaryFieldTypes,
  SummaryFieldsData,
} from 'app/components/ECWorkflowSummaryField';
import { Outlet, useParams, useLocation } from 'react-router';
import { useGetAttachmentQuery } from 'services/attachmentApi';
import { AttachmentCategory, DocumentCoreTypes } from 'types/Attachment';
import { WorkflowNextStatus } from 'types/WorkflowStatus';
import { useGetSPBranchUsersQuery } from 'services/userApi';
import { SPWorkOrder } from 'types/WorkOrders';
import { CreateSPInvoiceDrawer } from './CreateSPInvoiceDrawer';
import {
  useGetAssetWarrantyQuery,
  useGetAssetsListQuery,
  useLazyGetAssetByIdQuery,
} from 'services/assetApi';
import { populateDropDownFields } from 'utils/pageUtils';
import { ECWorkOrderUpdateEtaModalContent } from 'app/components/ECWorkOrderUpdateEtaModalContent';
import { ECWorkOrderRejectModalContent } from 'app/components/ECWorkOrderRejectModalContent';
import { Popover } from '@mui/material';
import { P } from 'types/Permission';
import { CreateProposalDrawer } from './CreateProposalDrawer';
import { WorkflowStatusGroupName } from 'app/components/ECWorkflowStatusBadge';
import { Close } from '@mui/icons-material';
import { useGetPriorityListQuery } from 'services/assetTypeApi';
import { useCustomerUser } from 'app/hooks';
import { ForbiddenPage } from '../ForbiddenPage';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { reassignAction } from '../DashboardPage/actions';
import { ECModal } from 'app/components/ECModal';
import { useGetProfileQuery } from 'services/profileApi';
import { moduleApi, useGetModuleDetailsByIdQuery } from 'services/moduleApi';
import { useNavigate } from 'react-router-dom';
import { generateWODetailsHTMLContent } from 'utils/geneate-html-content';
import { AllocatedInventoryItems } from './AllocatedInventoryItems';
import { hasPermission } from 'utils/permissions';
import _ from 'lodash';
import { useGetInventoryItemsAllocatedToWOQuery } from 'services/inventoryApi';
import { AdjustAllocatedInventoryItems } from './AdjustAllocatedInventoryItems';
import {
  convertComputedInventoryItemsToInventoryShape,
  groupAndProcessInventoryItems,
} from 'utils/common';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { UpdateETAAction } from '../WorkOrderDetailsPage/UpdateETAAction';
import LastCommentExtraRow from '../WorkOrderDetailsPage/LastCommentExtraRow';

const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const formElementsDetails = require('./fancy_form_config_details.json');
const fancyFormConfirmComplete = require('./fancy_form_config_confirm.json');

const statusWithoutAddProposalAction = [
  WorkflowStatusGroupName.Cancelled,
  WorkflowStatusGroupName.Pending,
  WorkflowStatusGroupName.NotFixed,
  WorkflowStatusGroupName.Rejected,
];

export const statusNotToShowReassignSP = [
  WorkflowStatusGroupName.Unassigned,
  WorkflowStatusGroupName.Pending,
  WorkflowStatusGroupName.Rejected,
  WorkflowStatusGroupName.Cancelled,
  WorkflowStatusGroupName.Complete,
  WorkflowStatusGroupName.SoftCompleted,
];

const PRINT_DEBOUNCE_TIME = 1500;

export function ServiceProviderWorkOrderDetailsPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const isDetailsTab =
    !window.location.href.includes('attachments') &&
    !window.location.href.includes('notes') &&
    !window.location.href.includes('activity');

  const [drawerType, setDrawerType] = useState<
    | 'copy'
    | 'invoice'
    | 'proposal'
    | 'allocateInventoryItems'
    | 'adjustAllocatedInventoryItems'
  >();
  const [
    hasEditWorkorderPermission,
    hasEditPriorityOnWOPermission,
    hasReassignWorkorkOrderPermission,
  ] = useHasPermission([
    P.EditWorkOrder,
    P.EditPriorityOnWorkOrder,
    P.ReassignWorkOrder,
  ]);
  const [completeConfirm, setCompleteConfirm] = useState(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [summaryFieldsValues, setSummaryFieldsValues] = useState<any>({});
  const [formFields, setFormFields] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [isOpenRejectModal, setIsOpenRejectModal] = useState<boolean>(false);
  const [detailsFormFields, setDetailsFormFields] = useState<
    ECEasyFormFieldType[]
  >(formElementsDetails.fields);

  const [statusData, setStatusData] = useState<WorkflowNextStatus>();
  const [isOpenAcceptModal, setIsOpenAcceptModal] = useState<boolean>(false);

  const {
    data: workOrder,
    isSuccess: isSuccessWorkOrder,
    isLoading: isLoadingWorkOrder,
  } = useGetModuleDetailsByIdQuery(
    { id: id || '', moduleName: 'sp/workorder', includeLastCommentUserInfo: 1 },
    { skip: !id },
  );

  const { data: allocatedInventoryItems } =
    useGetInventoryItemsAllocatedToWOQuery(
      { workOrderId: workOrder?.id },
      { skip: !workOrder?.id },
    );

  const { data: workOrderNextStatus } = useGetWorkOrderNextStatusListQuery(
    Number(id) || 0,
    { skip: !id || !isDetailsTab },
  );

  const { data: declineTypesData } = useGetDeclineTypesQuery(undefined, {
    skip: !isDetailsTab,
  });

  const { data: userProfile, isSuccess: isSuccessUserProfile } =
    useGetUserProfileQuery(undefined, {
      skip: !isDetailsTab,
    });

  const { data: companyProfile } = useGetProfileQuery(undefined, {
    skip: !isDetailsTab,
  });

  const closeAcceptModal = useCallback(() => {
    setIsOpenAcceptModal(false);
  }, []);

  const { data: spUsersData, isSuccess: spUsersIsSuccess } =
    useGetSPBranchUsersQuery(
      {
        branchId: workOrder?.request?.asset?.branch?.id,
        spCompanyId: userProfile?.company?.id || 0,
      },
      {
        skip:
          !workOrder?.request?.asset?.branch?.id ||
          !userProfile?.company?.id ||
          !isEditMode ||
          !isDetailsTab,
      },
    );

  const isUserHaveGetInventoryPermission = useMemo(() => {
    if (isSuccessUserProfile && Array.isArray(userProfile?.permissions)) {
      return hasPermission({
        userPermissions: userProfile.permissions?.map(p => p.action),
        scopes: [P.GetInventory],
      });
    }
    return false;
  }, [userProfile, isSuccessUserProfile]);

  useEffect(() => {
    if (spUsersData && spUsersIsSuccess) {
      const [_newCreateFormFields, newEditFormFields] = populateDropDownFields({
        responseData: spUsersData,
        editFormFields: fancyFormElementsEdit?.fields,
        dataOptionsFieldName: 'fullName',
        dataValuesFieldName: 'id',
        formFieldName: 'assigneeSPUser.fullName',
      });
      setFormFields(newEditFormFields);
    }
  }, [spUsersIsSuccess, spUsersData]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const notificationAction = searchParams.get('notificationAction');

  useEffect(() => {
    if (!workOrderNextStatus || !notificationAction) return;

    const statusToChange = workOrderNextStatus.find(
      item => item.name.toLowerCase() === notificationAction,
    );

    if (!statusToChange) return;

    if (notificationAction === 'accept') {
      setStatusData(statusToChange);
      setIsOpenAcceptModal(true);
    } else if (notificationAction === 'reject') {
      setIsOpenRejectModal(true);
    }
  }, [notificationAction, workOrderNextStatus]);

  const isAsset = useMemo(
    () => _.isNil(workOrder?.request?.asset?.generalAsset),
    [workOrder?.request?.asset?.generalAsset],
  );

  const moduleName = useMemo(() => {
    return isAsset ? 'asset' : 'general-asset';
  }, [isAsset]);

  const { data: attachments } = useGetAttachmentQuery(
    {
      module: moduleName,
      moduleId:
        workOrder?.request?.asset?.generalAsset?.id ||
        workOrder?.request?.asset?.id ||
        0,
      category: AttachmentCategory.Photo,
    },
    { skip: !workOrder?.request?.asset?.id || !isDetailsTab || !moduleName },
  );

  const assetProfileAttachment = useMemo(() => {
    const matchingPhotos = attachments?.others?.filter(
      attachment =>
        attachment?.documentCoreType?.code ===
        DocumentCoreTypes.assetProfilePhoto,
    );
    if (matchingPhotos && matchingPhotos.length > 0) {
      return matchingPhotos[matchingPhotos.length - 1];
    }
  }, [attachments]);

  const { data: assetWarranty } = useGetAssetWarrantyQuery(
    workOrder?.request?.asset?.id || null,
    { skip: !workOrder?.request?.asset?.id || !isDetailsTab },
  );

  const initializeFields = (wo: SPWorkOrder) => {
    setSummaryFieldsValues({
      id: wo?.serviceProviderWoId,
      assignedTo: {
        data: wo.assigneeSPUser?.id,
        label: wo.assigneeSPUser?.fullName,
      },
      categoryId: wo.request?.category?.id,
      assetId: wo.request?.asset?.id,
      priorityId: wo.request?.priority?.id,
    });
  };

  useEffect(() => {
    setFormFields(
      fancyFormElementsEdit?.fields?.map(field => {
        if (field.type === 'group') {
          return field?.subFields?.map(subField => {
            if (workOrder?.mutableFields?.includes?.(subField?.fieldName)) {
              subField.readOnly = false;
            } else {
              subField.readOnly = true;
            }
          });
        }

        if (
          workOrder?.mutableFields?.includes?.(field?.fieldName) ||
          field?.fieldName === 'assigneeSPUser.fullName'
        ) {
          field.readOnly = false;
        } else {
          field.readOnly = true;
        }

        return field;
      }),
    );
  }, [workOrder?.mutableFields]);

  const { data: priorityData, isSuccess: isSuccessPriority } =
    useGetPriorityListQuery(
      {
        cid: workOrder?.request?.customer?.id,
      },
      {
        skip: !isEditMode || !isDetailsTab || !workOrder?.request?.customer?.id,
      },
    );

  useEffect(() => {
    if (isSuccessWorkOrder) {
      fancyFormElementsEdit.config.name = `WO #${workOrder.id}`;
      initializeFields(workOrder);
    }
  }, [workOrder, isSuccessWorkOrder]);

  const [
    doUpdateWorkOrder,
    {
      data: updateData,
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset: resetUpdateWorkOrder,
    },
  ] = useUpdateServiceProviderWorkOrderMutation();

  const useUpdateWorkOrder = useCallback(() => {
    const doUpdate = async data => {
      if (!id) {
        return;
      }
      const {
        nte,
        assigneeSPUser,
        address,
        workorderAge,
        ...updatedDataWithoutPrev
      } = data;
      const updatedData = {
        ...updatedDataWithoutPrev,
        id: +id,
        serviceProviderWoId: summaryFieldsValues.id,
        requestCategoryId: summaryFieldsValues?.categoryId,
        assetId: summaryFieldsValues?.assetId,
        priorityId: summaryFieldsValues?.priorityId,
      };
      if (nte && nte != workOrder?.nte) {
        updatedData.nte = nte;
      }
      if (
        assigneeSPUser.id &&
        assigneeSPUser.id !== workOrder?.spAssigneeUserId
      ) {
        updatedData.spAssigneeUserId = data?.assigneeSPUser.id;
        // User clicked on "Unselected" option
        if (assigneeSPUser.id === -1) {
          updatedData.spAssigneeUserId = null;
        } else {
          doUpdateWorkOrder(updatedData);
          return;
        }
      }

      doUpdateWorkOrder(updatedData);
    };

    return [
      doUpdate,
      {
        data: updateData,
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset: resetUpdateWorkOrder,
      },
    ];
  }, [
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    id,
    summaryFieldsValues,
    doUpdateWorkOrder,
    resetUpdateWorkOrder,
  ]);

  const [
    doUpdateWorkOrderStatus,
    { isLoading: isLoadingActions, isSuccess: isSuccessUpdateAction },
  ] = useWorkOrderActionClickMutation();

  const doUpdateStatus = useCallback(
    async (data: WorkflowNextStatus) => {
      if (data.statusGroupName === WorkflowStatusGroupName.Accepted) {
        setStatusData(data);
        setIsOpenAcceptModal(true);
      } else {
        doUpdateWorkOrderStatus({
          id: workOrder?.id || 0,
          statusTo: data.statusTo,
          actionId: data.actionId,
          ...data,
        });
      }
    },
    [workOrder, doUpdateWorkOrderStatus],
  );

  useEffect(() => {
    if (isSuccessUpdateAction) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Work Order status updated successfully',
        }),
      );
    }
    if ((updateData as any)?.spCompanyChanged) {
      redirectToWorkordersList();
    }
  }, [isSuccessUpdateAction, dispatch, updateData]);

  const handleCopyClick = () => {
    setDrawerType('copy');
  };
  const handleClose = useCallback(() => setDrawerType(undefined), []);

  const handleSummaryFieldChange = (fieldName: string) => (value: any) => {
    setSummaryFieldsValues(prevValues => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  const addInvoice = useMemo(() => {
    const onAddInvoice = () => {
      const hasPendingProposal = workOrder?.request?.proposals?.some(
        proposal => {
          const proposalStatus =
            proposal.statusGroup as WorkflowStatusGroupName;
          return proposalStatus === WorkflowStatusGroupName.Pending;
        },
      );

      if (hasPendingProposal) {
        setCompleteConfirm(true);
      } else {
        setDrawerType('invoice');
      }
    };
    return <ECButton onClick={onAddInvoice}>Add Invoice</ECButton>;
  }, [workOrder]);

  const renderAddProposal = useMemo(() => {
    const onAddProposal = () => {
      setDrawerType('proposal');
    };
    return <ECButton onClick={onAddProposal}>Add Proposal</ECButton>;
  }, []);

  const renderUpdateEtaPopup = useMemo(() => {
    return workOrder?.mutableFields?.includes('eta') ? (
      <ECRequirePermission scopes={[P.GetWorkOrder, P.EditWorkOrder]}>
        <ECButton
          onClick={e => {
            setAnchorEl(e.currentTarget);
            setAction('eta');
          }}
        >
          Update ETA
        </ECButton>
      </ECRequirePermission>
    ) : null;
  }, [workOrder]);

  const renderRejectWorkOrder = useMemo(() => {
    return (
      <ECRequirePermission scopes={[P.GetWorkOrder, P.EditWorkOrder]}>
        <ECButton
          variant="outlined"
          color="error"
          startIcon={
            <Close sx={{ color: theme => theme.palette.error.main }} />
          }
          sx={theme => ({
            border: 1,
            borderColor: `${theme.palette.error.outlinedRestingBackground} !important`,
            color: `${theme.palette.error.main} !important`,
          })}
          onClick={e => {
            setIsOpenRejectModal(true);
          }}
        >
          Reject
        </ECButton>
      </ECRequirePermission>
    );
  }, [workOrder, workOrderNextStatus]);

  const handleCloseModal = () => {
    setAnchorEl(null);
    setAction(null);
  };

  const handleCloseRejectModal = () => {
    setIsOpenRejectModal(false);
  };

  const [action, setAction] = useState<'eta' | null>(null);

  const popoverId =
    action === 'eta'
      ? 'simple-popover'
      : action === 'assignTechnician'
        ? 'assign-techinician-comment'
        : undefined;

  const allowedStatusToCreateInvoice = useMemo(
    () => [
      WorkflowStatusGroupName.Complete,
      WorkflowStatusGroupName.SoftCompleted,
    ],
    [],
  );

  const isCreateInvoiceAllowed = useMemo(() => {
    const hasInvoices = workOrder?.invoice?.id;
    return (
      allowedStatusToCreateInvoice?.includes?.(
        workOrder?.workflowStatus?.group?.name as any,
      ) && !hasInvoices
    );
  }, [
    workOrder?.workflowStatus,
    workOrder?.invoice,
    allowedStatusToCreateInvoice,
  ]);

  const [isOpenReassignModal, setIsOpenReassignModal] =
    useState<boolean>(false);

  const closeReassignModal = useCallback(() => {
    setIsOpenReassignModal(false);
  }, []);

  useEffect(() => {
    if (
      ((!isLoadingActions && isSuccessUpdateAction) ||
        (!isUpdateLoading && isUpdateSuccess)) &&
      !(updateData as any)?.spCompanyChanged
    ) {
      dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
    }
  }, [
    isLoadingActions,
    isSuccessUpdateAction,
    isUpdateLoading,
    isUpdateSuccess,
    updateData,
  ]);

  const isInternalCompany = useMemo(() => {
    return companyProfile?.spProfile?.spType?.code === 'INTERNAL';
  }, [companyProfile?.spProfile?.spType?.code]);

  const additionalActions = useMemo(() => {
    let availableAdditionalActions: any = [];
    if (!workOrder) {
      return [];
    }

    if (isCreateInvoiceAllowed) {
      availableAdditionalActions.push(addInvoice);
    }

    const workOrderStatus = workOrder?.workflowStatus?.group
      ?.name as WorkflowStatusGroupName;
    const woCompletedWithApprovedInvoice =
      workOrderStatus === WorkflowStatusGroupName.Complete &&
      workOrder?.invoice?.id &&
      workOrder?.invoice?.workflowStatus?.group?.name ===
        WorkflowStatusGroupName.Approved;
    const woCompletedWithPaidInvoice =
      workOrderStatus === WorkflowStatusGroupName.Complete &&
      workOrder?.invoice?.id &&
      workOrder?.invoice?.workflowStatus?.group?.name ===
        WorkflowStatusGroupName.Paid;
    if (
      statusWithoutAddProposalAction.includes(workOrderStatus) ||
      woCompletedWithApprovedInvoice ||
      woCompletedWithPaidInvoice ||
      workOrder?.request?.proposals?.length > 0 ||
      // can't add proposal if there is COUPA PO
      workOrder?.invoice?.coupaId
    ) {
      availableAdditionalActions.push(...[renderUpdateEtaPopup]);
    } else {
      availableAdditionalActions.push(
        ...[renderUpdateEtaPopup, renderAddProposal],
      );
    }
    if (workOrderNextStatus?.find(status => status.name === 'Reject')) {
      availableAdditionalActions.push(renderRejectWorkOrder);
    }
    if (
      hasReassignWorkorkOrderPermission &&
      workOrder?.mutableFields?.includes('spCompanyId')
    ) {
      availableAdditionalActions.push(
        <ECButton
          key={reassignAction.buttonLabel}
          onClick={() => setIsOpenReassignModal(true)}
          scopes={reassignAction.scopes}
        >
          {reassignAction.buttonLabel}
        </ECButton>,
      );
    }
    return availableAdditionalActions;
  }, [
    workOrder,
    addInvoice,
    renderUpdateEtaPopup,
    renderAddProposal,
    renderRejectWorkOrder,
    workOrderNextStatus,
    isCreateInvoiceAllowed,
    statusNotToShowReassignSP,
    reassignAction,
  ]);

  const customerId = workOrder?.request?.customer?.id;
  const { data: categoryListData, isSuccess: categoriesSuccess } =
    useGetCategoriesByCompanyListQuery(customerId ? [customerId] : [], {
      skip:
        !customerId ||
        !workOrder?.request?.customer?.id ||
        !isDetailsTab ||
        !isEditMode,
    });

  const dropdownCategoryList = useMemo(() => {
    if (categoriesSuccess && categoryListData) {
      const categories = categoryListData?.data?.map(category => ({
        label: category.name,
        data: category.id,
      }));

      // if current category is not on list, add it
      if (
        workOrder?.request?.category?.name &&
        workOrder?.request?.category?.id &&
        !categories?.some(
          category => category.data === workOrder?.request?.category?.id,
        )
      ) {
        categories?.push({
          label: workOrder?.request?.category?.name,
          data: workOrder?.request?.category?.id,
        });
      }
      return categories;
    } else {
      return [];
    }
  }, [
    categoriesSuccess,
    categoryListData,
    workOrder?.request?.category?.name,
    workOrder?.request?.category?.id,
  ]);

  const { data: assets, isSuccess: isSuccessAssets } = useGetAssetsListQuery(
    {
      branchId: workOrder?.request?.asset?.branch?.id,
      agId: workOrder?.request?.asset?.assetType?.id,
      limitByUserBranch: false,
    },
    {
      skip:
        !workOrder?.request?.asset?.branch?.id ||
        !workOrder?.request?.asset?.assetType?.id ||
        !isEditMode ||
        !isDetailsTab,
    },
  );

  const workOrderAsset = useMemo(() => {
    return !workOrder?.request?.asset?.generalAsset?.id &&
      workOrder?.request?.asset?.id &&
      !workOrder?.request?.pmId
      ? {
          href: workOrder.request?.asset?.id?.toString(),
          text:
            workOrder.request?.asset?.name ||
            workOrder.request?.asset?.id?.toString(),
        }
      : undefined;
  }, [workOrder]);

  const workOrderGeneralAsset = useMemo(() => {
    return workOrder?.request?.asset?.generalAsset && !workOrder?.request?.pmId
      ? {
          href: workOrder.request?.asset?.generalAsset?.id?.toString(),
          text:
            workOrder.request?.asset?.generalAsset?.name ||
            workOrder.request?.asset?.generalAsset?.id?.toString(),
        }
      : undefined;
  }, [workOrder]);

  const workOrderPm = useMemo(() => {
    return workOrder?.request?.pmId
      ? {
          href: workOrder?.request?.pmId.toString(),
          text:
            workOrder.request?.pm?.name || workOrder?.request?.pmId.toString(),
        }
      : undefined;
  }, [workOrder]);

  const dropdownPriorityList = useMemo(() => {
    if (priorityData && isSuccessPriority) {
      const priorities = priorityData?.data?.map(priority => ({
        label: priority.name,
        data: priority.id,
      }));
      // if current priority is not on list, add it
      if (
        workOrder?.priorityId &&
        !priorities?.some(priority => priority.data === workOrder?.priorityId)
      ) {
        priorities?.push({
          label: workOrder?.request?.priority?.name,
          data: workOrder?.priorityId,
        });
      }
      return priorities;
    }
    return [];
  }, [workOrder?.priorityId, isSuccessPriority, priorityData]);

  const lastCommentExtraComponent = useMemo(() => {
    return (
      <LastCommentExtraRow
        lastCommentUser={workOrder?.request?.lastCommentUser}
        lastCommentEmail={workOrder?.request?.lastCommentEmail}
      />
    );
  }, [workOrder]);

  const summaryData = useMemo(() => {
    const summaryFields: SummaryFieldsData[] = [
      {
        id: 'woId',
        label: 'Work Order ID',
        data: workOrder?.id,
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'woStatus',
        label: 'WO Status',
        data:
          workOrder?.workflowStatus?.name ||
          workOrder?.workflowStatus?.group?.name,
        type: SummaryFieldTypes.Status,
      },
      {
        id: 'customer',
        label: 'Customer',
        data: workOrder?.request?.customer?.name,
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'location',
        label: 'Location',
        data: workOrder?.request?.asset?.branch?.name,
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'storeNumber',
        label: 'Store Number',
        data: workOrder?.request?.asset?.branch?.storeNumber,
        type: SummaryFieldTypes.Text,
      },
    ];

    const foundAsset = assets?.data?.some(
      asset => asset.id === workOrder?.request?.asset?.id,
    );

    if (foundAsset && assets && assets.data.length > 1) {
      summaryFields.push({
        id: 'assetName',
        label: 'Asset Name',
        data: workOrder?.mutableFields?.includes('assetId')
          ? summaryFieldsValues?.categoryId || ''
          : workOrder?.request?.asset?.name,
        type: workOrder?.mutableFields?.includes('assetId')
          ? SummaryFieldTypes.Select
          : SummaryFieldTypes.Text,
        onChange: optionData =>
          handleSummaryFieldChange('assetId')(optionData?.data),
        options: assets?.data?.map(asset => ({
          label: asset.generalAsset?.name || asset.name,
          data: asset.generalAsset?.id || asset.id,
        })),
        isEditable: workOrder?.mutableFields?.includes('assetId'),
      });
    } else {
      summaryFields.push({
        id: 'assetId',
        label: 'Asset',
        data: workOrder?.request?.asset?.name,
        type: SummaryFieldTypes.Text,
      });
    }

    summaryFields.push(
      {
        id: 'serviceProviderWoId',
        label: 'Service Provider WO ID',
        defaultData: workOrder?.serviceProviderWoId,
        data: summaryFieldsValues?.id || '',
        isEditable: true,
        onChange: handleSummaryFieldChange('id'),
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'category',
        label: 'Category',
        data: summaryFieldsValues?.categoryId || '',
        type: SummaryFieldTypes.Select,
        onChange: optionData =>
          handleSummaryFieldChange('categoryId')(optionData?.data),
        options: isEditMode
          ? dropdownCategoryList
          : [
              {
                ...workOrder?.request?.category,
                label: workOrder?.request?.category?.name,
              },
            ],
        isEditable: workOrder?.mutableFields?.includes('requestCategoryId'),
      },
      {
        id: 'priority',
        label: 'Priority',
        data:
          isEditMode && hasEditPriorityOnWOPermission
            ? summaryFieldsValues?.priorityId
            : workOrder?.request?.priority?.name,
        type:
          isEditMode && hasEditPriorityOnWOPermission
            ? SummaryFieldTypes.Select
            : SummaryFieldTypes.Priority,
        onChange: optionData =>
          handleSummaryFieldChange('priorityId')(optionData?.data),
        options: dropdownPriorityList,
        isEditable: isEditMode && hasEditPriorityOnWOPermission,
      },
    );

    if (workOrder?.coupaSetting?.coupaPoUrl) {
      summaryFields.push({
        id: 'coupaId',
        label: '',
        type: SummaryFieldTypes.CoupaCode,
        data: workOrder?.invoice?.coupaId,
        href: workOrder?.coupaSetting?.coupaPoUrl || '',
      });
    }
    const lastComment = workOrder?.request?.lastComment;
    if (lastComment) {
      summaryFields.push({
        id: 'lastComment',
        label: 'Last Comment',
        data: lastComment,
        type: SummaryFieldTypes.ChipText,
        extraComponent: lastCommentExtraComponent,
        shouldShowModalOnClick: true,
      });
    }

    return summaryFields;
  }, [
    workOrder,
    assets?.data,
    dropdownCategoryList,
    priorityData?.data,
    spUsersData,
    summaryFieldsValues,
    userProfile,
    isEditMode,
    hasEditPriorityOnWOPermission,
    dropdownPriorityList,
    lastCommentExtraComponent,
  ]);

  const handleConfirmProceed = () => {
    setDrawerType('invoice');
    setCompleteConfirm(false);
  };

  const handleCancelConfirmComplete = () => {
    setCompleteConfirm(false);
  };

  const confirmCompleteModalContent = useMemo(() => {
    return (
      <ECEasyForm
        pattern="modal"
        config={fancyFormConfirmComplete.config}
        fields={fancyFormConfirmComplete.fields}
        isSendingData={isLoadingActions}
        isLoading={isLoadingActions}
        isLoadingForm={false}
        onFormSubmit={handleConfirmProceed}
        customDrawerTitle={
          'There is an open Proposal on the Work Order.  Adding an Invoice will cancel the Proposal.'
        }
        additionalActions={
          <ECButton
            type="button"
            variant="text"
            onClick={handleCancelConfirmComplete}
          >
            {t('translation:dynamicForm.cancel')}
          </ECButton>
        }
      />
    );
  }, [handleCancelConfirmComplete, handleConfirmProceed]);

  const isCustomer = useCustomerUser();

  const navigate = useNavigate();

  const [reassignSelectedSp, setReassignSelectedSp] = useState<any>(null);

  const onSubmitReassignWorkOrder = useCallback(() => {
    // make sure if SP select another SP he will be redirect since he loses access to the WO
    if (reassignSelectedSp !== companyProfile?.id) {
      redirectToWorkordersList();
    }
  }, [workOrder, companyProfile, reassignSelectedSp, navigate]);

  const redirectToWorkordersList = () => {
    navigate('../sp/work-orders');
  };

  const invalidateTags = () => {
    dispatch(workOrdersApi.util.invalidateTags(['WorkOrderById']));
    dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
    dispatch(workOrdersApi.util.invalidateTags(['WorkOrderStatusList']));
    dispatch(
      dashboardApi.util.invalidateTags([
        'PendingWorkorders',
        'UnassignedWorkorders',
      ]),
    );
  };
  const handlePrintButtonClick = () => {
    const wo = {
      ...workOrder,
      workflowStatus: workOrder?.workflowStatus?.name,
      locationName: workOrder?.request?.asset?.branch?.name,
      locationStoreNumber: workOrder?.request?.asset?.branch?.storeNumber,
      serviceProviderName: workOrder?.serviceProvider?.name,
      assetName: workOrder?.request?.asset?.name,
      assetImageURL: assetProfileAttachment?.url,
      assetGroupName: workOrder?.request?.asset?.assetType?.name,
      category: workOrder?.request?.category?.name,
      priorityName: workOrder?.request?.priority?.name,
      spAssigneeUserName: workOrder?.assigneeSPUser?.fullName,
      tradeName: workOrder?.request?.trade?.name,
      problem: workOrder?.request?.problem?.name,
      serviceProviderPhone: userProfile?.company?.phone,
      serviceProviderPhoneExt: userProfile?.company?.phoneExt,
      requestedBy: workOrder?.request?.requestedBy,
      jobTitleName: userProfile?.jobTitle?.name,
      customerCode: workOrder?.customCode,
      spwoId: workOrder?.serviceProviderWoId,
      createdBy: null,
      workOrderAge: null,
    };
    const htmlContent = generateWODetailsHTMLContent(
      wo,
      workOrder?.request?.asset,
      assetWarranty?.[0]?.status,
    );
    const newTab = window.open();
    if (newTab) {
      newTab.document.write(htmlContent);
      const debouncedPrint = _.debounce(() => {
        newTab.print();
      }, PRINT_DEBOUNCE_TIME);
      debouncedPrint();
    }
  };

  const hideAllocateInventoryButtonStates = useMemo(
    () => [
      WorkflowStatusGroupName.Complete,
      WorkflowStatusGroupName.Cancelled,
      WorkflowStatusGroupName.SoftCompleted,
      WorkflowStatusGroupName.Pending,
    ],
    [],
  );

  const handleAllocateInventoryButtonClicked = useCallback(() => {
    setDrawerType('allocateInventoryItems');
  }, []);

  const handleAdjustAllocateInventoryButtonClicked = useCallback(() => {
    setDrawerType('adjustAllocatedInventoryItems');
  }, []);

  useEffect(() => {
    if (!isDetailsTab) {
      return;
    }
    const isAllocatedItemsAvailable =
      !!allocatedInventoryItems?.allocatedItems &&
      allocatedInventoryItems?.allocatedItems?.length > 0;
    if (workOrder && isSuccessWorkOrder) {
      const localDetailsFormFields = [...detailsFormFields];
      const allocatedInventoryField = localDetailsFormFields?.find(
        field => field.fieldName === 'allocatedInventory',
      );
      if (allocatedInventoryField) {
        // if the customer of the WO is not parent of the SP, whole inventory should be hidden
        if (userProfile?.company?.parentId !== workOrder?.request?.customer?.id)
          allocatedInventoryField.visible = false;

        const shouldHideAllocateInventory = !(
          !hideAllocateInventoryButtonStates?.includes?.(
            workOrder?.workflowStatus?.group?.name?.toUpperCase(),
          ) &&
          isUserHaveGetInventoryPermission &&
          isInternalCompany &&
          userProfile?.company?.parentId === workOrder?.request?.customer?.id &&
          userProfile?.customerBranchesForSP
            ?.map(customer => customer.companyId)
            .includes(workOrder?.request?.customer?.id)
        );

        allocatedInventoryField.hideAllocateButton =
          shouldHideAllocateInventory;
        allocatedInventoryField.actionButtonClicked = isAllocatedItemsAvailable
          ? handleAdjustAllocateInventoryButtonClicked
          : handleAllocateInventoryButtonClicked;
        allocatedInventoryField.value = groupAndProcessInventoryItems(
          inventoryAllocatedItems,
        );

        const checkinTimeField = localDetailsFormFields?.find(
          field => field.fieldName === 'checkinTime',
        );

        if (checkinTimeField) {
          checkinTimeField.visible = !!workOrder?.showCheckinTime;
        }

        setDetailsFormFields(localDetailsFormFields);
      }
    }
  }, [workOrder, isSuccessWorkOrder, allocatedInventoryItems]);

  const existingDataAllocatedInventoryItemsDrawer = useMemo(() => {
    return {
      workOrderId: workOrder?.id,
      nte: workOrder?.nte,
      invoiceTotal: workOrder?.invoice?.total ?? 0,
    };
  }, [workOrder]);

  const handleInventoryDrawerClose = useCallback(() => {
    handleClose();
  }, []);

  const closeRejectModal = useCallback(() => {
    setIsOpenRejectModal(false);
  }, []);

  const inventoryAllocatedItems = useMemo(() => {
    return allocatedInventoryItems?.allocatedItems
      ? convertComputedInventoryItemsToInventoryShape(
          allocatedInventoryItems?.allocatedItems,
        )
      : [];
  }, [allocatedInventoryItems?.allocatedItems]);

  const [triggerAsset, resultAsset] = useLazyGetAssetByIdQuery({});
  const { data: assetObject, isSuccess: isSuccessAsset } = resultAsset;
  useEffect(() => {
    if (!isDetailsTab) {
      return;
    }
    if (workOrder?.request?.asset?.id) {
      triggerAsset(workOrder.request.asset.id);
    }
  }, [workOrder?.request?.asset?.id]);

  const additionalTabs = useMemo(() => {
    return assetObject?.refrigerantTrackingEnable === 1
      ? [
          {
            value: 'refrigerant',
            label: 'Refrigerant Tracking',
            scopes: [P.GetWorkOrder],
            content: <Outlet />,
            link: 'refrigerant',
            editable: false,
            visible: true,
          },
        ]
      : [];
  }, [assetObject?.refrigerantTrackingEnable]);

  if (isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <ECWorkflowTemplate
        showAdditionalButtons={false}
        tabsAsLinks
        title={`${t('translation:workflow.summary.woId')} #${workOrder?.id}`}
        summaryData={summaryData}
        workflowStatus={workOrder?.workflowStatus?.group?.name}
        useUpdateMutation={useUpdateWorkOrder}
        editConfig={fancyFormElementsEdit.config}
        editFields={formFields}
        detailsConfig={formElementsDetails.config}
        detailsFields={detailsFormFields}
        detailsData={workOrder}
        imgSrc={assetProfileAttachment?.url}
        profileAttachment={assetProfileAttachment}
        moduleName={moduleName}
        moduleId={id}
        warrantyBadgeType={assetWarranty?.[0]?.status}
        actions={workOrderNextStatus}
        //onCopyClick={handleCopyClick}
        onActionClick={doUpdateStatus}
        onExitEditModeWithoutSave={() => initializeFields(workOrder)}
        onEditClose={handleCloseModal || undefined}
        showAddCommentButton
        ignoredActionNames={['Reject']}
        isEditAllowed={
          hasEditWorkorderPermission && !!workOrder?.mutableFields?.length
        }
        asset={workOrderAsset}
        generalAsset={workOrderGeneralAsset}
        pm={workOrderPm}
        invoices={
          workOrder?.invoice
            ? {
                content: [
                  {
                    href: workOrder.invoice?.id.toString(),
                    text: workOrder.invcViewId,
                  },
                ],
              }
            : undefined
        }
        proposals={{
          content: workOrder?.request?.proposals?.map(proposal => {
            return { href: proposal.id.toString(), text: proposal.viewId };
          }),
        }}
        workOrder={{
          href: workOrder?.id.toString(),
          text: workOrder?.viewId,
        }}
        rfps={
          workOrder?.rfpId
            ? {
                content: [
                  {
                    href: workOrder?.rfpId?.toString() ?? '',
                    text: workOrder?.rfpId?.toString() ?? '',
                  },
                ],
              }
            : undefined
        }
        additionalActions={additionalActions}
        isLoadingActions={isLoadingActions}
        onEditButtonClick={setIsEditMode}
        showPrintButton
        onPrintButtonClick={handlePrintButtonClick}
        additionalTabs={additionalTabs}
        showSkeletonLoader={isLoadingWorkOrder}
      />
      {isDetailsTab && (
        <>
          <CreateSPInvoiceDrawer
            allocatedInventoryItems={allocatedInventoryItems}
            isOpen={drawerType === 'invoice'}
            onClose={handleClose}
            existingData={{
              companyId: workOrder?.request?.customer?.id,
              spCompanyId: workOrder?.spCompanyId,
              requestId: workOrder?.requestId, // not visible, but required for creation
              workorderId: workOrder?.id,
              pmId: workOrder?.request?.pmId,
              requestCategoryName: workOrder?.request?.category?.name,
              assetName: workOrder?.request?.asset?.name,
              assetId: workOrder?.request?.asset?.id,
              problem: workOrder?.request?.problem?.name,
              branchName: workOrder?.request?.asset?.branch?.name,
              customer: workOrder?.request?.customer?.name,
              nte: workOrder?.nte,
              workflowStatusId: 1,
              workflowId: workOrder?.workflowId,
              internal: workOrder?.internal,
              requiredFields: workOrder?.requiredFields,
              enableCoupa: workOrder?.coupaSetting?.enableCoupa,
              coupaSetting: workOrder?.coupaSetting,
              isActiveWarranty: ['active', 'partial'].includes(
                assetWarranty?.[0]?.status?.toLowerCase(),
              ),
            }}
          />
          <CreateProposalDrawer
            isOpen={drawerType === 'proposal'}
            onClose={handleClose}
            existingData={{
              companyId: workOrder?.request?.customer?.id,
              spCompanyId: workOrder?.spCompanyId,
              requestId: workOrder?.requestId, // not visible, but required for creation
              workorderId: workOrder?.id,
              assetName: workOrder?.request?.asset?.name,
              assetId: workOrder?.request?.asset?.id,
              problem: workOrder?.request?.problem?.name,
              branchName: `${
                workOrder?.request?.asset?.branch?.name +
                ', ' +
                workOrder?.request?.asset?.branch?.mailingAddress?.cityName +
                ' • ' +
                'Store #' +
                workOrder?.request?.asset?.branch?.storeNumber
              }`,
              customer: workOrder?.request?.company?.name,
              nte: workOrder?.nte,
              workflowStatusId: 36,
              workflowId: workOrder?.workflowId,
              requiredFields: workOrder?.requiredFields,
            }}
          />
          <AllocatedInventoryItems
            isOpen={drawerType === 'allocateInventoryItems'}
            onClose={handleInventoryDrawerClose}
            existingData={existingDataAllocatedInventoryItemsDrawer}
          />
          <AdjustAllocatedInventoryItems
            isOpen={drawerType === 'adjustAllocatedInventoryItems'}
            onClose={handleInventoryDrawerClose}
            existingData={existingDataAllocatedInventoryItemsDrawer}
            allocatedInventoryItems={{
              allocatedItems: inventoryAllocatedItems,
            }}
          />
          <Popover
            id={popoverId}
            open={action === 'eta'}
            anchorEl={anchorEl}
            onClose={handleCloseModal}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              elevation: 0,
              style: {
                backgroundColor: 'transparent',
              },
            }}
          >
            <ECBox
              boxShadow={2}
              sx={{
                position: 'relative',
                mt: '10px',
                backgroundColor: 'green',
                '&::before': {
                  bgcolor: theme => theme.palette.common.white,
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  width: 12,
                  height: 12,
                  top: -6,
                  transform: 'rotate(45deg)',
                  left: 'calc(10%)',
                },
              }}
            />
            <ECWorkOrderUpdateEtaModalContent
              row={workOrder}
              handleCloseModal={handleCloseModal}
            />
          </Popover>
          <ECModal
            isOpen={isOpenRejectModal}
            onClose={closeRejectModal}
            noPadding
          >
            <ECWorkOrderRejectModalContent
              row={workOrder}
              declineTypes={declineTypesData}
              fromDetails={true}
              handleCloseModal={handleCloseRejectModal}
              workOrderNextStatus={workOrderNextStatus}
            />
          </ECModal>
          <ECModal
            isOpen={isOpenReassignModal}
            onClose={closeReassignModal}
            noPadding
          >
            <reassignAction.modalContent
              onClose={closeReassignModal}
              onCancel={closeReassignModal}
              module="workorder"
              selectedRows={[workOrder]}
              invalidateQuery={
                reassignSelectedSp !== companyProfile?.id
                  ? undefined
                  : invalidateTags
              }
              additionalData={{
                tradeId: workOrder?.request?.trade?.id,
                branchId: workOrder?.request?.asset?.branch?.id,
              }}
              customerId={
                isInternalCompany ? workOrder?.request?.customer?.id : undefined
              }
              onChange={setReassignSelectedSp}
              onSubmit={onSubmitReassignWorkOrder}
            />
          </ECModal>
          <ECModal
            isOpen={completeConfirm}
            onClose={() => setCompleteConfirm(false)}
          >
            {confirmCompleteModalContent}
          </ECModal>
          <ECModal
            isOpen={isOpenAcceptModal}
            onClose={closeAcceptModal}
            noPadding
          >
            <UpdateETAAction
              onClose={closeAcceptModal}
              onCancel={closeAcceptModal}
              customAction={() => {
                doUpdateWorkOrderStatus({
                  id: workOrder?.id || 0,
                  ...statusData,
                });
              }}
              selectedWO={workOrder}
            />
          </ECModal>
        </>
      )}
    </>
  );
}
