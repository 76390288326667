import { ECFormControlLabel, ECFormGroup } from 'app/components/ECForm';
import { useEffect, useState } from 'react';
import {
  ECBox,
  ECButton,
  ECGrid,
  ECStack,
  ECTextField,
  ECTypography,
} from 'app/components';
import { ECEasyFormGridItem } from 'app/components/ECForm/ECEasyFormGridItem';
import { ECPhoneField } from 'app/components/ECPhoneField';
import { ECEasyFormSectionGridItem } from 'app/components/ECForm/ECFormCell';
import { ECCheckbox } from 'app/components/ECCheckbox';
import { Alert, AlertTitle } from '@mui/material';
import { useLazyGetServiceProvidersByCompanyQuery } from 'services/profileApi';
import { useTranslation } from 'react-i18next';
import { CompanyEntity } from 'types/Company';
import { isValidEmail } from 'utils/validate-fields';
import { cleanedPhoneNumber } from 'utils/strings/phone';

interface Step1Props {
  existingData?: any;
  onClose?: () => void;
  setExistingData?: (fieldName: string, value: any) => void;
  onCompaniesFetch: (data: CompanyEntity[], contacts: Contact[]) => void;
}

export interface Contact {
  email?: string;
  phone?: string;
  ext?: string;
  smsNotification?: boolean;
}

export const Step1 = ({
  existingData,
  setExistingData,
  onClose,
  onCompaniesFetch,
}: Step1Props) => {
  const { t } = useTranslation();

  const [contacts, setContacts] = useState<Contact[]>([{}]);
  const [validations, setValidations] = useState<any[]>([]);

  const [trigger, companies] = useLazyGetServiceProvidersByCompanyQuery();

  const handleInviteClick = async () => {
    const { name, email, phone } = existingData;
    let newValidations: any[] = [];
    if (!name?.trim()) {
      newValidations.push({
        field: 'name',
        validationMessage: 'Company Name is required!',
      });
    }
    if (!email) {
      newValidations.push({
        field: 'email',
        validationMessage: 'Company Email is required!',
      });
    } else if (!isValidEmail(email)) {
      newValidations.push({
        field: 'email',
        validationMessage: t('translation:form.validation.correctFormatEmail'),
      });
    }
    if (!phone) {
      newValidations.push({
        field: 'phone',
        validationMessage: 'Company Phone is required!',
      });
    }
    setValidations(newValidations);
    if (newValidations.length === 0) {
      trigger({
        name,
        email,
        phone,
      });
    }
  };

  useEffect(() => {
    if (companies.isSuccess) {
      onCompaniesFetch(companies.data ?? [], contacts);
    }
  }, [companies.isSuccess]);

  return (
    <>
      <ECBox
        display="flex"
        flexDirection="column"
        sx={{ width: '100%', padding: '2rem' }}
      >
        <ECEasyFormSectionGridItem>
          <ECTypography
            variant="h6"
            sx={{ marginBottom: '1rem' }}
            color={theme => theme.palette.text.primary}
          >
            Company Information
          </ECTypography>
        </ECEasyFormSectionGridItem>

        <ECGrid container spacing={2}>
          <ECEasyFormGridItem config={{ cols: 1 }}>
            <ECTextField
              key="name"
              id="name"
              name="name"
              sx={{ width: '100%' }}
              error={validations.find(v => v.field === 'name') !== undefined}
              helperText={
                validations.find(v => v.field === 'name')?.validationMessage
              }
              variant="filled"
              label="Company Name *"
              placeholder="Company Name"
              value={existingData?.name}
              InputLabelProps={existingData?.name ? { shrink: true } : {}}
              onChange={event => {
                setExistingData?.('name', event.target.value);
                setValidations([]);
              }}
            />
          </ECEasyFormGridItem>

          <ECEasyFormGridItem config={{ cols: 1 }}>
            <ECTextField
              key="email"
              id="email"
              name="email"
              sx={{ width: '100%' }}
              error={validations.find(v => v.field === 'email') !== undefined}
              helperText={
                validations.find(v => v.field === 'email')?.validationMessage
              }
              variant="filled"
              label="Company Email *"
              placeholder="Company Email"
              value={existingData?.email}
              InputLabelProps={existingData?.email ? { shrink: true } : {}}
              onChange={event => {
                setExistingData?.('email', event.target.value);
                setValidations([]);
              }}
            />
          </ECEasyFormGridItem>

          <ECBox
            display="flex"
            justifyContent="space-between"
            sx={{ width: '100%', padding: '1rem' }}
            gap={'5px'}
          >
            <ECBox>
              <ECPhoneField
                key="phone"
                id="phone"
                name="phone"
                error={validations.find(v => v.field === 'phone') !== undefined}
                validationMessage={
                  validations.find(v => v.field === 'phone')?.validationMessage
                }
                variant="filled"
                label="Company Phone *"
                value={existingData?.phone}
                onChange={event => {
                  const clearedValue = cleanedPhoneNumber(event.target.value);
                  setExistingData?.('phone', clearedValue);
                  setValidations([]);
                }}
              />
            </ECBox>
            <ECBox display="flex" gap={'1px'} alignItems={'start'}>
              <ECTextField
                key="phoneExt"
                id="phoneExt"
                name="phoneExt"
                sx={{
                  marginBottom:
                    validations.find(v => v.field === 'phone') !== undefined
                      ? '20px'
                      : '0',
                  maxWidth: '110px',
                }}
                variant="filled"
                label="EXT"
                placeholder="EXT"
                value={existingData?.phoneExt ?? ''}
                inputProps={{ maxLength: 10 }}
                InputLabelProps={existingData?.phoneExt ? { shrink: true } : {}}
                onChange={event => {
                  const clearedValue = event.target.value.replace(
                    /[^0-9]/g,
                    '',
                  );
                  setExistingData?.('phoneExt', clearedValue);
                }}
              />

              <ECFormGroup
                sx={{
                  px: 1.5,
                  height: '56px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <ECFormControlLabel
                  control={
                    <ECCheckbox
                      id="smsNotification"
                      name="smsNotification"
                      value={existingData?.smsNotification}
                      checked={existingData?.smsNotification}
                      onChange={event => {
                        setExistingData?.(
                          'smsNotification',
                          event.target.checked,
                        );
                      }}
                      sx={{
                        '&.Mui-checked': {
                          color: theme => theme.palette.primary.main,
                        },
                      }}
                    />
                  }
                  label="Send Invitation via SMS"
                />
              </ECFormGroup>
            </ECBox>
          </ECBox>

          <ECEasyFormGridItem config={{ cols: 1 }}>
            <ECTextField
              key="vendorId"
              id="vendorId"
              name="vendorId"
              sx={{ width: '100%' }}
              variant="filled"
              label="Vendor ID"
              placeholder="Vendor ID"
              value={existingData?.vendorId}
              InputLabelProps={existingData?.vendorId ? { shrink: true } : {}}
              onChange={event => {
                setExistingData?.('vendorId', event.target.value);
              }}
            />
          </ECEasyFormGridItem>

          <ECEasyFormSectionGridItem>
            <ECTypography
              variant="h6"
              color={theme => theme.palette.text.primary}
            >
              Invitation Contacts
            </ECTypography>
          </ECEasyFormSectionGridItem>

          {contacts.map((contact, index) => (
            <>
              <ECBox
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: '100%', padding: '1rem' }}
              >
                <ECEasyFormGridItem config={{ cols: 1 }}>
                  <ECTextField
                    key={`contactEmail${index}`}
                    id={`contactEmail${index}`}
                    name={`contactEmail${index}`}
                    sx={{ width: '100%' }}
                    variant="filled"
                    label="Contact Email"
                    placeholder="Contact Email"
                    value={contact.email}
                    InputLabelProps={contact.email ? { shrink: true } : {}}
                    onChange={event => {
                      const newContacts = [...contacts];
                      newContacts[index].email = event.target.value;
                      setContacts(newContacts);
                    }}
                  />
                </ECEasyFormGridItem>
                {index > 0 && (
                  <ECFormGroup sx={{ px: 1.5 }}>
                    <ECButton
                      onClick={() => {
                        const newContacts = [...contacts];
                        newContacts.splice(index, 1);
                        setContacts(newContacts);
                      }}
                      variant="text"
                      color="error"
                    >
                      {t('translation:dynamicForm.remove')}
                    </ECButton>
                  </ECFormGroup>
                )}
              </ECBox>

              <ECBox
                display="flex"
                alignItems="start"
                justifyContent="space-between"
                sx={{ width: '100%', padding: '1rem' }}
                gap={'5px'}
              >
                <ECBox>
                  <ECPhoneField
                    key={`contactPhone${index}`}
                    id={`contactPhone${index}`}
                    name={`contactPhone${index}`}
                    variant="filled"
                    label="Contact Phone"
                    value={contact.phone}
                    onChange={event => {
                      const clearedValue = cleanedPhoneNumber(
                        event.target.value,
                      );
                      const newContacts = [...contacts];
                      newContacts[index].phone = clearedValue;
                      setContacts(newContacts);
                    }}
                  />
                </ECBox>
                <ECBox display="flex" gap={'1px'} alignItems="start">
                  <ECTextField
                    key={`contactPhoneExt${index}`}
                    id={`contactPhoneExt${index}`}
                    name={`contactPhoneExt${index}`}
                    variant="filled"
                    label="EXT"
                    placeholder="EXT"
                    value={contact.ext ?? ''}
                    sx={{ maxWidth: '110px' }}
                    inputProps={{ maxLength: 10 }}
                    InputLabelProps={contact.ext ? { shrink: true } : {}}
                    onChange={event => {
                      const clearedValue = event.target.value.replace(
                        /[^0-9]/g,
                        '',
                      );
                      const newContacts = [...contacts];
                      newContacts[index].ext = clearedValue;
                      setContacts(newContacts);
                    }}
                  />

                  <ECFormGroup
                    sx={{
                      px: 1.5,
                      height: '56px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <ECFormControlLabel
                      control={
                        <ECCheckbox
                          id={`contactSendInvitationSMS${index}`}
                          name={`contactSendInvitationSMS${index}`}
                          value={contact.smsNotification}
                          checked={contact.smsNotification}
                          onChange={event => {
                            const newContacts = [...contacts];
                            newContacts[index].smsNotification =
                              event.target.checked;
                            setContacts(newContacts);
                          }}
                          sx={{
                            '&.Mui-checked': {
                              color: theme => theme.palette.primary.main,
                            },
                          }}
                        />
                      }
                      label="Send Invitation via SMS"
                    />
                  </ECFormGroup>
                </ECBox>
              </ECBox>
            </>
          ))}
          {contacts.length < 3 && (
            <ECEasyFormGridItem config={{ cols: 1 }}>
              <ECButton
                variant="text"
                sx={{ marginBottom: '2rem' }}
                onClick={() => setContacts([...contacts, {}])}
              >
                {t('translation:pages.serviceProviderInvite.addContact')}
              </ECButton>
            </ECEasyFormGridItem>
          )}
          <ECEasyFormGridItem
            optionalSx={{ marginBottom: '2rem' }}
            config={{ cols: 1 }}
          >
            <Alert variant="outlined" severity="warning">
              <AlertTitle>SMS</AlertTitle>
              {t('translation:pages.serviceProviderInvite.smsInfo')}
            </Alert>
          </ECEasyFormGridItem>
        </ECGrid>

        <ECBox
          display="flex"
          sx={{ width: '100%' }}
          bgcolor={theme => theme.palette.marketingSuggestion.mainBackground}
        >
          <ECGrid
            container
            spacing={2}
            sx={{
              flexDirection: 'row',
              justifyContent: 'right',
              p: 1,
              padding: 3,
            }}
          >
            <ECEasyFormGridItem config={{ cols: 1 }}>
              <ECStack direction="row" justifyContent="right" spacing={2}>
                <ECButton
                  type="button"
                  variant="text"
                  sx={{ height: 42 }}
                  onClick={onClose}
                >
                  {t('translation:dynamicForm.cancel')}
                </ECButton>
                <ECButton
                  type={'submit'}
                  variant="contained"
                  sx={{
                    height: 42,
                    width: 'auto',
                  }}
                  onClick={handleInviteClick}
                >
                  {t('translation:pages.serviceProviderInvite.invite')}
                </ECButton>
              </ECStack>
            </ECEasyFormGridItem>
          </ECGrid>
        </ECBox>
      </ECBox>
    </>
  );
};
