import { useCallback, useEffect, useState } from 'react';
import { ECBox, ECEasyForm, ECEasyFormFieldType } from 'app/components';
import _ from 'lodash';
import { useMediaQuery, useTheme } from '@mui/material';

interface Step2Props {
  existingData?: any;
  onSubmit?: (data: any) => void;
}

const fancyFormElementsCreate = require('./fancy_form_config_step2_create.json');

export const Step2 = ({ existingData, onSubmit }: Step2Props) => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const handleExistingData = useCallback(
    (field: ECEasyFormFieldType) => {
      if (!field) return;

      if (field.type === 'group') {
        return {
          ...field,
          subFields: field?.subFields?.map(handleExistingData),
        };
      }
      return {
        ...field,
        value: _.get(existingData, field.fieldName) || '',
      };
    },
    [existingData],
  );

  const [formFields, setFormFields] = useState<any[]>(
    () => fancyFormElementsCreate.fields?.map(handleExistingData) || [],
  );

  useEffect(() => {
    setFormFields(
      fancyFormElementsCreate.fields?.map(handleExistingData) || [],
    );
  }, [existingData, handleExistingData]);

  const submitForm = useCallback(
    (formData, output) => {
      const body = {
        name: formData.get('company.name'),
        phone: formData.get('company.phone').trim().replace(/\D/g, ''),
        email: formData.get('company.email'),
        files: formData.get('company.files'),
        address: {
          line1: formData.get('address.line1'),
          line2: formData.get('address.line2'),
          cityName: formData.get('address.cityName'),
          stateProvinceCode: formData.get('address.stateProvinceCode'),
          zipCodePostalCode: formData.get('address.zipCodePostalCode'),
          countryCode: output.find(
            field => field.fieldName === 'address.countryCode',
          )?.value,
        },
      };

      onSubmit?.({
        company: {
          ...body,
        },
      });
    },
    [formFields],
  );

  return (
    <ECBox
      paddingInline={isMobileSize ? 0 : `15%`}
      alignItems={'center'}
      justifyContent={'center'}
      display={'flex'}
      flexDirection={'column'}
    >
      <ECEasyForm
        isLoadingForm={false}
        isSendingData={false}
        config={fancyFormElementsCreate.config}
        fields={formFields}
        existingData={existingData}
        showSaveButton={false}
        onFormSubmit={(formData, output) => {
          submitForm(formData, output);
        }}
        showWideSaveButton
      />
    </ECBox>
  );
};
