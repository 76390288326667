import React, { useCallback, useState } from 'react';
import { Popover, Typography } from '@mui/material';
import { UserBase } from 'types/User';
import { ECBox } from '../ECBox';
import { ECDivider } from '../ECDivider';

interface MentionUserPopoverProps {
  user: UserBase;
}

const MentionUserPopover: React.FC<MentionUserPopoverProps> = ({ user }) => {
  const [isVisibleHoverModal, setisVisibleHoverModal] = useState(false);
  const [anchorElMention, setAnchorElMention] = useState<HTMLElement | null>(
    null,
  );

  const handleMouseEnter = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setisVisibleHoverModal(true);
      setAnchorElMention(event.currentTarget);
    },
    [],
  );

  const handleMouseLeave = useCallback(() => {
    setisVisibleHoverModal(false);
    setAnchorElMention(null);
  }, []);
  return (
    <>
      <span
        style={{
          backgroundColor: 'rgba(189, 217, 255, 0.3)',
          color: '#0072CE',
        }}
        key={user.id}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        @{user?.firstName} {user?.lastName}&nbsp;
      </span>

      <Popover
        id="mention-popover"
        open={isVisibleHoverModal}
        anchorEl={anchorElMention}
        style={{ pointerEvents: 'none' }}
        onClose={handleMouseLeave}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <ECBox
          boxShadow={2}
          sx={{
            position: 'relative',
            mt: '10px',
            backgroundColor: 'green',
            '&::before': {
              bgcolor: theme => theme.palette.common.white,
              content: '""',
              display: 'block',
              position: 'absolute',
              width: 12,
              height: 12,
              top: -6,
              transform: 'rotate(45deg)',
              left: 'calc(10%)',
            },
          }}
        />
        <ECBox p={1} bgcolor="transparent" mt={-1}>
          <ECBox
            sx={theme => ({
              borderRadius: 1,
              boxShadow: 2,
              width: '350px',
              bgcolor: theme.palette.common.white,
            })}
            p={2}
          >
            {/* User Name */}
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {user?.firstName} {user?.lastName}
            </Typography>

            {/* Job Title Section */}
            <Typography variant="caption" color="textSecondary">
              Job Title
            </Typography>
            <Typography variant="body2" color="textPrimary" fontSize={16}>
              {user?.jobTitle?.name || 'N/A'}
            </Typography>

            <ECDivider sx={{ marginBottom: 1 }} />

            {/* Email Section */}
            <Typography variant="caption" color="textSecondary" mt={2}>
              Email
            </Typography>
            <Typography variant="body2" color="textPrimary" fontSize={16}>
              {user?.email || 'N/A'}
            </Typography>
            <ECDivider />
          </ECBox>
        </ECBox>
      </Popover>
    </>
  );
};

export default MentionUserPopover;
