import { ECAutocompleteStyled } from '../ECAutocomplete';
import { ECBox } from '../ECBox';
import { ECTextField } from '../ECTextField';
import { ECTypography } from '../ECTypography';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as _ from 'lodash';
import { ECCircularProgress } from '../ECCircularProgress';
import { FormHelperText, ListItemText, Stack } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { ECChip } from '../ECChip';
import { ECMenuItem } from '../ECSelect';
import { ECCheckbox } from '../ECCheckbox';
import { ECOnlyMeFilter } from '../ECTable/ECOnlyMeFilter';
import { ECIconButton } from '../ECIconButton';
import { ECPopover } from '../ECPopover';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { ECPopper } from '../ECPopper';

interface ECAutoCompletePaginatedFiltersProps {
  fieldName: string;
  filterKey: string;
  value?: any;
  disabled?: boolean;
  isReadOnlyForm?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  label?: string;
  variant?: any;
  sx?: any;
  isLoading?: boolean;
  noMoreDataToFetch?: boolean;
  queryParams?: any;
  shouldUseOriginalQueryParams?: boolean;
  useQuery?: any;
  obAlias?: string;
  st?: string;
  sbAlias?: string;
  error?: boolean;
  validationMessage?: string;
  required?: boolean;
  multiple?: boolean;
  groupBy?: (option: any) => string;
  groupByTransform?: (options: any[]) => any[];
  onLoadMoreData?: (fieldName: string, newValue?: string) => void;
  onChange?: (newValue) => void;
  helperText?: string;
  limitTags?: number;
  shouldUseFirstOptionAsDefault?: boolean;
  filterOptionsIds?: any[];
  filterOptionsFn?: (option: any) => boolean;
  extraSearchField?: string;
  optionNameAttribute?: string;
  extraSearchFieldGroup?: 'or' | 'and';
  extraSearchFieldsAlias?: string[];
  renderCustomOption?: (option: any) => React.ReactNode;
  renderCustomSelectedValue?: (value: any) => React.ReactNode;
  shouldSkipQuery?: boolean;
  customDataPath?: string;
  size?: 'small' | 'medium';
  filterOptions?: any;
  endpoint?: any;
  onFilterSubmit?: any;
  totalCount?: number;
}

const DEBOUNCE_TIME = 500;
const PER_PAGE = 10;

export const ECAutoCompletePaginatedFilters = ({
  fieldName,
  filterKey,
  value,
  disabled,
  isReadOnlyForm,
  readOnly,
  placeholder,
  label,
  isLoading: isLoadingFromProps,
  sx,
  noMoreDataToFetch: noMoreDataToFetchFromProps,
  queryParams,
  shouldUseOriginalQueryParams,
  useQuery,
  obAlias,
  sbAlias,
  st,
  error,
  validationMessage,
  multiple,
  onLoadMoreData,
  onChange,
  helperText,
  limitTags,
  shouldUseFirstOptionAsDefault,
  optionNameAttribute = 'name',
  filterOptionsIds,
  filterOptionsFn,
  extraSearchField,
  extraSearchFieldGroup,
  extraSearchFieldsAlias,
  renderCustomOption,
  renderCustomSelectedValue,
  shouldSkipQuery,
  groupBy,
  groupByTransform,
  size,
  customDataPath = 'data',
  filterOptions,
  endpoint,
  onFilterSubmit,
  totalCount,
}: ECAutoCompletePaginatedFiltersProps) => {
  const [text, setText] = useState('');
  const [page, setPage] = useState(0);
  const [selectAll, setSelectAll] = useState<boolean | undefined>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [selectedItems, setSelectedItems] = useState<any[]>(value || []);

  useEffect(() => {
    setSelectedItems(value || []);
  }, [value]);

  const openPopoverOptions = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const idPopover = useMemo(
    () => (openPopoverOptions ? 'filter-popover' : undefined),
    [openPopoverOptions],
  );

  const filterRef = useRef(null);
  const handleClickDropdown = useCallback(() => {
    setAnchorEl(filterRef?.current);
  }, []);

  const { activeFilter: activeFilterFromSelector } = useSelector(
    (state: RootState) => state.page.filter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [activeFilterFromSelector]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const params = useMemo(() => {
    const initialParams = shouldUseOriginalQueryParams
      ? queryParams
      : queryParams
        ? {
            p: page,
            t: PER_PAGE,
            sb: sbAlias || obAlias || 'name',
            s: text ? `*${text}*` : '',
            st: st || undefined,
            sglo: extraSearchFieldGroup || undefined,
            sg: extraSearchField ? 0 : undefined,
            sb1: extraSearchField ? extraSearchField : undefined,
            s1: extraSearchField && text ? `*${text}*` : undefined,
            sg1: extraSearchField ? 0 : undefined,
            sglo1: extraSearchFieldGroup || undefined,
            ob: obAlias || 'name',
            o: 'a',
            ...queryParams,
          }
        : {
            p: page,
            t: PER_PAGE,
            sb: sbAlias || obAlias || 'name',
            s: text ? `*${text}*` : '',
            st: st || undefined,
            sglo: extraSearchFieldGroup || undefined,
            sg: extraSearchField ? 0 : undefined,
            sb1: extraSearchField ? extraSearchField : undefined,
            s1: extraSearchField && text ? `*${text}*` : undefined,
            sg1: extraSearchField ? 0 : undefined,
            sglo1: extraSearchFieldGroup || undefined,
            ob: obAlias || 'name',
            o: 'a',
          };

    const finalParams = shouldUseOriginalQueryParams
      ? initialParams
      : Object.assign(
          {},
          initialParams,
          ...(extraSearchFieldsAlias?.map((alias, index) => ({
            [`sb${index + 1}`]: alias,
            [`s${index + 1}`]: text ? `*${text}*` : undefined,
            [`sg${index + 1}`]: 0,
            [`sglo${index + 1}`]: 'or',
          })) || []),
        );

    return finalParams;
  }, [
    extraSearchField,
    extraSearchFieldGroup,
    extraSearchFieldsAlias,
    obAlias,
    page,
    queryParams,
    sbAlias,
    shouldUseOriginalQueryParams,
    text,
    st,
  ]);

  const { data, isFetching } =
    useQuery?.(params, {
      skip: disabled || shouldSkipQuery,
    }) || {};

  useEffect(() => {
    setPage(0);
  }, [queryParams]);

  const [textForInput, setTextForInput] = useState(value?.name || '');

  useEffect(() => {
    if (!value) {
      setText('');
      setTextForInput('');
    } else {
      setTextForInput(value?.name);
    }
  }, [value]);

  const noMoreDataToFetch = useMemo(() => {
    if (!data) return false;
    if (!data?.config?.pagination) return true;
    if (data.config.pagination.totalCount === -1) {
      return !(data.data.length === (queryParams?.t || PER_PAGE));
    }

    return (
      (page + 1) * (queryParams?.t || PER_PAGE) >=
      data?.config?.pagination?.totalCount
    );
  }, [data, page, queryParams]);

  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    if (!!data && !isFetching) {
      setOptions(prevOptions => {
        return _.flatten(
          _.uniqBy(
            [
              _.isEmpty(value) ? undefined : value,
              ...prevOptions,
              ...(data?.[customDataPath] || data?.data || data),
            ],
            'id',
          )?.filter(
            option =>
              option &&
              !!option?.id &&
              !filterOptionsIds?.includes(option.id) &&
              (filterOptionsFn ? filterOptionsFn?.(option) : Boolean(option)),
          ),
        );
      });

      const isEndpointPaginated = !!data?.config;
      if (page === 0 && isEndpointPaginated) {
        setPage(1);
      }
    }
  }, [
    data,
    isFetching,
    filterOptionsIds,
    filterOptionsFn,
    customDataPath,
    page,
    value,
  ]);

  const optionsWithLoading = useMemo(() => {
    if (
      !_.compact(options)?.length ||
      (options?.[0]?.id &&
        options?.length === 1 &&
        options?.[0]?.id === value?.id)
    ) {
      return [{ id: 'empty', name: 'No options' }];
    }

    if (noMoreDataToFetch) {
      return _.uniqBy([...options], 'id');
    }

    return _.uniqBy([...options, { id: 'loading', name: 'Loading...' }], 'id');
  }, [options, noMoreDataToFetch, value?.id]);

  const areAllSelected = useMemo(() => {
    if (!multiple) return false;
    const selectableOptions = optionsWithLoading.filter(
      option =>
        option.id !== 'empty' && option.id !== 'loading' && Boolean(option.id),
    );
    return (
      selectableOptions.length > 0 &&
      selectableOptions.every(option =>
        selectedItems.some(selected => selected.id === option.id),
      )
    );
  }, [optionsWithLoading, selectedItems, multiple]);

  useEffect(() => {
    setSelectAll(areAllSelected);
  }, [areAllSelected]);

  const handleSelectAllToggle = () => {
    if (selectAll) {
      setSelectedItems([]);
      onChange?.([]);
    } else {
      const allOptions = optionsWithLoading.filter(
        option =>
          option.id !== 'empty' &&
          option.id !== 'loading' &&
          Boolean(option.id),
      );
      setSelectedItems(allOptions);
      onChange?.(allOptions);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (selectAll) {
      const newOptions = options.filter(
        option =>
          !selectedItems.find(selected => selected.id === option.id) &&
          option.id !== 'empty' &&
          option.id !== 'loading',
      );
      if (newOptions.length > 0) {
        const updatedSelectedItems = [...selectedItems, ...newOptions];
        setSelectedItems(updatedSelectedItems);
        onChange?.(updatedSelectedItems);
      }
    }
  }, [options, selectAll]);

  const renderSelectAllButton = useCallback(
    () => (
      <ECMenuItem
        onClick={handleSelectAllToggle}
        sx={{ color: 'primary.main' }}
      >
        {selectAll ? 'Deselect All' : 'Select All'}
      </ECMenuItem>
    ),
    [selectAll],
  );

  const renderAutocompleteOption = useCallback(
    (props, option, state) => {
      if (!option || option?.[optionNameAttribute] === value?.name) {
        return;
      }

      if (option.id === 'empty') {
        return (
          <ECBox
            key={`autocomplete-option-${option?.id}`}
            id={`autocomplete-id-${option?.id}-${option?.[optionNameAttribute]}`}
            bgcolor={theme => theme.palette.common.white}
            height={42}
          >
            <ECBox display="flex" p={1}>
              <ECTypography variant="body1">No options</ECTypography>
            </ECBox>
          </ECBox>
        );
      }

      if (option.id === 'loading') {
        return (
          <ECBox
            key={`autocomplete-option-${option?.id}`}
            id={`autocomplete-id-${option?.id}-${option?.[optionNameAttribute]}`}
            bgcolor={theme => theme.palette.common.white}
            height={42}
          >
            <ECBox display="flex" justifyContent="center" p={1}>
              <ECCircularProgress size={20} />
            </ECBox>
          </ECBox>
        );
      }

      if (option.id === 'selectAllToggle') {
        return renderSelectAllButton();
      }

      return (
        <ECBox>
          <ECMenuItem
            {...props}
            sx={{ height: '52px' }}
            key={option.fieldName || option.id}
          >
            <ECCheckbox checked={state.selected} />
            <ListItemText
              sx={{
                '& span': {
                  maxWidth: '170px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
              primary={option.label || option.name}
            />
            <ECOnlyMeFilter
              activeFilter={{
                [filterKey]: [
                  {
                    id: option?.id,
                    name: option?.fieldName || option?.name,
                  },
                ],
              }}
              closePopover={() => {
                setSelectedItems(
                  selectedItems.filter(item => item.id !== option.id),
                );
              }}
            />
          </ECMenuItem>
        </ECBox>
      );
    },
    [value?.name, optionNameAttribute, renderSelectAllButton, filterKey],
  );

  const handleChange = (event, val, reason, details) => {
    setSelectedItems(val);
    onChange?.(val);
  };

  const [hasPopulatedFirstOption, setHasPopulatedFirstOption] = useState(false);

  useEffect(() => {
    if (
      !hasPopulatedFirstOption &&
      !value &&
      shouldUseFirstOptionAsDefault &&
      typeof optionsWithLoading?.[0]?.id === 'number'
    ) {
      setHasPopulatedFirstOption(true);
      handleChange(null, [optionsWithLoading?.[0]], null, null);
    }
  }, [
    optionsWithLoading,
    hasPopulatedFirstOption,
    shouldUseFirstOptionAsDefault,
    value,
  ]);

  const debouncedHandleSearchChange = useRef(
    _.debounce((e, newValue, reason) => {
      if (reason === 'reset' || value?.id === '') {
        return;
      }

      const optionEl = document.querySelector(`[data-name="first"]`);
      optionEl?.scrollIntoView({ block: 'nearest', inline: 'start' });

      if (value?.id && newValue === value?.name) {
        return;
      }

      setText(newValue);
      setPage(0);
      setOptions([]);
    }, DEBOUNCE_TIME),
  ).current;

  const renderPopper = useCallback(props => {
    return (
      <ECPopper
        {...props}
        sx={{
          transform: 'translate3d(8px, 53px, 0px) !important',
          minWidth: 'fit-content !important',
          mt: '8px !important',
          '& .MuiPopover-paper': {
            boxShadow: 'none',
          },
          '.MuiAutocomplete-listbox': {
            boxShadow: 'none',
          },
          '.MuiPaper-elevation1': {
            boxShadow: 'none',
          },
          '.MuiPaper-elevation': {
            boxShadow: 'none',
          },
        }}
      />
    );
  }, []);

  const handleInputChange = (e, newValue, reason) => {
    if (reason === 'reset') return;
    setTextForInput(newValue);
    debouncedHandleSearchChange(e, newValue, reason);
  };

  const handleBlur = () => {
    setTextForInput(value?.name);
  };

  const updatedOptions = useMemo(() => {
    if (multiple) {
      const baseOptions = [
        { id: 'selectAllToggle', name: 'Select All' },
        ...optionsWithLoading,
      ];

      if (groupBy && groupByTransform) {
        return groupByTransform(baseOptions);
      }

      return baseOptions;
    }
    return optionsWithLoading;
  }, [multiple, optionsWithLoading, groupBy, groupByTransform]);

  const isReadOnly = isReadOnlyForm || readOnly;

  const filterChip = useMemo(() => {
    if (
      !activeFilter?.[filterKey]?.length ||
      activeFilter?.[filterKey]?.length === totalCount ||
      selectAll ||
      activeFilter?.[filterKey]?.length === 0
    ) {
      return (
        <ECChip
          sx={{ width: '48px', height: '24px' }}
          label="All"
          color="Light Grey"
        />
      );
    }

    return (
      <ECChip
        sx={{ width: '48px', height: '24px' }}
        label={activeFilter?.[filterKey]?.length}
        color="Dark Blue"
      />
    );
  }, [activeFilter, filterKey, selectAll, options]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      width="200px"
      height="40px"
      border="1px solid #E0E0E0"
      borderRadius="4px"
      display="flex"
      justifyContent="space-around"
    >
      <>
        <ECTypography
          color={theme => theme.palette.text.secondary}
          variant="caption"
          minWidth="70px"
        >
          {label}
        </ECTypography>
        <ECBox
          display="flex"
          alignItems="center"
          onClick={handleClickDropdown}
          ref={filterRef}
        >
          {filterChip}
          <ECIconButton
            type="default"
            size="small"
            noPadding
            sx={{ marginLeft: '5px' }}
          >
            <ArrowDropDown />
          </ECIconButton>
        </ECBox>
        <ECPopover
          id={idPopover}
          open={openPopoverOptions}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 35,
            horizontal: 90,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            bgcolor: 'transparent',
            '& .MuiPopover-paper': {
              width: '100%',
              maxWidth: '350px',
              maxHeight: 'calc(100vh - 150px)',
              overflow: 'hidden',
              borderRadius: '8px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              bgcolor: 'white',
            },
          }}
        >
          <ECBox
            display="flex"
            flexDirection="column"
            bgcolor="white"
            height="544px"
            maxHeight="calc(100vh - 150px)"
            width="350px"
            padding="8px"
            overflow="auto"
          >
            <ECAutocompleteStyled
              id={fieldName}
              disablePortal
              PopperComponent={renderPopper}
              sx={{
                '.MuiFilledInput-root': {
                  paddingTop: placeholder ? undefined : '10px',
                  paddingBottom: placeholder ? undefined : '10px',
                  paddingRight: '8px !important',
                },
                '.MuiAutocomplete-tag': {
                  display: 'none',
                },
                ...sx,
                width: '100%',
                maxWidth: '100%',
              }}
              size={size}
              multiple={multiple}
              value={selectedItems}
              disabled={disabled}
              groupBy={
                groupBy && groupByTransform
                  ? option => groupBy(option)
                  : undefined
              }
              options={updatedOptions}
              loading={isFetching}
              getOptionKey={option => option?.id}
              filterOptions={x => x}
              readOnly={isReadOnly}
              getOptionLabel={option => option?.[optionNameAttribute] ?? ''}
              isOptionEqualToValue={(option, val) =>
                val?.id
                  ? option?.id === val?.id
                  : option?.[optionNameAttribute] === val?.name
              }
              onInputChange={handleInputChange}
              onBlur={handleBlur}
              popupIcon={null}
              ListboxProps={{
                onScroll: (event: React.SyntheticEvent) => {
                  const listboxNode = event.currentTarget as HTMLElement;
                  if (
                    listboxNode.scrollTop + listboxNode.clientHeight >=
                      listboxNode.scrollHeight - 1 &&
                    !noMoreDataToFetch &&
                    !isFetching
                  ) {
                    setPage(prevPage => prevPage + 1);
                  }
                },
                role: 'list-box',
                sx: {
                  height: '480px',
                  maxWidth: '100%',
                  maxHeight: 'calc(100vh - 214px)',
                  overflow: 'auto',
                  border: 'none !important',
                  boxShadow: 'none !important',
                },
              }}
              inputValue={
                textForInput !== undefined ? textForInput : value?.name || text
              }
              limitTags={isReadOnly ? limitTags : undefined}
              getLimitTagsText={more => `& ${more} more`}
              renderInput={params => (
                <ECTextField
                  {...params}
                  error={!!validationMessage || !!error}
                  placeholder="Search"
                  size="medium"
                  variant="filled"
                  hiddenLabel={!label}
                  sx={{
                    '&:hover': {
                      '.clear': {
                        display: 'inline-flex',
                      },
                    },
                  }}
                  value={
                    textForInput !== undefined
                      ? textForInput
                      : value?.name || text
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <></>,
                  }}
                />
              )}
              filterSelectedOptions={false}
              disableCloseOnSelect={false}
              open={true}
              renderOption={renderAutocompleteOption}
              onChange={handleChange}
            />
          </ECBox>
        </ECPopover>

        {validationMessage && (
          <FormHelperText
            sx={theme => ({
              color: theme.palette.error.main,
              marginLeft: '14px',
            })}
          >
            {validationMessage}
          </FormHelperText>
        )}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </>
    </Stack>
  );
};
