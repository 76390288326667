import { ECStack, ECTypography } from 'app/components';
import React from 'react';

interface LastCommentExtraRowProps {
  lastCommentUser?: string;
  lastCommentEmail?: string;
}

const LastCommentExtraRow: React.FC<LastCommentExtraRowProps> = ({
  lastCommentUser,
  lastCommentEmail,
}) => {
  return (
    <ECStack
      direction="row"
      spacing={1}
      sx={{ marginLeft: '1px', marginTop: '0.5rem' }}
    >
      {lastCommentUser && (
        <ECTypography
          variant="subtitle2"
          sx={{ whiteSpace: 'nowrap' }}
          color={theme => theme.palette.text.secondary}
        >
          {lastCommentUser}
        </ECTypography>
      )}
      {lastCommentEmail && (
        <ECTypography
          variant="body1"
          sx={{
            position: 'relative',
            top: '0.8rem',
            fontSize: '1rem',
            lineHeight: '0',
          }}
        >
          •
        </ECTypography>
      )}
      {lastCommentEmail && (
        <ECTypography
          variant="subtitle2"
          sx={{ whiteSpace: 'nowrap' }}
          color={theme => theme.palette.text.secondary}
        >
          {lastCommentEmail}
        </ECTypography>
      )}
    </ECStack>
  );
};

export default LastCommentExtraRow;
