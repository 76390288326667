import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { ECBox } from '../ECBox';
import { ECTypography } from '../ECTypography';
import { formatDateForHistory } from 'utils/strings/formatDate';
import { KeyboardArrowDown } from '@mui/icons-material';
import { ECGrid } from '../ECGrid';
import { camelCaseToWords } from 'utils/common';
import { Method } from 'types/BaseType';
import { HistoryList } from 'types/History';
import { P } from 'types/Permission';
import parse from 'html-react-parser';
import { ECCollapse } from '../ECCollapse';
import { ECButton } from '../ECButton';
import {
  checkIfValidArrayEntry,
  formatValue,
  getHistoryEntry,
  hasManufacturerWarranty,
  hasValidWarrantyFeatures,
  hasWarrantyFeatures,
  isObjectOrArray,
  isValidHistoryEntry,
  parseSupplierContacts,
  removeLeadingTrailingQuotes,
} from './historyUtil';
import moment from 'moment';
import _ from 'lodash';
import { processRfpBody } from './rfpHistory';
import { processBranchBody } from './branchHistory';
import { HistoryActionLabels } from './HistoryActionLabels';
import { processAssetBody } from './assetHistory';
import StrikethroughText from './strikethroughText';
import ForwardArrow from './forwardArrow';
import CurrentDisplayingText from './currentDisplayingText';

const CARD_MAX_HEIGHT_COLLAPSED = 66;
const invoiceProposalHeader = {
  invoice_reset: {
    header1: 'Invoice Reset AW',
    header2: 'Approval Workflow Reset Invoice',
  },
  proposal_reset: {
    header1: 'Proposal Reset AW',
    header2: 'Approval Workflow Reset proposal',
  },
};

const moduleToShowHeaderLabels = [
  'rfp',
  'branch',
  'asset',
  'assetgeneral',
  'supplier',
  'purchaseorder',
];

const fieldsToConvert = ['purchaseorder.workflowStatus'];

const showAddedLabelForNewAction = ['purchaseorder'];

export interface ECHistoryEntryProps {
  moduleName: string;
  key?: string;
  previousBody?: Record<string, any>;
  entry: HistoryList;
  showVerticalDivider?: boolean;
  isApproved?: boolean;
}

export const ECHistoryEntry = ({
  entry,
  key,
  moduleName,
  previousBody,
  showVerticalDivider,
  isApproved,
}: ECHistoryEntryProps) => {
  let {
    beforeBody,
    createdAt: date,
    fullName: author,
    jobTitle,
    email,
    company,
    method,
    body,
    targetPermission,
    description,
    module,
    id,
  } = entry;

  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldShowSeeMoreButton, setShouldShowSeeMoreButton] = useState(false);
  const [cardHeight, setCardHeight] = useState(1);
  const cardRef = useRef<any>(null);
  const isAttachmentType = !!(body?.documentType ?? beforeBody?.documentType);

  body = useMemo(() => {
    if (isAttachmentType) {
      return {
        docType: body?.documentType,
        expirationDate: moment(date).format('L'),
        description: body?.description,
      };
    }
    if (module === 'supplier') {
      const [bodySupplierContactsObjects, otherBodyToRender] =
        parseSupplierContacts(module, body);
      return {
        ...otherBodyToRender,
        ...bodySupplierContactsObjects,
      };
    }

    if (module === 'inventory') {
      const { createdAt, createdBy, updatedAt, updatedBy, ...otherBodyFields } =
        body ?? {};
      return otherBodyFields;
    }
    return body;
  }, [body, module, date, method]);

  beforeBody = useMemo(() => {
    if (isAttachmentType) {
      return {
        docType: beforeBody?.documentType,
        expirationDate:
          method === 'POST' ? undefined : moment(date).format('L'),
        description: beforeBody?.description,
      };
    }

    if (module === 'supplier') {
      const [beforeBodySupplierContactsObjects, otherBeforeBody] =
        parseSupplierContacts(module, beforeBody);
      return {
        ...otherBeforeBody,
        ...beforeBodySupplierContactsObjects,
      };
    }

    if (module === 'inventory') {
      const { createdAt, createdBy, updatedAt, updatedBy, ...otherBeforeBody } =
        beforeBody ?? {};
      return otherBeforeBody;
    }
    return beforeBody;
  }, [beforeBody, module, date, method]);

  previousBody = useMemo(() => {
    if (isAttachmentType) {
      return {
        docType: previousBody?.documentType,
        expirationDate:
          method === 'POST' ? undefined : moment(date).format('L'),
        description: previousBody?.description,
      };
    }
    if (module === 'supplier') {
      const [previousBodySupplierContactsObjects, otherPreviousBody] =
        parseSupplierContacts(module, previousBody);
      return {
        ...otherPreviousBody,
        ...previousBodySupplierContactsObjects,
      };
    }

    if (module === 'inventory') {
      const {
        createdAt,
        createdBy,
        updatedAt,
        updatedBy,
        ...otherPreviousBody
      } = previousBody ?? {};
      return otherPreviousBody;
    }
    return previousBody;
  }, [previousBody, module, date, method]);

  const isUserApprovedHistory = useMemo(() => {
    if (!isApproved) return false;
    const isApprovedStatusUpdate =
      body?.statusUpdated?.toLowerCase() === 'approved';
    return isApprovedStatusUpdate;
  }, [isApproved, body]);

  useLayoutEffect(() => {
    if (cardRef.current?.clientHeight > CARD_MAX_HEIGHT_COLLAPSED) {
      setShouldShowSeeMoreButton(true);
    } else {
      setCardHeight(cardRef.current?.clientHeight);
    }
  }, [body, key]);

  const isResetWorkflowHistoryEntry = useMemo(() => {
    const _description = description?.toLowerCase();
    return (
      _description === 'invoice_reset' || _description === 'proposal_reset'
    );
  }, [description]);

  const invoiceProposalResetEntryFields = useMemo(() => {
    if (!isResetWorkflowHistoryEntry) return {};
    const approvalResetFields = {
      approvalWorkflowName: body?.approvalWorkflowName,
      resetDateAndTime: date,
      userName: author,
    };
    return approvalResetFields;
  }, [isResetWorkflowHistoryEntry, body, date, author]);

  const invoiceProposalResetHeader = useMemo(() => {
    if (!isResetWorkflowHistoryEntry) return null;
    const _description = description?.toLowerCase();
    return _description ? invoiceProposalHeader[_description] : null;
  }, [isResetWorkflowHistoryEntry, description]);

  const resetLabel = isResetWorkflowHistoryEntry
    ? description?.toLowerCase() === 'invoice_reset'
      ? `Invoice ID: ${body?.id}`
      : `Proposal ID: ${body?.id}`
    : null;

  const getId = (
    primaryId: number | undefined,
    secondaryId: string | undefined,
    fallbackId: string | undefined,
  ) => primaryId || secondaryId || fallbackId;

  const headerCardLabel = useMemo(() => {
    const getAction = () => {
      if (method === Method.PUT) {
        if (module === 'location') {
          if (body?.statusUpdated) return 'Status Updated';
          if (beforeBody?.onHold === 'Live' && body?.onHold === 'On Hold')
            return 'On Hold';
          if (beforeBody?.onHold === 'On Hold' && body?.onHold === 'Live')
            return 'Live';
        }
        if (module === 'asset') {
          if (hasManufacturerWarranty(body, beforeBody))
            return 'Manufacturer Warranty updated';
          if ([body, beforeBody].some(hasWarrantyFeatures))
            return 'SP Warranty Updated';
          if (targetPermission.includes(P.EditInvoice))
            return 'Component Warranty Updated';
        }
        return 'Updated';
      }

      if (method === Method.POST && module === 'asset') {
        if (hasWarrantyFeatures(body)) {
          if (
            targetPermission.includes(P.AddInvoice) &&
            hasValidWarrantyFeatures(beforeBody)
          ) {
            return 'SP Warranty Updated';
          }
          return 'SP Warranty Added';
        }
        if (targetPermission.includes(P.AddInvoice)) {
          return 'Component Warranty Updated';
        }
      }

      return isAttachmentType
        ? 'Uploaded'
        : showAddedLabelForNewAction.includes(module)
          ? 'Added'
          : 'Created';
    };
    const action = getAction();

    const labelsByModule: Record<string, { label1: string; label2: string }> = {
      asset: {
        label1: `Asset ID: ${getId(id, body?.id, beforeBody?.id)}`,
        label2: `${isAttachmentType ? 'Attachment ' : ''}${action.includes('Warranty') ? '' : 'Asset '}${action}`,
      },
      assetgeneral: {
        label1: `Asset ID: ${getId(id, body?.id, beforeBody?.id)}`,
        label2: `Asset ${isAttachmentType ? 'Attachment ' : ''}${action}`,
      },
      supplier: {
        label1: `Supplier ID: ${getId(id, body?.supplierId, beforeBody?.id)}`,
        label2: `Supplier ${action}`,
      },
      purchaseorder: {
        label1: `Purchase Order ID: ${getId(id, body?.id, beforeBody?.id)}`,
        label2: `Purchase Order ${action}`,
      },
      location: {
        label1: `Location ID: ${getId(id, body?.id, beforeBody?.id)}`,
        label2: `Location ${action}`,
      },
      rfp: {
        label1: `RFP ID: ${getId(id, body?.id, beforeBody?.id)}`,
        label2: `RFP ${action}`,
      },
    };

    return labelsByModule[module] || { label1: '', label2: '' };
  }, [
    id,
    body,
    beforeBody,
    method,
    isAttachmentType,
    module,
    showAddedLabelForNewAction,
  ]);

  const isGeneralAsset = useMemo(() => {
    return module === 'assetgeneral';
  }, [module]);

  const generalAssetsFields = useMemo(() => {
    if (!isGeneralAsset) return {};
    const fields = {};
    if (!body?.conceptName && beforeBody?.conceptName?.length > 0) {
      fields['conceptName'] = [];
    }
    if (!body?.flagUpdates && beforeBody?.flagUpdates?.length > 0) {
      fields['flagUpdates'] = [];
    }
    if (!body?.areaName && beforeBody?.areaName) {
      fields['areaName'] = null;
    }
    if (!body?.tag && beforeBody?.tag) {
      fields['tag'] = null;
    }
    return {
      ...body,
      ...fields,
    };
  }, [isGeneralAsset, body]);

  const parseHistoryValue = useCallback(
    (key: string, value: any) => {
      if (!fieldsToConvert.includes(`${module}.${key}`)) {
        return value;
      }
      if (typeof value === 'string' && JSON.parse(value))
        value = JSON.parse(value);
      if (value instanceof Object) {
        return value.name ? value.name : value.id ?? value;
      }

      return value;
    },
    [module],
  );

  const renderChanges = useCallback(() => {
    const assetGeneralArrayFields = ['conceptname', 'flagupdates'];

    let bodyToRender: any = (() => {
      if (isResetWorkflowHistoryEntry) return invoiceProposalResetEntryFields;
      if (isGeneralAsset) return generalAssetsFields;
      return body;
    })();

    // Process body based on module type
    const processBodyByModule = {
      asset: () => processAssetBody(bodyToRender, method),
      rfp: () => processRfpBody(bodyToRender, method),
      branch: () => processBranchBody(bodyToRender, method),
      purchaseorder: () => {
        const { updatedAt, ...newBodyToRender } = bodyToRender;
        return newBodyToRender;
      },
    };

    bodyToRender = processBodyByModule[module]?.() || bodyToRender;

    const loopThroughFields = {
      ...bodyToRender,
      ...Object.fromEntries(
        Object.entries(beforeBody || {}).filter(
          ([key]) => !(key in (bodyToRender || {})),
        ),
      ),
    };

    return (
      <>
        {body && (
          <>
            <ECCollapse
              in={isExpanded}
              collapsedSize={
                shouldShowSeeMoreButton ? CARD_MAX_HEIGHT_COLLAPSED : cardHeight
              }
              key={key}
              sx={{ overflow: 'visible' }}
            >
              <ECBox ref={cardRef} overflow="visible">
                {isUserApprovedHistory && (
                  <ECTypography
                    variant="subtitle2"
                    width="fit-content"
                    color={theme => theme.palette.text.primary}
                    fontSize={13}
                    sx={{
                      background: theme => theme.palette.other.ecYellow,
                      paddingX: 0.5,
                      paddingY: 0.1,
                      mb: 1,
                    }}
                  >
                    Approved
                  </ECTypography>
                )}
                {isResetWorkflowHistoryEntry && (
                  <>
                    <ECTypography
                      variant="subtitle2"
                      width="fit-content"
                      color={theme => theme.palette.text.secondary}
                      fontSize={13}
                      sx={{
                        fontWeight: 'bold',
                        mb: 1,
                      }}
                    >
                      {resetLabel}
                    </ECTypography>
                    <ECTypography
                      variant="subtitle2"
                      width="fit-content"
                      color={theme => theme.palette.text.secondary}
                      fontSize={13}
                      sx={{
                        background: theme => theme.palette.other.divider,
                        paddingX: 2,
                        paddingY: 0.2,
                        mb: 0.5,
                      }}
                    >
                      {invoiceProposalResetHeader?.header2}
                    </ECTypography>
                  </>
                )}
                {moduleToShowHeaderLabels?.includes(module) && (
                  <HistoryActionLabels
                    label1={headerCardLabel.label1}
                    label2={headerCardLabel.label2}
                  />
                )}
                {entry.endpoint.includes('replace') &&
                entry.endpoint.includes('asset') &&
                entry.endpoint.includes('general-asset') &&
                !body?.newAttachment ? (
                  <>
                    <ECTypography
                      variant="subtitle2"
                      minWidth="15vw"
                      color={theme => theme.palette.text.secondary}
                      fontSize={13}
                    >
                      {entry?.body?.replacedBy && (
                        <>
                          Asset replaced by Asset ID #
                          {entry?.body?.replacedBy.match(/\d+/)[0]}
                        </>
                      )}
                      {entry?.body?.replacedAssetId && (
                        <>
                          Asset replaces Asset ID #
                          {entry?.body?.replacedAssetId}
                        </>
                      )}
                    </ECTypography>
                  </>
                ) : (
                  <>
                    {targetPermission.some(
                      perm =>
                        perm === P.GetAttachment ||
                        perm === P.AddAttachment ||
                        perm === P.EditAttachment ||
                        perm === P.ReplaceAsset,
                    ) && (
                      <ECTypography
                        variant="subtitle2"
                        minWidth="15vw"
                        color={theme => theme.palette.text.secondary}
                        fontSize={13}
                      >
                        Updated{' '}
                        <span
                          style={{
                            fontWeight: 700,
                            fontStyle: 'italic',
                            color: 'rgba(0, 0, 0, 0.87)',
                            fontSize: '14px',
                          }}
                        >
                          {body?.newAttachment || 'new'}
                        </span>{' '}
                        file (View Attachment Tab)
                      </ECTypography>
                    )}
                    {Object.keys(loopThroughFields)?.map(bodyKey => {
                      // Before body section
                      const isStatusUpdate =
                        method === Method.PUT &&
                        bodyKey === 'statusUpdated' &&
                        (module === 'rfp' || module === 'purchaseorder');

                      const formattedBodyKey = camelCaseToWords(
                        bodyKey,
                      ).replace('Rfp', module !== 'rfp' ? 'Rfp' : 'RFP');
                      const formattedTitleText: string = isStatusUpdate
                        ? 'Status'
                        : formattedBodyKey;

                      const shouldDisplayHistoryEntry =
                        bodyToRender?.[bodyKey] !== previousBody?.[bodyKey] &&
                        checkIfValidArrayEntry(
                          previousBody,
                          bodyToRender,
                          bodyKey,
                        ) &&
                        (isValidHistoryEntry(bodyToRender, bodyKey) ||
                          (isGeneralAsset &&
                            assetGeneralArrayFields?.includes(
                              bodyKey?.toLowerCase(),
                            )));

                      const isGeneralAssetState =
                        entry.endpoint.includes('general-asset') &&
                        bodyKey?.toLowerCase() === 'state';

                      const isStateActive =
                        removeLeadingTrailingQuotes(
                          JSON.stringify(previousBody?.[bodyKey]),
                        ) === 'true';

                      const isNotConceptName =
                        bodyKey?.toLowerCase() !== 'conceptname';

                      const isBeforeObjectOrArray = isObjectOrArray(
                        previousBody?.[bodyKey],
                      );

                      const formattedBeforeText = isBeforeObjectOrArray
                        ? formatValue(previousBody?.[bodyKey], module)
                        : isGeneralAssetState
                          ? isStateActive
                            ? 'Active'
                            : 'Inactive'
                          : isNotConceptName
                            ? removeLeadingTrailingQuotes(
                                JSON.stringify(
                                  parseHistoryValue(
                                    bodyKey,
                                    previousBody?.[bodyKey],
                                  ),
                                ),
                              )
                            : null;

                      const isPreviousBodyConceptOrFlagWithArrayContent =
                        Array.isArray(previousBody?.[bodyKey]) &&
                        ['conceptname', 'flagupdates'].includes(
                          bodyKey?.toLowerCase(),
                        ) &&
                        previousBody?.[bodyKey]?.length > 0;

                      const bodyFieldExist =
                        bodyToRender?.[bodyKey] !== undefined &&
                        bodyToRender?.[bodyKey] !== null;
                      const previousFieldExist =
                        previousBody?.[bodyKey] !== null &&
                        previousBody?.[bodyKey] !== undefined;

                      // arrow through section
                      const isValidPreviousValue =
                        (previousBody?.[bodyKey] ||
                          previousBody?.[bodyKey] === false) &&
                        !Array.isArray(previousBody?.[bodyKey]) &&
                        bodyToRender?.[bodyKey] !== null &&
                        bodyToRender?.[bodyKey] !== undefined &&
                        !String(previousBody?.[bodyKey]).includes(
                          'undefined',
                        ) &&
                        !String(previousBody?.[bodyKey]).includes('-');

                      const isBodyConceptOrFlagWithContent =
                        (bodyKey?.toLowerCase() === 'conceptname' ||
                          bodyKey?.toLowerCase() === 'flagupdates') &&
                        previousBody?.[bodyKey]?.length > 0 &&
                        bodyToRender?.[bodyKey]?.length > 0;

                      const isBeforeDisplay =
                        !String(previousBody?.[bodyKey]).includes(
                          'undefined',
                        ) &&
                        !String(previousBody?.[bodyKey]).includes('-') &&
                        (previousFieldExist ||
                          previousBody?.[bodyKey] === false);

                      // current body section
                      const isBodyObjectOrArray = isObjectOrArray(
                        bodyToRender?.[bodyKey],
                      );
                      const isBodyStateActive =
                        getHistoryEntry(bodyToRender, bodyKey) === 'true';

                      const isNotSpecialKey = ![
                        'conceptname',
                        'flagupdates',
                      ].includes(bodyKey?.toLowerCase());

                      const formattedBodyText = isBodyObjectOrArray
                        ? formatValue(bodyToRender[bodyKey], module)
                        : isGeneralAssetState
                          ? isBodyStateActive
                            ? 'Active'
                            : 'Inactive'
                          : isNotSpecialKey
                            ? parseHistoryValue(
                                bodyKey,
                                getHistoryEntry(bodyToRender, bodyKey),
                              )
                            : null;

                      const isAssetWarrantyObject =
                        module === 'asset' &&
                        ((typeof previousBody?.[bodyKey] === 'object' &&
                          previousBody?.[bodyKey] !== null &&
                          !Array.isArray(previousBody?.[bodyKey])) ||
                          (typeof bodyToRender?.[bodyKey] === 'object' &&
                            bodyToRender?.[bodyKey] !== null &&
                            !Array.isArray(bodyToRender?.[bodyKey]))) &&
                        (targetPermission.includes(P.EditInvoice) ||
                          targetPermission.includes(P.AddInvoice) ||
                          targetPermission.includes(P.EditAsset));

                      const isLogRowDisplaying =
                        formattedBeforeText !== formattedBodyText;

                      return (
                        <>
                          {shouldDisplayHistoryEntry && (
                            <ECBox display="flex" width="100%">
                              <ECBox display="flex" flex={1} width={0}>
                                <ECTypography
                                  variant="subtitle2"
                                  minWidth="15vw"
                                  maxWidth="20vw"
                                  color={theme => theme.palette.text.secondary}
                                >
                                  {isLogRowDisplaying &&
                                    `${formattedTitleText}: `}
                                </ECTypography>
                                {isLogRowDisplaying &&
                                  (isAssetWarrantyObject ? (
                                    <ECGrid
                                      container
                                      spacing={1}
                                      sx={{ marginTop: 2 }}
                                      marginLeft="-14vw"
                                    >
                                      {Object.keys(
                                        loopThroughFields?.[bodyKey] || {},
                                      )?.map(key => (
                                        <ECGrid item xs={12}>
                                          <ECTypography
                                            variant="subtitle2"
                                            color={theme =>
                                              theme.palette.text.secondary
                                            }
                                            sx={{
                                              width: '10vw',
                                            }}
                                            visibility={
                                              beforeBody?.[bodyKey]?.[key] !==
                                              bodyToRender?.[bodyKey]?.[key]
                                                ? 'visible'
                                                : 'hidden'
                                            }
                                          >
                                            {camelCaseToWords(key)}
                                          </ECTypography>
                                          <ECBox
                                            display="flex"
                                            flex={1}
                                            justifyContent="center"
                                            sx={{
                                              marginTop: -2,
                                              marginLeft: '12vw',
                                            }}
                                          >
                                            <StrikethroughText
                                              isVisible={
                                                beforeBody?.[bodyKey]?.[key] !==
                                                bodyToRender?.[bodyKey]?.[key]
                                              }
                                              formattedBeforeText={
                                                beforeBody?.[bodyKey]?.[key] ||
                                                previousBody?.[bodyKey]?.[key]
                                              }
                                            />
                                            <ForwardArrow
                                              isVisible={
                                                beforeBody?.[bodyKey]?.[key] !==
                                                  bodyToRender?.[bodyKey]?.[
                                                    key
                                                  ] &&
                                                (isValidPreviousValue ||
                                                  isBodyConceptOrFlagWithContent ||
                                                  beforeBody?.[bodyKey]?.[
                                                    key
                                                  ] ||
                                                  previousBody?.[bodyKey]?.[
                                                    key
                                                  ])
                                              }
                                            />
                                            <CurrentDisplayingText
                                              justifyContent={'flex-start'}
                                              visibility={
                                                bodyFieldExist &&
                                                beforeBody?.[bodyKey]?.[key] !==
                                                  bodyToRender?.[bodyKey]?.[key]
                                                  ? 'visible'
                                                  : 'hidden'
                                              }
                                              formattedBodyText={
                                                bodyToRender?.[bodyKey]?.[key]
                                              }
                                              bodyKey={bodyKey}
                                            />
                                          </ECBox>
                                        </ECGrid>
                                      ))}
                                    </ECGrid>
                                  ) : (
                                    <ECBox
                                      display="flex"
                                      flex={1}
                                      justifyContent="center"
                                    >
                                      {
                                        <StrikethroughText
                                          isVisible={isBeforeDisplay}
                                          formattedBeforeText={
                                            formattedBeforeText
                                          }
                                        />
                                      }

                                      {isPreviousBodyConceptOrFlagWithArrayContent && (
                                        <ECBox
                                          display="flex"
                                          flex={1}
                                          width={0}
                                          justifyContent="flex-end"
                                        >
                                          <ECTypography
                                            variant="subtitle2"
                                            maxWidth="100%"
                                            color={theme =>
                                              theme.palette.error.dark
                                            }
                                            sx={{
                                              textDecoration: 'line-through',
                                            }}
                                          >
                                            {previousBody?.[bodyKey]?.map(
                                              item => {
                                                return (
                                                  <p
                                                    style={{
                                                      marginTop: 1,
                                                      marginBottom: 1,
                                                    }}
                                                  >
                                                    {item}
                                                  </p>
                                                );
                                              },
                                            )}
                                          </ECTypography>
                                        </ECBox>
                                      )}
                                      <ForwardArrow
                                        isVisible={
                                          isValidPreviousValue ||
                                          isBodyConceptOrFlagWithContent ||
                                          isBeforeDisplay
                                        }
                                      />
                                      <CurrentDisplayingText
                                        justifyContent={
                                          (previousFieldExist ||
                                            (previousBody?.[bodyKey] ===
                                              false &&
                                              !bodyKey
                                                ?.toLowerCase()
                                                .includes('removed'))) &&
                                          !String(
                                            previousBody?.[bodyKey],
                                          ).includes('undefined') &&
                                          !String(
                                            previousBody?.[bodyKey],
                                          ).includes('-')
                                            ? 'flex-start'
                                            : 'center'
                                        }
                                        visibility={
                                          bodyFieldExist ? 'visible' : 'hidden'
                                        }
                                        formattedBodyText={formattedBodyText}
                                        bodyKey={bodyKey}
                                      />
                                    </ECBox>
                                  ))}
                              </ECBox>
                            </ECBox>
                          )}
                        </>
                      );
                    })}
                  </>
                )}
              </ECBox>
            </ECCollapse>
            {shouldShowSeeMoreButton && (
              <>
                <ECBox
                  display="flex"
                  width="100%"
                  flexDirection="column"
                  mt={-9}
                  mb={-2}
                >
                  <ECBox
                    width="100%"
                    height={96}
                    sx={{
                      background: isExpanded
                        ? 'transparent'
                        : 'linear-gradient(to bottom, #FFFFFF00, #FFFFFFFF)',
                    }}
                    justifyContent="center"
                    alignItems="flex-end"
                    display="flex"
                  />
                  <ECBox
                    display="flex"
                    bgcolor="white"
                    width="100%"
                    justifyContent="center"
                  >
                    <ECButton
                      variant="text"
                      onClick={() =>
                        setIsExpanded(prevIsExpanded => !prevIsExpanded)
                      }
                      endIcon={
                        <KeyboardArrowDown
                          sx={{
                            transform: isExpanded
                              ? 'rotate(180deg)'
                              : 'rotate(0deg)',
                            transition: '100ms linear all',
                          }}
                        />
                      }
                    >
                      {isExpanded ? 'See less' : 'See More'}
                    </ECButton>
                  </ECBox>
                </ECBox>
              </>
            )}
          </>
        )}
      </>
    );
  }, [
    body,
    previousBody,
    isExpanded,
    shouldShowSeeMoreButton,
    cardHeight,
    entry,
    key,
    targetPermission,
  ]);

  return (
    <ECBox display="flex" flexDirection="column" flexGrow={1} key={key}>
      {isResetWorkflowHistoryEntry && (
        <ECTypography
          variant="subtitle2"
          width="fit-content"
          color={theme => theme.palette.text.primary}
          fontSize={13}
          sx={{
            background: theme => theme.palette.other.divider,

            paddingX: 2,
            paddingY: 0.1,
            mb: 4,
          }}
        >
          {invoiceProposalResetHeader?.header1}
        </ECTypography>
      )}
      <ECBox sx={{ flexGrow: 1 }}>
        <ECGrid container spacing={2}>
          <ECGrid item xs>
            <ECBox display="flex" alignItems="center">
              <ECBox
                height={12}
                width={12}
                borderRadius={6}
                bgcolor={theme => theme.palette.primary.dark}
                mr={2}
              />
              <ECBox display="flex" flexDirection="column">
                <ECTypography variant="subtitle2" fontWeight="bold">
                  {author}
                </ECTypography>

                <ECTypography
                  variant="body2"
                  color={theme => theme.palette.primary.main}
                >
                  {email}
                </ECTypography>
              </ECBox>
            </ECBox>
          </ECGrid>

          <ECGrid item xs>
            <ECBox display="flex" flexDirection="column">
              <ECTypography variant="body2" fontWeight="bold">
                {jobTitle}
              </ECTypography>

              <ECTypography
                variant="subtitle2"
                color={theme => theme.palette.text.secondary}
              >
                {company}
              </ECTypography>
            </ECBox>
          </ECGrid>

          <ECGrid item xs>
            <ECBox display="flex" flexDirection="column">
              <ECTypography
                variant="body2"
                color={theme => theme.palette.text.secondary}
              >
                {formatDateForHistory(date)}
              </ECTypography>
            </ECBox>
          </ECGrid>
        </ECGrid>
      </ECBox>

      <ECBox display="flex" gap={4}>
        <ECBox
          bgcolor={theme => theme.palette.grey[400]}
          width="1px"
          display="flex"
          sx={{ marginLeft: '6px !important' }}
        />
        <ECBox
          display="flex"
          flexDirection="column"
          boxShadow={2}
          mt={5}
          borderRadius="md"
          minWidth={0}
          width="100%"
          p={2}
          position="relative"
          overflow="hidden"
        >
          {body?.rejectReason ? (
            <ECTypography
              variant="subtitle2"
              minWidth="15vw"
              color={theme => theme.palette.text.secondary}
              fontSize={13}
            >
              <span
                style={{
                  fontWeight: 700,
                  color: 'rgba(255, 0, 0, 0.87)',
                  fontSize: '14px',
                }}
              >
                Reject{' '}
              </span>
              with the reason{' '}
              <span
                style={{
                  fontWeight: 700,
                  fontStyle: 'italic',
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontSize: '14px',
                }}
              >
                {body?.rejectReason}
              </span>
              {body.note && (
                <ECTypography
                  variant="subtitle2"
                  minWidth="15vw"
                  maxWidth="20vw"
                  color={theme => theme.palette.text.secondary}
                >
                  A note was added
                </ECTypography>
              )}
            </ECTypography>
          ) : (
            <>
              {targetPermission.includes(P.AddComment) ? (
                <>
                  {moduleToShowHeaderLabels?.includes(module) && (
                    <>
                      <ECTypography
                        variant="subtitle2"
                        width="fit-content"
                        color={theme => theme.palette.text.secondary}
                        fontSize={13}
                        sx={{
                          fontWeight: 'bold',
                          mb: 1,
                        }}
                      >
                        {headerCardLabel.label1}
                      </ECTypography>

                      <ECTypography
                        variant="subtitle2"
                        width="fit-content"
                        color={theme => theme.palette.text.secondary}
                        fontSize={13}
                        sx={{
                          background: theme => theme.palette.other.divider,
                          paddingX: 2,
                          paddingY: 0.5,
                          mb: 0.5,
                        }}
                      >
                        Asset Comment Added
                      </ECTypography>
                    </>
                  )}
                  <ECBox display="flex" flexDirection="column">
                    <ECTypography
                      variant="subtitle2"
                      minWidth="15vw"
                      maxWidth="20vw"
                      color={theme => theme.palette.text.secondary}
                    >
                      A note was added
                    </ECTypography>

                    <ECTypography variant="subtitle2">
                      {parse(body?.body)}
                    </ECTypography>
                  </ECBox>
                </>
              ) : (
                <>
                  {method !== Method.PUT && (method as any) !== '' ? (
                    <>
                      {module !== 'rfp' && description && (
                        <ECBox display="flex" flexDirection="column">
                          <ECTypography
                            variant="subtitle2"
                            minWidth="15vw"
                            maxWidth="20vw"
                            color={theme => theme.palette.text.secondary}
                          >
                            {description}
                          </ECTypography>
                        </ECBox>
                      )}
                      {renderChanges()}
                    </>
                  ) : (
                    <>
                      {module !== 'rfp' &&
                        description &&
                        !isResetWorkflowHistoryEntry && (
                          <ECBox display="flex" flexDirection="column">
                            <ECTypography
                              variant="subtitle2"
                              minWidth="15vw"
                              maxWidth="20vw"
                              color={theme => theme.palette.text.secondary}
                            >
                              {description}
                            </ECTypography>
                          </ECBox>
                        )}

                      {renderChanges()}
                    </>
                  )}
                </>
              )}
            </>
          )}
          {(targetPermission.includes(P.ChangeInvoiceStatus) ||
            targetPermission.includes(P.ChangeProposalStatus)) &&
          !!beforeBody?.statusUpdated &&
          !!body?.statusUpdated &&
          beforeBody?.statusUpdated === body?.statusUpdated ? (
            <ECBox display="flex" flexDirection="column">
              <ECTypography
                variant="subtitle1"
                minWidth="15vw"
                maxWidth="20vw"
                color={theme => theme.palette.text.secondary}
              >
                Resubmit was executed.
              </ECTypography>
              <ECTypography variant="subtitle2">{body?.body}</ECTypography>
            </ECBox>
          ) : null}
        </ECBox>
      </ECBox>
    </ECBox>
  );
};
