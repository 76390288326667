export const AchTransfer = () => {
  return (
    <svg
      width="27"
      height="14"
      viewBox="0 0 27 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.27584 5.77012H4.82861L6.70838 0.101668H8.50109L10.3809 5.77012H8.93362L7.62513 1.52985H7.58161L6.27584 5.77012ZM6.0827 3.53928H9.10773V4.57997H6.0827V3.53928Z"
        fill="#0D0A2C"
      />
      <path
        d="M15.776 2.15538H14.4158C14.3977 2.0133 14.3605 1.88505 14.3043 1.77065C14.2481 1.65625 14.1737 1.55845 14.0812 1.47726C13.9887 1.39608 13.879 1.33426 13.752 1.29182C13.6269 1.24754 13.4882 1.22539 13.3358 1.22539C13.0656 1.22539 12.8326 1.29274 12.6367 1.42744C12.4426 1.56214 12.293 1.75681 12.1878 2.01145C12.0845 2.26609 12.0328 2.57424 12.0328 2.93589C12.0328 3.31232 12.0854 3.62784 12.1906 3.88248C12.2976 4.13527 12.4472 4.32625 12.6394 4.45542C12.8335 4.58274 13.0629 4.64639 13.3277 4.64639C13.4764 4.64639 13.6115 4.62702 13.733 4.58827C13.8563 4.54952 13.9642 4.49324 14.0567 4.41944C14.151 4.34378 14.2281 4.25244 14.288 4.14542C14.3496 4.03656 14.3922 3.91385 14.4158 3.77731L15.776 3.78561C15.7524 4.03656 15.6808 4.28381 15.5611 4.52738C15.4432 4.77095 15.2809 4.99329 15.0741 5.19442C14.8674 5.3937 14.6153 5.55239 14.3179 5.67048C14.0223 5.78857 13.6831 5.84762 13.3005 5.84762C12.7963 5.84762 12.3447 5.73506 11.9457 5.50995C11.5486 5.28299 11.2348 4.9527 11.0045 4.51908C10.7742 4.08545 10.659 3.55773 10.659 2.93589C10.659 2.31222 10.776 1.78357 11.0099 1.34995C11.2439 0.916324 11.5603 0.586956 11.9593 0.361842C12.3583 0.136727 12.8054 0.0241699 13.3005 0.0241699C13.6378 0.0241699 13.9497 0.0721452 14.2363 0.168096C14.5228 0.262201 14.7749 0.40059 14.9925 0.583265C15.2102 0.764095 15.387 0.986442 15.523 1.25031C15.659 1.51417 15.7433 1.81586 15.776 2.15538Z"
        fill="#0D0A2C"
      />
      <path
        d="M16.5465 5.77012V0.101668H17.8931V2.3768H20.0884V0.101668H21.4323V5.77012H20.0884V3.49222H17.8931V5.77012H16.5465Z"
        fill="#0D0A2C"
      />
      <path
        d="M0.577637 9.66151V9.02958H4.27853V9.66151H2.7865V13.895H2.06733V9.66151H0.577637Z"
        fill="black"
      />
      <path
        d="M4.66788 13.895V10.2459H5.34268V10.8256H5.38004C5.44542 10.6292 5.56061 10.4748 5.72561 10.3623C5.89217 10.2483 6.08052 10.1913 6.29067 10.1913C6.33425 10.1913 6.38562 10.1929 6.44477 10.196C6.50548 10.1992 6.55296 10.2032 6.5872 10.2079V10.8874C6.55918 10.8794 6.50937 10.8707 6.43777 10.8612C6.36616 10.8501 6.29456 10.8446 6.22295 10.8446C6.05795 10.8446 5.91085 10.8802 5.78165 10.9515C5.654 11.0212 5.55282 11.1186 5.4781 11.2437C5.40339 11.3672 5.36603 11.5082 5.36603 11.6666V13.895H4.66788Z"
        fill="black"
      />
      <path
        d="M8.20299 13.9757C7.97572 13.9757 7.77024 13.933 7.58656 13.8474C7.40288 13.7603 7.25733 13.6344 7.14993 13.4697C7.04407 13.305 6.99115 13.1031 6.99115 12.8639C6.99115 12.658 7.03006 12.4886 7.1079 12.3555C7.18573 12.2225 7.2908 12.1172 7.42311 12.0396C7.55543 11.9619 7.70331 11.9033 7.86675 11.8638C8.0302 11.8242 8.19676 11.7941 8.36643 11.7735C8.58125 11.7481 8.75559 11.7275 8.88946 11.7117C9.02333 11.6943 9.12062 11.6666 9.18133 11.6286C9.24204 11.5906 9.27239 11.5288 9.27239 11.4433V11.4266C9.27239 11.2192 9.2148 11.0584 9.09961 10.9444C8.98597 10.8303 8.8163 10.7733 8.59059 10.7733C8.35554 10.7733 8.1703 10.8264 8.03487 10.9325C7.901 11.037 7.80838 11.1534 7.75701 11.2817L7.10089 11.1297C7.17872 10.9079 7.29236 10.729 7.44179 10.5928C7.59279 10.455 7.76635 10.3552 7.96249 10.2934C8.15862 10.2301 8.36488 10.1984 8.58125 10.1984C8.72446 10.1984 8.87623 10.2158 9.03656 10.2507C9.19845 10.2839 9.34945 10.3457 9.48954 10.436C9.6312 10.5263 9.74716 10.6553 9.83745 10.8232C9.92773 10.9895 9.97288 11.2057 9.97288 11.4718V13.895H9.29107V13.3961H9.26305C9.21791 13.4879 9.1502 13.5782 9.05991 13.6669C8.96963 13.7556 8.85366 13.8292 8.712 13.8878C8.57035 13.9464 8.40068 13.9757 8.20299 13.9757ZM8.35476 13.4056C8.54778 13.4056 8.71278 13.3668 8.84977 13.2892C8.98831 13.2116 9.09338 13.1102 9.16498 12.9851C9.23815 12.8584 9.27473 12.723 9.27473 12.5788V12.1084C9.24982 12.1338 9.20156 12.1575 9.12996 12.1797C9.05991 12.2003 8.97974 12.2185 8.88946 12.2344C8.79918 12.2486 8.71123 12.2621 8.62561 12.2747C8.54 12.2858 8.46839 12.2953 8.4108 12.3033C8.27537 12.3207 8.15162 12.35 8.03954 12.3912C7.92902 12.4323 7.84029 12.4917 7.77336 12.5693C7.70798 12.6454 7.67529 12.7467 7.67529 12.8734C7.67529 13.0492 7.73911 13.1823 7.86675 13.2725C7.9944 13.3612 8.15707 13.4056 8.35476 13.4056Z"
        fill="black"
      />
      <path
        d="M11.6009 11.7283V13.895H10.9028V10.2459H11.5729V10.8398H11.6173C11.6998 10.6466 11.829 10.4914 12.0049 10.3742C12.1823 10.257 12.4057 10.1984 12.675 10.1984C12.9194 10.1984 13.1334 10.2507 13.3171 10.3552C13.5008 10.4581 13.6432 10.6118 13.7444 10.8161C13.8456 11.0204 13.8962 11.273 13.8962 11.5739V13.895H13.198V11.6594C13.198 11.395 13.1303 11.1883 12.9949 11.0394C12.8595 10.8889 12.6734 10.8137 12.4368 10.8137C12.2749 10.8137 12.1309 10.8493 12.0049 10.9206C11.8803 10.9919 11.7815 11.0964 11.7083 11.2342C11.6367 11.3704 11.6009 11.5351 11.6009 11.7283Z"
        fill="black"
      />
      <path
        d="M17.52 11.1368L16.8872 11.2508C16.8608 11.1685 16.8187 11.0901 16.7611 11.0156C16.7051 10.9412 16.6288 10.8802 16.5323 10.8327C16.4358 10.7852 16.3152 10.7614 16.1704 10.7614C15.9727 10.7614 15.8077 10.8066 15.6754 10.8969C15.5431 10.9855 15.4769 11.1004 15.4769 11.2413C15.4769 11.3633 15.5213 11.4615 15.61 11.5359C15.6987 11.6103 15.842 11.6713 16.0396 11.7188L16.6094 11.8519C16.9394 11.9295 17.1853 12.0491 17.3472 12.2106C17.5091 12.3721 17.5901 12.582 17.5901 12.8402C17.5901 13.0587 17.5278 13.2535 17.4033 13.4246C17.2803 13.594 17.1083 13.7271 16.8872 13.8237C16.6677 13.9203 16.4132 13.9686 16.1237 13.9686C15.7221 13.9686 15.3944 13.8815 15.1407 13.7073C14.887 13.5315 14.7313 13.282 14.6737 12.9589L15.3485 12.8544C15.3905 13.0334 15.4769 13.1688 15.6077 13.2607C15.7384 13.3509 15.9089 13.3961 16.119 13.3961C16.3479 13.3961 16.5308 13.3478 16.6677 13.2511C16.8047 13.153 16.8732 13.0334 16.8732 12.8924C16.8732 12.7784 16.8312 12.6826 16.7471 12.605C16.6646 12.5274 16.5378 12.4688 16.3665 12.4292L15.7595 12.2938C15.4248 12.2161 15.1773 12.0926 15.0169 11.9231C14.8582 11.7537 14.7788 11.5391 14.7788 11.2793C14.7788 11.0639 14.8379 10.8755 14.9562 10.7139C15.0745 10.5524 15.238 10.4265 15.4466 10.3362C15.6552 10.2443 15.8941 10.1984 16.1634 10.1984C16.551 10.1984 16.8561 10.2839 17.0787 10.455C17.3013 10.6244 17.4484 10.8517 17.52 11.1368Z"
        fill="black"
      />
      <path
        d="M20.0645 10.2459V10.8161H18.0378V10.2459H20.0645ZM18.5935 13.895V9.82305C18.5935 9.59499 18.6425 9.40573 18.7406 9.25527C18.8387 9.10322 18.9686 8.98998 19.1305 8.91555C19.2924 8.83952 19.4683 8.80151 19.6582 8.80151C19.7983 8.80151 19.9182 8.81339 20.0178 8.83715C20.1174 8.85932 20.1914 8.87991 20.2396 8.89892L20.0762 9.47383C20.0435 9.46433 20.0015 9.45324 19.9501 9.44057C19.8987 9.42632 19.8365 9.41919 19.7633 9.41919C19.5936 9.41919 19.4722 9.46195 19.3991 9.54747C19.3274 9.633 19.2916 9.75653 19.2916 9.91808V13.895H18.5935Z"
        fill="black"
      />
      <path
        d="M22.1893 13.9686C21.836 13.9686 21.5316 13.8918 21.2763 13.7382C21.0226 13.5829 20.8265 13.3652 20.6879 13.0849C20.551 12.8029 20.4825 12.4727 20.4825 12.0942C20.4825 11.7204 20.551 11.391 20.6879 11.1059C20.8265 10.8208 21.0195 10.5983 21.267 10.4384C21.5161 10.2784 21.8072 10.1984 22.1403 10.1984C22.3426 10.1984 22.5388 10.2325 22.7287 10.3006C22.9186 10.3687 23.089 10.4756 23.24 10.6213C23.391 10.767 23.5101 10.9562 23.5973 11.1891C23.6845 11.4203 23.728 11.7014 23.728 12.0324V12.2842H20.8771V11.7521H23.0439C23.0439 11.5652 23.0065 11.3997 22.9318 11.2556C22.8571 11.1099 22.752 10.9951 22.6166 10.9111C22.4827 10.8272 22.3255 10.7852 22.1449 10.7852C21.9488 10.7852 21.7776 10.8343 21.6313 10.9325C21.4865 11.0291 21.3744 11.1558 21.295 11.3126C21.2172 11.4678 21.1783 11.6365 21.1783 11.8186V12.2344C21.1783 12.4783 21.2203 12.6857 21.3044 12.8568C21.39 13.0278 21.5091 13.1585 21.6616 13.2488C21.8142 13.3375 21.9924 13.3818 22.1963 13.3818C22.3286 13.3818 22.4493 13.3628 22.5582 13.3248C22.6672 13.2852 22.7614 13.2266 22.8408 13.149C22.9202 13.0714 22.9809 12.9756 23.0229 12.8615L23.6837 12.9827C23.6308 13.1807 23.5358 13.3541 23.3988 13.503C23.2634 13.6503 23.0929 13.7651 22.8875 13.8474C22.6835 13.9282 22.4508 13.9686 22.1893 13.9686Z"
        fill="black"
      />
      <path
        d="M24.5027 13.895V10.2459H25.1775V10.8256H25.2148C25.2802 10.6292 25.3954 10.4748 25.5604 10.3623C25.7269 10.2483 25.9153 10.1913 26.1254 10.1913C26.169 10.1913 26.2204 10.1929 26.2796 10.196C26.3403 10.1992 26.3877 10.2032 26.422 10.2079V10.8874C26.394 10.8794 26.3442 10.8707 26.2725 10.8612C26.2009 10.8501 26.1293 10.8446 26.0577 10.8446C25.8927 10.8446 25.7456 10.8802 25.6164 10.9515C25.4888 11.0212 25.3876 11.1186 25.3129 11.2437C25.2382 11.3672 25.2008 11.5082 25.2008 11.6666V13.895H24.5027Z"
        fill="black"
      />
    </svg>
  );
};
