import { useState, useRef, useEffect, useMemo, cloneElement } from 'react';
import { ECTableCell } from '.';
import { ECBox } from '../ECBox';
import { ECChip } from '../ECChip';
import { ECTooltip } from '../ECTooltip';
import { ECTypography } from '../ECTypography';
import { StyleConstants } from 'styles/StyleConstants';
import { ECPopover } from '../ECPopover';
interface ECChipListTableCellProps {
  col: any;
  row: any;
  onChipClick?: (row: any, chipData: any, col: any) => void;
  onChipDelete?: (chips: any[], chipData: any, col: any) => void;
  customWidthCollapseChips?: string | number;
  renderAsTableCell?: boolean;
  limitTags?: number;
  clickable?: boolean;
}

export const ECChipListTableCell: React.FC<
  ECChipListTableCellProps
> = props => {
  const {
    col,
    row,
    onChipClick,
    onChipDelete,
    customWidthCollapseChips,
    renderAsTableCell = true,
    limitTags,
    clickable,
  } = props;

  const [chips, setChips] = useState<any[]>(row[`${col.dataPath}`]);
  const [visibleChips, setVisibleChips] = useState(
    row[`${col.dataPath}`]?.length,
  );
  const prevInitialVisibleChipsLength = useRef(visibleChips);
  const refList = useRef<(HTMLDivElement | null)[]>([]);

  const [openDetails, setOpenDetails] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const hiddenChipsCount = useMemo(() => {
    if (!limitTags) {
      return 0;
    }
    return Math.max(row[`${col.dataPath}`]?.length - limitTags, 0);
  }, [row, col, limitTags]);

  const handleChipClick = event => {
    event.stopPropagation();
    setOpenDetails(prev => !prev);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setOpenDetails(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!chips?.length) {
      setVisibleChips(0);
      return;
    }

    if (refList.current) {
      const widthList = refList.current.map(ref => ref?.offsetWidth);
      const maxWidth: number = customWidthCollapseChips
        ? Number(customWidthCollapseChips)
        : Number(400 - StyleConstants.TABLE_CELL_X_MARGIN * 2 - 16);
      let totalWidth = 0;
      let visibleChips = 0;
      for (let i = 0; i < widthList.length; i++) {
        totalWidth += widthList[i] || 0;
        if (totalWidth > maxWidth) {
          break;
        }
        visibleChips++;
      }
      setVisibleChips(visibleChips);
    }
  }, [refList, chips]);

  const renderAllChips = useMemo(() => {
    return chips?.map((chipData, index) => (
      <ECChip
        key={`${index}-chip`}
        ref={ref => (refList.current[index] = ref)}
        label={
          chipData && col.chipTitleDataPath && chipData[col.chipTitleDataPath]
            ? chipData[col.chipTitleDataPath]
            : ''
        }
        color={chipData?.color}
        variant="outlined"
        sx={{
          margin: 1,
          maxWidth: '24vw',
          textOverflow: 'ellipsis',
        }}
        onClick={e => {
          e.stopPropagation();
          onChipClick?.(row, chipData, col);
        }}
        onDelete={
          onChipDelete
            ? () => {
                setChips(
                  chips.filter(
                    chip => JSON.stringify(chip) !== JSON.stringify(chipData),
                  ),
                );
                onChipDelete(row[`${col.dataPath}`], chipData, col);
              }
            : undefined
        }
      />
    ));
  }, [chips, col, onChipClick, onChipDelete, row]);

  const hiddenChips = useMemo(() => {
    if (chips?.length - visibleChips > 0) {
      return renderAllChips.slice(visibleChips).map(chip =>
        cloneElement(chip, {
          sx: {
            ...chip.props.sx,
            maxWidth: '100%',
          },
        }),
      );
    }
    return '';
  }, [renderAllChips, chips, visibleChips]);

  useEffect(() => {
    setChips(row[`${col.dataPath}`] || []);

    if (
      prevInitialVisibleChipsLength.current !== row[`${col.dataPath}`]?.length
    ) {
      prevInitialVisibleChipsLength.current = row[`${col.dataPath}`]?.length;
      setVisibleChips(row[`${col.dataPath}`]?.length);
    }
  }, [row, col]);

  if (renderAsTableCell) {
    return (
      <ECTableCell
        key={col.title}
        scope="row"
        sx={{ height: '100%', alignItems: 'center' }}
      >
        <ECBox display="flex" alignItems="center">
          {renderAllChips?.slice(0, visibleChips)}
          {chips?.length - visibleChips > 0 && (
            <ECTooltip title={hiddenChips}>
              <ECTypography
                variant="body2"
                bgcolor="#EEE"
                borderRadius="50%"
                textAlign="center"
                position="relative"
                width="2em"
                height="2em"
                pt="0.33em"
              >
                {`+${chips?.length - visibleChips}`}
              </ECTypography>
            </ECTooltip>
          )}
        </ECBox>
      </ECTableCell>
    );
  }

  if (clickable && limitTags) {
    return (
      <ECBox
        display="flex"
        gap={1}
        alignItems="center"
        sx={{ minWidth: '600px' }}
      >
        {chips?.slice(0, limitTags).map((value, index) => {
          const label = value?.label || value?.name;
          const truncatedLabel =
            label.length > 16 ? `${label.slice(0, 16)}...` : label;
          return (
            <ECChip
              key={`${label}-${index}`}
              label={truncatedLabel}
              color={value?.color}
              variant="outlined"
            />
          );
        })}
        {hiddenChipsCount > 0 && (
          <>
            <ECChip
              color={openDetails ? 'Dark Blue' : '#EEE'}
              label={hiddenChipsCount.toString()}
              sx={{
                color: openDetails ? 'white' : 'black',
                fontWeight: 'bold',
                height: '30px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              onClick={handleChipClick}
              aria-describedby="text-details-modal"
            />
            <ECPopover
              id={'text-details-modal'}
              open={openDetails}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              onClose={handleClose}
            >
              <ECBox p={2} sx={{ width: '35rem' }}>
                {chips
                  ?.slice(limitTags)
                  .map((value, index) => (
                    <ECChip
                      key={`${value?.label || value?.name}-${index}`}
                      label={value?.label || value?.name}
                      color={value?.color}
                      variant="outlined"
                      sx={{ margin: '5px' }}
                    />
                  ))}
              </ECBox>
            </ECPopover>
          </>
        )}
      </ECBox>
    );
  }

  return (
    <ECBox display="flex" alignItems="center">
      {renderAllChips?.slice(0, visibleChips)}
      {chips?.length - visibleChips > 0 && (
        <ECTooltip title={hiddenChips}>
          <ECTypography
            variant="body2"
            bgcolor="#EEE"
            borderRadius="50%"
            textAlign="center"
            position="relative"
            width="2em"
            height="2em"
            pt="0.33em"
          >
            {`+${chips?.length - visibleChips}`}
          </ECTypography>
        </ECTooltip>
      )}
    </ECBox>
  );
};
