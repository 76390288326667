import { useCallback, useLayoutEffect, useState } from 'react';
import {
  ECBox,
  ECEasyFormCreate,
  ECEasyFormFieldType,
  ECImage,
  ECLink,
  ECTypography,
} from 'app/components';
import { compact, get } from 'lodash';
import { useGetSupplierByIdQuery } from 'services/supplierApi';
import { DocumentCoreTypes } from 'types/Attachment';
import { Language } from '@mui/icons-material';
import { SupplierContactInfo } from '../PurchaseOrderDetailsPage/supplier-info-modal';
import { useCreatePurchaseOrderMutation } from 'services/purchaseOrderApi';
import { isoDateWithTimezoneAtZero } from 'utils/strings/formatDate';
import { formatPhone } from 'utils/strings/phone';
import { buildAddress } from 'utils/address';

interface Step2Props {
  existingData?: any;
  onClose?: () => void;
}

const fancyFormElementsCreate = require('./fancy_form_config_step3_create.json');

export const Step3 = ({ existingData, onClose }: Step2Props) => {
  const handleExistingData = useCallback(
    (field: ECEasyFormFieldType) => {
      const value = get(existingData, field.fieldName);
      return {
        ...field,
        value,
      };
    },
    [existingData],
  );

  const { data: supplier, isLoading: isLoadingSupplier } =
    useGetSupplierByIdQuery(Number(existingData?.supplier?.id));

  const [formFields, setFormFields] = useState<any[]>(
    fancyFormElementsCreate.fields?.map(handleExistingData),
  );

  useLayoutEffect(() => {
    const supplierLogoUrl = supplier?.attachments?.find(
      attachment =>
        attachment?.documentCoreType?.code ===
        DocumentCoreTypes.SupplierProfilePhoto,
    )?.thumbnailURL;

    const supplierHeaderField = formFields.find(
      field => field.fieldName === 'supplierHeader',
    );
    supplierHeaderField.value = (
      <ECBox display="flex" flexDirection="column" gap={2}>
        <ECBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <ECBox display="flex" gap={1} alignItems="center">
            <ECImage
              sx={{
                width: '100px',
                height: '50px',
                objectFit: 'cover',
                mr: 2,
              }}
              alt="supplier logo"
              src={supplierLogoUrl}
            />

            <ECTypography fontWeight="bold">{supplier?.name}</ECTypography>
          </ECBox>

          <ECLink
            id="hyperlink"
            target="_blank"
            href={
              supplier?.website
                ? supplier?.website.startsWith('http://') ||
                  supplier?.website.startsWith('https://')
                  ? supplier?.website
                  : `http://${supplier?.website}`
                : ''
            }
          >
            <Language sx={{ marginRight: 1 }} /> WEBSITE
          </ECLink>
        </ECBox>

        <ECBox display="flex" flexDirection="column" gap={1}>
          <ECBox display="flex" gap={1}>
            <ECTypography variant="body2" fontWeight="bold">
              Phone:{' '}
            </ECTypography>
            <ECTypography variant="body2">
              {formatPhone(supplier?.phone, supplier?.address?.countryCode)}
            </ECTypography>
          </ECBox>

          <ECBox display="flex" gap={1}>
            <ECTypography variant="body2" fontWeight="bold">
              Email:{' '}
            </ECTypography>
            <ECTypography variant="body2">{supplier?.email}</ECTypography>
          </ECBox>

          <ECBox display="flex" gap={1}>
            <ECTypography variant="body2" fontWeight="bold">
              Address:{' '}
            </ECTypography>
            <ECTypography variant="body2">
              {buildAddress(supplier?.address)}
            </ECTypography>
          </ECBox>
        </ECBox>
      </ECBox>
    );

    const supplierContactsField = formFields.find(
      field => field.fieldName === 'supplierContacts',
    );
    supplierContactsField.value = (
      <ECBox display="flex" flexDirection="column" gap={1} mb={1}>
        {supplier?.contacts?.map(contact => (
          <SupplierContactInfo
            key={contact.id}
            supplierCountryCode={supplier?.address?.countryCode}
            {...contact}
          />
        ))}
      </ECBox>
    );

    setFormFields([...formFields]);
  }, [existingData, supplier]);

  const [doCreatePO, { data, isError, error, isSuccess, reset, isLoading }] =
    useCreatePurchaseOrderMutation();

  const submit = useCallback(() => {
    const doSubmit = async data => {
      doCreatePO({
        supplierId: existingData?.supplier?.id,
        inventoryItems: data?.purchaseOrderItems?.map(item => ({
          name: item?.name,
          inventoryItemId: item?.id,
          partNumber: item?.partNumber,
          price: item?.unitCost,
          avgPrice: item?.isNewItem ? item?.unitCost : undefined,
          count: item?.quantity,
          measurementUnitId: item?.measurementUnit?.id,
        })),
        storageLocationId: data?.storageLocation?.id,
        purchaseOrderNumber: data?.purchaseOrderNumber,
        assignedUserId: data?.assignedTo?.id,
        dueDate: isoDateWithTimezoneAtZero(data?.dueDate),
        description: data?.description,
        shipToUser: data?.shipToUser,
        shipToUserPhone: data?.shipToUserPhone,
        shipToUserEmail: data?.shipToUserEmail,
        sameAsAssignedUser: !!compact(existingData?.sameAsAssignedUser)?.length,
      });
    };

    return [doSubmit, { data, isError, error, isSuccess, reset, isLoading }];
  }, [
    data,
    existingData,
    doCreatePO,
    error,
    isError,
    isLoading,
    isSuccess,
    reset,
  ]);

  return (
    <ECEasyFormCreate
      useCreateMutation={submit}
      onClose={onClose}
      formConfig={fancyFormElementsCreate.config}
      formFields={formFields}
      existingData={existingData}
      isLoading={isLoading}
      hideToolBar
      showTitle={false}
    />
  );
};
