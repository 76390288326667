import { BoxProps } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { P } from 'types/Permission';
import { ECBox, ECButton, ECRequirePermission, ECTypography } from '..';
import { ECStepItem } from '../ECStepItem';
import { ArrowBackIosOutlined } from '@mui/icons-material';
import { useTheme, useMediaQuery } from '@mui/material';

export interface ECTabContainerTabType {
  value: number;
  label: string;
  subLabel?: string;
  content: React.ReactNode;
  isMainTab?: boolean;
  scopes?: P[];
  subLabelBold?: boolean;
  hasBreakLineName?: boolean;
}

export interface ECStepsContainerProps {
  tabs: ECTabContainerTabType[];
  stepsContainerProps?: BoxProps;
  currentStep?: number;
  selectableSteps?: boolean;
  onChangeStep?: (step: number) => void;
  showBackButton?: boolean;
  onClickBackButton?: () => void;
}

export const ECStepsContainer = ({
  tabs,
  stepsContainerProps,
  currentStep,
  selectableSteps,
  onChangeStep,
  showBackButton,
  onClickBackButton,
}: ECStepsContainerProps) => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const [value, setValue] = useState(tabs?.[0]?.value ?? '');

  const handleChange = (value: number) => {
    setValue(value);
    onChangeStep?.(value);
  };

  useEffect(() => {
    if (currentStep) {
      setValue(tabs?.[currentStep - 1]?.value);
    }
  }, [currentStep]);

  return (
    <ECBox display="flex" flexDirection="column" height="100%">
      <ECBox
        display="flex"
        width="100%"
        justifyContent="center"
        alignItems="center"
        px={2}
        pt={2}
        {...stepsContainerProps}
      >
        {showBackButton && (
          <ECBox marginRight={isMobileSize ? 0 : '140px'}>
            <ECButton
              sx={{
                minWidth: isMobileSize ? 0 : '88px',
                display: 'flex',
                justifyContent: 'space-around',
                marginRight: isMobileSize ? '16px' : 0,
              }}
              onClick={() => onClickBackButton?.()}
            >
              <ArrowBackIosOutlined color="primary" />
              {!isMobileSize && <ECTypography> Back</ECTypography>}
            </ECButton>
          </ECBox>
        )}
        {tabs.map((tab, index) => (
          <ECStepItem
            id={tab.value}
            name={tab.label}
            subLabel={tab.subLabel}
            subLabelBold={tab.subLabelBold}
            onClick={
              selectableSteps && value > tab.value ? handleChange : undefined
            }
            isSelected={value === tab.value}
            showDivider={index !== 0}
            showOnlyIsSelectedSubLabel
            disabledBackgroundColor="#181A1D"
            hasBreakLineName={tab?.hasBreakLineName}
          />
        ))}
      </ECBox>
      <ECBox display="flex" height="100%">
        {tabs?.map(
          tab =>
            value === tab.value && (
              <ECRequirePermission scopes={tab.scopes ?? []}>
                {tab.content}
              </ECRequirePermission>
            ),
        )}
      </ECBox>
    </ECBox>
  );
};
