import {
  ECTypography,
  ECMainTabBox,
  ECBox,
  ECButton,
  ECLink,
} from 'app/components';
import { history } from 'utils/history';
import SuspendedImage from './assets/suspended.svg';
import { HelpOutline, Payment } from '@mui/icons-material';

export function SuspendedPage() {
  //const navigate = history?.navigate;
  const location = history?.location as any;
  const statusCode = location?.state?.statusCode;
  return (
    <ECMainTabBox
      component="main"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <ECBox>
        <ECTypography
          variant="h1"
          fontWeight="bold"
          color={theme => theme.palette.primary.main}
        >
          {statusCode}
        </ECTypography>
      </ECBox>
      <ECBox>
        <ECTypography variant="h6">
          <ECBox
            component="img"
            // @ts-ignore-next-line
            src={SuspendedImage}
          />
        </ECTypography>
      </ECBox>
      <ECBox>
        <ECTypography variant="h3" fontWeight="bold">
          Subscription Suspended!
        </ECTypography>
      </ECBox>
      <ECBox maxWidth={560}>
        <ECTypography variant="body1" mt={4} textAlign={'center'}>
          We’re sorry, your subscription is Suspended due to Payment Issues.
          Please contact&nbsp;
          <ECLink href="https://helpcenter.ecotrak.com/en">
            Ecotrak Support
          </ECLink>
          &nbsp;to reactivate your account.
        </ECTypography>
      </ECBox>
      <ECBox>
        <ECLink href="https://helpcenter.ecotrak.com/en">
          <ECButton
            variant="contained"
            /* onClick={() =>
            isSPUser
              ? navigate?.('/panel/configuration/profile/billing')
              : navigate?.('/panel/dashboard')
          } */
            sx={{ marginTop: 4, marginBottom: 4, width: '560px' }}
            size="large"
            startIcon={<HelpOutline />}
          >
            Support Center
          </ECButton>
        </ECLink>
      </ECBox>
    </ECMainTabBox>
  );
}
