import {
  ECBox,
  ECCard,
  ECList,
  ECListItem,
  ECListItemIcon,
  ECTypography,
} from 'app/components';
import React, { useState, useEffect } from 'react';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import KitchenIcon from '@mui/icons-material/Kitchen';
import ChairAltIcon from '@mui/icons-material/ChairAlt';
import { ECToggleButton } from 'app/components/ECToggleButton';

interface IndustrialSectorProps {
  onChange?: (value: any) => void;
  value?: any | null;
}

const cookingEquipmentItems = [
  'Flat Top Grill',
  'Heat Lamp',
  'Fry Warmer',
  'Chopper',
  'Heat Lamp',
  'Bun Board',
  'Flat Top / Bun Warmer / Right Side',
  'more ...',
];
const refrigerationItems = [
  'Toppings Cooler',
  'Refrigerated Drawers',
  'Split Door Freezer',
  'Single Door Undercounter',
  'Undercounter Cooler',
  'Keg Cooler',
  'Beverage Station Ice Bin',
  'Tall Single Door Freezer',
  'more ...',
];
const otherItems = [
  'Alarms',
  'Carpet',
  'Flooring',
  'Doors',
  'Chairs',
  'Ceiling',
  'Dumpster/ Trash',
  'Exterior Signage',
  'more ...',
];

const BulletListItem = ({ text }: { text: string }) => (
  <ECListItem>
    <ECListItemIcon sx={{ minWidth: '12px' }}>
      <ECBox
        sx={{
          width: '5px',
          height: '5px',
          bgcolor: 'black',
          borderRadius: '50%',
        }}
      />
    </ECListItemIcon>
    <ECTypography variant="body2" sx={{ lineHeight: 0.7 }}>
      {text}
    </ECTypography>
  </ECListItem>
);
const sectorTypes = { Restaurant: 'Restaurant', Retail: 'Retail' };

export const IndustrialSector: React.FC<IndustrialSectorProps> = ({
  onChange,
  value,
}) => {
  const [selectedSector, setSelectedSector] = useState<string | null>(
    sectorTypes.Restaurant,
  );

  useEffect(() => {
    onChange?.(selectedSector);
  }, [selectedSector]);

  const handleSectorChange = (sector: string) => {
    setSelectedSector(sector);
  };

  return (
    <ECBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ width: '100%' }}
    >
      <ECBox
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <ECBox
          display="flex"
          justifyContent="center"
          gap={2}
          sx={{ width: '100%' }}
        >
          <ECToggleButton
            value="Restaurant"
            selected={selectedSector === sectorTypes.Restaurant}
            onClick={() => handleSectorChange(sectorTypes.Restaurant)}
            sx={{
              width: '50%',
              height: '130px',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <RestaurantIcon />
            Restaurant
          </ECToggleButton>
          <ECToggleButton
            value="Retail"
            selected={selectedSector === sectorTypes.Retail}
            onClick={() => handleSectorChange(sectorTypes.Retail)}
            sx={{
              width: '50%',
              height: '130px',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <ShoppingCartIcon />
            Retail
          </ECToggleButton>
        </ECBox>

        <ECBox
          display="flex"
          justifyContent="center"
          gap={3}
          sx={{
            width: '100%',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 2, sm: 3 },
          }}
        >
          <ECCard sx={{ width: '100%', maxWidth: { sm: '33%' } }}>
            <ECBox p={1}>
              <ECBox
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ padding: 1 }}
              >
                <ECTypography variant="h6">Cooking Equipment</ECTypography>
                <OutdoorGrillIcon color="action" sx={{ cursor: 'pointer' }} />
              </ECBox>
              <ECList
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                  overflow: 'auto',
                }}
                role="list"
              >
                {cookingEquipmentItems.map((item, index) => (
                  <BulletListItem key={index} text={item} />
                ))}
              </ECList>
            </ECBox>
          </ECCard>
          <ECCard sx={{ width: '100%', maxWidth: { sm: '33%' } }}>
            <ECBox p={1}>
              <ECBox
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ padding: 1 }}
              >
                <ECTypography variant="h6">Refrigeration</ECTypography>
                <KitchenIcon color="action" sx={{ cursor: 'pointer' }} />
              </ECBox>
              <ECList
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                  overflow: 'auto',
                }}
                role="list"
              >
                {refrigerationItems.map((item, index) => (
                  <BulletListItem key={index} text={item} />
                ))}
              </ECList>
            </ECBox>
          </ECCard>

          <ECCard sx={{ width: '100%', maxWidth: { sm: '33%' } }}>
            <ECBox p={1}>
              <ECBox
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ padding: 1 }}
              >
                <ECTypography variant="h6">Other Assets</ECTypography>
                <ChairAltIcon color="action" sx={{ cursor: 'pointer' }} />
              </ECBox>
              <ECList
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                  overflow: 'auto',
                }}
                role="list"
              >
                {otherItems.map((item, index) => (
                  <BulletListItem key={index} text={item} />
                ))}
              </ECList>
            </ECBox>
          </ECCard>
        </ECBox>
      </ECBox>
    </ECBox>
  );
};
