import { ECPaper } from 'app/components';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { useCallback, useEffect } from 'react';
import { EditForm2 } from 'app/components/ECDynamicPageTemplate/EditForm2';

const editFormElements = require('./fancy_form_config_edit.json');
const detailsFormElements = require('./fancy_form_config_details.json');

export function PaymentMethodEditDrawer({
  openPaymentMethodDrawer,
  handleClose,
  existingData,
}) {
  const useEmptyCreation = useCallback(() => {
    return [
      () => {
        handleClose();
      },
      {
        data: null,
        isError: false,
        error: null,
        isLoading: false,
        isSuccess: false,
      },
    ];
  }, [handleClose]);

  useEffect(() => {
    if (existingData) {
      const lastFourField = detailsFormElements.fields.find(
        field => field.fieldName === 'lastFour',
      );
      if (lastFourField) {
        lastFourField.value = '******************' + existingData.lastFour;
      }
    }
  }, [existingData]);

  return (
    <ECDrawerDetails
      open={openPaymentMethodDrawer}
      anchor="right"
      onClose={handleClose}
    >
      <ECPaper
        sx={{
          height: '100%',
          paddingTop: '64px',
          boxShadow: 'none',
        }}
        role="presentation"
      >
        <EditForm2
          row={existingData}
          formConfig={editFormElements.config}
          formFields={editFormElements.fields}
          detailsConfig={detailsFormElements.config}
          detailsFields={detailsFormElements.fields}
          useUpdateMutation={useEmptyCreation}
          onClose={handleClose}
        />
      </ECPaper>
    </ECDrawerDetails>
  );
}
