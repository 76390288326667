export interface AttachmentPost {
  module: string;
  id: number;
  thumbnailIndex: number;
  files: any[];
  category?: string;
  description?: string;
  isRestricted?: boolean;
  documentCoreTypeId?: number;
  name?: string;
  expiresOn?: string;
  documentRequiredId?: number;
  isCustomerOwnRequiredDocs?: boolean;
  isReqDocExpirationDate?: boolean;
}

export interface AttachmentList {
  thumbnails?: Attachment[];
  others: Attachment[];
  moduleName: string;
}

export interface Attachment {
  originalName: string;
  id: number;
  url: string;
  description: string;
  expirationDate: Date;
  fileName: string;
  fileType: string;
  isRestricted: boolean;
  documentType: AttachmentCategory;
  type: AttachmentCategory;
  attachmentType: AttachmentCategory;
  expiresOn: any;
  expires?: boolean | number;
  documentCoreType: AttachmentDocumentType;
  category: AttachmentCategory;
  documentRequiredId: number;
  moduleId?: number;
  canEdit?: boolean;
  createdByUserEmail?: string;
  createdAt?: Date;
}

export interface AttachmentListParams {
  module: string;
  moduleId: number;
  fileName?: string;
  category: AttachmentCategory;
  isCustomerRequiredDoc?: boolean;
  isSpRequiredDoc?: boolean;
}

export interface AttachmentRequiredDocsParams {
  module?: string;
  customerId: number;
  spId?: string;
}

export interface AttachmentDelete {
  module: string;
  moduleId: number;
  attachmentId: number;
  isCustomerOwnRequiredDocs?: boolean;
}

export interface AttachmentUpdate {
  module: string;
  moduleId?: number;
  attachmentId: number;
  description?: string;
  isRestricted?: boolean;
  documentCoreTypeId?: number;
  name?: string;
  isRequiredDoc?: boolean;
  expiresOn?: Date;
  isReqDocExpirationDate?: boolean;
}

export interface AttachmentDocumentType {
  code: string;
  createdBy: number;
  createdAt: string;
  updatedBy: number;
  updatedAt: string;
  id: number;
  moduleId: number;
  description?: string;
  name: string;
  private: number;
  expires: number;
  isUploaded?: boolean;
  companyId?: number;
  parentId?: number;
  isExpired?: boolean;
  default: number;
  attachment?: Attachment;
  isCustomer?: boolean;
  documentCoreTypeId?: number;
}

export enum AttachmentCategory {
  Photo = 'photo',
  Document = 'document',
  W_9 = 'W_9',
  All = 'all',
}

export enum DocumentCoreTypes {
  assetProfilePhoto = 'PROFILE_PHOTO',
  SupplierProfilePhoto = 'SUPPLIER_PROFILE_PHOTO',
}
