import {
  List,
  ListItem,
  ListItemButton,
  Paper,
  Typography,
} from '@mui/material';
import type { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { MentionSuggestion } from './mentionSuggestionOptions';
import { ECBox } from '../ECBox';

export type SuggestionListRef = {
  // For convenience using this SuggestionList from within the
  // mentionSuggestionOptions, we'll match the signature of SuggestionOptions's
  // `onKeyDown` returned in its `render` function
  onKeyDown: NonNullable<
    ReturnType<
      NonNullable<SuggestionOptions<MentionSuggestion>['render']>
    >['onKeyDown']
  >;
};

// This type is based on
// https://github.com/ueberdosis/tiptap/blob/a27c35ac8f1afc9d51f235271814702bc72f1e01/packages/extension-mention/src/mention.ts#L73-L103.
// TODO(Steven DeMartini): Use the Tiptap exported MentionNodeAttrs interface
// once https://github.com/ueberdosis/tiptap/pull/4136 is merged.
interface MentionNodeAttrs {
  id: string | null;
  label?: string | null;
}

export type SuggestionListProps = SuggestionProps<MentionSuggestion>;

const SuggestionList = forwardRef<
  SuggestionListRef,
  SuggestionListProps & { onSelect?: (user: MentionSuggestion) => void }
>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    if (index >= props.items.length) {
      // Make sure we actually have enough items to select the given index. For
      // instance, if a user presses "Enter" when there are no options, the index will
      // be 0 but there won't be any items, so just ignore the callback here
      return;
    }

    const suggestion = props.items[index];

    const mentionItem: MentionNodeAttrs = {
      id: suggestion.id,
      label: suggestion.mentionLabel,
    };
    props.command(mentionItem);

    // Trigger the onSelect callback
    props.onSelect?.(suggestion);
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length,
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  const listItemRefs = useRef<(HTMLLIElement | null)[]>([]);

  useEffect(() => {
    // Ensure the selected item is brought into view
    if (listItemRefs.current[selectedIndex]) {
      listItemRefs.current[selectedIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedIndex]);

  return props?.items?.length > 0 ? (
    <Paper elevation={5}>
      <List
        dense
        sx={{
          // In case there are contiguous stretches of long text that can't wrap:
          overflow: 'auto',
          maxHeight: 280,
        }}
      >
        {props?.items?.map((item, index) => (
          <ListItem
            key={item.id}
            disablePadding
            ref={el => (listItemRefs.current[index] = el)}
          >
            <ListItemButton
              selected={index === selectedIndex}
              onClick={() => {
                selectItem(index);
              }}
            >
              <ECBox display="flex" flexDirection="column">
                <Typography variant="body1">{item.mentionLabel}</Typography>
                {item?.jobTitle && (
                  <Typography
                    variant="caption"
                    mt={'2px'}
                    sx={{ opacity: '0.5' }}
                  >
                    {item.jobTitle}
                  </Typography>
                )}
              </ECBox>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Paper>
  ) : null;
});

SuggestionList.displayName = 'SuggestionList';

export default SuggestionList;
