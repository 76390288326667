import { VisibilityOff, Visibility } from '@mui/icons-material';
import {
  InputLabel,
  FormHelperText,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import {
  ECFormControl,
  ECIconButton,
  ECTextField,
  FieldTypes,
} from 'app/components';

interface Props extends Omit<TextFieldProps, 'onChange'> {
  fieldName: string;
  sx?: any;
  inputProps?: any;
  error?: boolean;
  onChange?: (value: any) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  value: any;
  helperText?: string | null;
  isNumber?: boolean;
  validationMessage?: string;
}

export const CreditCardCVVField = ({
  label,
  fieldName,
  type,
  inputProps,
  error,
  helperText,
  sx,
  value: valueFromProps,
  onChange,
  onBlur,
  validationMessage: validationMessageProps,
}: Props) => {
  const [value, setValue] = useState(valueFromProps);
  const [showPassword, setShowPassword] = useState(false);

  const [validationMessage, setValidationMessage] = useState<
    string | null | undefined
  >(helperText || validationMessageProps);

  useEffect(() => {
    setValue(valueFromProps);
  }, [valueFromProps]);

  useEffect(() => {
    setValidationMessage(validationMessageProps);
  }, [validationMessageProps]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = event.target.value.replace(/\D/g, '').trim();

      if (inputValue.length > 4) {
        inputValue = inputValue.slice(0, 4);
      }

      onChange && onChange(inputValue);
      setValue(inputValue);

      if (inputValue.length < 3 || inputValue.length > 4) {
        setValidationMessage('CVV is incorrect');
      } else {
        setValidationMessage(null);
      }
    },
    [onChange],
  );

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(event);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <ECFormControl variant="filled" sx={sx}>
      <ECTextField
        {...inputProps}
        variant="filled"
        inputMode="numeric"
        label={label}
        id={fieldName}
        name={fieldName}
        type={showPassword ? 'text' : 'password'}
        value={value?.toString()}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!validationMessage || !!helperText}
        helperText={validationMessage || helperText}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
        InputLabelProps={value ? { shrink: true } : {}}
        InputProps={{
          ...inputProps,
          endAdornment: (
            <InputAdornment position="end">
              <ECIconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </ECIconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
        }}
      />

      {error && helperText && (
        <FormHelperText
          sx={theme => ({
            bgcolor: 'transparent',
            color: theme.palette.error.main,
          })}
        >
          {helperText}
        </FormHelperText>
      )}
    </ECFormControl>
  );
};
