export const MasterCard = () => {
  return (
    <svg
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="0.5"
        width="34"
        height="23"
        rx="3.5"
        fill="white"
        stroke="#D9D9D9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.25 17.1569C17.0584 18.1852 15.5128 18.806 13.8238 18.806C10.0551 18.806 7 15.7154 7 11.903C7 8.09057 10.0551 5 13.8238 5C15.5128 5 17.0584 5.62075 18.25 6.64903C19.4416 5.62075 20.9872 5 22.6762 5C26.4449 5 29.5 8.09057 29.5 11.903C29.5 15.7154 26.4449 18.806 22.6762 18.806C20.9872 18.806 19.4416 18.1852 18.25 17.1569Z"
        fill="#ED0006"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.25 17.1569C19.7172 15.8908 20.6475 14.0068 20.6475 11.903C20.6475 9.79917 19.7172 7.91517 18.25 6.64903C19.4416 5.62075 20.9872 5 22.6762 5C26.4449 5 29.5 8.09057 29.5 11.903C29.5 15.7154 26.4449 18.806 22.6762 18.806C20.9872 18.806 19.4416 18.1852 18.25 17.1569Z"
        fill="#F9A000"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.25 6.64917C19.7172 7.9153 20.6475 9.79929 20.6475 11.9031C20.6475 14.0069 19.7172 15.8909 18.25 17.157C16.7828 15.8909 15.8525 14.0069 15.8525 11.9031C15.8525 9.79929 16.7828 7.9153 18.25 6.64917Z"
        fill="#FF5E00"
      />
    </svg>
  );
};
