import { useEffect, useMemo } from 'react';
import { ECEasyFormFieldType, ECWorkflowTemplate } from 'app/components';
import { useTranslation } from 'react-i18next';
import { SummaryFieldTypes } from 'app/components/ECWorkflowSummaryField';
import { useGetProfileQuery } from 'services/profileApi';
import { useDispatch } from 'react-redux';
import { setTitle } from 'store/slice/page';
import { Outlet } from 'react-router';
import { P } from 'types/Permission';

const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const formElementsDetails = require('./fancy_form_config_details.json');

const editFormFields = fancyFormElementsEdit.data.fields;

export function CompanyProfileDetailsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: companyDetails } = useGetProfileQuery();

  useEffect(() => {
    dispatch(setTitle(t('translation:panelDrawer.profile')));
  }, [dispatch]);

  const handleOutputChange = (
    output: ECEasyFormFieldType[],
    fieldName?: string,
  ) => {
    const addressCountry = output?.find(
      data => data?.fieldName === 'address.countryCode',
    )?.value;
    const billingCountry = output?.find(
      data => data?.fieldName === 'billingAddress.countryCode',
    )?.value;

    if (addressCountry?.value?.toLowerCase() === 'jm') {
      const editZipCodeField = editFormFields?.find(
        field => field.fieldName === 'address.zipCodePostalCode',
      );
      editZipCodeField.required = false;
    } else {
      const editZipCodeField = editFormFields?.find(
        field => field.fieldName === 'address.zipCodePostalCode',
      );
      editZipCodeField.required = false;
    }

    if (billingCountry?.value?.toLowerCase() === 'jm') {
      const editZipCodeField = editFormFields?.find(
        field => field.fieldName === 'billingAddress.zipCodePostalCode',
      );
      editZipCodeField.required = false;
    } else {
      const editZipCodeField = editFormFields?.find(
        field => field.fieldName === 'billingAddress.zipCodePostalCode',
      );
      editZipCodeField.required = false;
    }
  };
  const additionalTabs = useMemo(() => {
    return [
      {
        value: 'billing',
        label: 'billing',
        //scopes: [P.GetWorkOrder], permission not specicify in the BE
        content: <Outlet />,
        link: 'billing',
        editable: false,
        visible: true,
      },
    ];
  }, []);

  return companyDetails ? (
    <>
      <ECWorkflowTemplate
        tabsAsLinks
        title={t('translation:workflow.sidebar.company')}
        summaryData={[
          {
            id: 'name',
            label: 'Name',
            data: companyDetails?.name,
            type: SummaryFieldTypes.Text,
          },
          {
            id: 'dba',
            label: 'DBA',
            data: companyDetails?.dba,
            type: SummaryFieldTypes.Text,
          },
          {
            id: 'industry',
            label: 'Industry',
            data: companyDetails?.industry?.name,
            type: SummaryFieldTypes.Text,
          },
          {
            id: 'federalTaxClassification',
            label: 'Federal Tax Classification',
            data: companyDetails?.federalTaxClassification?.name,
            type: SummaryFieldTypes.Text,
          },
          // this field should only be visible if it's edit mode
          // since edit is disabled in this page, I'll leave it commented
          /* {
            id: 'fein',
            label: 'FEIN',
            data: companyDetails?.fein,
            type: SummaryFieldTypes.Text,
          }, */
          {
            id: 'ecotrakId',
            label: 'Ecotrak ID',
            data: companyDetails?.id,
            type: SummaryFieldTypes.Text,
          },
        ]}
        editConfig={fancyFormElementsEdit.data.config}
        editFields={fancyFormElementsEdit.data.fields}
        detailsConfig={formElementsDetails.data.config}
        detailsFields={formElementsDetails.data.fields}
        detailsData={companyDetails}
        imgSrc={companyDetails?.logo}
        showLogoOnError={true}
        hideNoteTab={true}
        hideActivityTab={true}
        isEditAllowed={false}
        onChange={handleOutputChange}
        additionalTabs={additionalTabs}
      />
    </>
  ) : null;
}
